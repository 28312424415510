import {Select as CommonSelect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Select from '../../../Select';
import Action from '../../../Action';
import {
	getLayerOpacity,
	getLayerOptions,
	getPreviousMapLayerSettings,
} from '../helpers';

function onFilterChange(
	componentKey,
	layerTemplatesComponentKey,
	mapKey,
	componentId
) {
	return (dispatch, getState) => {
		const state = getState();
		const component = Select.components.getByComponentKey(state, componentKey);

		const activeLayerTemplateKey =
			Select.cure.applicationStories.ap07.getActiveLayerTemplateKey(
				state,
				layerTemplatesComponentKey,
				mapKey
			);

		const tags = Select.cure.applicationStories.ap07.getBaseTags(
			state,
			componentKey
		);

		const activeTags = tags.map(tagKey => {
			return Select.cure.applicationStories.ap07.getActiveTagKey(
				state,
				componentKey,
				tagKey,
				mapKey
			);
		});

		//remove all layers from map
		const previousLayerSettings = getPreviousMapLayerSettings(state, mapKey);
		dispatch(Action.maps.removeAllMapLayers(mapKey));

		if (tags.length > 0 && activeTags.every(i => i)) {
			//get proper period and case
			const activeCaseTagKeys = Object.values(component.tagKeys)
				.filter(t => t.for === 'case')
				.map(t => t.active[mapKey]);

			const activeTagKeyForLayerName =
				component.tagKeys['02eac4aa-e4bc-407e-b864-4cd949c4f474'].active[
					mapKey
				];

			const promises = [];

			const casesParams = [
				{},
				{tagKeys: {in: activeCaseTagKeys}},
				null,
				1,
				100,
			];

			const tagsParams = [
				{},
				{key: {in: [activeTagKeyForLayerName]}},
				null,
				1,
				100,
			];

			const casesRequest = dispatch(
				Action.cases.useIndexed(...casesParams, componentId)
			);

			const tagsRequest = dispatch(
				Action.tags.useIndexed(...tagsParams, componentId)
			);

			promises.push(casesRequest, tagsRequest);

			return Promise.all(promises).then(() => {
				const cases = Select.cases.getIndexed(getState(), ...casesParams);
				const tags = Select.tags.getIndexed(getState(), ...tagsParams);
				const caseByKeys = cases.find(c =>
					c.data.tagKeys.every(t => activeCaseTagKeys.includes(t))
				);

				const caseKey = caseByKeys.key;

				const key = utils.uuid();
				dispatch(
					Action.maps.addMapLayerToIndex(
						mapKey,
						{
							key: key,
							layerKey: `${key}_${activeLayerTemplateKey}`,
							filterByActive: {
								place: true,
							},
							layerTemplateKey: activeLayerTemplateKey,
							metadataModifiers: {
								caseKey,
							},
							options: {
								...getLayerOptions(previousLayerSettings),
								name: tags[0].data.nameDisplay,
							},
							opacity: getLayerOpacity(previousLayerSettings),
						},
						1
					)
				);
			});
		}
	};
}

function setActiveTag(componentKey, parentTag, activeTag, mapKey) {
	return (dispatch, getState) => {
		const state = getState();
		const componentState = Select.components.getByComponentKey(
			state,
			componentKey
		);
		const updatedComponentState = {
			...componentState,
			tagKeys: {
				...componentState.tagKeys,
				[parentTag]: {
					...componentState.tagKeys[parentTag],
					active: {
						...componentState.tagKeys[parentTag].active,
						[mapKey]: activeTag,
					},
				},
			},
		};
		dispatch(Action.components.update(componentKey, updatedComponentState));
	};
}

function setActiveLayerTemplate(componentKey, activeLayerTemplate, mapKey) {
	return (dispatch, getState) => {
		const state = getState();
		const componentState = Select.components.getByComponentKey(
			state,
			componentKey
		);
		const updatedComponentState = {
			...componentState,
			active: {
				...componentState.active,
				[mapKey]: activeLayerTemplate,
			},
		};
		dispatch(Action.components.update(componentKey, updatedComponentState));
	};
}

function setLayerTemplateToMaps(
	mapSetKey,
	layerTemplatesComponentKey,
	layerTemplateKey,
	tagsComponentKey,
	componentId
) {
	return (dispatch, getState) => {
		const state = getState();

		const mapKeys = CommonSelect.maps.getMapSetMapKeys(state, mapSetKey);

		mapKeys.forEach(mapKey => {
			dispatch(
				Action.cure.applicationStories.ap07.setActiveLayerTemplate(
					layerTemplatesComponentKey,
					layerTemplateKey,
					mapKey
				)
			);

			dispatch(
				onFilterChange(
					tagsComponentKey,
					layerTemplatesComponentKey,
					mapKey,
					componentId
				)
			);
		});
	};
}

export default {
	setActiveTag,
	onFilterChange,
	setActiveLayerTemplate,
	setLayerTemplateToMaps,
};
