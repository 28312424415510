import PropTypes from 'prop-types';
import {useEffect} from 'react';
import AppThumbnail from '../../../../common/AppThumbnail';
import AppPreview from './AppPreview';
import {NothingFiltered} from '@gisatcz/visat-components';
import AppPreviewImage from './AppPreview/components/AppPreviewImage';
import AppPreviewBody from './AppPreview/components/AppPreviewBody';
import AppPreviewTitle from './AppPreview/components/AppPreviewTitle';
import AppPreviewDescription from './AppPreview/components/AppPreviewDescription';
import AppPreviewTags from './AppPreview/components/AppPreviewTags';
import AppPreviewTools from './AppPreview/components/AppPreviewTools';
import './style.scss';

const AppList = ({applicationStories, onMount, onUnmount}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	let content;
	if (applicationStories) {
		if (applicationStories.length) {
			content = (
				<div className="cure-AppList">
					{applicationStories &&
						applicationStories.map((app, index) => {
							const {key, data} = app;
							const {nameDisplay, description, configuration, tagKeys} = data;

							return (
								<AppPreview key={key} right={index % 2 === 1}>
									<AppPreviewImage>
										<AppThumbnail
											imageKey={configuration?.image?.key}
											imageAlt={configuration?.image?.alt}
										/>
									</AppPreviewImage>
									<AppPreviewBody>
										<AppPreviewTitle fullName={configuration?.appFullName}>
											{nameDisplay}
										</AppPreviewTitle>
										<AppPreviewTags
											tagKeys={configuration?.tagKeys || tagKeys}
										/>
										<AppPreviewDescription>{description}</AppPreviewDescription>
										<AppPreviewTools appKey={key} />
									</AppPreviewBody>
								</AppPreview>
							);
						})}
				</div>
			);
		} else {
			content = (
				<NothingFiltered message="None of the apps match filtering parameters" />
			);
		}
	} else {
		content = null;
	}

	return content;
};

AppList.propTypes = {applicationStories: PropTypes.array};

export default AppList;
