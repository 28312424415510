import {connect, setRecomputeState} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Actions from '../../../../state/Action';

import Presentation from './presentation';
import {utils} from '@gisatcz/ptr-utils';

const mapStateToProps = (state, ownProps) => {
	setRecomputeState(state);
	const configuration =
		Select.cure.applicationStories.getConfigForActiveApplicationStory(state);
	const wgbtTagKey = configuration.wgbtTagKeyC;
	const layerTemplatesComponentKey = 'ap08-layertemplates-c';
	const displayOverlay =
		Select.cure.applicationStories.ap08.getDisplayMapOverlayConfiguration(
			state,
			ownProps.mapSetKey,
			wgbtTagKey,
			layerTemplatesComponentKey
		);

	const layerTemplateKeysInMaps =
		Select.cure.applicationStories.ap08.getLayerTemplateKeysInMaps(
			state,
			layerTemplatesComponentKey
		);
	const noDataForCurrentPlace =
		Select.cure.applicationStories.isDataForCurrentPlaceAvailable(
			state,
			'ap08-urban-thermal-comfort',
			'App8_ShowcaseA' // there is only showcase A available in config (available places are the same)
		);

	return {
		tagsComponentKey: configuration.tagsComponentKeyC,
		layerTemplatesComponentKey: layerTemplatesComponentKey,
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, ownProps.mapSetKey),
		displayOverlay,
		wgbtTagKey,
		layerTemplateKeysInMaps,
		noDataForCurrentPlace,
	};
};

const mapDispatchToProps = () => {
	const componentId = `TagsParamsSelect_${utils.uuid()}`;
	return dispatch => {
		return {
			onActiveFilterChange: (
				tagsComponentKey,
				layerTemplatesComponentKey,
				mapKey,
				wgbtTagKey,
				layerTemplateKey
			) => {
				if (layerTemplateKey) {
					dispatch(
						Actions.cure.applicationStories.ap08.setActiveLayerTemplate(
							layerTemplatesComponentKey,
							layerTemplateKey,
							mapKey
						)
					);
				}
				dispatch(
					Actions.cure.applicationStories.ap08.onFilterChange(
						tagsComponentKey,
						layerTemplatesComponentKey,
						mapKey,
						componentId,
						wgbtTagKey
					)
				);
			},
			onUnmount: () => {
				dispatch(Actions.cases.useIndexedClear(componentId));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
