import PropTypes from 'prop-types';
import {zones} from '../../enumerations';

const RasterTooltipContent = ({response}) => {
	const zone = Math.round(Number.parseFloat(response.value_list) * 10) / 10;
	return (
		<div className="ptr-FeatureTooltip-body">
			<div>
				{zones[zone] ? <b>{`${zones[zone]}`}</b> : <b>{`Zone: ${zone}`}</b>}
			</div>
		</div>
	);
};

RasterTooltipContent.propTypes = {
	response: PropTypes.object,
	mapKey: PropTypes.string,
	layerKey: PropTypes.string,
};

export default RasterTooltipContent;
