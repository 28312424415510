import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';
import Action from '../../../../../state/Action';

const componentId = 'StyleBasedLegend';

const mapStateToProps = (state, ownProps) => {
	let styleKey;
	if (ownProps.styleKey) {
		styleKey = ownProps.styleKey;
	} else {
		styleKey = Select.cure.applicationStories.getStyleKeyForLegend(
			state,
			ownProps.applicationStoryKey,
			ownProps.showcaseKey,
			ownProps.layerKey,
			ownProps.layerTemplateKey
		);
	}

	let style;
	if (ownProps.style) {
		style = ownProps.style;
	} else {
		style = Select.cure.applicationStories.getPreparedStylesForLegend(
			state,
			ownProps.applicationStoryKey,
			ownProps.showcaseKey,
			ownProps.layerKey,
			ownProps.layerTemplateKey
		);
	}

	return {
		styleKey,
		baseStyle: style?.baseStyle,
		attributeStyle: style?.attributeStyle,
		attributeMetadata: style?.attributeStyle?.attributeKey
			? Select.attributes.getByKey(state, style?.attributeStyle?.attributeKey)
			: null,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAttributeChange: attributeKey => {
			dispatch(Action.attributes.useKeys([attributeKey], componentId));
		},
		onStyleChange: styleKey => {
			dispatch(Action.styles.useKeys([styleKey], componentId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
