import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../../state/Select';
import Action from '../../../../../../state/Action';

const mapStateToProps = state => {
	const sidePanelisOpen = Select.components.get(
		state,
		'CityDemonstrations',
		'sidePanelisOpen'
	);
	return {
		sidePanelisOpen,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onPanelControl: value => {
			dispatch(
				Action.components.set('CityDemonstrations', 'sidePanelisOpen', value)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
