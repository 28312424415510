import {useEffect} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import AppStoryHeaderSelect from '../AppStoryHeaderSelect';
import './style.scss';

const PlaceSelect = ({
	activePlaceKey,
	places,
	onActivePlaceChange,
	onMount,
	onUnmount,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	const classes = classNames('cure-PlaceSelect', {});

	const options = places?.map(place => {
		return {value: place.key, label: place.data.nameDisplay};
	});
	const value = options?.find(option => option.value === activePlaceKey);

	const setActivePlaceKey = place => {
		onActivePlaceChange(place.value);
	};

	return options?.length ? (
		<>
			<span className="cure-ApplicationStoryNav-divider">/</span>
			<div className={classes}>
				<AppStoryHeaderSelect
					options={options}
					value={value}
					menuPosition="fixed"
					onChange={setActivePlaceKey}
				/>
			</div>
		</>
	) : null;
};

PlaceSelect.propTypes = {
	activePlaceKey: PropTypes.string,
	centered: PropTypes.bool,
	places: PropTypes.array,
	onActivePlaceChange: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default PlaceSelect;
