import PropTypes from 'prop-types';
import {ResponsivePie} from '@nivo/pie';

import './style.scss';

const App10_ShowcaseD = () => {
	return (
		<div className="cure-App11_ShowcaseD">
			<ResponsivePie
				data={[
					{
						id: 'total_max_green_roof_pot',
						label: '% of total area with green roof potential (max)',
						value: 20.49,
						color: 'hsl(118, 51%, 41%)',
					},
					{
						id: 'total_no_green_roof_pot',
						label: '% of total area with no green roof potential',
						value: 79.51,
						color: 'hsl(58, 0%, 76%)',
					},
				]}
				colors={{datum: 'data.color'}}
				margin={{top: 10, right: 40, bottom: 60, left: 40}}
				enableArcLinkLabels={false}
				isInteractive={true}
				activeOuterRadiusOffset={5}
				animate={true}
				padAngle={3}
				innerRadius={0.3}
				transitionMode="startAngle"
				tooltip={props => {
					return (
						<div
							className="cure-BarChart-tooltip"
							style={{background: 'rgb(41, 41, 41)', color: props.datum.color}}
						>
							{props.datum.label}: {props.datum.value}%
						</div>
					);
				}}
				legends={[
					{
						anchor: 'bottom',
						direction: 'column',
						justify: false,
						translateX: -120,
						translateY: 60,
						itemsSpacing: 5,
						itemWidth: 100,
						itemHeight: 18,
						itemDirection: 'left-to-right',
						itemOpacity: 1,
						symbolSize: 18,
						symbolShape: 'circle',
					},
				]}
			/>
		</div>
	);
};

App10_ShowcaseD.propTypes = {
	datum: PropTypes.object,
	color: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.number,
};

export default App10_ShowcaseD;
