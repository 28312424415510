import {createSelector} from 'reselect';
import {
	compact as _compact,
	filter as _filter,
	find as _find,
	flatten as _flatten,
	forIn as _forIn,
	difference as _difference,
	includes as _includes,
	isEmpty as _isEmpty,
	uniq as _uniq,
} from 'lodash';
import {
	Select as CommonSelect,
	createRecomputeObserver,
	createRecomputeSelector,
} from '@gisatcz/ptr-state';

import {
	placesFilter,
	timelineLayerElementHeight,
	timelineLayerLineHeight,
} from '../../../constants/app';

import RouterSelectors from '../../router/selectors';
import productFilterSelectors from '../productFilter/selectors';

import ap01 from './ap01/selectors';
import ap02 from './ap02/selectors';
import ap04 from './ap04/selectors';
import ap07 from './ap07/selectors';
import ap08 from './ap08/selectors';

/**
 * Get configuration for active/current story
 * @param state {Object}
 * @return {Object|null}
 */
const getConfigForActiveApplicationStory = createSelector(
	[
		state =>
			CommonSelect.app.getConfiguration(state, 'configByApplicationStoryKey'),
		state => RouterSelectors.getStory(state),
	],
	(configs, storyKey) => {
		return configs?.[storyKey] || null;
	}
);

const getRasterLegend = createSelector(
	[
		getConfigForActiveApplicationStory,
		CommonSelect.places.getActiveKey,
		(state, activeCaseKey) => activeCaseKey,
	],
	(storyConfig, activePlaceKey, activeCaseKey) => {
		if (storyConfig && activePlaceKey && activeCaseKey) {
			return storyConfig.showcases[activeCaseKey].data.legend[activePlaceKey];
		} else {
			return null;
		}
	}
);

/**
 * Get available for active/current story
 * @param state {Object}
 * @return {Object|null}
 */
const getAvailablePlacesForActiveApplicationStory = createSelector(
	[getConfigForActiveApplicationStory],
	storyConfig => {
		return storyConfig?.placeKeys;
	}
);

const getForCurrentFilter = createSelector(
	[RouterSelectors.getTags, CommonSelect.scopes.getIndexed],
	(activeFilterTagKeys, applications) => {
		if (!activeFilterTagKeys?.length) {
			return applications;
		} else {
			return _filter(
				applications,
				application =>
					_difference(activeFilterTagKeys, application.data?.tagKeys)
						?.length === 0
			);
		}
	}
);

const getForCurrentFilterOrdered = createSelector(
	[
		getForCurrentFilter,
		state => CommonSelect.app.getConfiguration(state, 'scopes.order'),
	],
	(applications, scopeKeys) => {
		if (scopeKeys) {
			if (applications) {
				return _compact(
					scopeKeys.map(key => _find(applications, app => app.key === key))
				);
			} else {
				return null;
			}
		} else {
			return applications;
		}
	}
);

const getAvailableTags = createSelector(
	[CommonSelect.tags.getAllAsObject, CommonSelect.scopes.getAll],
	(tags, applications) => {
		if (tags && applications) {
			const tagKeys = _uniq(
				_flatten(applications.map(app => app.data.tagKeys))
			);
			if (tagKeys?.length) {
				return tagKeys.map(tagKey => tags[tagKey]);
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

/**
 * Get product tags grouped by category
 * @param state {Object}
 * @return {Array | null}
 */
const getProductTagsGroupedByCategory = createSelector(
	[productFilterSelectors.getCategories, getAvailableTags],
	(categoryTags, productTags) => {
		if (categoryTags && productTags) {
			let tagsGroupedByCategory = {};

			categoryTags.forEach(categoryTag => {
				const {key, data} = categoryTag;
				if (!tagsGroupedByCategory[key]) {
					tagsGroupedByCategory[key] = {
						key,
						data,
						tags: [],
					};
				}

				productTags.forEach(productTag => {
					if (_includes(productTag.data.tagKeys, categoryTag.key)) {
						tagsGroupedByCategory[key].tags.push(productTag);
					}
				});

				if (!tagsGroupedByCategory[key].tags.length) {
					delete tagsGroupedByCategory[key];
				}
			});

			if (_isEmpty(tagsGroupedByCategory)) {
				return null;
			} else {
				return Object.values(tagsGroupedByCategory);
			}
		} else {
			return null;
		}
	}
);

const getApplicationStoryKeyByScopeKey = createSelector(
	[
		state =>
			CommonSelect.app.getConfiguration(state, 'configByApplicationStoryKey'),
		(state, scopeKey) => scopeKey,
	],
	(configuration, scopeKey) => {
		if (configuration && scopeKey) {
			let applicationStoryKey = null;
			_forIn(configuration, (config, key) => {
				if (config.scopeKey === scopeKey) {
					applicationStoryKey = key;
				}
			});
			return applicationStoryKey;
		} else {
			return null;
		}
	}
);

const getScopeKeyByApplicationStoryKey = createSelector(
	[
		state =>
			CommonSelect.app.getConfiguration(state, 'configByApplicationStoryKey'),
		(state, applicationStoryKey) => applicationStoryKey,
	],
	(configuration, applicationStoryKey) => {
		return configuration?.[applicationStoryKey]?.scopeKey || null;
	}
);

const getConfigByApplicationStoryKey = createSelector(
	[
		state =>
			CommonSelect.app.getConfiguration(state, 'configByApplicationStoryKey'),
		(state, applicationStoryKey) => applicationStoryKey,
	],
	(configuration, applicationStoryKey) => {
		return configuration?.[applicationStoryKey] || null;
	}
);

const getActiveScopeByApplicationStoryKey = createSelector(
	[getScopeKeyByApplicationStoryKey, CommonSelect.scopes.getAllAsObject],
	(scopeKey, scopes) => {
		return scopes?.[scopeKey] || null;
	}
);

const timelineLayersKey = 'timelineLayers';
const timelinePeriodKey = 'timelinePeriod';

const defaultTimelineRow = {
	lineHeight: timelineLayerLineHeight,
	elementHeight: timelineLayerElementHeight,
	legend: {
		// layerTemplateKey,
	},
	items: [],
	controlMapState: false,
};

const defaultTimelineRowItemColors = {
	basic: '#98dbcc',
	active: 'var(--accent50)',
};

const darkTimelineRowItemColors = {
	basic: '#255951',
	active: 'var(--accent50)',
};

const defaultTimelineRowItem = {
	periods: {
		// layerTemplateKey
		filterByActive: {
			application: true,
		},
	},
	// mapZIndex: 2,
	states: ['basic', 'active', 'hover', 'disabled'],
	activeStates: ['basic'],
	layerState: {
		// layerTemplateKey,
		filterByActive: {
			application: true,
		},
	},
};

const defaultTimelinePeriod = {
	start: '2019-01-02',
	end: '2021-01-01',
};

const getTimelineItem = (timelineComponentItem, darkMode) => {
	const merged = {
		...defaultTimelineRowItem,
		...timelineComponentItem,
		colors: darkMode ? darkTimelineRowItemColors : defaultTimelineRowItemColors,
	};
	return merged;
};

const getTimelineRow = (timelineComponentsLayer, darkMode) => {
	const merged = {
		...defaultTimelineRow,
		...timelineComponentsLayer,
	};

	merged.items = merged.items.map(item => getTimelineItem(item, darkMode));

	return merged;
};

/**
 * Get timeline layers configurations
 * @return {Array}
 */
const getTimelineLayers = createSelector(
	[
		(state, timelineComponentKey) => timelineComponentKey,
		CommonSelect.components.getSubstate,
		RouterSelectors.getDarkModeActive,
	],
	(timelineComponentKey, components, darkMode) => {
		const timelineComponentsLayers =
			components?.[timelineComponentKey]?.[timelineLayersKey] || [];

		return timelineComponentsLayers.map(layer =>
			getTimelineRow(layer, darkMode)
		);
	}
);

/**
 * Get timeline period extent
 * @return {Object}
 */
const getTimelinePeriod = createSelector(
	[
		(state, timelineComponentKey) => timelineComponentKey,
		CommonSelect.components.getSubstate,
	],
	(timelineComponentKey, components) => {
		return (
			components?.[timelineComponentKey]?.[timelinePeriodKey] ||
			defaultTimelinePeriod
		);
	}
);

const getApplicationStoriesForPlace = createSelector(
	[
		(state, placeKey) => placeKey,
		state =>
			CommonSelect.app.getConfiguration(state, 'configByApplicationStoryKey'),
		CommonSelect.scopes.getAllAsObject,
	],
	(placeKey, config, scopes) => {
		if (scopes) {
			const applicationStories = [];
			_forIn(config, (data, applicationStoryKey) => {
				const {placeKeys, scopeKey} = data;
				if (placeKeys && placeKeys.includes(placeKey)) {
					const scope = scopes[scopeKey];
					applicationStories.push({
						applicationStoryKey,
						name: scope?.data?.nameDisplay,
					});
				}
			});

			return applicationStories;
		} else {
			return null;
		}
	}
);

const getPossibleApplicationStoriesForPlace = createSelector(
	[
		(state, placeKey) => placeKey,
		state =>
			CommonSelect.app.getConfiguration(state, 'configByApplicationStoryKey'),
		CommonSelect.scopes.getAllAsObject,
	],
	(placeKey, config, scopes) => {
		if (scopes) {
			const applicationStories = [];
			_forIn(config, (data, applicationStoryKey) => {
				const {possiblePlaceKeys, scopeKey} = data;
				if (possiblePlaceKeys && possiblePlaceKeys.includes(placeKey)) {
					const scope = scopes[scopeKey];
					applicationStories.push({
						applicationStoryKey,
						name: scope?.data?.nameDisplay,
					});
				}
			});

			return applicationStories;
		} else {
			return null;
		}
	}
);

const getPeriodByKeyObserver = createRecomputeObserver((state, periodKey) =>
	CommonSelect.periods.getByKey(state, periodKey)
);

const getLayerStateByKeyObserver = createRecomputeObserver(
	(state, [mapKey, layerKey]) =>
		CommonSelect.maps.getMapLayerStateByMapKeyAndLayerKey(
			state,
			mapKey,
			layerKey
		)
);

const getLayerTemplateByKeyObserver = createRecomputeObserver(
	(state, layerTemplateKey) =>
		CommonSelect.layerTemplates.getByKey(state, layerTemplateKey)
);

const getCaseBActiveLayerKey = createRecomputeSelector(mapKey => {
	const layers = CommonSelect.maps.getLayersStateByMapKeyObserver(mapKey);
	const firstAndOnlyLayer = layers[0];
	return firstAndOnlyLayer.key;
});

const getCaseBActiveLayerOpacity = createRecomputeSelector(mapKey => {
	const layers = CommonSelect.maps.getLayersStateByMapKeyObserver(mapKey);
	const firstAndOnlyLayer = layers[0];

	const layerState = getLayerStateByKeyObserver([
		mapKey,
		firstAndOnlyLayer.key,
	]);
	return layerState?.opacity >= 0 ? layerState?.opacity * 100 : 100;
});

const getCaseBActiveLayerDate = createRecomputeSelector(mapKey => {
	const layers = CommonSelect.maps.getLayersStateByMapKeyObserver(mapKey);
	const firstAndOnlyLayer = layers[0];
	const period = getPeriodByKeyObserver(
		firstAndOnlyLayer.metadataModifiers.periodKey
	);
	const time = period?.data?.nameDisplay || ' T ';

	return time.split('T')[0];
});

const getCaseBActiveLayerType = createRecomputeSelector(mapKey => {
	const layers = CommonSelect.maps.getLayersStateByMapKeyObserver(mapKey);
	const firstAndOnlyLayer = layers[0];

	const layerTemplate = getLayerTemplateByKeyObserver(
		firstAndOnlyLayer.layerTemplateKey
	);

	return layerTemplate?.data?.nameDisplay;
});

const getPlacesForActiveStory = createSelector(
	[
		state =>
			CommonSelect.places.getIndexed(
				state,
				placesFilter.filterByActive,
				placesFilter.filter,
				placesFilter.order,
				placesFilter.start,
				placesFilter.length
			),
		getAvailablePlacesForActiveApplicationStory,
	],
	(places, placeKeys) => {
		if (places?.length && placeKeys) {
			return places.filter(place => placeKeys.includes(place.key));
		} else {
			return null;
		}
	}
);

const isDataForCurrentPlaceAvailable = createSelector(
	[
		(state, appKey, showcaseKey) =>
			CommonSelect.app.getConfiguration(
				state,
				`configByApplicationStoryKey.${appKey}.showcases.${showcaseKey}`
			),
		CommonSelect.places.getActiveKey,
	],
	(showcaseConfig, activePlaceKey) => {
		if (showcaseConfig?.noDataPlacesKeys && activePlaceKey) {
			return showcaseConfig?.noDataPlacesKeys.includes(activePlaceKey);
		} else {
			return false;
		}
	}
);

const getStyleKeyForLegend = createSelector(
	[
		CommonSelect.places.getActiveKey,
		(state, applicationStoryKey, showcaseKey) =>
			CommonSelect.app.getConfiguration(
				state,
				`configByApplicationStoryKey.${applicationStoryKey}.showcases.${showcaseKey}.data.legend`
			),
		(state, applicationStoryKey, showcaseKey, layerKey) => layerKey,
		(state, applicationStoryKey, showcaseKey, layerKey, layerTemplateKey) =>
			layerTemplateKey,
	],
	(activePlaceKey, config, layerKey, layerTemplateKey) => {
		if (activePlaceKey && config && (layerTemplateKey || layerKey)) {
			const legendForLayerKey = config[activePlaceKey]?.[layerKey]?.styleKey;
			const legendForLayerTemplateKey =
				config[activePlaceKey]?.[layerTemplateKey]?.styleKey;
			return legendForLayerKey || legendForLayerTemplateKey || null;
		} else {
			return null;
		}
	}
);

const getStyleKeyForLegendByKey = createSelector(
	[
		CommonSelect.places.getActiveKey,
		(state, applicationStoryKey, showcaseKey) =>
			CommonSelect.app.getConfiguration(
				state,
				`configByApplicationStoryKey.${applicationStoryKey}.showcases.${showcaseKey}.data.legend`
			),
		(state, applicationStoryKey, showcaseKey, layerKey) => layerKey,
		(state, applicationStoryKey, showcaseKey, layerKey, layerTemplateKey) =>
			layerTemplateKey,
		(
			state,
			applicationStoryKey,
			showcaseKey,
			layerKey,
			layerTemplateKey,
			key
		) => key,
	],
	(activePlaceKey, config, layerKey, layerTemplateKey, key) => {
		if (activePlaceKey && config && (layerTemplateKey || layerKey) && key) {
			const legendForLayerKey =
				config[activePlaceKey]?.[layerKey]?.[key]?.styleKey;
			const legendForLayerTemplateKey =
				config[activePlaceKey]?.[layerTemplateKey]?.[key]?.styleKey;
			return legendForLayerKey || legendForLayerTemplateKey || null;
		} else {
			return null;
		}
	}
);

const getStyleForLegend = createSelector(
	[CommonSelect.styles.getAllAsObject, getStyleKeyForLegend],
	(styles, styleKey) => {
		return styles?.[styleKey] || null;
	}
);

const getStyleForLegendByKey = createSelector(
	[CommonSelect.styles.getAllAsObject, getStyleKeyForLegendByKey],
	(styles, styleKey) => {
		return styles?.[styleKey] || null;
	}
);

const getPreparedStylesForLegend = createSelector(
	[getStyleForLegend],
	style => {
		if (style) {
			const definition = style?.data?.definition?.rules?.[0]?.styles;
			const baseStyle = definition[0];
			const attributeStyle = definition[1];
			return {
				baseStyle,
				attributeStyle,
			};
		} else {
			return null;
		}
	}
);

const getPreparedStylesForLegendByKey = createSelector(
	[getStyleForLegendByKey],
	style => {
		if (style) {
			const definition = style?.data?.definition?.rules?.[0]?.styles;
			const baseStyle = definition[0];
			const attributeStyle = definition[1];
			return {
				baseStyle,
				attributeStyle,
			};
		} else {
			return null;
		}
	}
);

export default {
	ap01,
	ap02,
	ap04,
	ap07,
	ap08,

	getApplicationStoriesForPlace,
	getPossibleApplicationStoriesForPlace,
	getCaseBActiveLayerType,
	getCaseBActiveLayerDate,
	getCaseBActiveLayerOpacity,
	getCaseBActiveLayerKey,
	getActiveScopeByApplicationStoryKey,
	getApplicationStoryKeyByScopeKey,
	getConfigByApplicationStoryKey,
	getConfigForActiveApplicationStory,
	getAvailablePlacesForActiveApplicationStory,
	getScopeKeyByApplicationStoryKey,
	getForCurrentFilter,
	getForCurrentFilterOrdered,
	getPlacesForActiveStory,
	getProductTagsGroupedByCategory,
	getTimelineLayers,
	getTimelinePeriod,
	getRasterLegend,
	getStyleKeyForLegend,
	getStyleKeyForLegendByKey,
	getStyleForLegend,
	getStyleForLegendByKey,
	getPreparedStylesForLegend,
	getPreparedStylesForLegendByKey,

	isDataForCurrentPlaceAvailable,
};
