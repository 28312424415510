import PropTypes from 'prop-types';
import TagsSelect from './TagsSelect';

import './style.scss';

const ParametersSelects = ({
	componentKey,
	activeMapKey,
	onChange,
	tags,
	children,
}) => {
	const TagsElements = tags.map(tagKey => (
		<TagsSelect
			tagKey={tagKey}
			key={tagKey}
			componentKey={componentKey}
			activeMapKey={activeMapKey}
			onChange={onChange}
		/>
	));
	return (
		<div className="cure-ParametersSelect">
			{TagsElements}
			{children}
		</div>
	);
};

ParametersSelects.propTypes = {
	tags: PropTypes.array,
	componentKey: PropTypes.string,
	activeMapKey: PropTypes.string,
	onChange: PropTypes.func,
	children: PropTypes.node,
};

export default ParametersSelects;
