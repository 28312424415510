import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Icon} from '@gisatcz/ptr-atoms';
import Link from '../../../common/Link';
import './style.scss';
import {getRouter} from '../../../../router';

const IndexNavItem = ({active, disabled, icon, children, path}) => {
	const classes = classnames('cure-IndexNavItem', {
		'is-active': active,
		'is-disabled': disabled,
	});

	return (
		<Link
			tabIndex={0}
			classes={classes}
			name={path}
			router={getRouter()}
			paramsFilter={['tags', 'step']}
			recoverParamsFilter={['darkMode']}
			recoverParams={true}
		>
			<Icon icon={icon} />
			<div className="cure-IndexNavItem-title">{children}</div>
		</Link>
	);
};

IndexNavItem.propTypes = {
	active: PropTypes.bool,
	disabled: PropTypes.bool,
	icon: PropTypes.string,
	path: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

const IndexNav = ({activeTab, user}) => {
	const userIsLogged = user && user?.data?.name !== 'guest';
	return (
		<nav className="cure-IndexNav" data-tour="navigation">
			<IndexNavItem icon="ri-info" active={activeTab === 'about'} path={'home'}>
				About
			</IndexNavItem>
			<IndexNavItem
				icon="ri-city"
				active={activeTab === 'city-grand-challenges'}
				path={'city-grand-challenges'}
			>
				Grand Challenges
			</IndexNavItem>
			<IndexNavItem
				icon="ri-applications"
				active={activeTab === 'cure-applications'}
				path={'cure-applications'}
			>
				Applications
			</IndexNavItem>
			<IndexNavItem
				icon="map-pin"
				active={activeTab === 'city-demonstrations'}
				path={'city-demonstrations'}
			>
				Cities
			</IndexNavItem>
			{userIsLogged ? (
				<IndexNavItem
					icon="settings"
					active={activeTab === 'cure-services'}
					path={'cure-services'}
				>
					Services
				</IndexNavItem>
			) : null}
		</nav>
	);
};

IndexNav.propTypes = {
	activeTab: PropTypes.string,
	user: PropTypes.object,
};

export default IndexNav;
