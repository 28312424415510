import PropTypes from 'prop-types';
import {createElement, useEffect, useRef} from 'react';
import {connects} from '@gisatcz/ptr-state';
import {Mouse, Months, Years, Days, MapTimeline} from '@gisatcz/ptr-timeline';

import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import RasterMapTooltip from '../../../common/maps/MapTooltip/RasterMapTooltip';
import GetFeatureInfoTooltipContent from './GetFeatureInfoTooltipContent';

import getHoverContent from '../../../common/Timeline/Hover/getContent';
import MapTimelineLegend from '../../../common/Timeline/MapTimelineLegend';
import Showcase from '../../../common/Showcase';
import DateLabel from '../../../common/DateLabel';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';

import {timelineLayerLineHeight} from '../../../../constants/app';

import './style.scss';

const Tooltip = props => (
	<RasterMapTooltip {...props}>
		<GetFeatureInfoTooltipContent />
	</RasterMapTooltip>
);

const MapTimelinePresentation = MapTimeline.MapTimelinePresentation;
const LayerRowPresentation = MapTimeline.LayerRowPresentation;
const LayerRowItemPresentation = MapTimeline.LayerRowItemPresentation;
const LayerRowPeriodItemPresentation =
	MapTimeline.LayerRowPeriodItemPresentation;

const LayerRowComponent = connects.timeline.LayerRow(LayerRowPresentation);
const LayerRowItemComponent = connects.timeline.LayerRowItem(
	LayerRowItemPresentation
);
const LayerRowPeriodItemComponent = connects.timeline.LayerRowPeriodItem(
	LayerRowPeriodItemPresentation
);

const LayerRowItemComponentWrapped = props => (
	<LayerRowItemComponent
		{...props}
		LayerRowPeriodItemComponent={LayerRowPeriodItemComponent}
	/>
);
const LayerRowComponentWrapped = props => (
	<LayerRowComponent
		{...props}
		LayerRowItemComponent={LayerRowItemComponentWrapped}
	/>
);

const LEVELS = [
	{
		level: 'year',
		end: 2,
	},
	{
		level: 'month',
		end: 30,
	},
	{
		level: 'day',
		end: 300,
	},
];

const Levels = ({activeLevel, ...restProps}) => {
	switch (activeLevel) {
		case 'year':
			return createElement(Years, {...restProps, key: 'year'});
		case 'month':
			return createElement(Months, {...restProps, key: 'month'});
		case 'day':
			return createElement(Days, {...restProps, key: 'day'});
	}
	return createElement(Months, {...restProps, key: 'month'});
};

Levels.propTypes = {
	activeLevel: PropTypes.string,
};

const maximumTimelineVisibleRows = 6.5; //number of maximum visible rows
const App1_ShowcaseB = ({
	mapSetKey,
	activeMapKey,
	activePlaceKey,
	onActivePlaceKeyChange,
	timelineLayers,
	periodLimit,
	onLayerClick,
	updateTourStep,
}) => {
	useEffect(() => {
		onActivePlaceKeyChange();
	}, [activePlaceKey]);

	useEffect(() => {
		timeline?.current ? updateTourStep(timeline.current) : null;
	}, []);
	const timeline = useRef();

	const minTimelineHeight = timelineLayers
		? timelineLayers.length * timelineLayerLineHeight
		: 0;
	const contentHeight =
		timelineLayers.length * timelineLayerLineHeight >
		timelineLayerLineHeight * maximumTimelineVisibleRows
			? timelineLayerLineHeight * maximumTimelineVisibleRows
			: timelineLayers.length * timelineLayerLineHeight;

	return (
		<Showcase className="cure-App1_ShowcaseB" dataTour="showcase">
			<MapSetWrapper
				MapTooltip={Tooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerTemplateKey: '30b928f3-4d3b-4ffb-9457-310138157228', //day lt
							info: [
								<DateLabel
									key={'DateLabel_day_1'}
									format={'YYYY-MM-DD HH:mm'}
								/>,
							],
						},
						{
							layerTemplateKey: '3b5b8f1e-c5a3-4618-89f8-58e4097afd6c', //night lt
							info: [
								<DateLabel
									key={'DateLabel_night_1'}
									format={'YYYY-MM-DD HH:mm'}
								/>,
							],
						},
					],
				}}
				componentsByLayer={[
					{
						layerTemplateKey: '30b928f3-4d3b-4ffb-9457-310138157228', //day lt
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap01-surface-dynamics',
								showcaseKey: 'App1_ShowcaseB',
								title: 'Temperature',
								unit: '°C',
							},
						},
					},
					{
						layerTemplateKey: '3b5b8f1e-c5a3-4618-89f8-58e4097afd6c', //night lt
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap01-surface-dynamics',
								showcaseKey: 'App1_ShowcaseB',
								title: 'Temperature',
								unit: '°C',
							},
						},
					},
				]}
			/>

			{timelineLayers ? (
				<div data-tour="timeline" ref={timeline}>
					<MapTimelinePresentation
						LayerRowComponent={LayerRowComponentWrapped}
						mapKey={activeMapKey}
						getHoverContent={(...rest) =>
							getHoverContent(...rest, timelineLayers)
						}
						onLayerClick={onLayerClick}
						periodLimit={periodLimit}
						initPeriod={periodLimit}
						vertical={false}
						levels={LEVELS}
						selectMode={true}
						layers={timelineLayers}
						minTimelineHeight={minTimelineHeight}
						LegendComponent={MapTimelineLegend}
						contentHeight={contentHeight + 4}
					>
						<Levels />
						<Mouse mouseBufferWidth={20} key="mouse" />
					</MapTimelinePresentation>
				</div>
			) : null}
		</Showcase>
	);
};

App1_ShowcaseB.propTypes = {
	mapSetKey: PropTypes.string,
	activePlaceKey: PropTypes.string,
	onActivePlaceKeyChange: PropTypes.func,
	timelineLayers: PropTypes.array,
	onLayerClick: PropTypes.func,
	activeMapKey: PropTypes.string,
	rasterLegend: PropTypes.object,
	rasterLegendData: PropTypes.object,
	periodLimit: PropTypes.shape({
		start: PropTypes.string,
		end: PropTypes.string,
	}),
	updateTourStep: PropTypes.func,
};

export default App1_ShowcaseB;
