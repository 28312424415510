import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {Icon} from '@gisatcz/ptr-atoms';

const Service = ({name}) => {
	return (
		<div
			className="cure-ManageServices-apContainer"
			style={{border: '2px solid rgb(182, 182, 182)'}}
		>
			<div className="cure-ManageServices-apHead">
				<h2 className="cure-ManageServices-apTitle">{name}</h2>
				<div className="cure-ManageServices-openServiceBtnWrapper">
					<Button
						className="cure-ManageServices-openServiceBtn"
						disabled
						onClick={() => {}}
					>
						<div
							className="cure-ManageServices-openServiceIcon"
							style={{transform: 'rotate(0deg)'}}
						>
							<Icon icon={'ri-chevron-down'} />
						</div>
						Setup service
					</Button>
				</div>
			</div>
		</div>
	);
};

Service.propTypes = {
	name: PropTypes.string,
};

export default Service;
