import {useState, useEffect, useRef, cloneElement} from 'react';
import PropTypes from 'prop-types';
import {point} from '@turf/helpers';
import {toMercator} from '@turf/projection';
import './style.scss';

const composeGetFeatureInfoRequestURL = (
	url,
	layers,
	bbox,
	width,
	height,
	x,
	y,
	crs
) => {
	let validBBOX = bbox;
	let validCrs = 'EPSG:3857';
	if (crs === 'EPSG:4326') {
		const bl = point([bbox[0], bbox[1]]);
		const tr = point([bbox[2], bbox[3]]);
		validBBOX = [
			...toMercator(bl).geometry.coordinates,
			...toMercator(tr).geometry.coordinates,
		];
	}
	return `${url}?request=GetFeatureInfo&INFO_FORMAT=text/plain&bbox=${validBBOX}&height=${height}&width=${width}&i=${x}&j=${y}&&crs=${validCrs}&version=1.3.0&query_layers=${layers}&layers=${layers}`;
};

const GetFeatureInfoTooltip = ({
	timeout,
	// coordinates,
	bbox,
	layers,
	url,
	width,
	height,
	x,
	y,
	crs,
	children,
}) => {
	const [loading, setLoading] = useState(true);
	const [jsonResponse, setJsonResponse] = useState({});
	const timer = useRef();

	const queryData = async () => {
		const queryUrl = composeGetFeatureInfoRequestURL(
			url,
			layers,
			bbox,
			width,
			height,
			x,
			y,
			crs
		);
		const response = await fetch(queryUrl);
		const responseJson = await response.json();
		setJsonResponse(responseJson);
		setLoading(false);
	};

	useEffect(() => {
		timer.current = setTimeout(queryData, timeout);

		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	useEffect(() => {
		clearTimeout(timer.current);
		setLoading(true);

		timer.current = setTimeout(queryData, timeout);

		return () => {
			clearTimeout(timer.current);
		};
	}, [url, layers, bbox, width, height, x, y, crs]);

	const childrenWithProps = cloneElement(children, {response: jsonResponse});

	return (
		<div className="ptr-FeatureTooltip-content">
			{loading ? (
				<span className="info">Hold mouse on same position to get info</span>
			) : (
				childrenWithProps
			)}
		</div>
	);
};

GetFeatureInfoTooltip.propTypes = {
	timeout: PropTypes.number,
	// coordinates: PropTypes.array,
	bbox: PropTypes.array,
	layers: PropTypes.array,
	url: PropTypes.string,
	width: PropTypes.number,
	height: PropTypes.number,
	x: PropTypes.number,
	y: PropTypes.number,
	crs: PropTypes.string,
	children: PropTypes.node,
};

export default GetFeatureInfoTooltip;
