import PropTypes from 'prop-types';
import {useEffect} from 'react';

import './style.scss';

const ApplicationStoryBody = ({
	children,
	activeApplicationStoryKey,
	activeScope,
	onMount,
	onUnmount,
	onStoryKeyChange,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount(activeApplicationStoryKey);
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	useEffect(() => {
		if (onStoryKeyChange && typeof onStoryKeyChange === 'function') {
			onStoryKeyChange(activeApplicationStoryKey);
		}
	}, [activeApplicationStoryKey]);

	return <div className="cure-Story-body">{activeScope ? children : null}</div>;
};

ApplicationStoryBody.propTypes = {
	className: PropTypes.string,
	activeApplicationStoryKey: PropTypes.string,
	activeScope: PropTypes.object,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	onStoryKeyChange: PropTypes.func,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default ApplicationStoryBody;
