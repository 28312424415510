import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';

import App10_ShowcaseA from './App10_ShowcaseA';
import App10_ShowcaseC from './App10_ShowcaseC';
import App10_ShowcaseD from './App10_ShowcaseD';
import OrderServiceTool from '../../common/OrderServiceTool';
import StoryFooterSection from '../../common/StoryFooterSection';

const App10_NatureBasedSolutions = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						Nature-based solutions (NBS) are actions inspired by nature which
						use the features and complex system processes of nature, such as its
						ability to store carbon and regulate water flows, in order to help
						societies address a variety of environmental, social and economic
						challenges in sustainable ways. Nature-based solutions examples are
						green roofs, green walls, urban farms, etc.
					</StoryParagraph>
					<StoryParagraph className={''}>
						In urban areas, green roofs provide multiple benefits as reducing
						the risk of flooding by collecting rainwater, reduce the ambient
						temperature, improve energy efficiency in buildings and offer many
						social benefits associated with urban agriculture, well-being, noise
						reduction, healing, environmental and air quality and representing a
						valid alternative to increase the green areas, especially where
						available land for greening is limited.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Knowing the capacity of a city to host these types of nature-based
						solutions allows defining which areas have the highest potential to
						accommodate these solutions and provides decision-makers with
						different scenarios on green roof potential deployment. In addition,
						buildings suitability for roof rehabilitation buildings is provided
						to support actions prioritization.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Estimation of the maximum green roof potential
					</StorySubheadline>
					<StoryParagraph className={''}>
						The CURE Nature-Based Solutions allows to identify already existing
						green roofs as well as identifying areas with high roof retrofitting
						potential by quantifying potential green roof installation according
						to specific installation conditions. To accurately quantify the
						urban assets capabilities, it is crucial to identify firstly which
						buildings have flat or quasi-flat roofs and which ones present an
						already vegetated surface, in order to identify the maximum green
						roof potential.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Nature-Based Solutions service provides map-based results - the
						maximum green roof potential estimation inventory for each building
						in a neighbourhood, a city or a region. This is estimated according
						to physical parameters such as slope computation for each building.
						based on several parameters aiming at maximizing the impact of the
						solutions in a determinate area, such as NDVI, Land surface
						temperature and imperviousness.
					</StoryParagraph>
					<StoryParagraph className={''}>
						See and explore such an estimation of maximum green roof potential
						on the map left. Use slider to inspect variation, value distribution
						and local differences in the city.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Prioritization of areas with highest potential/benefit
					</StorySubheadline>
					<StoryParagraph className={''}>
						Although almost all buildings can host a green roof, some specific
						characteristics may require a complex and expensive design and
						installation and, as part of the urban initial assessment for
						greening potential and prioritization, the year of construction is
						used as an estimation to dismiss buildings which may require
						extensive rehabilitation.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The identification of buildings with highest greening potential is
						done according to physical parameters such as slope computation for
						each building and the identification of buildings with highest
						greening potential, based on several parameters aiming at maximizing
						the impact of the solutions in a determinate area, such as NDVI,
						Land surface temperature (LST) and degree of imperviousness.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The combination of the most beneficious area and the most suitable
						buildings is used for the prioritization, but it be can be further
						weighted by the user depending on the city characteristics. See and
						explore an estimation of maximum green roof potential on the map
						left and prioritization of areas with highest potential/benefit on
						the right. Use slider to inspect variation, value distribution and
						local differences in the city.
					</StoryParagraph>
					<StorySubheadline className={''}>
						Overall Green Roof Potential for the city
					</StorySubheadline>
					<StoryParagraph className={''}>
						See below the overall Green Roof Potential for the city as
						identified by The CURE Nature-Based Solutions service.
					</StoryParagraph>
					<App10_ShowcaseD />
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						Green roofs have been recognized as an effective planning tool and
						its wider implementation is supported by several institutions and
						governments either by providing direct incentives or tax reduction
						or by establishing regulations and obligations in greening
						buildings’ roofs (Liberalesso et al., 2020). While considering
						alternative approaches to increase green areas, evidence on suitable
						locations and buildings characteristics is needed (Santos et al.,
						2016). CURE Nature-Based Solutions service provides map-based
						results to make informed decisions on urban design and planning by
						providing an estimation of the maximum green roof potential,
						according to physical parameters such as slope computation for each
						building and the identification of buildings with highest greening
						potential, based on several parameters aiming at maximizing the
						impact of the solutions in a determinate area, such as NDVI, Land
						surface temperature and imperviousness. Furthermore, even though
						almost all buildings can host a green roof, some specific
						characteristics may require a complex and expensive design and
						installation and, as part of the urban initial assessment for
						greening potential and prioritization, the year of construction is
						used as an estimation to dismiss buildings which may require
						extensive rehabilitation. The combination of the most beneficious
						area and the most suitable buildings is used for the prioritization,
						which can be weighted by the user. In addition to the visualization
						through maps, specific information on total square meters that can
						be potentially greened is given. Results can be coupled with CURE
						Urban Thermal Comfort service to assess the potential improvement in
						the thermal performance of urban areas according to different
						scenarios generated.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							Klimatek Project 2016 -{' '}
							<a href="http://growgreenproject.eu/wp-content/uploads/2018/05/NBS-Climate-Adaptation-Basque-Country.pdf">
								Nature-based solutions
							</a>{' '}
							for local climate adaptation in the Basque Country
						</li>
						<li>
							Green infrastructure and public policies: An international review
							of green roofs and green walls incentives. Land use policy -
							Liberalesso, T., Oliveira Cruz, C., Matos Silva, C., Manso, M.,
							2020. 96. (
							<a href="https://doi.org/10.1016/j.landusepol.2020.104693">
								paper
							</a>
							).
						</li>
						<li>
							Quantifying the city’s green area potential gain using remote
							sensing data. Sustain. 8. - Santos, T., Tenedório, J.A.,
							Gonçalves, J.A., 2016. (
							<a href="https://doi.org/10.3390/su8121247">paper</a>
							).
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						The service provided is based on CLMS Copernicus Services, results
						generated by CURE Local Scale Surface Temperature Dynamics – LST
						service (based on CLMS and C3S) and cadastral information as main
						data inputs for the prioritization. Digital surface model is
						generated from LiDAR, processed by Tecnalia or VHR stereo imagery
						(WorldView-1 and WorldView-2), processed by DLR “Catena” operational
						infrastructure.
					</StoryParagraph>

					<StoryParagraph className={''}>
						The service is provided by{' '}
						<a href="https://www.tecnalia.com/">
							Tecnalia Research & Innovation
						</a>
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro10.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Identification and mapping of areas and buildings with high roof
						greening potential
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App10_ShowcaseA mapSetKey="ap10-nature-based-solutions-showcase-a" />
				<App10_ShowcaseC />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Sofia',
								link: 'https://www.dropbox.com/s/nw2dgvsvj7n9ohl/CURE_AP10_Sofia_sourcedata.zip?dl=0',
							},
							{
								city: 'San Sebastian',
								link: 'https://www.dropbox.com/s/rq8t95oywywhouq/CURE_AP10_SanSebastian_sourcedata.zip?dl=0',
							},
						]}
						otherApps={[
							{
								key: 'ap05-urban-flood-risk',
								label: 'Urban Flood Risk',
							},
							{
								key: 'ap08-urban-thermal-comfort',
								label: 'Urban Thermal Comfort',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App10_NatureBasedSolutions.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App10_NatureBasedSolutions;
