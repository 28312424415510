import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {Icon} from '@gisatcz/ptr-atoms';
import {useState} from 'react';
import Select from '../../../../../common/Select';

const defaultCity = {
	value: 'Heraklion',
	label: 'Heraklion',
};

const maximumTimeRange = 120;
const minimumTimeRange = 60;

const appStoryKey = 'ap01-surface-dynamics';

const LocalScaleSurfaceTemperatureDynamics = ({
	scope,
	onClose,
	cities,
	onServiceSent,
	openAppSettings,
	setOpenAppSettings,
}) => {
	const [city, setCity] = useState(defaultCity);
	const [startDay, setStartDay] = useState(null);
	const [endDay, setEndDay] = useState(null);

	const timeRange =
		endDay && startDay
			? (new Date(endDay) - new Date(startDay)) / (3600 * 24 * 1000)
			: null;
	const timerangeFit =
		startDay && endDay && startDay < endDay
			? timeRange <= maximumTimeRange && timeRange >= minimumTimeRange
			: false;

	const fulfill = city && startDay && endDay && timerangeFit;

	return (
		<div
			className="cure-ManageServices-apContainer"
			style={
				openAppSettings?.[appStoryKey]
					? {border: '2px solid var(--accent45)'}
					: {border: '2px solid rgb(182, 182, 182)'}
			}
		>
			<div className="cure-ManageServices-apHead">
				<h2 className="cure-ManageServices-apTitle">
					{scope?.data?.nameDisplay}
				</h2>
				<div className="cure-ManageServices-openServiceBtnWrapper">
					<Button
						className="cure-ManageServices-openServiceBtn"
						enabled
						onClick={() => {
							setOpenAppSettings(
								appStoryKey,
								!openAppSettings?.[appStoryKey],
								openAppSettings
							);
						}}
					>
						<div
							className="cure-ManageServices-openServiceIcon"
							style={
								openAppSettings?.[appStoryKey]
									? {transform: 'rotate(180deg)'}
									: {transform: 'rotate(0deg)'}
							}
						>
							<Icon icon={'ri-chevron-down'} />
						</div>
						Setup service
					</Button>
				</div>
			</div>
			<div
				className="cure-ManageServices-apSelects"
				style={
					openAppSettings?.[appStoryKey]
						? {
								overflow: 'visible',
								height: '100%',
								padding: '2rem 0 0 0',
						  }
						: {
								overflow: 'hidden',
								height: '0rem',
								padding: '0rem',
						  }
				}
			>
				<div className="cure-ManageServices-apSelectContainer">
					<h3 className="cure-ManageServices-apSelectTitle">Places</h3>
					<div className="cure-ManageServices-apSelect">
						<Select
							small
							options={cities}
							value={city}
							onChange={object => setCity(object)}
							menuPosition={'fixed'}
						/>
					</div>
				</div>
				<div className="cure-ManageServices-apSelectContainer">
					<h3 className="cure-ManageServices-apSelectTitle">Start date</h3>
					<div className="cure-ManageServices-apSelect">
						<input
							type="date"
							value={startDay}
							min="2018-01-01"
							max="2021-12-31"
							onChange={val => {
								setStartDay(val.target.value);
							}}
						/>
					</div>
				</div>
				<div className="cure-ManageServices-apSelectContainer">
					<h3 className="cure-ManageServices-apSelectTitle">End date</h3>
					<div className="cure-ManageServices-apSelect">
						<input
							type="date"
							value={endDay}
							min="2018-01-01"
							max="2021-12-31"
							onChange={val => {
								setEndDay(val.target.value);
							}}
						/>
					</div>
				</div>
				<div className="cure-ManageServices-apSelectContainer cure-ManageServices-note">
					<span>*</span>Minimum supported time range is {minimumTimeRange} days.
					Maximum supported time range is {maximumTimeRange} days.
				</div>
				<div className="cure-ManageServices-apStartBtnWrapper">
					<Button
						className="cure-ManageServices-apStartBtn"
						icon="settings"
						disabled={!fulfill}
						onClick={() => {
							setOpenAppSettings(appStoryKey, undefined, openAppSettings);
							onServiceSent({
								city: city?.label,
								start_date: startDay,
								end_date: endDay,
							});
							onClose(false);
							setCity(undefined);
						}}
					>
						Start processing
					</Button>
				</div>
			</div>
		</div>
	);
};

LocalScaleSurfaceTemperatureDynamics.propTypes = {
	cities: PropTypes.array,
	scope: PropTypes.object,
	onServiceSent: PropTypes.func,
	onClose: PropTypes.func,
	openAppSettings: PropTypes.object,
	setOpenAppSettings: PropTypes.func,
};

export default LocalScaleSurfaceTemperatureDynamics;
