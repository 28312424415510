import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		user: Select.users.getActive(state),
	};
};

const mapDispatchToProps = () => {
	return dispatch => {
		return {
			logoutUser: () => {
				return dispatch(Action.users.apiLogoutUser()).then(() => {
					dispatch(Action.users.apiLoadCurrentUser());
					//go to home
					dispatch(Action.router.updateAppUrl('cure-applications'));
				});
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
