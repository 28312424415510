const AppPreview = () => (
	<div>
		<h4>CURE Applications Overview Page – Application List</h4>
		<p>
			Examine applications synopsis and visual example of products generated.
			See relevant tags from all filter groups highlighted to know where you
			are. Dive into individual services storylines (using Explore button) or to
			service production interface (using Order service button).
		</p>
	</div>
);

export default AppPreview;
