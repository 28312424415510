import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {TourProvider} from '@reactour/tour';
import steps from './steps';

const Tour = ({
	children,
	redirectToHome,
	redirectToApplications,
	redirectToApplicationsAndFilter,
	redirectToStory,
	currentStepRef,
}) => {
	const [step, setStep] = useState(0);

	useEffect(() => {
		setStep(step);
	}, [currentStepRef]);

	const setCurrentStep = step => {
		switch (step) {
			case 0:
				redirectToHome();
				break;
			case 1:
				redirectToApplications();
				break;
			case 2:
				redirectToApplicationsAndFilter();
				break;
			case 3:
				redirectToApplicationsAndFilter();
				break;
			case 4:
				redirectToStory(2);
				break;
			case 11:
				redirectToStory(2);
				break;
			case 12:
				redirectToStory(-1);
				break;
			default:
				break;
		}
		setStep(step);
	};

	const style = {
		popover: base => ({
			...base,
			maxWidth: '90%',
		}),
	};

	return (
		<TourProvider
			steps={steps}
			currentStep={step}
			setCurrentStep={setCurrentStep}
			afterOpen={() => setCurrentStep(0)}
			showDots={false}
			styles={style}
		>
			{children}
		</TourProvider>
	);
};

Tour.propTypes = {
	children: PropTypes.node,
	redirectToApplications: PropTypes.func,
	redirectToApplicationsAndFilter: PropTypes.func,
	redirectToHome: PropTypes.func,
	redirectToStory: PropTypes.func,
	currentStepRef: PropTypes.object,
};

export default Tour;
