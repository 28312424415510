import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';

import presentation from './presentation';

const appStoryKey = 'App1_ShowcaseC';

const mapStateToProps = (state, ownProps) => {
	const configuration =
		Select.cure.applicationStories.getConfigForActiveApplicationStory(
			state,
			appStoryKey
		);

	return {
		date: configuration?.showcases?.[appStoryKey]?.data?.dates?.[
			ownProps.placeKey
		]?.[ownProps?.layerState?.layerTemplateKey]?.[
			ownProps?.layerState?.styleKey
		],
	};
};

const mapDispatchToPropsFactory = () => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(presentation);
