import PropTypes from 'prop-types';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import MapTooltip from '../../../common/maps/MapTooltip/VectorMapTooltip';
import Showcase from '../../../common/Showcase';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';
import DateLabel from '../../../common/DateLabel';

const App10_ShowcaseA = ({mapSetKey}) => {
	return (
		<Showcase>
			<MapSetWrapper
				MapTooltip={MapTooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						highlightActiveMap: false,
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerKey: 'ap10-nature-based-solutions-showcase-a-1-potential',
							info: [<DateLabel key={'DateLabel_day_1'} date={'2017'} />],
						},
						{
							layerKey: 'ap10-nature-based-solutions-showcase-a-2-priority',
							info: [<DateLabel key={'DateLabel_day_1'} date={'2017'} />],
						},
					],
				}}
				componentsByLayer={[
					{
						layerKey: 'ap10-nature-based-solutions-showcase-a-1-potential',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap10-nature-based-solutions',
								showcaseKey: 'App10_ShowcaseA',
								noData: false,
							},
						},
					},
					{
						layerKey: 'ap10-nature-based-solutions-showcase-a-2-priority',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap10-nature-based-solutions',
								showcaseKey: 'App10_ShowcaseA',
								noData: false,
							},
						},
					},
				]}
			/>
		</Showcase>
	);
};

App10_ShowcaseA.propTypes = {
	mapSetKey: PropTypes.string,
};

export default App10_ShowcaseA;
