import PropTypes from 'prop-types';
import './style.scss';

const CenteredWrap = ({children}) => {
	return <div className="cure-CenteredWrap">{children}</div>;
};

CenteredWrap.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

export default CenteredWrap;
