import {Children, isValidElement, cloneElement} from 'react';
import {ExpandableLabelsContainer} from '@gisatcz/visat-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import helpers from '../helpers';
import BackgroundLayersControl from '../BackgroundLayersControl';
import LayerLabel from '../LayerLabel';
import MapLegends from '../MapLegends';

import './style.scss';

const MapWrapper = ({
	activeMapKey,
	configurationsByLayer,
	componentsByLayer,
	mapKey,
	mapSetMapKeys,
	mapSetKey,
	layers,
	layersState,
	toolsLeft,
	additionalChildren,
	children,
	highlightActive = true,
	displayOverlay,
	Overlay,
}) => {
	const wrapperClasses = classnames('cure-MapWrapper', {
		active: mapKey === activeMapKey,
		'one-map': mapSetMapKeys?.length === 1,
		'not-highlight-active': !highlightActive,
	});

	const toolsOnLeft = toolsLeft || mapSetMapKeys?.indexOf(mapKey) === 0;
	const toolsContainerClasses = classnames('cure-MapTools', {
		'is-left': toolsOnLeft,
	});
	const legendsContainerClasses = classnames('cure-MapLegends', {
		'is-left': toolsOnLeft,
	});

	const additionalChildrenWithProps = Children.map(
		additionalChildren,
		child => {
			if (isValidElement(child)) {
				return cloneElement(child, {
					...child.props,
					layers,
					mapKey,
					mapSetKey,
				});
			}
			return child;
		}
	);

	return (
		<div className={wrapperClasses}>
			<div className={toolsContainerClasses}>
				<BackgroundLayersControl
					opensBottom
					opensRight={toolsOnLeft}
					mapKey={mapKey}
					mapSetKey={mapSetKey}
				/>
				{layersState?.length ? (
					<ExpandableLabelsContainer>
						{layersState.map(layerState => {
							const layerConfig = helpers.getConfigByLayerState(
								configurationsByLayer,
								layerState
							);

							const infoLayerConfig = layerConfig
								? layerConfig.map(lc => lc?.info)
								: [];
							return (
								<LayerLabel
									info={infoLayerConfig}
									key={layerState.key}
									layerState={layerState}
									layerConfiguration={helpers.getLayerConfig(layerConfig)}
									mapKey={mapKey}
								/>
							);
						})}
					</ExpandableLabelsContainer>
				) : null}
			</div>
			<MapLegends
				layersState={layersState}
				componentsByLayer={componentsByLayer}
				className={legendsContainerClasses}
				mapKey={mapKey}
			/>
			{additionalChildrenWithProps}
			{Overlay && displayOverlay?.[mapKey] ? (
				<Overlay mapKey={mapKey} />
			) : (
				children
			)}
		</div>
	);
};

MapWrapper.propTypes = {
	displayOverlay: PropTypes.object,
	Overlay: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.func,
		PropTypes.elementType,
	]),
	activeMapKey: PropTypes.string,
	additionalChildren: PropTypes.node,
	children: PropTypes.node,
	configurationsByLayer: PropTypes.array,
	componentsByLayer: PropTypes.array,
	highlightActive: PropTypes.bool,
	layers: PropTypes.array,
	layersState: PropTypes.array,
	mapKey: PropTypes.string,
	mapSetMapKeys: PropTypes.array,
	mapSetKey: PropTypes.string,
	toolsLeft: PropTypes.bool,
};

export default MapWrapper;
