import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const AppPreview = ({className, right, children}) => {
	const classes = classnames(
		'cure-AppPreview',
		{
			'is-right': right,
		},
		className
	);

	return (
		<div data-tour="app-preview" className={classes}>
			{children}
		</div>
	);
};

AppPreview.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	right: PropTypes.bool,
};

export default AppPreview;
