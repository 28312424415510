import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		applicationStoryKey:
			Select.cure.applicationStories.getApplicationStoryKeyByScopeKey(
				state,
				ownProps.appKey
			),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
