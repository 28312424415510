import Welcome from './Welcome';
import TopBar from './TopBar';
import Filter from './Filter';
import AppPreview from './AppPreview';
import StoryNav from './StoryNav';
import StoryAbout from './StoryAbout';
import Showcase from './Showcase';
import MapSetControls from './MapSetControls';
import LayerLabel from './LayerLabel';
import MapLegend from './MapLegend';
import Timeline from './Timeline';
import StoryFooter from './StoryFooter';

export default [
	{
		selector: '[data-tour="portal"]',
		content: Welcome,
	},
	{
		selector: '[data-tour="top-bar"]',
		content: TopBar,
	},
	{
		selector: '[data-tour="filter"]',
		content: Filter,
	},
	{
		selector: '[data-tour="app-preview"]',
		content: AppPreview,
	},
	{
		selector: '[data-tour="story-nav"]',
		content: StoryNav,
	},
	{
		selector: '[data-tour="showcase-about"]',
		content: StoryAbout,
	},
	{
		selector: '[data-tour="showcase"]',
		content: Showcase,
	},
	{
		selector: '[data-tour="background-control"]',
		content: <p>Change of the background layer of active map</p>,
	},
	{
		selector: '[data-tour="map-set-controls"]',
		content: MapSetControls,
	},
	{
		selector: '[data-tour="layer-label"]',
		content: LayerLabel,
	},
	{
		selector: '[data-tour="map-legend"]',
		content: MapLegend,
	},
	{
		selector: '[data-tour="timeline"]',
		content: Timeline,
	},
	{
		selector: '[data-tour="storyFooter"]',
		content: StoryFooter,
	},
];
