import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import './style.scss';

const App1_ShowcaseA_LayerSwitch = ({
	onMount,
	onUnmount,
	options,
	activeOptionKey,
	onLayerChange,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	const onChange = optionValue => {
		onLayerChange(optionValue, activeOption, options);
	};

	const activeOption = options.find(o => o.key === activeOptionKey);

	return activeOption ? (
		<div className="cure-LayerSwitch">
			<ButtonSwitch onClick={onChange} ghost>
				{options.map(options => (
					<ButtonSwitchOption
						key={options.key}
						value={options.key}
						active={options.key === activeOptionKey}
					>
						{options.nameDisplay}
					</ButtonSwitchOption>
				))}
			</ButtonSwitch>
		</div>
	) : null;
};

App1_ShowcaseA_LayerSwitch.propTypes = {
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	activeOptionKey: PropTypes.string,
	options: PropTypes.array,
	onLayerChange: PropTypes.func,
};

export default App1_ShowcaseA_LayerSwitch;
