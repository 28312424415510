import PropTypes from 'prop-types';
import {IconTool} from '@gisatcz/visat-components';
import Tooltip from '../../Tooltip';

const CompareMapsControl = ({maps, mapMode, setMapMode}) => {
	return (
		<IconTool
			tooltip={{
				text: 'Compare mode',
				position: 'left',
				component: Tooltip,
				id: 'Compare mode',
			}}
			active={mapMode === 'compare'}
			onClick={() => setMapMode(mapMode === 'compare' ? 'set' : 'compare')}
			disabled={maps?.length !== 2}
			floating
			medium
			icon="ri-compare"
			dataTour="swipe-mode-control"
		/>
	);
};

CompareMapsControl.propTypes = {
	maps: PropTypes.array,
	mapMode: PropTypes.string,
	setMapMode: PropTypes.func,
};

export default CompareMapsControl;
