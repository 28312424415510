import classnames from 'classnames';
import PropTypes from 'prop-types';

const AppPreviewTitle = ({className, fullName, children}) => {
	const classes = classnames('cure-AppPreviewTitle', {}, className);

	return (
		<h2 className={classes} title={fullName}>
			{children}
		</h2>
	);
};

AppPreviewTitle.propTypes = {
	className: PropTypes.string,
	fullName: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default AppPreviewTitle;
