import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		activeKeys: Select.components.getByComponentKey(
			state,
			ownProps.componentKey
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
