const StoryNav = () => (
	<div>
		<h4>Application Storyline Page - Navigation</h4>
		<p>
			Entering the individual storylines web presentation, see the upper
			navigation tab. Change cities for selected application or move to
			different application storyline for the same city easily.
		</p>
	</div>
);

export default StoryNav;
