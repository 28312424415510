import PropTypes from 'prop-types';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import MapTooltip from '../../../common/maps/MapTooltip';
import Showcase from '../../../common/Showcase';
import GetFeatureInfoTooltipContent from './tooltips/RasterTooltipContent';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';
import DateLabel from '../../../common/DateLabel';

import './style.scss';

const Tooltip = props => (
	<MapTooltip {...props}>
		<GetFeatureInfoTooltipContent />
	</MapTooltip>
);

const App5_ShowcaseA = ({mapSetKey}) => {
	return (
		<Showcase>
			<MapSetWrapper
				MapTooltip={Tooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						highlightActiveMap: false,
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerKey: 'ap05-urban-flood-risk-showcase-a-2-polygons',
							layerTemplateKey: '02c2edca-0a17-47f8-9062-785b44124ff7',
							info: [<DateLabel key={'DateLabel_day_1'} date={'2018'} />],
						},
					],
				}}
				componentsByLayer={[
					{
						layerTemplateKey: '02c2edca-0a17-47f8-9062-785b44124ff7',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap05-urban-flood-risk',
								showcaseKey: 'App5_ShowcaseA',
								title: 'Flood hazard zones',
								unit: 'm',
								noData: false,
							},
						},
					},
					{
						layerKey: 'ap05-urban-flood-risk-showcase-a-2-polygons',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap05-urban-flood-risk',
								showcaseKey: 'App5_ShowcaseA',
								title: 'Flood hazard zones',
								unit: 'm',
								noData: false,
							},
						},
					},
				]}
			/>
		</Showcase>
	);
};

App5_ShowcaseA.propTypes = {
	mapSetKey: PropTypes.string,
};

export default App5_ShowcaseA;
