import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		opacity: Select.maps.getMapLayerStateByMapKeyAndLayerKey(
			state,
			ownProps.mapKey,
			ownProps.layerKey
		)?.opacity,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onOpacityChange: opacity => {
			dispatch(
				Action.maps.setMapLayerOpacity(
					ownProps.mapKey,
					ownProps.layerKey,
					opacity / 100
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
