import PropTypes from 'prop-types';
import {useEffect} from 'react';
import ParametersSelects from '../../../common/ParametersSelects';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import RasterMapTooltip from '../../../common/maps/MapTooltip/RasterMapTooltip';
import GetFeatureInfoTooltipContent from './GetFeatureInfoTooltipContent';
import StyleBasedLegendByKey from './StyleBasedLegendByKey';
import Showcase from '../../../common/Showcase';
import ActiveTag from '../../../common/ActiveTag';
import LayerTemplateSelect from '../../../common/LayerTemplateSelect';
import Layer from '../../App8_UrbanThermalComfort/common/Layer';
import DateLabel from '../../../common/DateLabel';

import './style.scss';

const Tooltip = props => (
	<RasterMapTooltip {...props}>
		<GetFeatureInfoTooltipContent />
	</RasterMapTooltip>
);

const App7_ShowcaseA = ({
	onActiveFilterChange,
	mapSetKey,
	activeMapKey,
	onUnmount,
	parametersComponentKey,
	layerTemplatesComponentKey,
	activePlaceKey,
	onActivePlaceKeyChanged,
	layerTemplateSelectDisabled,
}) => {
	useEffect(() => {
		if (typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	useEffect(() => {
		if (activePlaceKey) {
			onActivePlaceKeyChanged(activePlaceKey, parametersComponentKey);
		}
	}, [activePlaceKey]);
	return (
		<Showcase className="cure-App7_ShowcaseA">
			<div className="top-bar">
				<ParametersSelects
					componentKey={parametersComponentKey}
					activeMapKey={activeMapKey}
					onActiveFilterChange={onActiveFilterChange}
				/>
				<LayerTemplateSelect
					disabled={layerTemplateSelectDisabled}
					componentKey={layerTemplatesComponentKey}
					activeMapKey={activeMapKey}
					title={'Data'}
					onActiveFilterChange={layerTemplateKey =>
						onActiveFilterChange(
							parametersComponentKey,
							activeMapKey,
							layerTemplateKey
						)
					}
				/>
			</div>
			<MapSetWrapper
				MapTooltip={Tooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						highlightActiveMap: true,
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerTemplateKey: 'd6bde774-2a6d-4eee-86a0-fa373c3bba4c',
							info: [
								<Layer
									key={'layer'}
									layerTemplateKey={'d6bde774-2a6d-4eee-86a0-fa373c3bba4c'}
								/>,
								<ActiveTag
									componentKey={parametersComponentKey}
									key={'ActiveTag_1'}
									parentTags={['4976755e-078b-441c-8fee-08e4ef60436a']}
								/>,
								<DateLabel key={'DateLabel_day_1'} date={'2018'} />,
							],
						},
						{
							layerTemplateKey: '2a196dd1-5529-4c05-a071-7e9c71c22fba',
							info: [
								<Layer
									key={'layer'}
									layerTemplateKey={'2a196dd1-5529-4c05-a071-7e9c71c22fba'}
								/>,
								<ActiveTag
									componentKey={parametersComponentKey}
									key={'ActiveTag_1'}
									parentTags={['4976755e-078b-441c-8fee-08e4ef60436a']}
								/>,
								<DateLabel key={'DateLabel_day_1'} date={'2018'} />,
							],
						},
					],
				}}
				componentsByLayer={[
					{
						layerTemplateKey: '2a196dd1-5529-4c05-a071-7e9c71c22fba',
						legend: {
							component: StyleBasedLegendByKey,
							props: {
								noData: false,
								applicationStoryKey: 'ap07-urban-air-quality',
								showcaseKey: 'App7_ShowcaseA',
								unit: 'μg/m3',
							},
						},
					},
					{
						layerTemplateKey: 'd6bde774-2a6d-4eee-86a0-fa373c3bba4c',
						legend: {
							component: StyleBasedLegendByKey,
							props: {
								noData: false,
								applicationStoryKey: 'ap07-urban-air-quality',
								showcaseKey: 'App7_ShowcaseA',
								unit: 'μg/m3',
							},
						},
					},
				]}
			/>
		</Showcase>
	);
};

App7_ShowcaseA.propTypes = {
	onActiveFilterChange: PropTypes.func,
	mapSetKey: PropTypes.string,
	activeMapKey: PropTypes.string,
	onUnmount: PropTypes.func,
	parametersComponentKey: PropTypes.string,
	layerTemplatesComponentKey: PropTypes.string,
	rasterLegendData: PropTypes.object,
	activePlaceKey: PropTypes.string,
	onActivePlaceKeyChanged: PropTypes.func,
	layerTemplateSelectDisabled: PropTypes.bool,
};

export default App7_ShowcaseA;
