import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';

import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const activeMapKey = ownProps.mapKey;

	const configuration =
		Select.cure.applicationStories.getConfigForActiveApplicationStory(state);

	const unitTagKey = configuration.unitTagKey;
	const parametersComponentKey = configuration.parametersComponentKey;
	const activeUnitTagKey = Select.cure.applicationStories.ap07.getActiveTagKey(
		state,
		parametersComponentKey,
		unitTagKey,
		activeMapKey
	);

	const tag = Select.tags.getByKey(state, activeUnitTagKey);
	return {
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, ownProps.mapSetKey),
		activeUniteTagName: tag.data.nameDisplay,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
