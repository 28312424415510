import PropTypes from 'prop-types';
import FeatureTooltip from '../../FeatureTootip';
import {isEmpty as _isEmpty} from 'lodash';
const VectorMapTooltip = ({
	layerKey,
	feature = {},
	children,
	event,
	...props
}) => {
	const properties = {...event?.object?.properties};
	delete properties['ogc_fid'];
	if (!_isEmpty(event?.object?.properties)) {
		// TODO handle children better
		return (
			<FeatureTooltip
				nameAttributeKey={layerKey?.props?.options?.nameAttributeKey} //TODO not key, fix in ptr-maps
				attributes={properties}
				featureKey={feature.key}
				{...props}
			>
				{children?.type?.name !== 'RasterTooltipContent' ? children : null}
			</FeatureTooltip>
		);
	}
};

VectorMapTooltip.propTypes = {
	layerKey: PropTypes.object,
	feature: PropTypes.object,
	event: PropTypes.object,
	children: PropTypes.node,
};

export default VectorMapTooltip;
