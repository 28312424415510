import {Action, Select as CommonSelect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Select from '../../../Select';
import {
	getLayerOpacity,
	getLayerOptions,
	getPreviousMapLayerSettings,
} from '../helpers';

function onFilterChange(
	tagsComponentKey,
	layerTemplatesComponentKey,
	mapKey,
	componentId,
	wgbtTagKey
) {
	return (dispatch, getState) => {
		const state = getState();
		const tagsComponent = Select.components.getByComponentKey(
			state,
			tagsComponentKey
		);

		const activeLayerTemplateKey =
			Select.cure.applicationStories.ap08.getActiveLayerTemplateKey(
				state,
				layerTemplatesComponentKey,
				mapKey
			);

		const tags = Select.cure.applicationStories.ap08.getBaseTags(
			state,
			tagsComponentKey
		);

		const activeTags = tags.map(tagKey => {
			return Select.cure.applicationStories.ap08.getActiveTagKey(
				state,
				tagsComponentKey,
				tagKey,
				mapKey
			);
		});

		activeTags.push(wgbtTagKey);

		//remove all layers from map
		dispatch(Action.maps.removeAllMapLayers(mapKey));
		const previousLayerSettings = getPreviousMapLayerSettings(state, mapKey);

		//apply current layer options if only one layer is in map
		const layers = CommonSelect.maps.getLayersStateByMapKey(state, mapKey);
		let layerOptions = {};
		if (layers?.length === 1 && layers[0]?.options) {
			layerOptions = layers[0]?.options;
		}

		if (tags.length > 0 && activeTags.every(i => i)) {
			//get proper period and case
			const activeCaseTagKeys = Object.values(tagsComponent.tagKeys)
				.filter(t => t.for === 'case')
				.map(t => t.active[mapKey]);

			activeCaseTagKeys.push(wgbtTagKey);
			const promises = [];

			const casesParams = [
				{},
				{tagKeys: {in: activeCaseTagKeys}},
				null,
				1,
				100,
			];

			const casesRequest = dispatch(
				Action.cases.useIndexed(...casesParams, componentId)
			);

			promises.push(casesRequest);
			return Promise.all(promises).then(() => {
				const cases = Select.cases.getIndexed(getState(), ...casesParams);

				const caseByKeys = cases.find(c =>
					c.data.tagKeys.every(t => activeCaseTagKeys.includes(t))
				);

				const caseKey = caseByKeys.key;
				const key = utils.uuid();
				dispatch(
					Action.maps.addMapLayerToIndex(
						mapKey,
						{
							key: key,
							layerKey: `${key}_${activeLayerTemplateKey}`,
							filterByActive: {
								place: true,
							},
							layerTemplateKey: activeLayerTemplateKey,
							metadataModifiers: {
								caseKey,
							},
							options: {
								...layerOptions,
								...getLayerOptions(previousLayerSettings),
							},
							opacity: getLayerOpacity(previousLayerSettings),
						},
						1
					)
				);
			});
		}
	};
}

function setActiveLayerTemplate(componentKey, activeLayerTemplate, mapKey) {
	return (dispatch, getState) => {
		const state = getState();
		const componentState = Select.components.getByComponentKey(
			state,
			componentKey
		);
		const updatedComponentState = {
			...componentState,
			active: {
				...componentState.active,
				[mapKey]: activeLayerTemplate,
			},
		};
		dispatch(Action.components.update(componentKey, updatedComponentState));
	};
}

export default {
	setActiveLayerTemplate,
	onFilterChange,
};
