import Link from '../../../common/Link';
import {getRouter} from '../../../../router';
import CenteredWrap from '../../../common/CenteredWrap';

import './style.scss';
import Hero from '../Hero';

const UrbanChallenges = () => {
	return (
		<div className="cure-UrbanChallenges">
			<Hero>
				<h1>City Grand Challenges</h1>
			</Hero>
			<CenteredWrap>
				<div className="cure-UrbanChallenges-content">
					<h2>City Requirements Specification</h2>
					<p>
						Cities seeking a sustainable future development are faced with a
						multiplicity of decisions which require a sound information basis
						coping with the complex interconnectedness of socio-economic and
						environmental issues in the urban context.
					</p>
					<p>
						These complex problems require a holistic approach to urban
						development, together with an integrated assessment of urban
						policies supported by a comprehensive set of economic, social, and
						environmental sustainability indicators, seeking “win-win”
						integrated solutions. These policy co-benefits are central to the
						delivery of the transformation agenda adopted by city politicians
						globally.
					</p>
					<p>
						Policies targeting climate resilience, compact cities and enhancing
						urban quality of life can lead to major climate co-benefits e.g.
						reduced greenhouse gas emissions. Clear co-benefits arise, for
						example, in improving air quality by reducing fossil fuel emissions
						creating accompanying positive health and environmental impacts.
					</p>
					<p>
						However, common city governmental process designed as silo structure
						generally inhibit the effective assessment of complex, multifaceted
						issues that cut across departmental priorities and thus the
						development of comprehensive co-benefits frameworks for policy
						decision making.
					</p>
					<p>
						At the same time inadequate urban intelligence including data,
						indicators and tools that cities can deploy to measure and monitor
						co-benefits is problematic. This intelligence can allow local
						authorities to test ‘what if’ scenarios, for example, concerning the
						reduction of air pollution to estimate the health cost savings of
						low-carbon transport policies.
					</p>
					<p>
						Similar tools for other co-benefits assessment can aid the
						incorporation of co-benefit considerations in the decision making
						process e.g. tools to quantify the estimated climate change
						mitigation and adaptation benefits associated with active travel and
						nature-based solutions.
					</p>
					<h2>CURE Services Demonstration Focus</h2>
					<p>
						The focus of the CURE services demonstration is defined in terms of
						3 grand challenges for European City planning – to support
						integrated planning strategies and policy frameworks aiming at
						“win-win” policy solutions:
					</p>
					<ul>
						<li>Climate Change Mitigation - Heat and CO2</li>
						<li>
							Climate Change Adaptation – Nature-Based Solutions and
							Flood/Subsidence
						</li>
						<li>Healthy Cities – Health, Thermal Comfort and Air Quality</li>
					</ul>
					<h2>Climate Change Mitigation - Heat and CO2</h2>
					<p>
						CURE applications supporting integrated urban planning solutions for
						climate change mitigation recognise urban heat island temperature as
						one of the most important parameters in climate monitoring.
					</p>
					<p>
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap01-surface-dynamics'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							The CURE surface temperature dynamics
						</Link>
						, monitors the spatio-temporal behaviour of cities’ surface
						temperature. Additional heat related CURE applications provide
						frequent local scale surface temperature estimations for cities
						related to{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap02-urban-heat-island'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							surface urban heat island
						</Link>
						,{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap03-urban-heat-emissions'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							urban heat emissions
						</Link>
						, and{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap09-urban-heat-storage'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							heat storage
						</Link>
						.
					</p>
					<p>
						All assist urban planners to optimize their adaptation strategies
						with regard to heat stress, and sustainable development
						optimisation. Combined with other CURE applications, co-benefits are
						secured in relation to key resilience challenges such as{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap04-urban-co2-emissions'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							CO2 emissions reduction
						</Link>
						.
					</p>
					<h2>
						Climate Change Adaptation - Nature-Based Solutions and
						Flood/Subsidence
					</h2>
					<p>
						CURE applications supporting integrated urban planning solutions for
						climate change adaptation include{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap10-nature-based-solutions'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							nature-based solutions
						</Link>
						, generating increased understanding of co-benefits arising from
						green roof. These offer multiple benefits such as energy savings,
						water run-off regulation, thermal comfort, air quality improvement,
						and urban heat island moderation.
					</p>
					<p>
						Similarly the{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap05-urban-flood-risk'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							urban flood service
						</Link>{' '}
						promotes flooding mitigation, as flood hazard and risk assessment
						supports urban planners in climate change adaptation activities.
					</p>
					<p>
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap06-urban-subsidence'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Urban subsidence, deformation and movement service
						</Link>{' '}
						assessment couples ground motion hazard monitoring with up-to-date
						assets information (e.g. land cover/land use at building block
						level) adding additional important aspect into multi-hazard
						evaluation.
					</p>
					<h2>Healthy Cities - Health, Thermal Comfort and Air Quality</h2>
					<p>
						CURE applications{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap07-urban-air-quality'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							urban air quality
						</Link>
						, and{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap08-urban-thermal-comfort'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							thermal comfort
						</Link>{' '}
						and the specific cross-cutting{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap11-health-impact'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							health impacts application
						</Link>{' '}
						support integrated urban planning strategies for the creation of
						healthy cities. In these, air quality remain below critical levels
						and where health promoting aspects, such as active travel and access
						to green areas are prioritised in urban planning.
					</p>
					<p>
						Embedded in the key city political objectives for post-covid
						recovery specified in neighbourhood planning and wider city planning
						sustainable development objectives. The focus on hybrid “new normal’
						local living and working (15 Minute City) is supported by visions
						and strategies for active travel (cycling and walking) etc.
					</p>
					<p>
						These visions also leverage nature-based solutions in promoting
						strategic networks of green and blue infrastructure supporting
						active travel to realise “net-zero neighbourhood” co-benefit
						solutions.
					</p>
				</div>
			</CenteredWrap>
		</div>
	);
};

UrbanChallenges.propTypes = {};

export default UrbanChallenges;
