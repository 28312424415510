import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		mapMode: Select.components.get(state, ownProps.mapSetKey, 'mode'),
		mapKeys: Select.maps.getMapSetMapKeys(state, ownProps.mapSetKey),
		activePlaceKey: Select.places.getActiveKey(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return dispatch => {
		return {
			onMount: activePlaceKey => {
				// TODO no method for set active map set key
				dispatch(
					Action.maps.updateStateFromView({activeSetKey: ownProps.mapSetKey})
				);
				dispatch(
					Action.cure.setMapViewFromPlace(activePlaceKey, ownProps.mapSetKey)
				);
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
