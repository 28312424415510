import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		chartMode: Select.components.get(state, 'App2_ShowcaseC_Chart', 'mode'),
		data: Select.cure.applicationStories.ap02.getShowcaseCData(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onChartModeChange: mode => {
			dispatch(Action.components.set('App2_ShowcaseC_Chart', 'mode', mode));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
