import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../state/Select';

import Presentation from './presentation';

const mapStateToProps = state => {
	const configuration =
		Select.cure.applicationStories.getConfigForActiveApplicationStory(state);

	const wgbtTagKey = configuration.wgbtTagKeyC;
	const label = configuration?.tooltip?.label?.[wgbtTagKey];
	return {
		label,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
