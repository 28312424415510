import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	return {
		active: Select.cure.productFilter.isTagPresentInCurrentFilter(
			state,
			ownProps.tagKey
		),
		metadata: Select.tags.getByKey(state, ownProps.tagKey),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
