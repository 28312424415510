import data from './data/data.json';
import dataTypes from './data/dataTypes.json';
import costTypes from './data/costTypes.json';
import impacts from './data/impacts.json';

import PropTypes from 'prop-types';

import BarChart from '../../../common/BarChart';
import ShowcaseHead from '../../../common/ShowcaseHead';
import InfoWindow from '../../../common/InfoWindow';
import Showcase from '../../../common/Showcase';

import './style.scss';

const App11_ShowcaseB = ({activePlace, activeKeys, componentKey}) => {
	const infoWindow = (
		<div className="cure-App11_ShowcaseB-infoContent">
			<h3 className="cure-App11_ShowcaseB-infoTitle">Data description</h3>
			<ul>
				<li>
					The EVA model system applies a set of standard costs for acute and
					chronic mortality and morbidity, derived for Denmark. To apply the EVA
					system for other countries, the standard set for standard costs can
					either be replaced by a set of locally developed standard costs that
					applies for the specific country or the standard set of standard costs
					can be transformed into another country using the OECD benefit
					transfer methodology formula (OECD 2012: 138);, Where VSL is the value
					of statistical life, and YY is GDP per capita (purchasing power parity
					(PPP) adjusted) and \betaβ is the income elasticity (of 0.8). Data has
					been obtained from The World Bank (https://data.worldbank.org) and
					results refer to 2019. We find that costs of mortality health effects
					in Bulgaria can be estimated to be 48% of the standard costs of health
					effects in EVA, and 78% for the EU27 countries as a mean. The choice
					of local Bulgarian prices or EU27 mean prices is a political choice.
					OECD (2012) Mortality health valuation in environmental, health and
					transport policies, Paris.
				</li>
				<li>
					Where VSL is the value of statistical life, and YY is GDP per capita
					(purchasing power parity (PPP) adjusted) and \betaβ is the income
					elasticity (of 0.8). Data has been obtained from The World Bank
					(https://data.worldbank.org) and results refer to 2019. We find that
					costs of mortality health effects in Bulgaria can be estimated to be
					48% of the standard costs of health effects in EVA, and 78% for the
					EU27 countries as a mean. The choice of local Bulgarian prices or EU27
					mean prices is a political choice. OECD (2012) Mortality health
					valuation in environmental, health and transport policies, Paris.
				</li>
			</ul>
		</div>
	);
	const city = {
		data: [
			{
				key: activePlace?.key,
				nameInternal: activePlace?.data?.nameInternal,
				nameDisplay: activePlace?.data?.nameDisplay,
			},
		],
	};
	const dataProperties = [city, dataTypes, costTypes, impacts];
	const yMetadata = activeKeys?.['key2'];
	return (
		<Showcase>
			<ShowcaseHead
				data={data}
				dataProperties={dataProperties}
				componentKey={componentKey}
				infoWindow={<InfoWindow content={infoWindow} />}
				noSelectIndex={[0]}
				reverseSelectsOrder={true}
				customLabel={
					<div className="cure-App11_ShowcaseB-headline">
						<h1 className="cure-App11_ShowcaseB-mainHeading">
							Health Costs by Pollution Source Contribution
						</h1>
						<h2 className="cure-App11_ShowcaseB-subHeading">
							{activeKeys?.['key' + (dataProperties.length - 1)]?.nameDisplay}
						</h2>
					</div>
				}
			/>
			<BarChart
				dataProperties={dataProperties}
				data={data}
				height="calc(100% - 8rem)"
				componentKey={componentKey}
				groupMode="grouped"
				padding={0.01}
				innerPadding={5}
				labelX={'Pollution Source Contribution'}
				labelY={
					yMetadata
						? `${yMetadata?.nameDisplay} ${
								yMetadata?.unit ? `[${yMetadata?.unit}]` : ''
						  }`
						: 'Value'
				}
			/>
		</Showcase>
	);
};

App11_ShowcaseB.propTypes = {
	activePlace: PropTypes.object,
	activeKeys: PropTypes.object,
	componentKey: PropTypes.string,
};

export default App11_ShowcaseB;
