import PropTypes from 'prop-types';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import MapTooltip from '../../../common/maps/MapTooltip/VectorMapTooltip';
import Showcase from '../../../common/Showcase';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';
import DateLabel from './DateLabel';

import './style.scss';

const App1_ShowcaseC = ({mapSetKey, placeKey}) => {
	return (
		<Showcase>
			<MapSetWrapper
				MapTooltip={MapTooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						highlightActiveMap: false,
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerTemplateKey: '8bae7095-aca7-48bb-8281-05e636d35f5f', //day lt
							info: [<DateLabel key={'DateLabel'} placeKey={placeKey} />],
						},
					],
				}}
				componentsByLayer={[
					{
						layerKey: 'ap01-surface-dynamics-showcase-c-1-urban-atlas-polygons',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap01-surface-dynamics',
								showcaseKey: 'App1_ShowcaseC',
								title: 'Maximal temperature',
							},
						},
					},
					{
						layerKey: 'ap01-surface-dynamics-showcase-c-2-urban-atlas-polygons',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap01-surface-dynamics',
								showcaseKey: 'App1_ShowcaseC',
								title: 'Minimal temperature',
							},
						},
					},
				]}
			/>
		</Showcase>
	);
};

App1_ShowcaseC.propTypes = {
	mapSetKey: PropTypes.string,
	placeKey: PropTypes.string,
};

export default App1_ShowcaseC;
