import PropTypes from 'prop-types';
import CatalogFilterCategorySection from '../../CatalogFilter/CatalogFilterCategorySection';
import '../../CatalogFilter/style.scss';

const AppFilter = ({tagsByCategory}) => {
	let categoriesContent;

	if (tagsByCategory?.length) {
		categoriesContent = tagsByCategory.map(category => (
			<CatalogFilterCategorySection
				key={category.key}
				categoryTagKey={category.key}
				title={category.data?.nameDisplay}
				tags={category.tags}
			/>
		));
	} else {
		categoriesContent = (
			<>
				<CatalogFilterCategorySection />
				<CatalogFilterCategorySection />
				<CatalogFilterCategorySection />
			</>
		);
	}

	return (
		<div className="cure-CatalogFilter" data-tour="filter">
			{categoriesContent}
		</div>
	);
};

AppFilter.propTypes = {
	tagsByCategory: PropTypes.array,
};

export default AppFilter;
