import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';
import {getRouter} from '../../../router';
import Link from '../../common/Link';

import App3_ShowcaseA from './App3_ShowcaseA';
import App3_ShowcaseC from './App3_ShowcaseC';
import OrderServiceTool from '../../common/OrderServiceTool';
import StoryFooterSection from '../../common/StoryFooterSection';

const App3_UrbanHeatEmissions = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						The sensible heat flux is the transfer of heat caused by the
						difference in temperature between the surface and the air. It is
						generally positive during daytime (heating) and negative during
						nighttime (cooling). In urban environments, it can be close to zero
						or even positive also during nighttime, i.e. nocturnal cooling is
						reduced.
					</StoryParagraph>
					<StoryParagraph className={''}>
						This CURE application generates maps of daily sensible heat flux (W
						m<span className="cure-Story-smallSymbolTop">2</span>) for urban
						areas with a pixel size of 100 m × 100 m. Since{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap01-surface-dynamics'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Land Surface Temperature (LST) service
						</Link>{' '}
						is an essential input, the availability of maps is the same i.e. up
						to 4 maps a day.
					</StoryParagraph>
					{/* THIS IS NOT MARKED AS A SHOWCASE */}
					<StorySubheadline className={'cure-Story-InfoBox'}>
						Urban Energy Balance
					</StorySubheadline>
					<StoryParagraph className={''}>
						The sensible heat flux is the transfer of heat caused by the
						difference in temperature between the surface and the air. It is
						generally positive during daytime (heating) and negative during
						nighttime (cooling). In urban environments, it can be close to zero
						or even positive also during nighttime, i.e. nocturnal cooling is
						reduced.
					</StoryParagraph>
					<StoryParagraph>
						The energy input Q* is partitioned into the energy fluxes sensible
						heat Q<span className="cure-Story-smallSymbolBottom">H</span>,
						latent heat Q<span className="cure-Story-smallSymbolBottom">E</span>
						, and storage heat
						<span style={{fontSize: '0.8rem'}}> &#9651;</span>Q
						<span className="cure-Story-smallSymbolBottom">S</span>. In cities,
						40-60% of Q* go into the storage term during daytime. For natural
						surfaces, only 5-15% of daytime Q* are stored in the ground, meaning
						less energy is available for the sensible (and latent) heat flux in
						cities. The city therefore acts as a huge battery, storing energy
						during daytime, which is then released during nighttime.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Intra-urban Heat Emission Dynamics
					</StorySubheadline>
					<StoryParagraph className={''}>
						In order to understand, why sensible heat flux is lower in the city
						center than in the rural surroundings during daytime and vice versa
						during nighttime, some basic knowledge about the urban energy
						balance is required (see description text on urban energy balance).
						Here, nighttime scenes provide information about nocturnal heat load
						while daytime scenes show the distinct differences between natural
						and urban surface in sensible heat flux due to the different
						partitioning of the available energy into energy fluxes, like e.g.
						the storage heat flux in the CURE{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap09-urban-heat-storage'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Urban Heat Storage Monitoring service
						</Link>
						.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Interactive timeline of the temporal distribution Q
						<span style={{fontSize: '0.6rem'}}>H</span> maps are available (
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap01-surface-dynamics'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Local Scale Surface Temperature Dynamics
						</Link>
						). Users can select particular Q
						<span className="cure-Story-smallSymbolBottom">H</span> scenes in
						the timeline. On click Q
						<span className="cure-Story-smallSymbolBottom">H</span> maps are
						visualized. In time acquisition dates of scene-based satellite data
						are illustratory shown.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Aggregated statistics for user-defined units
					</StorySubheadline>
					<StoryParagraph className={''}>
						Urban Heat Emission information can be related to user defined units
						(e.g. urban plots, neighbourhood blocks, districts) to be ready for
						specific level of analysis and assessment. See visualization of CLMS
						Urban Atlas blocks with aggregated Urban Heat Emission coming from
						the CURE Urban Heat Emission service. Hoover on the map to explore
						aggregated Urban Heat Emission values. Use slider to inspect
						variation, value distribution and differences between daytime and
						nighttime. Hoover on the map to show information about sensible heat
						flux values in context of the urban land use class for the
						respective Urban Atlas block and inspect differences between daytime
						and nighttime values of sensible heat flux.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						Maps of Urban Heat Emissions are an important component for
						estimating the overall heat load for people living in cities,
						especially during nighttime. In combination with the other CURE heat
						applications &ldquo;
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap02-urban-heat-island'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Surface Urban Heat Island Assessment
						</Link>
						&rdquo;, &ldquo;
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap08-urban-thermal-comfort'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Urban Thermal Comfort
						</Link>
						&rdquo; and &ldquo;
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap09-urban-heat-storage'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Urban Heat Storage Monitoring
						</Link>
						&rdquo; it provides a comprehensive overview of a city’s heat load.
						As such, it guides local and regional decision makers in efficient
						city intervention planning and policy-making to reduce impacts due
						to climate change.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								Real-time measurements of sensible heat flux at urban flux
								towers:
								<br />
								<a href="http://rslab.gr/heraklion_eddy.html">Heraklion</a>
								<br />
								<a href="https://mcr.unibas.ch/dolueg2/index.php?project=basel">
									Basel
								</a>
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								<a href="https://www.mdpi.com/2072-4292/13/18/3598">
									Scientific paper
								</a>{' '}
								about urban heat (and cool) islands and its explanation by the
								different partitioning of the available energy into energy
								fluxes for urban and natural surfaces (Parlow, 2021).
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						This service is based on the aerodynamic resistance method (ARM). It
						uses high resolution digital object models (DOM) of buildings and
						trees for estimation of roughness parameters, in-situ measurements
						of standard meteorology and the CURE Land Surface Temperature
						service as input. The local data sets are supported by Copernicus
						Services CLMS (Urban Atlas) and C3S (ERA5).
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<StoryParagraph className={''}>
						This service is provided by{' '}
						<a href="https://duw.unibas.ch/en/research-groups/atmospheric-sciences/">
							Atmospheric Sciences, University of Basel, Switzerland
						</a>
						.
					</StoryParagraph>
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro03.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Monitor and Analyze Heat Emissions in your city
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App3_ShowcaseA mapSetKey="ap03-urban-heat-emissions-a" />
				<App3_ShowcaseC mapSetKey="ap03-urban-heat-emissions-showcase-c" />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Basel',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AAAvSWvccdwJwftq9bzmMzJNa/CURE_AP03_BASEL_sourcedata.ZIP?raw=1',
							},
							{
								city: 'Heraklion',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AABwefCC6UH_YDXJrCPRWDYPa/CURE_AP03_HERAKLION_sourcedata.ZIP?raw=1',
							},
						]}
						otherApps={[
							{
								key: 'ap02-urban-heat-island',
								label: 'Surface Urban Heat Island Assessment',
							},
							{
								key: 'ap04-urban-co2-emissions',
								label: 'Urban CO2 Emissions Monitoring',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App3_UrbanHeatEmissions.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App3_UrbanHeatEmissions;
