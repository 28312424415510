import PropTypes from 'prop-types';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import Showcase from '../../../common/Showcase';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';
import MapTooltip from '../../../common/maps/MapTooltip';
import RasterTooltipContent from './tooltips/RasterTooltipContent';
import DateLabel from '../../../common/DateLabel';

const Tooltip = props => (
	<MapTooltip {...props}>
		<RasterTooltipContent />
	</MapTooltip>
);

const App6_ShowcaseA = ({mapSetKey}) => {
	return (
		<Showcase>
			<MapSetWrapper
				MapTooltip={Tooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						highlightActiveMap: false,
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerKey: 'ap06-urban-subsidence-showcase-a-1-zones',
							info: [<DateLabel key={'DateLabel_day_1'} date={'2014-2016'} />],
						},
						{
							layerTemplateKey: '8d713084-fcf1-4df3-b1a9-a7af0c588222',
							info: [<DateLabel key={'DateLabel_day_1'} date={'2014-2016'} />],
						},
					],
				}}
				componentsByLayer={[
					{
						layerKey: 'ap06-urban-subsidence-showcase-a-1-zones',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap06-urban-subsidence',
								showcaseKey: 'App6_ShowcaseA',
								noData: false,
							},
						},
					},
					{
						layerTemplateKey: '8d713084-fcf1-4df3-b1a9-a7af0c588222',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap06-urban-subsidence',
								showcaseKey: 'App6_ShowcaseA',
								noData: false,
							},
						},
					},
				]}
			/>
		</Showcase>
	);
};

App6_ShowcaseA.propTypes = {
	mapSetKey: PropTypes.string,
	rasterLegendData: PropTypes.object,
};

export default App6_ShowcaseA;
