import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {getRouter} from '../../../router';

const OrderServiceTool = ({
	user,
	config,
	setServicesModalIsOpen,
	applicationStoryKey,
	setOpenAppSettings,
	isDark,
}) => {
	const userIsLogged = user && user?.data?.name !== 'guest';
	return (
		<Button
			disabled={userIsLogged ? (config ? false : true) : true}
			icon="settings"
			onClick={() => {
				getRouter().nav(
					isDark ? '/cure-services?darkMode=true' : '/cure-services'
				);
				setServicesModalIsOpen();
				setOpenAppSettings(applicationStoryKey, true);
			}}
		>
			Order service
		</Button>
	);
};

OrderServiceTool.propTypes = {
	user: PropTypes.array,
	config: PropTypes.object,
	setServicesModalIsOpen: PropTypes.func,
	applicationStoryKey: PropTypes.any,
	setOpenAppSettings: PropTypes.func,
	isDark: PropTypes.bool,
};

export default OrderServiceTool;
