import {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Input} from '@gisatcz/ptr-atoms';
import './style.scss';

const LoginForm = ({onSubmitLogin, clearErrorsAndMessages, errors}) => {
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const formRef = useRef(null);
	const [emailValid, setEmailValid] = useState(null);
	const [passwordValid, setPasswordValid] = useState(null);

	const handleSubmit = async event => {
		//Prevent page reload
		event.preventDefault();
		var {pass, uname} = event.target;

		onSubmitLogin(uname.value, pass.value);
	};

	// Generate JSX code for error message
	const renderErrorMessage = () => <div className="error">{errors}</div>;

	const clearFormInfo = () => {
		clearErrorsAndMessages();
	};

	const checkEmailValidity = val => {
		const mailformat = new RegExp(
			/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
		);
		if (mailformat.test(val)) {
			setEmailValid(true);
		} else {
			setEmailValid(false);
		}

		setUserName(val);
	};

	const checkPasswordValidity = val => {
		setPasswordValid(val && val.length >= 6);
		setPassword(val);
	};

	const onFormChange = () => {
		clearFormInfo();
	};

	// TODO handle signup form
	return (
		<div className="form">
			<form ref={formRef} onSubmit={handleSubmit} onChange={onFormChange}>
				<div className="cure-InputContainer">
					<label>Username</label>
					<Input
						name={'uname'}
						required={true}
						email={true}
						onChange={checkEmailValidity}
						placeholder={'email'}
						className={`${
							emailValid === null ? '' : emailValid ? 'valid' : 'invalid'
						}`}
						value={userName}
					/>
				</div>
				<div className="cure-InputContainer">
					<label>Password </label>
					<Input
						name={'pass'}
						required={true}
						password={true}
						onChange={checkPasswordValidity}
						placeholder={'At least 6 character password'}
						className={`${
							passwordValid === null ? '' : passwordValid ? 'valid' : 'invalid'
						}`}
						value={password}
					/>
					{errors ? renderErrorMessage() : null}
				</div>
				<div className="cure-ButtonContainer">
					<button
						tabIndex={0}
						type="submit"
						disabled={!!errors || !passwordValid || !emailValid}
						className={'ptr-button primary'}
					>
						Log in
					</button>
					{/*<button*/}
					{/*	tabIndex={0}*/}
					{/*	className={'cure-login-btn'}*/}
					{/*	onClick={onCreateNewUserClick}*/}
					{/*>*/}
					{/*	{"Don't have account yet? Sign up."}*/}
					{/*</button>*/}
					<a
						tabIndex={0}
						className={'cure-login-btn'}
						href="mailto:cure_login@gisat.cz"
					>
						{"Don't have account yet? Contact us at cure_login@gisat.cz."}
					</a>
				</div>
			</form>
		</div>
	);
	// }
};

LoginForm.propTypes = {
	onSubmitLogin: PropTypes.func,
	onCreateNewUserClick: PropTypes.func,
	clearErrorsAndMessages: PropTypes.func,
	errors: PropTypes.string,
};

export default LoginForm;
