import PropTypes from 'prop-types';
import classNames from 'classnames';
import './style.scss';

const Hero = ({children, className}) => {
	const classes = classNames('cure-Hero', {}, className);

	return <div className={classes}>{children}</div>;
};

Hero.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
};

export default Hero;
