import PropTypes from 'prop-types';
import './style.scss';

const ChartTooltip = ({children}) => {
	return <div className="ptr-ChartTooltip">{children}</div>;
};

ChartTooltip.propTypes = {
	children: PropTypes.node,
};

export default ChartTooltip;
