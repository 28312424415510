import PropTypes from 'prop-types';
import classNames from 'classnames';
import MapLegendClassItem from './MapLegendClassItem';
import MapLegendClassesBar from './MapLegendClassesBar';
import './style.scss';

const MapLegendAttributeClasses = ({intervals, isExpanded}) => {
	const classes = classNames('cure-MapLegend-AttributeClasses', {
		'is-expanded': isExpanded,
	});

	return (
		<div className={classes}>
			{isExpanded ? (
				intervals.map(interval => (
					<MapLegendClassItem
						key={interval.interval}
						color={interval.fill}
						label={
							interval?.name ||
							`${interval?.interval[0]} - ${interval?.interval[1]}`
						}
					/>
				))
			) : (
				<MapLegendClassesBar intervals={intervals} />
			)}
		</div>
	);
};

MapLegendAttributeClasses.propTypes = {
	intervals: PropTypes.array,
	isExpanded: PropTypes.bool,
};

export default MapLegendAttributeClasses;
