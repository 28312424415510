import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {Icon} from '@gisatcz/ptr-atoms';
import {useState} from 'react';
import Select from '../../../../../common/Select';

const defaultCity = {
	value: 'Heraklion',
	label: 'Heraklion',
};

const appStoryKey = 'ap05-urban-flood-risk';

const LocalScaleSurfaceTemperatureDynamics = ({
	scope,
	onClose,
	cities,
	onServiceSent,
	openAppSettings,
	setOpenAppSettings,
}) => {
	const [city, setCity] = useState(defaultCity);
	const [waterLevel, setWaterLevel] = useState(0);

	return (
		<div
			className="cure-ManageServices-apContainer"
			style={
				openAppSettings?.[appStoryKey]
					? {border: '2px solid var(--accent45)'}
					: {border: '2px solid rgb(182, 182, 182)'}
			}
		>
			<div className="cure-ManageServices-apHead">
				<h2 className="cure-ManageServices-apTitle">
					{scope?.data?.nameDisplay}
				</h2>
				<div className="cure-ManageServices-openServiceBtnWrapper">
					<Button
						className="cure-ManageServices-openServiceBtn"
						enabled
						onClick={() => {
							setOpenAppSettings(
								appStoryKey,
								!openAppSettings?.[appStoryKey],
								openAppSettings
							);
						}}
					>
						<div
							className="cure-ManageServices-openServiceIcon"
							style={
								openAppSettings?.[appStoryKey]
									? {transform: 'rotate(180deg)'}
									: {transform: 'rotate(0deg)'}
							}
						>
							<Icon icon={'ri-chevron-down'} />
						</div>
						Setup service
					</Button>
				</div>
			</div>
			<div
				className="cure-ManageServices-apSelects"
				style={
					openAppSettings?.[appStoryKey]
						? {
								overflow: 'visible',
								height: '100%',
								padding: '2rem 0 0 0',
						  }
						: {
								overflow: 'hidden',
								height: '0rem',
								padding: '0rem',
						  }
				}
			>
				<div className="cure-ManageServices-apSelectContainer">
					<h3 className="cure-ManageServices-apSelectTitle">Places</h3>
					<div className="cure-ManageServices-apSelect">
						<Select
							disabled
							small
							options={cities}
							value={city}
							onChange={object => setCity(object)}
							menuPosition={'fixed'}
						/>
					</div>
				</div>
				<div className="cure-ManageServices-apSelectContainer">
					<h3 className="cure-ManageServices-apSelectTitle">
						Water Level [0-100 m]
					</h3>
					<div className="cure-ManageServices-apSelect">
						<input
							name={'water_level'}
							type={'number'}
							min="0"
							max="100"
							required
							step={0.1}
							onChange={e => {
								if (e.target.validity.valid) {
									setWaterLevel(Number.parseFloat(e.target.value));
								}
							}}
							className={`ptr-input-text`}
							value={waterLevel}
						/>
					</div>
				</div>
				<div className="cure-ManageServices-apStartBtnWrapper">
					<Button
						className="cure-ManageServices-apStartBtn"
						icon="settings"
						disabled={false}
						onClick={() => {
							setOpenAppSettings(
								appStoryKey,
								!openAppSettings?.[appStoryKey],
								openAppSettings
							);
							onServiceSent({
								city: city?.label,
								water_level: waterLevel,
							});
							onClose(false);
							setCity(undefined);
						}}
					>
						Start processing
					</Button>
				</div>
			</div>
		</div>
	);
};

LocalScaleSurfaceTemperatureDynamics.propTypes = {
	cities: PropTypes.array,
	scope: PropTypes.object,
	onServiceSent: PropTypes.func,
	onClose: PropTypes.func,
	openAppSettings: PropTypes.object,
	setOpenAppSettings: PropTypes.func,
};

export default LocalScaleSurfaceTemperatureDynamics;
