import PropTypes from 'prop-types';
import {cloneElement, isValidElement} from 'react';

import './style.scss';

const LayerLabelHeader = ({title, info, layerState, mapKey}) => {
	const infoEl =
		info && info.length
			? info.map(infoByLayer => {
					return infoByLayer && infoByLayer.length
						? infoByLayer.map((i, index) => {
								let iEl = null;
								let key = index;
								if (isValidElement(i)) {
									iEl = cloneElement(i, {...i.props, layerState, mapKey});
								} else {
									iEl = i;
									key = `${index}_${i}`;
								}
								return (
									<div className="cure-LayerLabelHeader-info" key={key}>
										{iEl}
									</div>
								);
						  })
						: null;
			  })
			: null;

	return (
		<div className="cure-LayerLabelHeader" data-tour="layer-label">
			<div className="cure-LayerLabelHeader-title">{title}</div>
			{infoEl.flat()}
		</div>
	);
};

LayerLabelHeader.propTypes = {
	mapKey: PropTypes.string,
	title: PropTypes.string,
	info: PropTypes.array,
	layerState: PropTypes.object,
};

export default LayerLabelHeader;
