import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import showcaseCdata from '../../../../data/app02/showcaseC/suhii_timeSeries.json';
import Select from '../../../Select';

const getShowcaseCData = createSelector(
	[
		CommonSelect.places.getActive,
		state => Select.components.get(state, 'App2_ShowcaseC_Chart', 'mode'),
	],
	(activePlace, mode) => {
		if (activePlace && (!mode || mode === 'active')) {
			const city = activePlace?.data?.nameInternal;
			return showcaseCdata.filter(item => item.placeNameInternal === city);
		} else {
			return showcaseCdata;
		}
	}
);

export default {
	getShowcaseCData,
};
