import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';

//Showcases
import App9_ShowcaseA from './App9_ShowcaseA';
import App9_ShowcaseC from './App9_ShowcaseC';
import OrderServiceTool from '../../common/OrderServiceTool';
import StoryFooterSection from '../../common/StoryFooterSection';

const App9_UrbanHeatStorage = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						Observations of global temperature evolution indicate a pronounced
						air temperature warming, since an increase in the occurrence of heat
						waves and the Urban Heat Island (UHI) effects tends to exacerbate
						such warming. Among all the effects caused by the substitution of
						natural ecosystems for urban land-use, the most pronounced is the
						increase in the amount of energy stored in the urban canopy, which
						is approximately 2-6 times larger than in non-urban canopies. The
						slow release of this energy, stored mainly in the buildings and
						paved surfaces of cities, causes the UHI effect and it is related to
						the energy efficiency and consumption in cities. The CURE Urban Heat
						Storage Monitoring application deploys various earth observation and
						in-situ urban data towards monitoring urban heat storage. The
						storage heat flux (ΔQS) is defined as the net flow of heat stored
						within a volume that includes air, trees, buildings and ground. The
						ability to absorb, store and release heat depends on the thermal
						mass and morphology. This CURE application provides time series maps
						at local scale (100 m) of the storage heat flux at the time of
						Sentinel-3 overpasses and operates for Heraklion and Basel in CURE.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>Heat Storage Maps</StorySubheadline>
					<StoryParagraph className={''}>
						CURE uses Copernicus Services and Satellite data for estimating the
						heat storage flux for urban areas at the time of the satellite
						acquisitions. Quantitative information on the heat stored in and
						released from areas of 100 m × 100 m are provided in the form of
						maps. The map values represent the rate of energy exchange between
						the atmosphere and the surface, with positive values indicating
						energy storage in urban surfaces, and negative values indicating
						energy release. For example, an area appearing with a value of 400
						W/m<sup>2</sup> is storing energy corresponding to 400 W/m
						<sup>2</sup> at that time (the satellite acquisition time).
						Similarly, a value of -70 W/m
						<sup>2</sup> suggests energy release of 70 W/m<sup>2</sup> at the
						specific for the respective area.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Since CURE is using satellite data to retrieve land surface
						temperature, which is necessary input to the heat storage flux
						estimation, products are only available corresponding to the
						satellite acquisition times. Therefore, heat storage flux maps may
						be available twice a day (daytime and nighttime) with a pixel size
						of 100 m × 100 m. Due to complete cloud cover over the city, data
						for several days are not available. Missing data for certain areas
						in the rest of the maps are caused by partial cloud cover in the
						scene during the satellite acquisitions.{' '}
					</StoryParagraph>
					<StoryParagraph className={''}>
						Scroll a time bar at the bottom of the maps and visualize available
						products.{' '}
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Heat Storage Change for user-defined units{' '}
					</StorySubheadline>
					<StoryParagraph className={''}>
						Heat storage change information can be related to user defined units
						(e.g. urban plots, neighbourhood blocks, districts) to be ready for
						specific level of analysis. See visualization of CLMS Urban Atlas
						polygons with aggregated heat storage change coming from the CURE
						service. Hoover on the map to explore aggregated values over
						specific areas. Use slider to inspect variation, value distribution
						and differences between daytime and nighttime.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						Heat storage maps along with the other heat related CURE products
						provide thorough information on the city’s heat load for different
						times of the day and different seasons, including the past and
						future. Storage heat fluxes maps allow evaluating its intra-urban
						variations, which consists valuable information to support
						mitigation and adaptation strategies to reduce heat. At the time of
						the release of this application, no similar service or product are
						available.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								<a href="http://urbanfluxes.eu/">The URBANFLUXES project,</a>
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								Real-time measurements of sensible heat flux at urban flux
								towers:{' '}
								<a href="http://rslab.gr/heraklion_eddy.html">Heraklion</a> and{' '}
								<a href="https://mcr.unibas.ch/dolueg2/index.php?project=basel">
									Basel
								</a>
								.
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						The service is based on{' '}
						<a href="https://sentinels.copernicus.eu/web/sentinel/home">
							Copernicus Sentinel satellite imagery
						</a>
						, <a href="https://land.copernicus.eu/">CLMS</a>,{' '}
						<a href="https://climate.copernicus.eu/">C3S</a> and{' '}
						<a href="https://atmosphere.copernicus.eu/">CAMS</a> Copernicus
						Services. The methodology is using the Objective Hysteresis Model (
						<a href="https://rmets.onlinelibrary.wiley.com/doi/abs/10.1002/joc.1198">
							https://rmets.onlinelibrary.wiley.com/doi/abs/10.1002/joc.1198
						</a>
						) parameterized with information from Copernicus.
					</StoryParagraph>
					<StoryParagraph className={''}>
						This service is provided by{' '}
						<a href="http://rslab.gr/">
							Foundation for Research and Technology Hellas (FORTH)
						</a>
						, Greece.
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro09.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Monitor and analyze how energy is stored and released in your city
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App9_ShowcaseA mapSetKey="ap09-urban-heat-storage-showcase-a" />
				<App9_ShowcaseC mapSetKey="ap09-urban-heat-storage-showcase-c" />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Basel',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AABvNSvW9XysxfRH1RF5CKu1a/CURE_AP09_Basel_sourcedata.zip?raw=1',
							},
							{
								city: 'Heraklion',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AAArjgUDcYCHm7EaEsPtm3PCa/CURE_AP09_Heraklion_sourcedata.zip?raw=1',
							},
						]}
						otherApps={[
							{
								key: 'ap02-urban-heat-island',
								label: 'Surface Urban Heat Island Assessment',
							},
							{
								key: 'ap10-nature-based-solutions',
								label: 'Nature-Based Solutions',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App9_UrbanHeatStorage.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App9_UrbanHeatStorage;
