import PropTypes from 'prop-types';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import MapTooltip from '../../../common/maps/MapTooltip/VectorMapTooltip';
import Showcase from '../../../common/Showcase';
import ShowcaseNoDataInfo from '../../../common/Showcase/ShowcaseNoDataInfo';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';
import DateLabel from '../../../common/DateLabel';

import './style.scss';

const App3_ShowcaseC = ({mapSetKey, noDataForCurrentPlace}) => {
	return (
		<Showcase>
			{noDataForCurrentPlace ? (
				<ShowcaseNoDataInfo>
					This showcase has no data for selected city
				</ShowcaseNoDataInfo>
			) : (
				<MapSetWrapper
					MapTooltip={MapTooltip}
					mapSetKey={mapSetKey}
					config={{
						mapSet: {
							highlightActiveMap: false,
							controls: {
								horizontal: true,
							},
						},
						layers: [
							{
								layerKey:
									'ap03-urban-heat-emissions-showcase-c-1-urban-atlas-polygons',
								info: [
									<DateLabel key={'DateLabel_day_1'} date={'2019-07-21'} />,
								],
							},
							{
								layerKey:
									'ap03-urban-heat-emissions-showcase-c-2-urban-atlas-polygons',
								info: [
									<DateLabel key={'DateLabel_day_1'} date={'2019-07-21'} />,
								],
							},
						],
					}}
					componentsByLayer={[
						{
							layerKey:
								'ap03-urban-heat-emissions-showcase-c-1-urban-atlas-polygons',
							legend: {
								component: StyleBasedLegend,
								props: {
									applicationStoryKey: 'ap03-urban-heat-emissions',
									showcaseKey: 'App3_ShowcaseC',
								},
							},
						},
						{
							layerKey:
								'ap03-urban-heat-emissions-showcase-c-2-urban-atlas-polygons',
							legend: {
								component: StyleBasedLegend,
								props: {
									applicationStoryKey: 'ap03-urban-heat-emissions',
									showcaseKey: 'App3_ShowcaseC',
								},
							},
						},
					]}
				/>
			)}
		</Showcase>
	);
};

App3_ShowcaseC.propTypes = {
	mapSetKey: PropTypes.string,
	noDataForCurrentPlace: PropTypes.bool,
};

export default App3_ShowcaseC;
