export const zones = {
	0: 'No zone',
	1: 'Zone 1: 0 - 0.2 m',
	2: 'Zone 2: 0.2 - 0.5 m',
	3: 'Zone 3: 0.5 - 1 m',
	4: 'Zone 4: 1 - 2 m',
	5: 'Zone 5: > 2m',
};

export const uaClasses = {
	11100: 'Continuous Urban fabric (S.L. > 80%)',
	11210: 'Discontinuous Dense Urban Fabric (S.L.: 50% - 80%)',
	11220: 'Discontinuous Medium Density Urban Fabric (S.L.: 30% - 50%)',
	11230: 'Discontinuous Low Density Urban Fabric (S.L.: 10% - 30%)',
	11240: 'Discontinuous very low density urban fabric (S.L. < 10%)',
	11300: 'Isolated Structures',
	12100: 'Industrial, commercial, public, military and private units',
	12300: 'Port areas',
	14100: 'Green urban areas',
	14200: 'Sports and leisure facilities',
	21000: 'Arable land (annual crops)',
	22000: 'Permanent crops',
	32000: 'Herbaceous vegetation associations',
};
