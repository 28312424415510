import {isPlainObject as _isPlainObject} from 'lodash';
// import timelineSelectors from './selectors';

import {
	commonSelectors as common,
	Select as CommonSelect,
} from '@gisatcz/ptr-state';
import {createSelector} from 'reselect';

/**
 * TODO tests
 * For each layer defined in layerRow.items and its periods execute callback with parameters [layer, mapLayerDefinition, period||periodKey]
 * @param {Object} state
 * @param {Object} layerRow
 * @param {function} callback
 */
export const forEachPeriodAndLayerInLayerRow = (state, layerRow, callback) => {
	const activeKeys = common.getAllActiveKeys(state);
	layerRow?.items?.forEach(timelineLayer => {
		//iterate over layer periods defined in array
		if (timelineLayer?.periods?.length > 0) {
			timelineLayer?.periods.forEach(timelineLayerPeriodItem => {
				const timelineMapLayerDefinition =
					CommonSelect.timeline.mapTimeline.getTimelineMapLayerPeriodDefinition(
						timelineLayer.layerState,
						timelineLayerPeriodItem?.key,
						activeKeys
					);

				callback(
					timelineLayer,
					timelineMapLayerDefinition,
					timelineLayerPeriodItem
				);
			});
		} else if (_isPlainObject(timelineLayer?.periods)) {
			// iterate over layer periods defined by period filter
			const periodsConfig = timelineLayer.periods;
			// fixme
			// should be shared variable also for selector
			const order = null;
			const start = 0;
			const length = 1000;
			const periodKeys =
				CommonSelect.timeline.mapTimeline.getPeriodKeysForFilteredSpatialRelations(
					state,
					periodsConfig.filterByActive,
					periodsConfig.filter,
					order,
					start,
					length
				) || [];

			periodKeys.forEach(periodKey => {
				const timelineMapLayerDefinition =
					CommonSelect.timeline.mapTimeline.getTimelineMapLayerPeriodDefinition(
						timelineLayer.layerState,
						periodKey,
						activeKeys
					);

				callback(timelineLayer, timelineMapLayerDefinition, periodKey);
			});
		}
	});
};

export const getPreviousMapLayerSettings = createSelector(
	[CommonSelect.maps.getMapLayersStateByMapKey],
	layers => {
		if (layers.length > 0) {
			const {opacity, options} = layers[0];
			return {
				opacity,
				options,
			};
		} else {
			return null;
		}
	}
);

/**
 * @param prevSettings
 * @param prevSettings.opacity {number}
 * @return {*|number}
 */
export const getLayerOpacity = prevSettings => {
	return prevSettings && Object.hasOwn(prevSettings, 'opacity')
		? prevSettings.opacity
		: 1;
};

/**
 * @param prevSettings
 * @param prevSettings.options {Object}
 * @return {Object}
 */
export const getLayerOptions = prevSettings => {
	return {
		hoverable:
			prevSettings?.options && Object.hasOwn(prevSettings.options, 'hoverable')
				? prevSettings.options.hoverable
				: true,
		legendHidden:
			prevSettings?.options &&
			Object.hasOwn(prevSettings.options, 'legendHidden')
				? prevSettings.options.legendHidden
				: false,
	};
};
