import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		noDataForCurrentPlace:
			Select.cure.applicationStories.isDataForCurrentPlaceAvailable(
				state,
				'ap03-urban-heat-emissions',
				'App3_ShowcaseC'
			),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
