import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../../state/Action';
import Select from '../../../../../../state/Select';
import Presentation from './presentation';

const appKey = 'app10';

const mapStateToProps = (state, ownProps) => {
	const scope = Select.scopes.getByKey(state, ownProps.scopeKey);
	const cities = Select.cure
		.getCitiesForProcessing()
		.map(c => ({value: c, label: c}));
	return {
		scope,
		cities,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onServiceSent: params => {
			return dispatch(Action.cure.sendUserServices(appKey, params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
