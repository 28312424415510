import './style.scss';
import {getRouter} from '../../../../router';
import Link from '../../../common/Link';

import copernicus from '../../../../assets/img/copernicus_logo.png';
import wekeo from '../../../../assets/img/wekeo_logo.png';
// import eu from '../../../../assets/img/eu.webp';
import CenteredWrap from '../../../common/CenteredWrap';
import Hero from '../Hero';

// const fundingText =
// 	"CURE has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement No 870337.";

const About = () => {
	return (
		<div className="cure-About">
			<Hero>
				<h1 data-tour="portal">CURE PORTAL</h1>
				<p>
					CURE provides services for urban resilience through the CURE System
					implemented in{' '}
					<a
						href="https://www.wekeo.eu/"
						target="_blank"
						rel="noreferrer noopener"
					>
						Wekeo
					</a>{' '}
					of the{' '}
					<a
						href="https://www.copernicus.eu/en"
						target="_blank"
						rel="noreferrer noopener"
					>
						Copernicus DIAS (Data and Information Access Services)
					</a>
					.
				</p>
			</Hero>
			<CenteredWrap>
				<div className="cure-About-content">
					<p>
						The CURE project has developed 11 cross-cutting applications among
						the Copernicus Services, addressing the multi-dimensional nature of
						urban resilience:
					</p>
					<ul>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap01-surface-dynamics'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Local Scale Surface Temperature Dynamics
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap04-urban-co2-emissions'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Urban CO
								<span className="cure-AppStory-smallSymbolBottom">2</span>{' '}
								Emissions Monitoring
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap05-urban-flood-risk'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Urban Flood Risk
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap10-nature-based-solutions'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Nature-Based Solutions
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap07-urban-air-quality'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Urban Air Quality
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap08-urban-thermal-comfort'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Urban Thermal Comfort
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap11-health-impact'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Socioeconomic perspective of Health Impacts
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap06-urban-subsidence'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Urban Subsidence, Movements and Deformation Risk
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap03-urban-heat-emissions'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Urban Heat Emissions Monitoring
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap09-urban-heat-storage'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Urban Heat Storage Monitoring
							</Link>
						</li>
						<li>
							<Link
								tabIndex={0}
								name={`applicationStoryScreen`}
								updateParams={{key: 'ap02-urban-heat-island'}}
								router={getRouter()}
								paramsFilter={['tags', 'step']}
								recoverParamsFilter={['darkMode']}
							>
								Surface Urban Heat Island Assessment
							</Link>
						</li>
					</ul>

					<p>
						The CURE data products, linked to the CURE System, are open and
						available for demonstration and evaluation through this portal{' '}
					</p>
					<div className="cure-About-logos">
						<a
							href="https://www.copernicus.eu/en"
							target="_blank"
							rel="noreferrer noopener"
						>
							<img src={copernicus} alt="copernicus" />
						</a>
						<a
							href="https://www.wekeo.eu/"
							target="_blank"
							rel="noreferrer noopener"
						>
							<img src={wekeo} alt="wekeo" />
						</a>
					</div>

					{/*<h4>The acknowledgement</h4>*/}
					{/*<p>{fundingText}</p>*/}
					{/*<div className="cure-About-logos">*/}
					{/*	<img src={eu} alt="eu" />*/}
					{/*</div>*/}
				</div>
			</CenteredWrap>
		</div>
	);
};

About.propTypes = {};

export default About;
