import PropTypes from 'prop-types';
import ShowcaseNoDataInfo from '../../../../common/Showcase/ShowcaseNoDataInfo';
const Overlay = ({mapKey, onClick}) => (
	<ShowcaseNoDataInfo mapKey={mapKey} onClick={onClick}>
		This showcase has no data for selected combination of WGBT Type and layer.
	</ShowcaseNoDataInfo>
);
Overlay.propTypes = {
	mapKey: PropTypes.string,
	onClick: PropTypes.func,
};

export default Overlay;
