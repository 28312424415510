import {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Input} from '@gisatcz/ptr-atoms';
import './style.scss';

const SingUpForm = ({createUser, messages, errors, clearErrorsAndMessages}) => {
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const formRef = useRef(null);
	const [emailValid, setEmailValid] = useState(null);
	const [passwordValid, setPasswordValid] = useState(null);

	useEffect(() => {
		return clearErrorsAndMessages;
	}, []);

	const handleSubmit = async event => {
		//Prevent page reload
		event.preventDefault();
		var {pass, uname} = event.target;

		createUser(uname.value, pass.value);
	};

	// Generate JSX code for error message
	const renderErrorMessages = () => <div className="error">{errors}</div>;

	const clearFormInfo = () => {
		clearErrorsAndMessages();
	};

	const checkEmailValidity = val => {
		const mailformat = new RegExp(
			/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
		);
		if (mailformat.test(val)) {
			setEmailValid(true);
		} else {
			setEmailValid(false);
		}

		setUserName(val);
	};

	const checkPasswordValidity = val => {
		setPasswordValid(val && val.length >= 6);
		setPassword(val);
	};

	const onFormChange = () => {
		clearFormInfo();
	};

	if (messages) {
		return <div>{messages}</div>;
	} else {
		return (
			<div className="form">
				<form ref={formRef} onSubmit={handleSubmit} onChange={onFormChange}>
					<div className="cure-InputContainer">
						<label>Username</label>
						<Input
							name={'uname'}
							required={true}
							email={true}
							onChange={checkEmailValidity}
							placeholder={'email'}
							className={`${
								emailValid === null ? '' : emailValid ? 'valid' : 'invalid'
							}`}
							value={userName}
						/>
					</div>
					<div className="cure-InputContainer">
						<label>Password </label>
						<Input
							name={'pass'}
							required={true}
							password={true}
							onChange={checkPasswordValidity}
							placeholder={'At least 6 character password'}
							className={`${
								passwordValid === null
									? ''
									: passwordValid
									? 'valid'
									: 'invalid'
							}`}
							value={password}
						/>
						{errors ? renderErrorMessages() : null}
					</div>
					<div className="cure-ButtonContainer">
						<button
							tabIndex={0}
							type="submit"
							disabled={!!errors || !passwordValid || !emailValid}
							className={'ptr-button primary'}
						>
							Sign up
						</button>
					</div>
				</form>
			</div>
		);
	}
};

SingUpForm.propTypes = {
	createUser: PropTypes.func,
	clearErrorsAndMessages: PropTypes.func,
	messages: PropTypes.string,
	errors: PropTypes.string,
};

export default SingUpForm;
