import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import RouterSelectors from '../../../router/selectors';

/**
 * Get configuration for active/current story
 * @param state {Object}
 * @return {Object|null}
 */
const getConfigForActiveApplicationStory = createSelector(
	[
		state =>
			CommonSelect.app.getConfiguration(state, 'configByApplicationStoryKey'),
		state => RouterSelectors.getStory(state),
	],
	(configs, storyKey) => {
		return configs?.[storyKey] || null;
	}
);

const getBaseTags = createSelector(
	[CommonSelect.components.getByComponentKey],
	component => {
		if (component?.tagKeys) {
			return Object.keys(component?.tagKeys);
		} else {
			return [];
		}
	}
);

const getOptionTagKeys = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		(state, componentKey, tagKey) => tagKey,
	],
	(component, tagKey) => {
		if (component?.tagKeys) {
			if (component?.tagKeys?.[tagKey]?.keys) {
				return component?.tagKeys?.[tagKey]?.keys;
			} else {
				return [];
			}
		} else {
			return [];
		}
	}
);

const getActiveTagKey = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		(state, componentKey, tagKey) => tagKey,
		(state, componentKey, tagKey, mapKey) => mapKey,
	],
	(component, tagKey, mapKey) => {
		if (component?.tagKeys) {
			if (component?.tagKeys?.[tagKey]?.keys) {
				const activeTagKey = component?.tagKeys?.[tagKey]?.active?.[mapKey];
				return activeTagKey;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getActiveLayerTemplateKey = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		(state, componentKey, mapKey) => mapKey,
	],
	(component, mapKey) => {
		return component?.active?.[mapKey];
	}
);

const getDisplayMapOverlayConfiguration = createSelector(
	[
		getConfigForActiveApplicationStory,
		CommonSelect.maps.getMapSetByKey,
		state => CommonSelect.components.getAllByKeyObserver(state),
		(state, mapSetKey, wgbtTagKey) => wgbtTagKey,
		(state, mapSetKey, wgbtTagKey, layerTemplatesComponentKey) =>
			layerTemplatesComponentKey,
	],
	(
		appConfiguration,
		mapSet,
		components,
		wgbtTagKey,
		layerTemplatesComponentKey
	) => {
		const mapKeys = mapSet.maps;
		const layerTemplatesComponent = components[layerTemplatesComponentKey];
		const disableMapForLayerTemplateKey =
			appConfiguration.disableMapForLayerTemplateKey;
		const disableMapForWGBTKey = appConfiguration.disableMapForWGBTKey;

		const overlayConfiguration = {};

		mapKeys.forEach(mapKey => {
			const layerTemplateFit =
				layerTemplatesComponent.active[mapKey] ===
				disableMapForLayerTemplateKey;
			const tagFit = wgbtTagKey === disableMapForWGBTKey;
			if (layerTemplateFit && tagFit) {
				overlayConfiguration[mapKey] = true;
			}
		});
		return overlayConfiguration;
	}
);

const getLayerTemplateKeysInMaps = createSelector(
	[
		state => CommonSelect.components.getAllByKeyObserver(state),
		(state, layerTemplatesComponentKey) => layerTemplatesComponentKey,
	],
	(components, layerTemplatesComponentKey) => {
		const layerTemplatesComponent = components[layerTemplatesComponentKey];
		const layerTemplateKeysInMaps = new Set();
		for (const layerTemplateKey of Object.values(
			layerTemplatesComponent.active
		)) {
			// layerTemplateKeysInMaps.push(layerTemplateKey);
			layerTemplateKeysInMaps.add(layerTemplateKey);
		}
		return [...layerTemplateKeysInMaps];
	}
);

const getStyleKeyForLegend = createSelector(
	[
		CommonSelect.places.getActiveKey,
		(state, applicationStoryKey, showcaseKey) =>
			CommonSelect.app.getConfiguration(
				state,
				`configByApplicationStoryKey.${applicationStoryKey}.showcases.${showcaseKey}.data.legend`
			),
		(state, applicationStoryKey, showcaseKey, layerTemplateKey) =>
			layerTemplateKey,
		(state, applicationStoryKey, showcaseKey, layerTemplateKey, caseKey) =>
			caseKey,
	],
	(activePlaceKey, config, layerTemplateKey, caseKey) => {
		if (activePlaceKey && config && layerTemplateKey && caseKey) {
			const legendForLayerTemplateKey =
				config[activePlaceKey]?.[caseKey]?.[layerTemplateKey]?.styleKey;
			return legendForLayerTemplateKey || null;
		} else {
			return null;
		}
	}
);

const getStyleForLegend = createSelector(
	[CommonSelect.styles.getAllAsObject, getStyleKeyForLegend],
	(styles, styleKey) => {
		return styles?.[styleKey] || null;
	}
);

const getPreparedStylesForLegend = createSelector(
	[getStyleForLegend],
	style => {
		if (style) {
			const definition = style?.data?.definition?.rules?.[0]?.styles;
			const baseStyle = definition[0];
			const attributeStyle = definition[1];
			return {
				baseStyle,
				attributeStyle,
			};
		} else {
			return null;
		}
	}
);

export default {
	getBaseTags,
	getOptionTagKeys,
	getActiveTagKey,
	getActiveLayerTemplateKey,
	getDisplayMapOverlayConfiguration,
	getLayerTemplateKeysInMaps,
	getStyleKeyForLegend,
	getPreparedStylesForLegend,
};
