const Showcase = () => (
	<div>
		<h4>Application Storyline Page – Interactive visuals </h4>
		<p>
			We made it intuitive, but familiarise with the main functionalities to
			streamline your work with mapping visuals. Chart and table visuals are
			even more straightforward, so use it in an intuitive way.
		</p>
	</div>
);

export default Showcase;
