const Filter = () => (
	<div>
		<h4>CURE Applications Overview Page - Filter</h4>
		<p>
			Check applications available. Filter individual applications according to
			policy response, topic and Copernicus Core services used.
		</p>
	</div>
);

export default Filter;
