import {connect, Select as CommonSelect} from '@gisatcz/ptr-state';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const component = CommonSelect.components.getByComponentKey(
		state,
		'ap04-tags-parameters'
	);

	const activeKey =
		component?.tagKeys?.[ownProps.parentPropKey]?.active?.[ownProps.mapKey];

	return {
		active: activeKey === ownProps.propKey,
	};
};

const mapDispatchToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
