import {connect} from '@gisatcz/ptr-state';
import Actions from '../../../../../state/Action';

import Presentation from './presentation';

const mapStateToProps = () => {
	return {};
};

const mapDispatchToProps = () => {
	return (dispatch, ownProps) => {
		return {
			onClick: () => {
				dispatch(Actions.maps.setMapSetActiveMapKey(ownProps.mapKey));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
