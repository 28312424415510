import Layer from '../Layer';

const getHoverContent = (x, time, evt, hoverContext, layerRows) => {
	const clientY = evt.clientY;

	// remove timeline as a overlay
	const hoveredOverlays = hoverContext?.hoveredItems?.filter(
		i => i.key !== 'timeline'
	);

	let top = 0;
	// select row by mouse position
	const layerRowMouseIntersection = layerRows?.find(layerRow => {
		top = top + (layerRow.lineHeight - layerRow.elementHeight) / 2;
		const layerRowTop = top;
		top = top + layerRow.elementHeight;
		const layerRowBottom = top;
		top = top + (layerRow.lineHeight - layerRow.elementHeight) / 2;
		const mouseIntersectRow =
			layerRowTop <= clientY && layerRowBottom >= clientY;
		return mouseIntersectRow;
	});

	const intersectionOverlaysElms =
		hoveredOverlays?.length > 0 && layerRowMouseIntersection ? (
			<div
				key={'layerRowMouseIntersection.key'}
				className={'ptr-timeline-tooltip-layer'}
			>
				<div>
					<span
						className="dot"
						style={{
							backgroundColor: layerRowMouseIntersection.items[0].colors.basic,
						}}
					></span>
				</div>
				<div>
					<div>
						<em>
							<Layer
								className={'ptr-timeline-tooltip-layer'}
								layerTemplateKey={
									layerRowMouseIntersection.legend.layerTemplateKey
								}
							/>
						</em>
					</div>
					<div className="ptr-timeline-tooltip-description-period">{`${hoveredOverlays[0].overlay?.origin?.originPeriod?.data?.nameDisplay}`}</div>
				</div>
			</div>
		) : null;

	return (
		<div>
			<div className={'ptr-timeline-tooltip-time'}>
				<b>{`${time.format('YYYY')}`}</b>-<b>{`${time.format('MM')}`}</b>-
				<b>{`${time.format('DD')}`}</b>
			</div>
			{intersectionOverlaysElms}
		</div>
	);
};

export default getHoverContent;
