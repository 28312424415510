import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';
import {getRouter} from '../../../router';
import Link from '../../common/Link';
import OrderServiceTool from '../../common/OrderServiceTool';

import App7_ShowcaseA from './App7_ShowcaseA';
import StoryFooterSection from '../../common/StoryFooterSection';

const App7_UrbanAirQuality = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						Ambient air pollution is one of the key environmental problems in
						Europe.{' '}
						<a href="https://www.eea.europa.eu/ims/exceedance-of-air-quality-standards">
							The European Environmental Agency (EEA)
						</a>{' '}
						estimates that currently more than 95% of the European population is
						exposed to air pollution levels above the guidelines of the World
						Health Organization. Exposure to these high levels of air pollution
						is associated with a significant premature mortality: in 2019, more
						than 350.000 premature deaths in the EU-27 are related to exposure
						to air pollution. Because of the severe health impacts, air
						pollution also has considerable economic impacts, increasing medical
						costs and reducing productivity through reduced activity days across
						various economic sectors.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The CURE urban air quality application uses the ATMO-Street air
						quality model chain to compose street-level air pollution maps and
						data, thereby providing information on pollution hotspots in the
						city centre and the most important local sources for the air
						pollution.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Detection of hotspots and compliance checking
					</StorySubheadline>
					<StoryParagraph className={''}>
						The pollution maps identify the pollution hotspots in the urban
						area. For nitrogen dioxide (NO
						<span className="cure-Story-smallSymbolBottom">2</span>), the
						highest concentrations are observed near the most important roads,
						whereas for particulate matter (PM), the highest concentrations are
						observed in the neighbourhoods with an abundance of coal and wood
						burning heaters.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The maps can also be used for compliance checking. The EU Ambient
						Air Quality Directive sets a threshold of 40 μg/m
						<span className="cure-Story-smallSymbolTop">3</span> respectively 20
						μg/m<span className="cure-Story-smallSymbolTop">3</span> for the
						yearly mean NO
						<span className="cure-Story-smallSymbolBottom">2</span> and PM
						<span className="cure-Story-smallSymbolBottom">2.5</span>{' '}
						concentrations. We thus observe a breach of the standards for NO
						<span className="cure-Story-smallSymbolBottom">2</span> near the
						busiest streets, while the standards for PM
						<span className="cure-Story-smallSymbolBottom">2.5</span> are
						breached at most locations in the city center. The more stringent
						WHO guidelines are exceeded at almost all locations in the model
						domain, both for NO
						<span className="cure-Story-smallSymbolBottom">2</span> (10 μg/m
						<span className="cure-Story-smallSymbolTop">3</span>) and PM
						<span className="cure-Story-smallSymbolBottom">2.5</span> (5 μg/m
						<span className="cure-Story-smallSymbolTop">3</span>), indicating
						that significant health effects are expected everywhere in the
						metropolitan area.
					</StoryParagraph>
					<StorySubheadline className={''}>
						Spatial variation of air pollution
					</StorySubheadline>
					<StoryParagraph className={''}>
						Air pollution concentrations vary strongly over short distances, as
						visualized by the concentrations along a cross-section in the city
						center. Because of these strong spatial gradients, the modelling of
						urban air quality requires models operating at high resolution,
						which should include the effects of high rise buildings adjacent to
						roads (the so-called street-canyon effect).
					</StoryParagraph>
					<StorySubheadline className={''}>
						Sector contribution
					</StorySubheadline>
					<StoryParagraph className={''}>
						The pie plots indicate the contribution of the most important local
						emissions sectors (residential, traffic, industry and energy
						production) and the background contribution, which is the share of
						the pollution originating either outside the domain, or from sectors
						that are not explicitly considered (e.g. rail transport). The
						results highlight the importance of the local road traffic emissions
						(for NO
						<span className="cure-Story-smallSymbolBottom">2</span>) and the
						local residential emissions (for PM
						<span className="cure-Story-smallSymbolBottom">2.5</span>).
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						The street-level air quality maps of this application allow
						stakeholders (e.g. urban planners, grass-roots movements and
						nations, regional and city administrations) to identify pollution
						hotspots in the city centre, and to develop adequate air quality
						plans to reduce the pollution. The results of the application can be
						used to determine the premature mortality related to exposure to air
						pollution, as is for instance done in CURE’s health impact
						application (
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap11-health-impact'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Socioeconomic perspective of Health Impacts
						</Link>
						). Because our methodology relies on general available input
						datasets, the modelling can easily be replicated for any city in
						Europe.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								High resolution air quality map for Belgium (
								<a href="https://irceline.be/en/air-quality/measurements/nitrogen-dioxide/history/no2_anmean_rioifdm ">
									irCELine portal
								</a>
								).
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								Description and validation statistics of the ATMO-Street air
								quality model chain. (
								<a href="https://www.sciencedirect.com/science/article/pii/S1352231022000115?via%3Dihub">
									Publication
								</a>
								)
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								Detailed description of the service and validation using air
								quality measurements (<a>CURE D3.4 Report</a>).
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						The service is based on CLMS, C3S and CAMS Copernicus services,
						using the CLMS building height data, the C3S reanalysis
						meteorological data and the CAMS emissions and air quality
						reanalysis data as main inputs.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The methodology is based on the ATMO-Street air quality model chain
						developed by <a href="https://vito.be/">VITO</a>.
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<StoryParagraph className={''}>
						For more information on the current application, contact the{' '}
						<b>VITO air quality modelling team</b>.
					</StoryParagraph>
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro07.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Mapping and analyzing air pollution at street-level scale
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App7_ShowcaseA mapSetKey={'ap07-urban-air-quality-showcase-a'} />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Bristol',
								link: 'https://www.dropbox.com/s/ql7ec6rayme5yvt/CURE_AP07_Bristol_sourcedata.zip?dl=0',
							},
							{
								city: 'Ostrava',
								link: 'https://www.dropbox.com/s/smzc0uru56snjpo/CURE_AP07_Ostrava_sourcedata.zip?dl=0',
							},
							{
								city: 'Sofia',
							},
						]}
						otherApps={[
							{
								key: 'ap04-urban-co2-emissions',
								label: 'Urban CO2 Emissions Monitoring',
							},
							{
								key: 'ap11-health-impact',
								label: 'Health Impact',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App7_UrbanAirQuality.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App7_UrbanAirQuality;
