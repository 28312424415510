import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		activeKeys: Select.components.getByComponentKey(
			state,
			ownProps.componentKey
		),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		selectActiveKey: (key, value) => {
			dispatch(Action.components.set(ownProps.componentKey, key, value));
		},
		onMount: () => {
			const update = ownProps?.dataProperties?.reduce((acc, item, index) => {
				acc['key' + index] = item.data[0];
				return acc;
			}, {});

			dispatch(Action.components.update(ownProps.componentKey, update));
		},
		onUnmount: () => {
			dispatch(Action.components.remove(ownProps.componentKey));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
