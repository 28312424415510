import {createElement} from 'react';
import barChartdata from './data/data.json';
import barChartTypes from './data/types.json';
import './style.scss';
import BarChart from '../../../../common/BarChart';
import ShowcaseHead from '../../../../common/ShowcaseHead';
import InfoWindow from '../../../../common/InfoWindow';
import App5_ShowcaseB_ChartTooltip from './App5_ShowcaseB_ChartTooltip';

const App5_ShowcaseB_BarChart = () => {
	const dataProperties = [barChartTypes];
	return (
		<div className="cure-App5_ShowcaseB-BarChart">
			<ShowcaseHead
				data={barChartdata}
				dataProperties={dataProperties}
				componentKey="app5-urban-flood-risk-showcase-b-BarChartSelects"
				customLabel={
					<h3 className="cure-App5_ShowcaseB-BarChartTitle">
						Number of buildings in flood risk zones by land use
					</h3>
				}
				selectType="switch"
				infoWindow={
					<InfoWindow
						content={
							<div>
								<h4 className="cure-App5_ShowcaseB-InfoWindowTitle">
									Description
								</h4>
								<p className="cure-App5_ShowcaseB-InfoWindowParagraph">
									A bar chart showing the number of buildings endangered by
									flood hazard (in city) per UA land use classes.
								</p>
							</div>
						}
					/>
				}
			/>
			<BarChart
				data={barChartdata}
				dataProperties={dataProperties}
				componentKey="app5-urban-flood-risk-showcase-b-BarChartSelects"
				keys={['1', '2', '3', '4', '5']}
				indexBy="code"
				colors={{
					1: 'rgb(129,15,124)',
					2: 'rgb(136,86,167)',
					3: 'rgb(140,150,198)',
					4: 'rgb(179,205,227)',
					5: 'rgb(208,224,246)',
				}}
				legendDirection="row"
				legendWidth={20}
				groupMode="stacked"
				layout="horizontal"
				enableLabel={false}
				enableLabelX={false}
				legendTranslateX={-40}
				legendTranslateY={60}
				offsetY={-60}
				labelY={'Classes'}
				legendItemsSpacing={30}
				marginTop={60}
				marginRight={20}
				marginLeft={0}
				innerPadding={0.5}
				Tooltip={props =>
					createElement(App5_ShowcaseB_ChartTooltip, {...props, unit: 'a'})
				}
			/>
		</div>
	);
};

export default App5_ShowcaseB_BarChart;
