import PropTypes from 'prop-types';
import {createElement, useEffect} from 'react';
import {connects} from '@gisatcz/ptr-state';
import {Mouse, Months, Years, Days, MapTimeline} from '@gisatcz/ptr-timeline';

import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import RasterMapTooltip from '../../../common/maps/MapTooltip/RasterMapTooltip';
import GetFeatureInfoTooltipContent from './GetFeatureInfoTooltipContent';

import getHoverContent from '../../../common/Timeline/Hover/getContent';
import MapTimelineLegend from '../../../common/Timeline/MapTimelineLegend';
import Showcase from '../../../common/Showcase';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';

import DateLabel from '../../../common/DateLabel';

import {timelineLayerLineHeight} from '../../../../constants/app';

import './style.scss';

const Tooltip = props => (
	<RasterMapTooltip {...props}>
		<GetFeatureInfoTooltipContent />
	</RasterMapTooltip>
);

const MapTimelinePresentation = MapTimeline.MapTimelinePresentation;
const LayerRowPresentation = MapTimeline.LayerRowPresentation;
const LayerRowItemPresentation = MapTimeline.LayerRowItemPresentation;
const LayerRowPeriodItemPresentation =
	MapTimeline.LayerRowPeriodItemPresentation;

const LayerRowComponent = connects.timeline.LayerRow(LayerRowPresentation);
const LayerRowItemComponent = connects.timeline.LayerRowItem(
	LayerRowItemPresentation
);
const LayerRowPeriodItemComponent = connects.timeline.LayerRowPeriodItem(
	LayerRowPeriodItemPresentation
);

const LayerRowItemComponentWrapped = props => (
	<LayerRowItemComponent
		{...props}
		LayerRowPeriodItemComponent={LayerRowPeriodItemComponent}
	/>
);
const LayerRowComponentWrapped = props => (
	<LayerRowComponent
		{...props}
		LayerRowItemComponent={LayerRowItemComponentWrapped}
	/>
);

const LEVELS = [
	{
		level: 'year',
		end: 2,
	},
	{
		level: 'month',
		end: 30,
	},
	{
		level: 'day',
		end: 300,
	},
];

const Levels = ({activeLevel, ...restProps}) => {
	switch (activeLevel) {
		case 'year':
			return createElement(Years, {...restProps, key: 'year'});
		case 'month':
			return createElement(Months, {...restProps, key: 'month'});
		case 'day':
			return createElement(Days, {...restProps, key: 'day'});
	}
	return createElement(Months, {...restProps, key: 'month'});
};

Levels.propTypes = {
	activeLevel: PropTypes.string,
};

const maximumTimelineVisibleRows = 6.5; //number of maximum visible rows

const App3_ShowcaseA = ({
	mapSetKey,
	activeMapKey,
	activePlaceKey,
	onActivePlaceKeyChange,
	timelineLayers,
	periodLimit,
	onLayerClick,
}) => {
	useEffect(() => {
		onActivePlaceKeyChange();
	}, [activePlaceKey]);

	const minTimelineHeight = timelineLayers
		? timelineLayers.length * timelineLayerLineHeight
		: 0;
	const contentHeight =
		timelineLayers.length * timelineLayerLineHeight >
		timelineLayerLineHeight * maximumTimelineVisibleRows
			? timelineLayerLineHeight * maximumTimelineVisibleRows
			: timelineLayers.length * timelineLayerLineHeight;

	return (
		<Showcase className="cure-App3_ShowcaseA">
			<MapSetWrapper
				MapTooltip={Tooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerTemplateKey: '980c591b-f6af-4d87-a8d8-e1f924143814', //day lt
							info: [
								<DateLabel
									key={'DateLabel_day_1'}
									format={'YYYY-MM-DD HH:mm'}
								/>,
							],
						},
						{
							layerTemplateKey: '3455afd2-39ec-49fe-9936-7a1e5bef9e42', //day lt
							info: [
								<DateLabel
									key={'DateLabel_night_1'}
									format={'YYYY-MM-DD HH:mm'}
								/>,
							],
						},
					],
				}}
				componentsByLayer={[
					{
						layerTemplateKey: '980c591b-f6af-4d87-a8d8-e1f924143814', // day
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap03-urban-heat-emissions',
								showcaseKey: 'App3_ShowcaseA',
								title: 'Heat flux',
								unit: 'W/m2',
							},
						},
					},
					{
						layerTemplateKey: '3455afd2-39ec-49fe-9936-7a1e5bef9e42', // night
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap03-urban-heat-emissions',
								showcaseKey: 'App3_ShowcaseA',
								title: 'Heat flux',
								unit: 'W/m2',
							},
						},
					},
				]}
			/>

			{timelineLayers ? (
				<MapTimelinePresentation
					LayerRowComponent={LayerRowComponentWrapped}
					mapKey={activeMapKey}
					getHoverContent={(...rest) =>
						getHoverContent(...rest, timelineLayers)
					}
					onLayerClick={onLayerClick}
					periodLimit={periodLimit}
					initPeriod={periodLimit}
					vertical={false}
					levels={LEVELS}
					selectMode={true}
					layers={timelineLayers}
					minTimelineHeight={minTimelineHeight}
					LegendComponent={MapTimelineLegend}
					contentHeight={contentHeight + 4}
				>
					<Levels />
					<Mouse mouseBufferWidth={20} key="mouse" />
				</MapTimelinePresentation>
			) : null}
		</Showcase>
	);
};

App3_ShowcaseA.propTypes = {
	mapSetKey: PropTypes.string,
	activePlaceKey: PropTypes.string,
	onActivePlaceKeyChange: PropTypes.func,
	timelineLayers: PropTypes.array,
	onLayerClick: PropTypes.func,
	activeMapKey: PropTypes.string,
	rasterLegend: PropTypes.object,
	rasterLegendData: PropTypes.object,
	periodLimit: PropTypes.shape({
		start: PropTypes.string,
		end: PropTypes.string,
	}),
};

export default App3_ShowcaseA;
