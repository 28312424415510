import classNames from 'classnames';
import PropTypes from 'prop-types';
import {useMountUnmount} from '../../../../../utils/hooks';
import AppStoryHeaderSelect from '../AppStoryHeaderSelect';
import './style.scss';

const StorySelect = ({
	activeApplicationStoryKey,
	applicationStories,
	onActiveStoryChange,
	onMount,
	onUnmount,
}) => {
	useMountUnmount(onMount, onUnmount);

	const classes = classNames('cure-StorySelect', {});

	const options = applicationStories?.map(scope => {
		return {value: scope.key, label: scope.data.nameDisplay};
	});
	const value = options?.find(
		option => option.value === activeApplicationStoryKey
	);

	const setActiveApplicationStoryKey = scope => {
		onActiveStoryChange(scope.value);
	};

	return options?.length ? (
		<>
			<span className="cure-ApplicationStoryNav-divider">/</span>
			<div className={classes}>
				<AppStoryHeaderSelect
					options={options}
					value={value}
					menuPosition="fixed"
					menuWidth="25rem"
					highlighted
					onChange={setActiveApplicationStoryKey}
				/>
			</div>
		</>
	) : null;
};

StorySelect.propTypes = {
	activeApplicationStoryKey: PropTypes.string,
	applicationStories: PropTypes.array,
	onActiveStoryChange: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default StorySelect;
