export default [
	'Copenhagen',
	'Heraklion',
	'Munich',
	'San Sebastian',
	'Tirana',
	'Elbasan',
	'Shkodër',
	'Vlorë',
	'Wien',
	'Graz',
	'Linz',
	'Salzburg',
	'Innsbruck',
	'Klagenfurt',
	'Sarajevo',
	'Banja Luka',
	'Mostar',
	'Tuzla',
	'Zenica',
	'Brussel',
	'Antwerpen',
	'Gent',
	'Charleroi',
	'Liège',
	'Brugge',
	'Namur',
	'Leuven',
	'Mons',
	'Kortrijk',
	'Oostende',
	'Sofia',
	'Plovdiv',
	'Varna',
	'Burgas',
	'Pleven',
	'Ruse',
	'Vidin',
	'Stara Zagora',
	'Sliven',
	'Dobrich',
	'Shumen',
	'Yambol',
	'Haskovo',
	'Pazardzhik',
	'Blagoevgrad',
	'Veliko Tarnovo',
	'Vratsa',
	'Zürich',
	'Genève',
	'Basel',
	'Bern',
	'Lausanne',
	'Winterthur',
	'St. Gallen',
	'Luzern',
	'Lugano',
	'Biel/Bienne',
	'Lefkosia',
	'Lemesos',
	'Praha',
	'Brno',
	'Ostrava',
	'Plzeň',
	'Ústí nad Labem',
	'Olomouc',
	'Liberec',
	'České Budějovice',
	'Hradec Králové',
	'Pardubice',
	'Zlín',
	'Karlovy Vary',
	'Jihlava',
	'Most',
	'Chomutov-Jirkov',
	'Berlin',
	'Hamburg',
	'München',
	'Köln',
	'Frankfurt am Main',
	'Stuttgart',
	'Leipzig',
	'Dresden',
	'Düsseldorf',
	'Bremen',
	'Hannover',
	'Nürnberg',
	'Bielefeld',
	'Halle an der Saale',
	'Magdeburg',
	'Wiesbaden',
	'Göttingen',
	'Darmstadt',
	'Trier',
	'Freiburg im Breisgau',
	'Regensburg',
	'Frankfurt (Oder)',
	'Weimar',
	'Schwerin',
	'Erfurt',
	'Augsburg',
	'Bonn',
	'Karlsruhe',
	'Mönchengladbach',
	'Mainz',
	'Ruhrgebiet',
	'Kiel',
	'Saarbrücken',
	'Koblenz',
	'Rostock',
	'Kaiserslautern',
	'Iserlohn',
	'Wilhelmshaven',
	'Tübingen',
	'Flensburg',
	'Marburg',
	'Konstanz',
	'Neumünster',
	'Brandenburg an der Havel',
	'Gießen',
	'Lüneburg',
	'Bayreuth',
	'Celle',
	'Aschaffenburg',
	'Bamberg',
	'Plauen',
	'Neubrandenburg',
	'Fulda',
	'Kempten',
	'Landshut',
	'Rosenheim',
	'Stralsund',
	'Friedrichshafen',
	'Offenburg',
	'Görlitz',
	'Schweinfurt',
	'Greifswald',
	'Wetzlar',
	'Passau',
	'Münster',
	'Chemnitz',
	'Aachen',
	'Krefeld',
	'Lübeck',
	'Kassel',
	'Solingen',
	'Osnabrück',
	'Oldenburg',
	'Heidelberg',
	'Paderborn',
	'Würzburg',
	'Bremerhaven',
	'Heilbronn',
	'Remscheid',
	'Ulm',
	'Pforzheim',
	'Ingolstadt',
	'Gera',
	'Reutlingen',
	'Cottbus',
	'Siegen',
	'Hildesheim',
	'Zwickau',
	'Wuppertal',
	'Jena',
	'Düren',
	'Bocholt',
	'København',
	'Århus',
	'Odense',
	'Aalborg',
	'Tallinn',
	'Tartu',
	'Narva',
	'Athina',
	'Thessaloniki',
	'Patra',
	'Irakleio',
	'Larisa',
	'Volos',
	'Ioannina',
	'Kavala',
	'Kalamata',
	'Madrid',
	'Barcelona',
	'Valencia',
	'Sevilla',
	'Zaragoza',
	'Málaga',
	'Murcia',
	'Las Palmas',
	'Valladolid',
	'Palma de Mallorca',
	'Santiago de Compostela',
	'Vitoria/Gasteiz',
	'Oviedo',
	'Pamplona/Iruña',
	'Santander',
	'Toledo',
	'Badajoz',
	'Logroño',
	'Bilbao',
	'Córdoba',
	'Alicante',
	'Vigo',
	'Gijón',
	'Santa Cruz de Tenerife',
	'Coruña',
	'Reus',
	'Lugo',
	'Girona',
	'Cáceres',
	'Torrevieja',
	'Avilés',
	'Talavera de la Reina',
	'Palencia',
	'Ferrol',
	'Pontevedra',
	'Gandia',
	'Guadalajara',
	'Manresa',
	'Ciudad Real',
	'Benidorm',
	'Ponferrada',
	'Zamora',
	'Irun',
	'Arrecife',
	'Granada',
	'Elche',
	'Cartagena',
	'Jerez de la Frontera',
	'San Sebastián',
	'Almería',
	'Burgos',
	'Salamanca',
	'Albacete',
	'Castellón de la Plana',
	'Huelva',
	'Cádiz',
	'León',
	'Tarragona',
	'Jaén',
	'Lleida',
	'Ourense',
	'Algeciras',
	'Marbella',
	'Alcoy',
	'Ávila',
	'Cuenca',
	'Eivissa',
	'Linares',
	'Lorca',
	'Mérida',
	'Sagunto',
	'Puerto de la Cruz',
	'Igualada',
	'Helsinki',
	'Tampere',
	'Turku',
	'Oulu',
	'Lahti',
	'Kuopio',
	'Jyväskylä',
	'Paris',
	'Lyon',
	'Toulouse',
	'Strasbourg',
	'Bordeaux',
	'Nantes',
	'Lille',
	'Montpellier',
	'Saint-Etienne',
	'Le Havre',
	'Rennes',
	'Amiens',
	'Nancy',
	'Metz',
	'Reims',
	'Orléans',
	'Dijon',
	'Poitiers',
	'Clermont-Ferrand',
	'Caen',
	'Limoges',
	'Besançon',
	'Grenoble',
	'Ajaccio',
	'Toulon',
	'Valenciennes',
	'Tours',
	'Angers',
	'Brest',
	'Le Mans',
	'Avignon',
	'Mulhouse',
	'Dunkerque',
	'Perpignan',
	'Nîmes',
	'Pau',
	'Bayonne',
	'Annemasse',
	'Annecy',
	'Lorient',
	'Montbéliard',
	'Troyes',
	'Saint-Nazaire',
	'La Rochelle',
	'Angoulême',
	'Boulogne-sur-Mer',
	'Chambéry',
	'Chalon-sur-Saône',
	'Chartres',
	'Niort',
	'Calais',
	'Béziers',
	'Arras',
	'Bourges',
	'Saint-Brieuc',
	'Quimper',
	'Vannes',
	'Cherbourg',
	'Tarbes',
	'Compiègne',
	'Belfort',
	'Roanne',
	'Saint-Quentin',
	'Beauvais',
	'Creil',
	'Evreux',
	'Châteauroux',
	'Brive-la-Gaillarde',
	'Albi',
	'Fréjus',
	'Châlons-en-Champagne',
	'Marseille',
	'Nice',
	'Lens - Liévin',
	'Carvin',
	'Douai',
	'Valence',
	'Rouen',
	'Melun',
	'Martigues',
	'Charleville',
	'Colmar',
	'Cannes',
	'Zagreb',
	'Rijeka',
	'Slavonski Brod',
	'Osijek',
	'Split',
	'Pula',
	'Zadar',
	'Budapest',
	'Miskolc',
	'Nyíregyháza',
	'Pécs',
	'Debrecen',
	'Szeged',
	'Gyõr',
	'Kecskemét',
	'Székesfehérvár',
	'Szombathely',
	'Szolnok',
	'Tatabánya',
	'Veszprém',
	'Békéscsaba',
	'Kaposvár',
	'Eger',
	'Dunaújváros',
	'Zalaegerszeg',
	'Sopron',
	'Dublin',
	'Cork',
	'Limerick',
	'Galway',
	'Waterford',
	'Reykjavík',
	'Roma',
	'Milano',
	'Napoli',
	'Torino',
	'Palermo',
	'Genova',
	'Firenze',
	'Bari',
	'Bologna',
	'Catania',
	'Venezia',
	'Verona',
	'Cremona',
	'Trento',
	'Trieste',
	'Perugia',
	'Ancona',
	'Pescara',
	'Campobasso',
	'Caserta',
	'Taranto',
	'Potenza',
	'Catanzaro',
	'Reggio di Calabria',
	'Sassari',
	'Cagliari',
	'Padova',
	'Brescia',
	'Modena',
	'Foggia',
	'Salerno',
	'Piacenza',
	'Bolzano',
	'Udine',
	'La Spezia',
	'Lecce',
	'Barletta',
	'Pesaro',
	'Como',
	'Pisa',
	'Treviso',
	'Varese',
	'Asti',
	'Pavia',
	'Massa',
	'Cosenza',
	'Savona',
	'Matera',
	'Acireale',
	'Avellino',
	'Pordenone',
	'Lecco',
	'Altamura',
	'Battipaglia',
	'Bisceglie',
	'Carpi',
	'Cerignola',
	'Gallarate',
	'Gela',
	'Sassuolo',
	'Messina',
	'Prato',
	'Parma',
	'Livorno',
	"Reggio nell'Emilia",
	'Ravenna',
	'Ferrara',
	'Rimini',
	'Siracusa',
	'Bergamo',
	'Forlì',
	'Latina',
	'Vicenza',
	'Terni',
	'Novara',
	'Alessandria',
	'Arezzo',
	'Grosseto',
	'Brindisi',
	'Trapani',
	'Ragusa',
	'Andria',
	'Trani',
	"L'Aquila",
	'Vilnius',
	'Kaunas',
	'Panevėžys',
	'Alytus',
	'Klaipėda',
	'Šiauliai',
	'Luxembourg',
	'Rīga',
	'Liepāja',
	'Jelgava',
	'Daugavpils',
	'Podgorica',
	'Skopje',
	'Bitola',
	'Tetovo',
	'Prilep',
	'Valletta',
	"s' Gravenhage",
	'Amsterdam',
	'Rotterdam',
	'Utrecht',
	'Eindhoven',
	'Tilburg',
	'Groningen',
	'Enschede',
	'Arnhem',
	'Heerlen',
	'Breda',
	'Nijmegen',
	'Apeldoorn',
	'Leeuwarden',
	'Sittard-Geleen',
	'Roosendaal',
	'Alphen aan den Rijn',
	'Bergen op Zoom',
	'Gouda',
	'Middelburg      ',
	's-Hertogenbosch',
	'Amersfoort',
	'Maastricht',
	'Leiden',
	'Zwolle',
	'Ede',
	'Deventer',
	'Alkmaar',
	'Venlo',
	'Almelo',
	'Lelystad',
	'Oss',
	'Assen',
	'Veenendaal',
	'Soest',
	'Oslo',
	'Bergen',
	'Trondheim',
	'Stavanger',
	'Kristiansand',
	'Tromsø',
	'Warszawa',
	'Łódź',
	'Kraków',
	'Wrocław',
	'Poznań',
	'Gdańsk',
	'Szczecin',
	'Bydgoszcz',
	'Lublin',
	'Katowice',
	'Białystok',
	'Kielce',
	'Toruń',
	'Olsztyn',
	'Rzeszów',
	'Opole',
	'Gorzów Wielkopolski',
	'Zielona Góra',
	'Jelenia Góra',
	'Nowy Sącz',
	'Suwałki',
	'Konin',
	'Częstochowa',
	'Radom',
	'Płock',
	'Kalisz',
	'Koszalin',
	'Słupsk',
	'Jastrzębie-Zdrój',
	'Siedlce',
	'Piotrków Trybunalski',
	'Lubin',
	'Piła',
	'Inowrocław',
	'Ostrowiec Świętokrzyski',
	'Gniezno',
	'Stargard Szczeciński',
	'Ostrów Wielkopolski',
	'Przemyśl',
	'Zamość',
	'Chełm',
	'Pabianice',
	'Głogów',
	'Stalowa Wola',
	'Tomaszów Mazowiecki',
	'Łomża',
	'Leszno',
	'Świdnica',
	'Tczew',
	'Ełk',
	'Bielsko-Biała',
	'Rybnik',
	'Wałbrzych',
	'Elbląg',
	'Włocławek',
	'Tarnów',
	'Legnica',
	'Grudziądz',
	'Lisboa',
	'Porto',
	'Braga',
	'Funchal',
	'Coimbra',
	'Aveiro',
	'Faro',
	'Viseu',
	'Viana do Castelo',
	'Póvoa de Varzim',
	'Guimarães',
	'Bucureşti',
	'Cluj-Napoca',
	'Timişoara',
	'Craiova',
	'Brăila',
	'Oradea',
	'Bacău',
	'Arad',
	'Sibiu',
	'Târgu Mureş',
	'Piatra Neamţ',
	'Cǎlǎraşi',
	'Giurgiu',
	'Alba Iulia',
	'Focşani',
	'Târgu Jiu',
	'Tulcea',
	'Târgovişte',
	'Slatina',
	'Bârlad',
	'Roman',
	'Bistriţa',
	'Constanta',
	'Iasi',
	'Galati',
	'Brasov',
	'Ploiesti',
	'Pitesti',
	'Baia Mare',
	'Buzau',
	'Satu Mare',
	'Botosani',
	'Ramnicu Valcea',
	'Suceava',
	'Drobeta-Turnu Severin',
	'Beograd',
	'Novi Sad',
	'Niš',
	'Kragujevac',
	'Subotica',
	'Novi Pazar',
	'Zrenjanin',
	'Kraljevo',
	'Čačak',
	'Kruševac',
	'Leskovac',
	'Valjevo',
	'Vranje',
	'Smederevo',
	'Stockholm',
	'Göteborg',
	'Malmö',
	'Jönköping',
	'Umeå',
	'Uppsala',
	'Linköping',
	'Örebro',
	'Västerås',
	'Norrköping',
	'Helsingborg',
	'Borås',
	'Ljubljana',
	'Maribor',
	'Bratislava',
	'Košice',
	'Banská Bystrica',
	'Nitra',
	'Prešov',
	'Žilina',
	'Trnava',
	'Trenčín',
	'Ankara',
	'Adana-Mersin',
	'Antalya',
	'Balıkesir',
	'Denizli',
	'Diyarbakır',
	'Edirne',
	'Erzurum',
	'Gaziantep',
	'Antakya',
	'Istanbul',
	'Izmir',
	'Kars',
	'Kastamonu',
	'Kayseri',
	'Konya',
	'Malatya',
	'Nevşehir',
	'Samsun',
	'Siirt',
	'Trabzon',
	'Van',
	'Zonguldak',
	'Eskişehir',
	'Şanlıurfa',
	'Kahramanmaraş',
	'Batman',
	'Sivas',
	'Elâzığ',
	'Isparta',
	'Çorum',
	'Osmaniye',
	'Aksaray',
	'Aydın',
	'Siverek',
	'Afyonkarahisar',
	'Ordu',
	'Niğde',
	'Uşak',
	'Ağrı',
	'Karaman',
	'Yumurtalik',
	'Rize',
	'Ergani',
	'Kütahya',
	'Kadirli',
	'Karabük',
	'Çanakkale',
	'Akçakale',
	'Erciş',
	'Ereğli',
	'Adıyaman',
	'Viranşehir',
	'Fethiye',
	'Ceylanpinar',
	'Tokat',
	'Patnos',
	'Ödemiş',
	'Bolu',
	'Bandirma',
	'Muş',
	'Elbistan',
	'Nizip',
	'Suruç',
	'Salihli',
	'Kilis',
	'Kızıltepe',
	'Midyat',
	'Cizre',
	'Çankırı',
	'Bingöl',
	'Akşehir',
	'Polatli',
	'Manavgat',
	'Yozgat',
	'Alaşehir',
	'London',
	'West Midlands',
	'Leeds',
	'Glasgow',
	'Liverpool',
	'Edinburgh',
	'Manchester',
	'Cardiff',
	'Sheffield',
	'Bristol',
	'Belfast',
	'Newcastle upon Tyne',
	'Leicester',
	'Aberdeen',
	'Cambridge',
	'Exeter',
	'Lincoln',
	'Portsmouth',
	'Worcester',
	'Coventry',
	'Kingston upon Hull',
	'Stoke-on-Trent',
	'Nottingham',
	'Guildford',
	'Burnley',
	'Hastings',
	'Brighton and Hove',
	'Plymouth',
	'Swansea',
	'Derby',
	'Southampton',
	'Northampton',
	'Bournemouth',
	'Colchester',
	'Dundee City',
	'Falkirk',
	'Reading',
	'Blackpool',
	'Blackburn with Darwen',
	'Newport',
	'Middlesbrough',
	'Oxford',
	'Preston',
	'Norwich',
	'Chester',
	'Ipswich',
	'Cheltenham',
	'Pristina',
	'Prizren',
	'Mitrovicë',
];
