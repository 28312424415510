import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';

const getBaseTags = createSelector(
	[CommonSelect.components.getByComponentKey],
	component => {
		if (component?.tagKeys) {
			return Object.keys(component?.tagKeys);
		} else {
			return [];
		}
	}
);

const getActiveLayerTemplateKey = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		(state, componentKey, mapKey) => mapKey,
	],
	(component, mapKey) => {
		return component?.active?.[mapKey];
	}
);

const getOptionTagKeys = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		(state, componentKey, tagKey) => tagKey,
	],
	(component, tagKey) => {
		if (component?.tagKeys) {
			if (component?.tagKeys?.[tagKey]?.keys) {
				return component?.tagKeys?.[tagKey]?.keys;
			} else {
				return [];
			}
		} else {
			return [];
		}
	}
);

const getActiveTagKey = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		(state, componentKey, tagKey) => tagKey,
		(state, componentKey, tagKey, mapKey) => mapKey,
	],
	(component, tagKey, mapKey) => {
		if (component?.tagKeys) {
			if (component?.tagKeys?.[tagKey]?.keys) {
				const activeTagKey = component?.tagKeys?.[tagKey]?.active?.[mapKey];
				return activeTagKey;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

const getStyleKeyForLegend = createSelector(
	[
		CommonSelect.places.getActiveKey,
		(state, applicationStoryKey, showcaseKey) =>
			CommonSelect.app.getConfiguration(
				state,
				`configByApplicationStoryKey.${applicationStoryKey}.showcases.${showcaseKey}.data.legend`
			),
		(state, applicationStoryKey, showcaseKey, caseKey) => caseKey,
		(state, applicationStoryKey, showcaseKey, caseKey, layerTemplateKey) =>
			layerTemplateKey,
	],
	(activePlaceKey, config, caseKey, layerTemplateKey) => {
		if (activePlaceKey && config && caseKey && layerTemplateKey) {
			const legendForLayerTemplateKey =
				config[activePlaceKey]?.[layerTemplateKey]?.[caseKey]?.styleKey;
			return legendForLayerTemplateKey || null;
		} else {
			return null;
		}
	}
);

const getStyleForLegend = createSelector(
	[CommonSelect.styles.getAllAsObject, getStyleKeyForLegend],
	(styles, styleKey) => {
		return styles?.[styleKey] || null;
	}
);

const getPreparedStylesForLegend = createSelector(
	[getStyleForLegend],
	style => {
		if (style) {
			const definition = style?.data?.definition?.rules?.[0]?.styles;
			const baseStyle = definition[0];
			const attributeStyle = definition[1];
			return {
				baseStyle,
				attributeStyle,
			};
		} else {
			return null;
		}
	}
);

export default {
	getBaseTags,
	getOptionTagKeys,
	getActiveTagKey,
	getActiveLayerTemplateKey,
	getPreparedStylesForLegend,
	getStyleKeyForLegend,
};
