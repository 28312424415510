import PropTypes from 'prop-types';
import {useEffect} from 'react';
import AppStoryHeaderSelect from '../../ApplicationStoryScreen/ApplicationStoryHeader/ApplicationStoryNav/AppStoryHeaderSelect';

import './style.scss';

const LayerTemplateSelect = ({
	// activeKey,
	// componentKey,
	options,
	onChange,
	load,
	filter,
	title,
	value,
	disabled,
}) => {
	useEffect(() => {
		if (typeof load === 'function') {
			load(filter);
		}
	}, []);

	return (
		<div className={'cure-TagSelect'}>
			<span>{title}</span>
			<AppStoryHeaderSelect
				options={options}
				onChange={onChange}
				value={value}
				disabled={disabled === true}
			/>
		</div>
	);
};

LayerTemplateSelect.propTypes = {
	activeKey: PropTypes.string,
	componentKey: PropTypes.string,
	activeMapKey: PropTypes.string,
	onChange: PropTypes.func,
	load: PropTypes.func,
	filter: PropTypes.object,
	options: PropTypes.array,
	title: PropTypes.string,
	value: PropTypes.object,
	disabled: PropTypes.bool,
};

export default LayerTemplateSelect;
