import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const component = Select.components.getByComponentKey(
		state,
		ownProps.componentKey
	);
	return {
		errors: component?.errors,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onSubmitLogin: (name, password) => {
			dispatch(
				Action.cure.loginUser(
					name,
					password,
					true,
					ownProps.componentKey,
					ownProps.onCloseModal
				)
			);
		},
		clearErrorsAndMessages: () => {
			dispatch(Action.components.set(ownProps.componentKey, 'errors', null));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
