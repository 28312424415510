import PropTypes from 'prop-types';
import './style.scss';
import {getRouter} from '../../../../../router';
import Link from '../../../../common/Link';
import ReactResizeDetector from 'react-resize-detector';
import {useState, useEffect} from 'react';
import Footer from '../../../../Footer/presentation';
import {IconTool} from '@gisatcz/visat-components';

const CityInfo = ({name}) => {
	switch (name) {
		case 'berlin':
			return (
				<>
					<p>
						Berlin is the capital and largest city of Germany by both area and
						population of 3.7 million inhabitants. The city is experiencing
						rapid transformation growth that represents both opportunities and
						challenges for urban development planning. In managing growth, the
						city administration is committed to a culture of participation to
						ensure Berlin continues to be a sustainable and cosmopolitan
						metropolis.{' '}
					</p>
					<p>
						Following cross-cutting services have been tested in Berlin as the
						CURE front-running city:
					</p>
				</>
			);
		case 'copenhagen':
			return (
				<>
					<p>
						Copenhagen is the capital and the most populous city of Denmark with
						population of more then 800,000 inhabitants. Copenhagen is known to
						be a green and hugely liveable city with a high quality of life. It
						is internationally recognised as one of the world ’s best places to
						live in. It is an inspiring and diverse city with a mixture of old
						and new buildings and an active lifestyle with green urban spaces
						and well-established pedestrians and bicyclist paths that provide
						this city its green credentials. Copenhagen is also a global
						front-runner within sustainable urban development with a vision to
						become the first CO2 neutral capital in the world by 2025. To
						achieve this, Copenhagen is emphasising public-private partnerships,
						and uses a quadruple helix approach to engage companies/
						organisations, universities and citizens.
					</p>
					<p>
						Following cross-cutting services have been tested in Copenhagen as
						the CURE front-running city.
					</p>
				</>
			);
		case 'heraklion':
			return (
				<>
					<p>
						Heraklion is the largest city and the administrative capital of the
						island of Crete and capital of Heraklion regional unit. It is the
						fourth largest city in Greece with a population of 211,370
						inhabitants. The Municipality of Heraklion signed the EU Covenant of
						Mayors agreement, which is an ambitious initiative to involve local
						authorities and citizens in reducing energy consumption, saving
						resources and upgrading the quality of life of citizens. The design
						of actions based on the “sustainable development” model is the basis
						of its policy to make Heraklion a viable “green city”.
					</p>
					<p>
						Following cross-cutting services have been tested in Heraklion as
						the CURE front-running city.
					</p>
				</>
			);
		case 'sofia':
			return (
				<>
					<p>
						Sofia is the capital and largest city of Bulgaria with the
						metropolitan region of Sofia inhabited by a population of 1.67
						million. Sofia has well established framework for a long-term
						strategy for its sustainable development with leading principles in
						the provision of maximum transparency in the process of strategic
						and spatial planning and the creation of opportunities for
						participation of citizens and experts in an informed conversation
						about the future of the city. For these purposes the municipality
						cooperates with various municipal structures, universities and
						researchers, industry, non-governmental and commercial
						organisations.
					</p>
					<p>
						Following cross-cutting services have been tested in Sofia as the
						CURE front-running city.
					</p>
				</>
			);
		case 'san-sebastian':
			return (
				<>
					<p>
						The Basque city of San Sebastián, home to about 186,000 inhabitants,
						is the administrative capital of the province of Guipúzcoa and a
						major culture and tourist hub in Northern Spain. As a coastal city,
						San Sebastián is on the frontline of climate change and therefore
						has to strengthen their resilience and take ambitious climate and
						energy action. In June 2018, the city has adopted its long-term
						roadmap “Plan de Acción Klima 2050”, which reflects a clear
						commitment to tackle climate change, catalyse the clean energy
						transformation and maintain an urban environment that makes
						responsible use of our planet’s resources.
					</p>
					<p>
						Following cross-cutting services have been tested in San Sebastian
						as the CURE follower city.
					</p>
				</>
			);
		case 'bristol':
			return (
				<>
					<p>
						Bristol is a city and county in the South West of England, United
						Kingdom and has about 454,000 inhabitants. The city has two
						universities and hosts thousands of students, commuters and tourists
						on daily basis. Culturally vibrant and with a diverse population
						Bristol benefits from a strong local economy and unique sense of
						identity. A heightened awareness of environmental issues amongst its
						residents is evidenced by the Bristol’s European Green Capital award
						in 2015. Despite its successes the city is also host to some of the
						most deprived communities in the UK, with significant disparities
						between the wealthiest and poorest communities. In addition, Bristol
						struggles with dangerous and illegal levels of air pollution and has
						one of the most congested highway networks in the country which
						threatens to act as a handbrake on future growth.
					</p>
					<p>
						Following cross-cutting services has been tested in Bristol as the
						CURE follower city.
					</p>
				</>
			);
		case 'ostrava':
			return (
				<>
					<p>
						Ostrava is a city in the borderland of Moravia and Silesia in the
						Czech Republic. With a population of 290,000, it is the third
						largest city and the largest post-industrial agglomeration in the
						Czech Republic The polycentric model of settlement, which is the
						consequence of mining and its accompanying phenomena (colonization,
						contamination, undermining, transport), comprises several
						medium-sized centres and one regional metropolis. The transformation
						which Ostrava has been undergoing since mining and heavy industry
						decline generates numerous social-economic, urbanistic and
						environmental challenges. It requires a municipality focus on the
						long-term evidence-based strategies of the settlement and the entire
						agglomeration towards sustainable, resilient and healthy future.
					</p>
					<p>
						Following cross-cutting services has been tested in Ostrava as the
						CURE follower city.
					</p>
				</>
			);
		case 'basel':
			return (
				<>
					<p>
						Basel is a city in north-western Switzerland on the river Rhine.
						Basel is Switzerland&apos;s third-most-populous city with a
						population about 175,000 inhabitants. Basel Municipality has been
						pursuing a progressive environmental and climate policy of an
						exemplary nature for decades. Climate protection enjoys a broad
						citizen support focusing on sustainable urban green, renewable
						energies and energy efficiency and sustainable mobility ranking
						Basel as one of the top ten cities with the highest quality of
						living worldwide.
					</p>
					<p>
						Following cross-cutting services has been tested in Basel as the
						CURE follower city.
					</p>
				</>
			);
		case 'munich':
			return (
				<>
					<p>
						Munich is the capital and most populous city of the German state of
						Bavaria. With a population of more than 1.5 mil. inhabitants.
						Climate change and population growth present the city of Munich with
						special challenges. Local extreme weather events lead to increased
						intensity and frequency of heat stress and floodings, at the same
						time the population is growing rapidly. Back in 2009, Munich set a
						target of running entirely on renewable energy by 2025, which would
						make it the first city with over one million residents to achieve
						this goal. In order to do so, the city develops integrated solutions
						to meet the challenges of climate change and subsequent
						densification in Munich to be implemented at various planning levels
						and processes in urban planning. Munich residents enjoy a high
						quality of life ranking city consistently among the top 10 cities
						with the highest quality of life worldwide.
					</p>
					<p>
						Following cross-cutting service has been tested in Munich as the
						CURE follower city.
					</p>
				</>
			);
		case 'vitoria-gasteiz':
			return (
				<>
					<p>
						Vitoria-Gasteiz is the seat of government and the capital city of
						the Basque Country and of the province of Álava in northern Spain
						with a population of more than 250,000 inhabitants. Vitoria-Gasteiz
						is a sustainable city that has worked for years on a city model that
						also embraces its landscape, even as it grows. At times, during the
						20th Century, the city’s population multiplied by four in just a
						decade, but planning ensured that it stayed inside city limits in
						order to respect natural spaces and boundaries. It is the first
						Spanish municipality to be awarded the title of European Green
						Capital (in 2012) and it has been also recognized by the UN with the
						Global Green City Award (in 2019). This dynamic city develops in a
						human scale which illuminates the decades-long work dedicated to
						sustainable mobility and green infrastructure for the citizens of
						Vitoria-Gasteiz.
					</p>
					<p>
						Following cross-cutting service has been tested in Vitoria-Gasteiz
						as the CURE follower city.
					</p>
				</>
			);
	}
};

CityInfo.propTypes = {
	name: PropTypes.string,
};

const CityInfoPanel = ({
	cityMetadata,
	applicationStories,
	sidePanelisOpen = true,
	onCitySelect,
	places,
}) => {
	const [smallScreen, setSmallScreen] = useState(false);
	let isSmallScreen = window.matchMedia('(max-width: 767.98px)');
	useEffect(() => {
		isSmallScreen.matches ? setSmallScreen(true) : setSmallScreen(false);
	}, []);
	return (
		<div
			className="cure-CityInfoPanel"
			style={
				smallScreen
					? {
							minHeight: '20rem',
							width: '100%',
							marginBottom: sidePanelisOpen ? 0 : '-20rem',
					  }
					: {
							height: '100%',
							minWidth: '25rem',
							marginLeft: sidePanelisOpen ? 0 : '-25rem',
					  }
			}
		>
			<ReactResizeDetector
				onResize={() =>
					isSmallScreen.matches ? setSmallScreen(true) : setSmallScreen(false)
				}
			/>
			<div className="cure-CityInfoPanel-header">
				<h2 className="cure-CityInfoPanel-headerTitle">
					{cityMetadata ? cityMetadata?.data?.nameDisplay : 'Select a city'}
				</h2>
				{cityMetadata ? (
					<div className="cure-CityInfoPanel-headerIcon">
						<IconTool
							icon={'arrow-left'}
							tooltip={{
								text: 'List of cities',
								position: 'right',
							}}
							onClick={() => onCitySelect(undefined, undefined, [undefined])}
						/>
					</div>
				) : null}
			</div>
			<CityInfo name={cityMetadata?.data?.nameInternal} />
			<ul className="cure-CityInfoPanel-list">
				{cityMetadata
					? applicationStories &&
					  applicationStories.map(story => (
							<li key={story.applicationStoryKey}>
								<Link
									tabIndex={0}
									name={`applicationStoryScreen`}
									updateParams={{key: story.applicationStoryKey}}
									router={getRouter()}
									paramsFilter={['tags', 'step']}
									recoverParamsFilter={['darkMode']}
								>
									{story?.name}
								</Link>
							</li>
					  ))
					: places?.map(place => (
							<li className="cure-CityInfoPanel-selectCity" key={place.key}>
								<button
									className="cure-CityInfoPanel-selectCityBtn"
									onClick={() =>
										onCitySelect(undefined, undefined, [place.key])
									}
									onMouseEnter={() =>
										onCitySelect(undefined, undefined, [place.key], 'active')
									}
									onMouseLeave={() =>
										onCitySelect(undefined, undefined, [undefined], 'active')
									}
								>
									{place.data.nameDisplay}
								</button>
							</li>
					  ))}
			</ul>

			{smallScreen ? (
				<Footer className={'is-cityDemonstrations-smallScreen'} />
			) : null}
		</div>
	);
};

CityInfoPanel.propTypes = {
	cityMetadata: PropTypes.object,
	applicationStories: PropTypes.array,
	sidePanelisOpen: PropTypes.bool,
	onCitySelect: PropTypes.func,
	places: PropTypes.array,
};

export default CityInfoPanel;
