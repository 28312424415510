import Action from '../../../Action';
import Select from '../../../Select';
import {getLayerOpacity, getLayerOptions} from '../helpers';

function setShowcaseALayer(
	componentKey,
	activeOptionKey,
	activeOption,
	options,
	optionKey
) {
	return (dispatch, getState) => {
		const state = getState();

		// TODO move to separate action
		if (activeOptionKey !== optionKey) {
			dispatch(
				Action.components.update(componentKey, {
					activeOptionKey: optionKey,
				})
			);
		}

		//remove prev layers
		let prevLayersSettings = {};
		if (activeOption && activeOption.maps) {
			for (const map of activeOption.maps) {
				dispatch(Action.maps.removeMapLayers(map.key, [map.layerKey]));
				const layerState = Select.maps.getMapLayerStateByMapKeyAndLayerKey(
					state,
					map.key,
					map.layerKey
				);
				prevLayersSettings[map.key] = {
					options: layerState?.options,
					opacity: layerState?.opacity,
				};
			}
		}

		const newActiveOption = options.find(o => o.key === optionKey);

		//add new layers
		if (newActiveOption && newActiveOption.maps) {
			for (const map of newActiveOption.maps) {
				let prevSettings = prevLayersSettings[map.key];
				dispatch(
					Action.maps.addMapLayers(map.key, [
						{
							key: map.layerKey,
							layerTemplateKey: map.layerTemplate,
							filterByActive: {
								application: true,
								place: true,
							},
							opacity: getLayerOpacity(prevSettings),
							options: getLayerOptions(prevSettings),
						},
					])
				);
			}
		}
	};
}

export default {
	setShowcaseALayer,
};
