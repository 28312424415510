import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';
import {getRouter} from '../../../router';
import Link from '../../common/Link';

import App8_ShowcaseB from './App8_ShowcaseB';
import App8_ShowcaseA from './App8_ShowcaseA';
import App8_ShowcaseC from './App8_ShowcaseC';
import OrderServiceTool from '../../common/OrderServiceTool';
import StoryFooterSection from '../../common/StoryFooterSection';

const App8_UrbanThermalComfort = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						Heat stress is an increasing problem in many European cities due to
						the ongoing climate change. Citizens experience higher levels of
						heat stress than people living in rural areas due to higher surface
						and air temperatures (the Urban Heat Island effect, addressed in{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap02-urban-heat-island'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Surface Urban Heat Island Assessment
						</Link>
						), lower wind speeds and higher levels of solar and thermal
						radiation coming from building materials. Heat stress has a negative
						impact on sleep, productivity, morbidity and mortality of urban
						residents. It is therefore essential to have a good understanding of
						the factors contributing to heat stress, identifying hot spots in a
						city, and assessing the effectiveness of adaptation measures (e.g.
						green roofs, addressed in{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap10-nature-based-solutions'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Nature-Based Solutions
						</Link>
						).
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Detection of hotspots and scenario analysis
					</StorySubheadline>
					<StoryParagraph className={''}>
						These maps show the Wet Bulb Globe Temperature (WBGT), which
						combines the air temperature, the wet bulb temperature and the black
						globe temperature, during a typical hot summer day. The daily
						maximum WBGT map focusses on the hottest hours of the day, when
						shading is crucial to lower the local heat stress. The daily minimum
						WBGT map focusses on the nighttime situation, when the urban heat
						island effect is at its strongest. The daily mean WBGT map shows a
						time-average of the heat stress situation. When assessing the WBGT
						values, it is good to keep in mind that the values are typically a
						few degrees lower than regular air temperature values.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Highlighting differences in different scenarios
					</StorySubheadline>
					<StoryParagraph className={''}>
						The Wet Bulb Globe Temperature Differences (WBGTD) between different
						adaptation scenarios are highlighted to see the effect of various
						greening or material options. Again, the daily maximum WBGTD shows
						differences in the hottest hours of the day, when shading is crucial
						to lower the local heat stress. The daily minimum WBGTD map outlines
						differences in the nighttime situation, when the urban heat island
						effect is at its strongest. The daily mean WBGTD then presents
						differences in a time-average of the heat stress situation. When
						assessing the WBGTD values, it is good to keep in mind, that
						identified difference of a few degrees can already have a strong
						effect on human thermal comfort.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Overview statistics
					</StorySubheadline>
					<StoryParagraph className={''}>
						This table shows the impact of a number of heat stress adaptation
						options on the mean WBGT values in the urban areas of the city
						(based on Urban Atlas classification). Also a comparison to a rural
						reference value is given. As the impact of the scenarios is averaged
						out over a large area, the differences between the scenarios are
						small, but significant.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						The European Environmental Agency reports that 2% of deaths across
						15 European cities in the 1990s were attributable to heat, and due
						to climate change, annual fatalities from extreme heat could rise
						tenfold by 2050. It is therefore important to have a good
						understanding of the local heat stress situation in cities. The
						international standard heat stress indicator is the Wet Bulb Globe
						Temperature (WBGT), which combines the air temperature, the wet bulb
						temperature and the black globe temperature. The WBGT can be
						measured easily with rather cheap equipment, making it an ideal
						indicator for model validation and citizen science campaigns to
						raise awareness about heat stress issues.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The detailed WBGT simulations of this application allow stakeholders
						(e.g. urban planners and city administrations) to identify hotspots,
						give them insight in the local variation of heat stress and the
						impact of potential adaptation measures (e.g. urban trees are most
						effective to reduce daytime heat stress, while green roofs and soil
						unsealing are most effective to reduce nighttime heat stress). Our
						methodology uses general available input datasets and can be easily
						replicated for any city in Europe.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								<a href="https://doi.org/10.1016/j.uclim.2015.01.001">
									Publication
								</a>{' '}
								on the urban climate model UrbClim.
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								<a href="https://doi.org/10.3390/cli8010006">Publication</a> on
								the Wet Bulb Globe Temperature module of UrbClim.
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								VITO’s Urban Climate research{' '}
								<a href="https://vito.be/en/product/urbclim-urban-climate-modelling">
									team.
								</a>
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						The Urban Thermal Comfort application simulates the Wet Bulb Globe
						Temperature (ISO standard for quantifying thermal comfort) with a
						spatial resolution of 2m, based on a very detailed land use map that
						is calculated from Copernicus Data Warehouse layers. The location of
						the trees in the cities is added from the Urban Atlas Street Tree
						layers. The thermal comfort model downscales meteorological
						conditions from the ERA5 reanalysis of the European Center for
						Medium-range Weather Forecasts to the city center, and combines
						these with detailed radiation maps in which the shading effect of
						buildings and trees is taken into account.
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<StoryParagraph className={''}>
						This service is provided by the Belgian research institute{' '}
						<a href="https://vito.be/en">VITO</a>. For more information contact{' '}
						<a>dirk.lauwaet@vito.be</a>.
					</StoryParagraph>
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro08.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Explore the impact of adaptation measures on local heat stress
						during a hot summer day
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App8_ShowcaseA mapSetKey={'ap08-urban-thermal-comfort-showcase-a'} />
				<App8_ShowcaseC mapSetKey={'ap08-urban-thermal-comfort-showcase-c'} />
				<App8_ShowcaseB />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Copenhagen',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AAANkYBM0rRf3IpjLEvCh5hsa/CURE_AP08_Copenhagen_sourcedata.zip?raw=1',
							},
							{
								city: 'Ostrava',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AAD0XDiIDS3q9Moac1FWPK_Qa/CURE_AP08_Ostrava_sourcedata.zip?raw=1',
							},
							{
								city: 'San Sebastian',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AACH59lTFnWSm4H5OE0aTLYSa/CURE_AP08_SanSebastian_sourcedata.zip?raw=1',
							},
							{
								city: 'Sofia',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AADAzp7Zk5oqxFrarjVYOsKsa/CURE_AP08_Sofia_sourcedata.zip?raw=1',
							},
						]}
						otherApps={[
							{
								key: 'ap03-urban-heat-emissions',
								label: 'Urban Heat Emissions Monitoring',
							},
							{
								key: 'ap10-nature-based-solutions',
								label: 'Nature-Based Solutions',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App8_UrbanThermalComfort.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App8_UrbanThermalComfort;
