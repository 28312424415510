import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	return {
		options: Select.components.get(state, ownProps.componentKey, 'options'),
		activeOptionKey: Select.components.get(
			state,
			ownProps.componentKey,
			'activeOptionKey'
		),
	};
};

const mapDispatchToPropsFactory = () => {
	return (dispatch, ownProps) => {
		return {
			onMount: () => {},
			onLayerChange: (optionKey, activeOption, options) => {
				dispatch(
					Action.cure.applicationStories.ap01.setShowcaseALayer(
						ownProps.componentKey,
						ownProps.activeOptionKey,
						activeOption,
						options,
						optionKey
					)
				);
			},
		};
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(Presentation);
