import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';
import Action from '../../../state/Action';

const mapStateToProps = (state, ownProps) => {
	return {
		user: Select.users.getActive(state),
		config: Select.app.getConfiguration(
			state,
			`configByApplicationStoryKey.${ownProps.applicationStoryKey}.orderingService`
		),
		isDark: Select.router.getDarkModeActive(state),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		setServicesModalIsOpen: () => {
			return dispatch(
				Action.components.set('ordered-services', 'servicesModalIsOpen', true)
			);
		},
		setOpenAppSettings: (name, status) => {
			return dispatch(
				Action.components.set('ordered-services', 'openAppSettings', {
					[name]: status,
				})
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
