import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';
import App4_ShowcaseA from './App4_ShowcaseA';
import OrderServiceTool from '../../common/OrderServiceTool';
import StoryFooterSection from '../../common/StoryFooterSection';

const App4_UrbanFloodRisk = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}></StoryParagraph>
					<StorySubheadline className={'cure-Story-InfoBox'}>
						Partitioning of CO
						<span
							className="cure-Story-smallSymbolBottom"
							style={{fontWeight: 600}}
						>
							2
						</span>{' '}
						emission sources/sinks
					</StorySubheadline>
					<StoryParagraph className={''}>
						Urban CO<span className="cure-Story-smallSymbolBottom">2</span>{' '}
						emissions have a spatial dimension due to the heterogeneous nature
						of urban land use/land cover and urbanization. In this CURE
						application, the CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions
						are partitioned into an anthropogenic (traffic, heating/cooling) and
						a biogenic component (urban green space):
					</StoryParagraph>
					<StoryParagraph className={''}>
						<ul style={{margin: '0px 0px 1rem 1rem'}}>
							<li>Anthropogenic source from fossil fuel combustion by cars</li>
							<li>
								Anthropogenic source from fossil fuel combustion through
								heating/cooling
							</li>
							<li>Biogenic source from human respiration</li>
							<li>
								Biogenic source/sink from plant/soil respiration and
								photosynthesis
							</li>
						</ul>
					</StoryParagraph>
					<StorySubheadline className={'cure-Story-InfoBox'}>
						Eddy Covariance measurements
					</StorySubheadline>
					<StoryParagraph className={''}>
						A very fast and simultaneous measurement of vertical wind and CO
						<span className="cure-Story-smallSymbolBottom">2</span>
						{''} concentration provides the base for calculating the vertical
						exchange of CO
						<span className="cure-Story-smallSymbolBottom">2</span> (CO
						<span className="cure-Story-smallSymbolBottom">2</span> flux).
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Intra-urban CO
						<span
							className="cure-Story-smallSymbolBottom"
							style={{fontWeight: 600}}
						>
							2
						</span>{' '}
						emission dynamics
					</StorySubheadline>
					<StoryParagraph className={''}>
						Interactive maps help the user to assess, analyse and compare the CO
						<span className="cure-Story-smallSymbolBottom">2</span>
						{''} emissions from different sources/sinks for the area of
						investigation. Choose a map for the respective city, year, season
						and week days (working days/weekend). Hoover on the map to show the
						values for total CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions as
						well the respective values for the individual contributions.
					</StoryParagraph>
					<StoryParagraph className={''}>
						This CURE application provides maps of mean daily (g CO
						<span className="cure-Story-smallSymbolBottom">2</span> m
						<span className="cure-Story-smallSymbolTop">-2</span> d
						<span className="cure-Story-smallSymbolTop">-1</span>) and hourly (g
						CO<span className="cure-Story-smallSymbolBottom">2</span> m
						<span className="cure-Story-smallSymbolTop">-2</span> h
						<span className="cure-Story-smallSymbolTop">-1</span>) CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions
						for urban areas with a pixel size of 100 m × 100 m. Maps are
						produced for all seasons (DJF: December-January-February, MAM:
						March-April-May, JJA: June-July-August, SON:
						September-October-November) in 2018 and 2019 for working days and
						weekends.
					</StoryParagraph>

					{/* <StorySidePanelCase className={''}> */}
					{/* <> */}
					<StorySubheadline className={''}>
						Seasonal difference in CO
						<span
							className="cure-Story-smallSymbolBottom"
							style={{fontWeight: 600}}
						>
							2
						</span>{' '}
						emissions
					</StorySubheadline>
					<StoryParagraph className={''}>
						Change season specification for left or right map to show
						differences in seasonal CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions.
						Use slider to compare maps. Seasonal differences are most pronounced
						in building emissions and vegetation, but both depending on climate
						zones. Hoover for exact values.
					</StoryParagraph>
					<StorySubheadline className={''}>
						Working days and weekends
					</StorySubheadline>
					<StoryParagraph className={''}>
						Working days and weekends show distinct differences in traffic
						emissions. Change week days specification for left or right map to
						highlight differences of traffic CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions
						between working days and weekends. Only slight seasonal variations
						can be seen. Hoover for exact values.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						Spatial planning strategies have an influence on the urban form, and
						consequently affect CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions
						through changes in traffic patterns, energy consumption and location
						and extent of urban green areas in the city and their contribution
						to the overall emissions. Knowing the portion of the anthropogenic
						and the biogenic part of CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions in
						a high spatial resolution (neighborhood scale) will provide urban
						planners with an additional decision support tool for developing
						emission reduction strategies.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The interpretation of CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions
						spatial-temporal pattern helps to understand changes in traffic
						patterns, energy consumption and location and extent of urban green
						areas in the city and they contribution to the overal emissions.
						Granular information on the anthropogenic and the biogenic part of
						CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions in
						a high spatial resolution (neighborhood scale) provide urban
						planners with an additional potential for informed decision support.
						The service addresses needs of urban administration and planners to
						optimise emission reduction strategies providing solid ground for
						activities towards their climate change mitigation and adaptation
						interventions.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								Real-time measurements of CO
								<span className="cure-Story-smallSymbolBottom">2</span> flux at
								urban flux towers:
								<br />
								<a href="http://rslab.gr/heraklion_eddy.html">Heraklion</a>
								<br />
								<a href="https://mcr.unibas.ch/dolueg2/index.php?project=basel">
									Basel
								</a>
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								<a href="https://www.mdpi.com/2072-4292/13/18/3598">
									Scientific paper
								</a>{' '}
								about urban heat (and cool) islands and its explanation by the
								different partitioning of the available energy into energy
								fluxes for urban and natural surfaces (Parlow, 2021).
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						The processing chain of Urban CO
						<span className="cure-Story-smallSymbolBottom">2</span> Emissions
						Monitoring relies mainly on local data (high resolution models of
						buildings and trees, traffic data) which are completed by Land Cover
						information derived from Sentinel-2 and Copernicus CLMS services
						(Urban Atlas), seasonal information about vegetation activity by the
						Normalized Difference Vegetation Index NDVI (derived from
						Sentinel-2) and measurements of CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions at
						an urban flux tower site.
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<StoryParagraph className={''}>
						This service is provided by{' '}
						<a href="https://duw.unibas.ch/en/research-groups/atmospheric-sciences/">
							Atmospheric Sciences, University of Basel, Switzerland
						</a>
						.
					</StoryParagraph>
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro04.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Monitor and analyse the CO
						<span className="cure-Story-smallSymbolBottom">2</span> emissions in
						your city
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App4_ShowcaseA mapSetKey={'ap04-surface-dynamics-showcase-c'} />
				{/* <App4_ShowcaseA mapSetKey={'ap04-surface-dynamics-showcase-c'} />
				<App4_ShowcaseA mapSetKey={'ap04-surface-dynamics-showcase-c'} /> */}
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Basel',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AACyOf3lkvpMxs4eYgqKS8yXa/CURE_AP04_BASEL_sourcedata.ZIP?raw=1',
							},
							{
								city: 'Heraklion',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AABTh7TodzI_jBk0s-yndEhsa/CURE_AP04_HERAKLION_sourcedata.ZIP?raw=1',
							},
						]}
						otherApps={[
							{
								key: 'ap03-urban-heat-emissions',
								label: 'Urban Heat Emissions Monitoring',
							},
							{
								key: 'ap07-urban-air-quality',
								label: 'Urban Air Quality',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App4_UrbanFloodRisk.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App4_UrbanFloodRisk;
