import {useEffect} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './style.scss';
import Select from '../Select';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';

const ShowcaseHead = ({
	className,
	onMount,
	onUnmount,
	data,
	dataProperties,
	activeKeys,
	selectActiveKey,
	customLabel,
	noSelectIndex,
	reverseSelectsOrder,
	infoWindow,
	selectType,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	let availableKeys = [];
	let contemporaryKeys = [];
	let activeData = data;

	dataProperties?.forEach(
		(properties, index) => (
			properties.data.forEach(property =>
				activeData
					? Object.keys(activeData).forEach(key =>
							key == property.nameInternal
								? contemporaryKeys.push(property)
								: null
					  )
					: null
			),
			contemporaryKeys.length > 0
				? // if active key is not included in available keys - set default key as the active key
				  contemporaryKeys.find(
						item => item.key == activeKeys?.['key' + index].key
				  ) == undefined
					? selectActiveKey('key' + index, contemporaryKeys[0])
					: null
				: null,
			availableKeys.push(contemporaryKeys),
			(activeData = activeData?.[activeKeys?.['key' + index]?.nameInternal]),
			(contemporaryKeys = [])
		)
	);

	const selects = [];

	const options = availableKeys?.map(items => {
		return items?.map(item => {
			return {
				value: item.nameInternal,
				label: item.nameDisplay,
				title: item.title,
				unit: item.unit,
			};
		});
	});

	let selectElement;

	activeKeys && options && data
		? options.forEach(
				(item, index) => (
					(selectElement = (
						<div key={index} className="cure-ShowcaseHead-selectContainer">
							{dataProperties?.[index]?.selectLabel ? (
								<h3 className="cure-ShowcaseHead-selectLabel">
									{dataProperties[index].selectLabel}
								</h3>
							) : null}
							<div className="cure-ShowcaseHead-select">
								{selectType !== 'switch' ? (
									<Select
										small
										options={item}
										onChange={selectedValue => {
											selectActiveKey('key' + index, {
												key: selectedValue.value,
												nameDisplay: selectedValue.label,
												nameInternal: selectedValue.value,
												title: selectedValue.title,
												unit: selectedValue.unit,
											});
										}}
										value={
											item.length < 1
												? ''
												: {
														value: activeKeys['key' + index].nameInternal,
														label: activeKeys['key' + index].nameDisplay,
												  }
										}
										disabled={item.length <= 1 ? true : false}
									/>
								) : (
									<ButtonSwitch
										className="cure-ShowcaseHead-switchBtn"
										onClick={selectedValue => {
											selectActiveKey('key' + index, {
												key: selectedValue.value,
												nameDisplay: selectedValue.label,
												nameInternal: selectedValue.value,
												unit: selectedValue.unit,
											});
										}}
										ghost
									>
										{item.map(option => (
											<ButtonSwitchOption
												key={option.value}
												value={option}
												active={
													activeKeys['key' + index].nameInternal == option.value
												}
											>
												{option.label}
											</ButtonSwitchOption>
										))}
									</ButtonSwitch>
								)}
							</div>
						</div>
					)),
					!noSelectIndex?.includes(index)
						? !reverseSelectsOrder
							? selects.push(selectElement)
							: selects.unshift(selectElement)
						: null
				)
		  )
		: null;

	return (
		<div className={classnames('cure-ShowcaseHead', {}, className)}>
			<div className="cure-ShowcaseHead-headline">
				{customLabel ? (
					customLabel
				) : (
					<h2
						className="cure-ShowcaseHead-headlineTitle"
						style={infoWindow ? {paddingRight: '0.5rem'} : {padding: '0'}}
					>
						{activeKeys?.['key' + (dataProperties.length - 1)]?.nameDisplay}
					</h2>
				)}
				{infoWindow}
			</div>
			{data ? (
				<div className="cure-ShowcaseHead-selectsContainer">{selects}</div>
			) : null}
		</div>
	);
};

ShowcaseHead.propTypes = {
	className: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	data: PropTypes.object,
	dataProperties: PropTypes.array,
	availableKeys: PropTypes.array,
	activeKeys: PropTypes.object,
	selectActiveKey: PropTypes.func,
	customLabel: PropTypes.element,
	noSelectIndex: PropTypes.array,
	reverseSelectsOrder: PropTypes.bool,
	infoWindow: PropTypes.element,
	selectType: PropTypes.string,
};

export default ShowcaseHead;
