import PropTypes from 'prop-types';
import ChartTooltip from '../../../../common/ChartTooltip';

import './style.scss';

const App2_ShowcaseC_ChartTooltip = ({point}) => {
	if (point) {
		const {color, serieId, data} = point;
		return (
			<ChartTooltip>
				<div className="cure-App2_ShowcaseC_ChartTooltip-header">
					<div
						className="cure-App2_ShowcaseC_ChartTooltip-header-icon"
						style={{background: color}}
					/>
					<div className="cure-App2_ShowcaseC_ChartTooltip-title">
						{serieId}
					</div>
				</div>
				<div className="cure-App2_ShowcaseC_ChartTooltip-content">
					<div className="cure-App2_ShowcaseC_ChartTooltip-item big">
						<span>SUHII point estimate:</span>
						{data?.y?.toFixed(3)}
					</div>
					<div className="cure-App2_ShowcaseC_ChartTooltip-item">
						<span>Months:</span>
						{data?.months?.join(', ')}
					</div>
					<div className="cure-App2_ShowcaseC_ChartTooltip-item">
						<span>SUHII 95% confidence interval:</span>(
						{data?.suhii_ci?.[0]?.toFixed(3)} -{' '}
						{data?.suhii_ci?.[1]?.toFixed(3)})
					</div>
					<div className="cure-App2_ShowcaseC_ChartTooltip-item">
						<span>Number of scenes:</span>
						{data?.number_of_scenes}
					</div>
				</div>
			</ChartTooltip>
		);
	} else {
		return null;
	}
};

App2_ShowcaseC_ChartTooltip.propTypes = {
	point: PropTypes.object,
};

export default App2_ShowcaseC_ChartTooltip;
