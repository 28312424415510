export const appKey = 'horizon2020CureAppOverview';

export const gaId = 'UA-59584085-19';

export const screens = {
	about: 'about',
	'cure-applications': 'cure-applications',
	'city-demonstrations': 'city-demonstrations',
	'city-grand-challenges': 'city-grand-challenges',
	'cure-services': 'cure-services',
};

export const tagsFilter = {
	filterByActive: {application: true},
	filter: {},
	order: [],
	start: 1,
	length: 1000,
};

export const placesFilter = {
	filterByActive: {application: true},
	filter: {},
	order: [],
	start: 1,
	length: 20,
};

export const scopesFilter = {
	filterByActive: {application: true},
	filter: {},
	order: [['nameDisplay', 'ascending']],
	start: 1,
	length: 11,
};

// timeline
export const timelineLayerLineHeight = 20; //px
export const timelineLayerElementHeight = 8; //px, rest is for line padding
