import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import Presentation from '../../../../common/maps/MapLegends/StyleBasedLegend/presentation';

const componentId = 'StyleBasedLegend';

const mapStateToProps = (state, ownProps) => {
	const caseKey = ownProps?.layerState?.metadataModifiers?.caseKey;
	const styleKey = Select.cure.applicationStories.ap08.getStyleKeyForLegend(
		state,
		ownProps.applicationStoryKey,
		ownProps.showcaseKey,
		ownProps.layerTemplateKey,
		caseKey
	);

	const style = Select.cure.applicationStories.ap08.getPreparedStylesForLegend(
		state,
		ownProps.applicationStoryKey,
		ownProps.showcaseKey,
		ownProps.layerTemplateKey,
		caseKey
	);

	return {
		styleKey,
		baseStyle: style?.baseStyle,
		attributeStyle: style?.attributeStyle,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAttributeChange: attributeKey => {
			dispatch(Action.attributes.useKeys([attributeKey], componentId));
		},
		onStyleChange: styleKey => {
			dispatch(Action.styles.useKeys([styleKey], componentId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
