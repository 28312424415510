import PropTypes from 'prop-types';
import CenteredWrap from '../../common/CenteredWrap';
import About from './About/presentation';
import UrbanChallenges from './UrbanChallenges/presentation';
import ApplicationCatalog from './ApplicationCatalog';
import CityDemonstrations from './CityDemonstrations';
import Footer from '../../Footer/presentation';
import {screens} from '../../../constants/app';
import './style.scss';
import Services from './Services';

const getScreenComponent = name => {
	switch (name) {
		case screens['about']:
			return <About />;
		case screens['cure-applications']:
			return (
				<CenteredWrap>
					<div className="cure-IndexBody-content">
						<ApplicationCatalog />
					</div>
				</CenteredWrap>
			);
		case screens['city-grand-challenges']:
			return <UrbanChallenges />;
		case screens['city-demonstrations']:
			return <CityDemonstrations />;
		case screens['cure-services']:
			return <Services />;
	}
};

const IndexBody = ({activeScreen}) => {
	return (
		<div className="cure-IndexBody">
			{activeScreen ? getScreenComponent(activeScreen) : null}
			<Footer
				className={
					activeScreen == 'city-demonstrations' ? 'is-cityDemonstrations' : ''
				}
			/>
		</div>
	);
};

IndexBody.propTypes = {
	activeScreen: PropTypes.string,
};

export default IndexBody;
