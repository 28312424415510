import {useEffect} from 'react';

/**
 * @param onMount {func}
 * @param onUnmount {func}
 * @param onMountParams {Array}
 */
export const useMountUnmount = (onMount, onUnmount, onMountParams) =>
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMountParams?.length ? onMount(...onMountParams) : onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);
