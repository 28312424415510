import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const component = Select.components.getByComponentKey(
		state,
		ownProps.componentKey
	);
	return {
		messages: component?.messages,
		errors: component?.errors,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		createUser: (name, password) => {
			return dispatch(
				Action.cure.createUser(name, password, ownProps.componentKey)
			);
		},
		clearErrorsAndMessages: () => {
			dispatch(Action.components.set(ownProps.componentKey, 'errors', null));
			dispatch(Action.components.set(ownProps.componentKey, 'messages', null));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
