import PropTypes from 'prop-types';

import './style.scss';

const renderContent = (layerKey, value) => {
	switch (layerKey) {
		case 'ap02-urban-heat-island-showcase-a-1-heat':
			return (
				<div>
					<b>Temperature:</b> <b>{`${value} °C`}</b>
				</div>
			);
		case 'ap02-urban-heat-island-showcase-a-2-heat':
			return (
				<div>
					<b>Imperviousness:</b> <b>{`${value} %`}</b>
				</div>
			);
		default:
			return null;
	}
};

const GetFeatureInfoTooltipContent = props => {
	const layerKey = props?.event?.layer?.props?.layerKey;
	const value =
		Math.round(Number.parseFloat(props.response.value_list) * 10) / 10;
	return (
		<div className="ptr-FeatureTooltip-body">
			{renderContent(layerKey, value)}
		</div>
	);
};

GetFeatureInfoTooltipContent.propTypes = {
	event: PropTypes.object,
	response: PropTypes.object,
	mapKey: PropTypes.string,
};

export default GetFeatureInfoTooltipContent;
