import {Button} from '@gisatcz/ptr-atoms';
import PropTypes from 'prop-types';
import {useEffect} from 'react';

import './style.scss';

import ManageServices from './ManageServices';
import Table from '../../../common/Table';
import {Icon} from '@gisatcz/ptr-atoms';

const appNumbersToLabels = app => {
	switch (app) {
		case 'app1':
			return 'Local Scale Surface Temperature Dynamics';
		case 'app2':
			return 'Surface Urban Heat Island Assessment';
		case 'app3':
			return 'Urban Heat Emissions Monitoring';
		case 'app4':
			return 'Urban CO2 Emissions Monitoring';
		case 'app5':
			return 'Urban Flood Risk';
		case 'app6':
			return 'Urban Subsidence, Movements and Deformation Risk';
		case 'app7':
			return 'Urban Air Quality';
		case 'app8':
			return 'Urban Thermal Comfort';
		case 'app9':
			return 'Urban Heat Storage Monitoring';
		case 'app10':
			return 'Nature-Based Solutions';
		case 'app11':
			return 'Socioeconomic perspective of Health Impacts';
	}
};

const tableHead = [
	{
		id: 1,
		name: 'Application',
		selector: 'app',
		sortable: true,
	},
	{
		id: 2,
		name: 'Created',
		selector: 'created',
		sortable: true,
		right: true,
	},
	{
		id: 3,
		name: 'Expiration',
		selector: 'expiration',
		sortable: true,
		right: true,
	},
	{
		id: 4,
		name: 'Download',
		selector: row =>
			row.result ? (
				<a href={row.result}>
					<Icon icon="ri-forward" />
				</a>
			) : (
				'Not available'
			),
		right: true,
	},
	{
		id: 5,
		name: 'Status',
		selector: 'status',
		sortable: true,
		right: true,
		conditionalCellStyles: [
			{
				when: row => row.status == 'SUCCESS',
				style: {
					color: '#2fad39',
				},
			},
			{
				when: row => row.status == 'RUNNING',
				style: {
					color: '#c9c016',
				},
			},
			{
				when: row => row.status == 'QUEUED',
				style: {},
			},
			{
				when: row => row.status == 'FAILED',
				style: {
					color: '#FF4A4A',
				},
			},
			{
				when: row => row.status == 'RECEIVED',
				style: {
					color: 'hsl(170.68deg, 59.68%, 48.63%)',
				},
			},
			{
				when: row => row.status == 'INVALID',
				style: {
					color: '#7F8487',
				},
			},
		],
	},
];

const customSort = (rows, selector, direction) => {
	return rows.sort((rowA, rowB) => {
		// use the selector function to resolve your field names by passing the sort comparitors
		let aField = selector(rowA);
		let bField = selector(rowB);

		let rowDate = new Date(aField);
		let comparison = 0;

		rowDate.toString() !== 'Invalid Date'
			? ((aField = new Date(aField).getTime()),
			  (bField = new Date(bField).getTime()))
			: null;

		if (aField < bField) {
			comparison = 1;
		} else if (aField > bField) {
			comparison = -1;
		}

		return direction === 'desc' ? comparison * -1 : comparison;
	});
};

const Services = ({
	onMount,
	onUnmount,
	services,
	createUserServices,
	orderStatus,
	setOrderStatus,
	user,
	servicesModalIsOpen,
	setServicesModalIsOpen,
	closeAppSettings,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	const adjustedServices = services?.map(item => {
		return {
			app: appNumbersToLabels(item.app),
			created: new Date(item.created).toUTCString(),
			expiration: new Date(
				new Date(item.created).setDate(
					new Date(item.created).getDate() + Number(item.expiration)
				)
			).toUTCString(),
			key: item.key,
			orderId: item.orderId,
			result: item.result,
			status: item.status,
		};
	});

	useEffect(() => {
		orderStatus == 'success'
			? (createUserServices(), setOrderStatus(undefined))
			: null;
	}, [orderStatus]);

	const userIsLogged = user?.data?.name !== 'guest';

	if (userIsLogged) {
		return (
			<div className="cure-Services-container">
				<div className="cure-Services-servicesBtnContainer">
					<Button
						className="cure-Services-servicesBtn"
						onClick={() => setServicesModalIsOpen(true)}
					>
						<Icon icon={'ri-add'} />
						<span className="cure-Services-servicesBtnLabel">Add process</span>
					</Button>
				</div>
				<div className="cure-Services-Table">
					<Table
						data={adjustedServices}
						options={tableHead}
						responsive
						highlightOnHover
						fixedHeader
						pagination
						selectableRowsHighlight
						paginationPerPage={10}
						defaultSortFieldId={2}
						customSort={customSort}
						tableHeight="100%"
					/>
				</div>
				<ManageServices
					isOpen={servicesModalIsOpen}
					onClose={() => {
						setServicesModalIsOpen(false);
						closeAppSettings();
					}}
				/>
			</div>
		);
	} else {
		return null;
	}
};

Services.propTypes = {
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	services: PropTypes.array,
	createUserServices: PropTypes.func,
	orderStatus: PropTypes.string,
	setOrderStatus: PropTypes.func,
	user: PropTypes.object,
	servicesModalIsOpen: PropTypes.bool,
	setServicesModalIsOpen: PropTypes.func,
	closeAppSettings: PropTypes.func,
};

export default Services;
