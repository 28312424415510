import PropTypes from 'prop-types';
import classnames from 'classnames';
import {MapControls} from '@gisatcz/ptr-maps';
import CompareMapsControl from '../CompareMapsControl';
import MapComponentsPositionWrapper from '../MapComponentsPositionWrapper';
import './style.scss';

const MapSetControls = props => {
	const classes = classnames('cure-MapSetControls', {
		'is-horizontal': props.horizontal,
	});

	return (
		<MapComponentsPositionWrapper
			{...props}
			className={classes}
			dataTour={'map-set-controls'}
		>
			{props.withoutCompare ? (
				<></>
			) : (
				<CompareMapsControl mapSetKey={props.mapSetKey} />
			)}
			<MapControls levelsBased zoomOnly />
		</MapComponentsPositionWrapper>
	);
};

MapSetControls.propTypes = {
	horizontal: PropTypes.bool,
	mapSetKey: PropTypes.string,
	withoutCompare: PropTypes.bool,
};

export default MapSetControls;
