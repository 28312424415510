import {connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import Presentation from './presentation';
// import Select from '../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const optionsKeys = Select.cure.applicationStories.ap04.getOptionTagKeys(
		state,
		ownProps.componentKey,
		ownProps.tagKey
	);

	const mainTag = Select.tags.getByKey(state, ownProps.tagKey);
	const title = mainTag ? mainTag?.data?.nameDisplay : '';

	const tags = Select.tags.getByKeys(state, optionsKeys) || [];
	const activeTagKey = Select.cure.applicationStories.ap04.getActiveTagKey(
		state,
		ownProps.componentKey,
		ownProps.tagKey,
		ownProps.activeMapKey
	);

	return {
		optionsKeys,
		title,
		tags,
		activeTagKey,
	};
};

const mapDispatchToProps = () => {
	const componentId = `tags_${utils.uuid()}`;
	return (dispatch, ownProps) => {
		return {
			loadKeys: optionsKeys => {
				dispatch(
					Action.tags.useKeys([...optionsKeys, ownProps.tagKey], componentId)
				);
			},
			onChange: val => {
				dispatch(
					Action.cure.applicationStories.ap04.setActiveTag(
						ownProps.componentKey,
						ownProps.tagKey,
						val.value,
						ownProps.activeMapKey
					)
				);

				if (typeof ownProps.onChange === 'function') {
					ownProps.onChange();
				}
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
