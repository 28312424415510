import {Select as commonSelect, connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';

import presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const tags = [];
	if (ownProps.parentTags?.length) {
		ownProps.parentTags.forEach(parentTag => {
			const activeTagKey = Select.cure.applicationStories.ap04.getActiveTagKey(
				state,
				ownProps.componentKey,
				parentTag,
				ownProps.mapKey
			);
			const tag = commonSelect.tags.getByKey(state, activeTagKey);
			if (tag) {
				tags.push(tag);
			}
		});
	}

	return {tags};
};

const mapDispatchToPropsFactory = () => {
	return {};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(presentation);
