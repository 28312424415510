import {connect, Select as CommonSelect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Actions from '../../../../state/Action';

import Presentation from './presentation';
import {utils} from '@gisatcz/ptr-utils';

const sofiaPlaceKey = '86a682c3-af49-4062-be05-1965eb216c32';
const copernicusLayerTemplate = 'd6bde774-2a6d-4eee-86a0-fa373c3bba4c';
const showCaseKey = 'App7_ShowcaseA';
const layerTemplatesComponentKey = 'ap07-layertemplates-a';

const mapStateToProps = (state, ownProps) => {
	const configuration =
		Select.cure.applicationStories.getConfigForActiveApplicationStory(state);
	const rasterLegendData = Select.cure.applicationStories.getRasterLegend(
		state,
		showCaseKey
	);
	const activePlaceKey = CommonSelect.places.getActiveKey(state);
	return {
		parametersComponentKey: configuration.parametersComponentKey,
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, ownProps.mapSetKey),
		rasterLegendData,
		layerTemplatesComponentKey,
		activePlaceKey,
		layerTemplateSelectDisabled: activePlaceKey !== sofiaPlaceKey,
	};
};

const mapDispatchToProps = () => {
	const componentId = `TagsParamsSelect_${utils.uuid()}`;
	return (dispatch, ownProps) => {
		return {
			onActivePlaceKeyChanged: (activePlaceKey, tagsComponentKey) => {
				if (activePlaceKey !== sofiaPlaceKey) {
					dispatch(
						Actions.cure.applicationStories.ap07.setLayerTemplateToMaps(
							ownProps.mapSetKey,
							layerTemplatesComponentKey,
							copernicusLayerTemplate,
							tagsComponentKey,
							componentId
						)
					);
				}
			},
			onActiveFilterChange: (tagsComponentKey, mapKey, layerTemplateKey) => {
				if (layerTemplateKey) {
					dispatch(
						Actions.cure.applicationStories.ap07.setActiveLayerTemplate(
							layerTemplatesComponentKey,
							layerTemplateKey,
							mapKey
						)
					);
				}

				dispatch(
					Actions.cure.applicationStories.ap07.onFilterChange(
						tagsComponentKey,
						layerTemplatesComponentKey,
						mapKey,
						componentId
					)
				);
			},
			onUnmount: () => {
				dispatch(Actions.cases.useIndexedClear(componentId));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
