import classNames from 'classnames';
import {
	ReactCompareSlider,
	ReactCompareSliderHandle,
} from 'react-compare-slider';
import PropTypes from 'prop-types';
import {connects} from '@gisatcz/ptr-state';
import {PresentationMap, DeckGlMap} from '@gisatcz/ptr-maps';
import MapSetInfo from '../MapSetInfo';
import MapSetControls from '../MapSetControls';
import MapWrapper from '../MapWrapper';
import helpers from '../helpers';

import './style.scss';

const ConnectedMap = connects.Map(PresentationMap);

const MapCompare = ({
	leftMapKey,
	rightMapKey,
	mapSetKey,
	componentsByLayer,
	config,
	children,
	className,
	MapTooltip,
	mapTooltipProps,
	displayOverlay,
	Overlay,
}) => {
	const classes = classNames('ptr-MapCompareSlider', className);
	return (
		<ReactCompareSlider
			onlyHandleDraggable
			handle={
				<ReactCompareSliderHandle
					buttonStyle={{position: 'absolute', top: 'calc(50% - 23px)'}}
				/>
			}
			className={classes}
			itemOne={
				<ConnectedMap
					mapComponent={DeckGlMap}
					stateMapKey={leftMapKey}
					wrapper={MapWrapper}
					wrapperProps={{
						mapSetKey,
						toolsLeft: true,
						highlightActive: helpers.getMapSetConfig(
							config?.mapSet,
							'highlightActiveMap'
						),
						componentsByLayer,
						configurationsByLayer: config?.layers,
						displayOverlay,
						Overlay,
					}}
					Tooltip={MapTooltip}
					tooltipProps={{...mapTooltipProps, renderLeft: true}}
				/>
			}
			itemTwo={
				<ConnectedMap
					mapComponent={DeckGlMap}
					stateMapKey={rightMapKey}
					wrapper={MapWrapper}
					wrapperProps={{
						mapSetKey,
						highlightActive: config?.mapSet?.highlightActiveMap,
						componentsByLayer,
						configurationsByLayer: config?.layers,
						displayOverlay,
						Overlay,
					}}
					Tooltip={MapTooltip}
					tooltipProps={mapTooltipProps}
				>
					<MapSetInfo mapSetKey={mapSetKey} />
					{helpers.getMapSetConfig(config?.mapSet, 'controls') ? (
						<MapSetControls
							mapSetKey={mapSetKey}
							key={'MapSetControls'}
							horizontal={helpers.getMapSetConfig(
								config?.mapSet,
								'controls.horizontal'
							)}
						/>
					) : (
						<></>
					)}
					{children}
				</ConnectedMap>
			}
		/>
	);
};

MapCompare.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	componentsByLayer: PropTypes.array,
	config: PropTypes.object,
	leftMapKey: PropTypes.string.isRequired,
	rightMapKey: PropTypes.string.isRequired,
	mapSetKey: PropTypes.string,
	MapTooltip: PropTypes.func,
	mapTooltipProps: PropTypes.object,
	displayOverlay: PropTypes.object,
	Overlay: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.func,
		PropTypes.elementType,
	]),
};

export default MapCompare;
