import PropTypes from 'prop-types';
import Showcase from '../../../common/Showcase';
import LineChart from './LineChart';
import App2_ShowcaseC_ChartTooltip from './App2_ShowcaseC_ChartTooltip';
import './style.scss';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';

const App2_ShowcaseC = ({chartMode, onChartModeChange, data}) => {
	return (
		<Showcase className="cure-App2_ShowcaseC">
			<div className="cure-App2_ShowcaseC-title">
				<h2>Surface Urban Heat Island Indicator (SUHII)</h2>
				<p>
					Results calculated for two-month periods of the years 2018 and 2019
				</p>
			</div>
			<div className="cure-App2_ShowcaseC-switcher">
				<ButtonSwitch onClick={onChartModeChange} ghost>
					<ButtonSwitchOption
						key="active"
						value="active"
						active={chartMode !== 'all'}
					>
						Active city
					</ButtonSwitchOption>
					<ButtonSwitchOption
						key="all"
						value="all"
						active={chartMode === 'all'}
					>
						All cities
					</ButtonSwitchOption>
				</ButtonSwitch>
			</div>
			<div className="cure-App2_ShowcaseC-chart-wrapper">
				<LineChart
					data={data}
					tooltip={App2_ShowcaseC_ChartTooltip}
					colors={{datum: 'color'}}
				/>
			</div>
		</Showcase>
	);
};

App2_ShowcaseC.propTypes = {
	chartMode: PropTypes.string,
	onChartModeChange: PropTypes.func,
	data: PropTypes.array,
};

export default App2_ShowcaseC;
