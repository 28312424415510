import barChartData from './data/barChart/data.json';
import barChartTypes from './data/barChart/types.json';
import tableData from './data/table/data.json';

import PropTypes from 'prop-types';
import {ButtonSwitch, ButtonSwitchOption} from '@gisatcz/ptr-atoms';
import './style.scss';
import {useState} from 'react';

import Table from '../../../common/Table';
import BarChart from '../../../common/BarChart';
import ShowcaseHead from '../../../common/ShowcaseHead';
import InfoWindow from '../../../common/InfoWindow';
import Showcase from '../../../common/Showcase';

const App10_ShowcaseC = activePlace => {
	const [showTable, setShowTable] = useState(false);
	const infoWindow = (
		<div className="cure-App10_ShowcaseC-infoContent">
			<h3 className="cure-App10_ShowcaseC-infoTitle">Data description</h3>
			<p className="cure-App10_ShowcaseC-infoDescription">
				Representation of the maximum roof area that can be potentially
				vegetated compared to the total roof area by neighborhoods of the city
				of San Sebastian.
			</p>
		</div>
	);
	const city = {
		data: [
			{
				key: activePlace?.activePlace?.key,
				nameInternal: activePlace?.activePlace?.data?.nameInternal,
				nameDisplay: activePlace?.activePlace?.data?.nameDisplay,
			},
		],
	};
	const dataProperties = [city, barChartTypes];
	return (
		<Showcase>
			<ShowcaseHead
				data={!showTable ? barChartData : undefined}
				dataProperties={dataProperties}
				componentKey="ap10-nature-basedSolutions-showcase-c-BarChartSelects"
				noSelectIndex={[0]}
				customLabel={
					<h2 className="cure-App10_ShowcaseC-headlineTitle">
						San Sebastian´s Neighborhoods Green Roof Potential
					</h2>
				}
				infoWindow={<InfoWindow content={infoWindow} />}
				reverseSelectsOrder={true}
				selectType="switch"
			/>

			{!showTable ? (
				<BarChart
					data={barChartData}
					dataProperties={dataProperties}
					componentKey="ap10-nature-basedSolutions-showcase-c-BarChartSelects"
					legendDirection="row"
					legendTranslateX={-1}
					legendTranslateY={60}
					marginTop={10}
					marginBottom={120}
					marginRight={40}
					marginLeft={80}
					innerPadding={0.5}
					colors={{
						'Area with green roof potential (max)': 'hsl(118, 51%, 41%)',
						'Area with no green roof potential': 'hsl(58, 0%, 76%)',
					}}
				/>
			) : (
				<div className="cure-App10_ShowcaseC-Table">
					<Table
						data={tableData.data}
						options={tableData.columns}
						responsive
						highlightOnHover
						striped
						fixedHeader
					/>
				</div>
			)}
			<div className="cure-App10_ShowcaseC-switchWrapper">
				<ButtonSwitch
					className="cure-App10_ShowcaseC-switchBtn"
					onClick={() => setShowTable(!showTable)}
					ghost
				>
					<ButtonSwitchOption key="chart" value="chart" active={!showTable}>
						Chart
					</ButtonSwitchOption>
					<ButtonSwitchOption key="table" value="table" active={showTable}>
						Table
					</ButtonSwitchOption>
				</ButtonSwitch>
			</div>
		</Showcase>
	);
};

App10_ShowcaseC.propTypes = {
	activePlace: PropTypes.object,
};

export default App10_ShowcaseC;
