import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';
//Showcases
import App1_ShowcaseA from './App1_ShowcaseA';
import App1_ShowcaseB from './App1_ShowcaseB';
import App1_ShowcaseC from './App1_ShowcaseC';
//
import PropTypes from 'prop-types';
import {getRouter} from '../../../router';
import Link from '../../common/Link';
import StoryFooterSection from '../../common/StoryFooterSection';
import {useEffect, useRef} from 'react';

const App1_SurfaceDynamics = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
	updateTourStep,
}) => {
	useEffect(() => {
		storyFooter?.current ? updateTourStep(storyFooter.current) : null;
	}, [activeStep]);
	const storyFooter = useRef();
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						The surface temperature is one of the most important parameters in
						the physical processes of urban surface energy, water balance and
						the land-atmosphere exchanges. Thence, it constitutes a valuable
						information source for the understanding of the natural and human
						components of the Earth system. In this context, the service
						provides frequent local scale surface temperature estimations, which
						are essential data for other CURE services too, i.e. the{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap02-urban-heat-island'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Surface Urban Heat Island (UHI) Assessment Service
						</Link>
						, the{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap03-urban-heat-emissions'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Urban Heat Emissions Monitoring Service
						</Link>{' '}
						and the{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap09-urban-heat-storage'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Urban Heat Storage Monitoring Service
						</Link>
						.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Detailed Land Surface Temperature (LST)
					</StorySubheadline>
					<StoryParagraph className={''}>
						This CURE service generates daily land surface temperature maps for
						urban areas exploiting the Copernicus Sentinel-3 thermal
						acquisitions and detailed dynamic information on the land surface
						from Sentinel-2 and land cover / land use information from the CLMS
						and the atmosphere information from C3S. Similar maps available from
						Copernicus are coarse, with a pixel corresponding to more than 1 km
						on the ground, which makes it difficult to assess the intra-urban
						variations of surface temperature. The CURE urban LST compensates
						the spatial-temporal resolution trade-off of the satellite LST
						products, providing dense time series of 100 m spatial resolution
						LST. See example and compare the CURE LST product in 100m resolution
						(left) with original Copernicus service in 1km resolution (right).{' '}
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Intra-urban Surface Temperature Dynamics
					</StorySubheadline>
					<StoryParagraph className={''}>
						<em data-tour="showcase-about">
							The CURE LST maps resolve the intra-urban variations of surface
							temperature, while providing insights on the surface temperature
							dynamics, with up to 4 maps per day. These detailed, frequent
							surface temperature maps are highly important as input for
							assessing the surface urban heat island, the heat emissions and
							the heat storage and the thermal comfort. See interactive timeline
							of the temporal distribution of Sentinel-3 LST maps for LST
							daytime and nighttime values. Select the point in the timeline and
							see a corresponding LST product in a map.
						</em>
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Aggregated temperatures for user-defined units
					</StorySubheadline>
					<StoryParagraph className={''}>
						Land Surface Temperature (LST) information can be related to user
						defined units (e.g. urban plots, neighbourhood blocks, districts) to
						be ready for specific level of analysis. See visualization of CLMS
						Urban Atlas polygons with aggregated temperatures coming from the
						CURE Land Surface Temperature (LST) service. Hoover on the map to
						explore aggregated land surface temperature values. Use slider to
						inspect variation, value distribution and differences between
						daytime and nighttime.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						The surface temperature is one of the most important parameters in
						the physical processes of urban surface energy and water balance
						including the land-atmosphere exchanges. CURE Land Surface
						Temperature dynamics service provides detailed spatial-temporal
						monitoring and analysis of the land surface temperature of your
						city. As such, it guides local and regional decision makers in
						efficient city intervention planning and policy-making.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								Copernicus LST products description -{' '}
								<a href="https://sentinels.copernicus.eu/web/sentinel/user-guides/sentinel-3-slstr/overview/geophysical-measurements/land-surface-temperature">
									the Sentinel-3 SLSTR User Guide
								</a>
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								Copernicus LST products description -{' '}
								<a href="https://land.copernicus.eu/global/products/lst">
									Copernicus Global Land service
								</a>
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								RSLab LST product (Landsat based){' '}
								<a href="http://rslab.gr/downloads_LandsatLST.html">
									documentation and service
								</a>
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						The service is based on{' '}
						<a href="https://sentinels.copernicus.eu/web/sentinel/home">
							Copernicus Sentinel satellite imagery
						</a>
						, <a href="https://land.copernicus.eu/">CLMS</a> and{' '}
						<a href="https://climate.copernicus.eu/">C3S</a> Copernicus
						Services. The methodology is based on{' '}
						<a href="https://sentinels.copernicus.eu/web/sentinel/missions/sentinel-3">
							Sentinel-3
						</a>{' '}
						thermal image downscaling (
						<a href="https://www.mdpi.com/2072-4292/7/4/4139">
							https://www.mdpi.com/2072-4292/7/4/4139
						</a>
						) using several <a href="https://land.copernicus.eu/">CLMS</a>{' '}
						products for detailed surface cover information,{' '}
						<a href="https://sentinel.esa.int/web/sentinel/missions/sentinel-2">
							Sentinel-2
						</a>{' '}
						imagery for urban surface dynamics and atmospheric information from{' '}
						<a href="https://climate.copernicus.eu/">C3S</a>. This service is
						provided by Foundation for Research and Technology Hellas (FORTH),
						Greece.
					</StoryParagraph>
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro01.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Monitor and analyze the surface temperature dynamics in your city
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				<App1_ShowcaseA mapSetKey="ap01-surface-dynamics-showcase-a" />
				<App1_ShowcaseB mapSetKey="ap01-surface-dynamics-showcase-b" />
				<App1_ShowcaseC />
				<StoryMainPanelFooter className={''}>
					<em
						className="cure-Story-mainPanel-footer-tour"
						data-tour="storyFooter"
						ref={storyFooter}
					>
						<StoryFooterSection
							applicationStoryKey={activeApplicationStoryKey}
							downloadOptions={[
								{
									city: 'Basel',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AABiylipzRxhZA-wc1orMuXba/CURE_AP01_%20Basel_sourcedata.zip?raw=1',
								},
								{
									city: 'Berlin',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AAAdmzavPse0mSllJKOTTyiDa/CURE_AP01_%20Berlin_sourcedata.zip?raw=1',
								},
								{
									city: 'Bristol',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AACGHTPl67S4uZtrtyF-JPTwa/CURE_AP01_%20Bristol_sourcedata.zip?raw=1',
								},
								{
									city: 'Copenhagen',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AAA30Hoxv3qvSa9qeOK7GYJIa/CURE_AP01_%20Copenhagen_sourcedata.zip?raw=1',
								},
								{
									city: 'Heraklion',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AABingoMuPx_Peo9Ov1r6TwCa/CURE_AP01_Heraklion_sourcedata.zip?raw=1',
								},
								{
									city: 'Munich',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AADOFaibwttnh6E95bz1OaZEa/CURE_AP01_%20Munich_sourcedata.zip?raw=1',
								},
								{
									city: 'Ostrava',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AADfBck6WEyvSVHMeVh8zgkja/CURE_AP01_%20Ostrava_sourcedata.zip?raw=1',
								},
								{
									city: 'San Sebastian',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AABm17i50lrdtSG6MzkGCZf6a/CURE_AP01_%20San%20Sebastian_sourcedata.zip?raw=1',
								},
								{
									city: 'Sofia',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AAAgzyTAz4cL1RfSiCXfdUMFa/CURE_AP01_%20Sofia_sourcedata.zip?raw=1',
								},
								{
									city: 'Vitoria-Gasteiz',
									link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AACGi0JG1a7WOTCDN7rhDMnfa/CURE_AP01_%20Vitoria-Gasteiz_sourcedata.zip?raw=1',
								},
							]}
							otherApps={[
								{
									key: 'ap02-urban-heat-island',
									label: 'Surface Urban Heat Island Assessment',
								},
								{
									key: 'ap03-urban-heat-emissions',
									label: 'Urban Heat Emissions Monitoring',
								},
								{
									key: 'ap08-urban-thermal-comfort',
									label: 'Urban Thermal Comfort',
								},
								{
									key: 'ap09-urban-heat-storage',
									label: 'Urban Heat Storage Monitoring',
								},
								{
									key: 'ap10-nature-based-solutions',
									label: 'Nature-Based Solutions',
								},
							]}
						/>
					</em>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App1_SurfaceDynamics.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
	updateTourStep: PropTypes.func,
};

export default App1_SurfaceDynamics;
