import {useState, useRef} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {IconTool, useOnClickOutside} from '@gisatcz/visat-components';

import './style.scss';

const InfoWindow = ({content}) => {
	const infoWindow = useRef();
	const [windowIsOpen, setWindowIsOpen] = useState(false);

	const classes = (primaryClass, secondClass) =>
		classNames(primaryClass, {}, secondClass);

	const handleClickOutside = () => {
		if (windowIsOpen) {
			setWindowIsOpen(false);
		}
	};

	useOnClickOutside(infoWindow, handleClickOutside);

	return (
		<div className="cure-InfoWindow" ref={infoWindow}>
			<IconTool
				icon="ri-info"
				tooltip={{
					text: 'Information',
					position: 'top',
				}}
				onClick={() => setWindowIsOpen(!windowIsOpen)}
				className={
					windowIsOpen
						? classes('cure-InfoWindow-Btn', 'is-active')
						: 'cure-InfoWindow-Btn'
				}
			/>
			<div
				className={'cure-InfoWindow-window'}
				style={
					windowIsOpen
						? {height: '30rem', overflow: 'auto'}
						: {height: '0', overflow: 'hidden'}
				}
			>
				<div className="cure-InfoWindow-windowConnect" />
				<div className="cure-InfoWindow-windowContent">{content}</div>
			</div>
		</div>
	);
};

InfoWindow.propTypes = {
	content: PropTypes.element,
};

export default InfoWindow;
