import PropTypes from 'prop-types';

import './style.scss';

// Available props
// info,
// event,
// layerKey,
// mapKey,
// const GetFeatureInfoTooltipContent = ({response, mapKey, layerKey}) => {
const GetFeatureInfoTooltipContent = ({response}) => {
	const value = Math.round(Number.parseFloat(response.value_list) * 10) / 10;
	return (
		<div className="ptr-FeatureTooltip-body">
			<div>
				<b>CO2 emissions:</b> <b>{`${value}g CO2 m2 / d`}</b>
			</div>
		</div>
	);
};

GetFeatureInfoTooltipContent.propTypes = {
	response: PropTypes.object,
	mapKey: PropTypes.string,
	layerKey: PropTypes.string,
};

export default GetFeatureInfoTooltipContent;
