import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import Action from '../../../state/Action';
import Presentation from './presentation';
import {utils} from '@gisatcz/ptr-utils';

const order = null;
const start = 1;
const length = 100;

const mapStateToProps = (state, ownProps) => {
	const component = Select.components.getByComponentKey(
		state,
		ownProps.componentKey
	);

	const filter = component.filter;

	const layerTemplates = Select.layerTemplates.getIndexed(
		state,
		null,
		filter,
		order,
		start,
		length
	);

	const options =
		layerTemplates?.map(t => ({
			value: t.key,
			label: t.data.nameDisplay,
		})) || [];
	const activeKey = component?.active?.[ownProps.activeMapKey];
	const activeLt = layerTemplates?.find(lt => lt.key === activeKey);

	return {
		filter,
		activeKey: component?.active?.[ownProps.activeMapKey],
		options,
		value: {
			value: component?.active?.[ownProps.activeMapKey],
			label: activeLt?.data.nameDisplay,
		},
	};
};

const mapDispatchToProps = () => {
	const componentId = `LayerTemplareSelect_${utils.uuid()}`;
	return (dispatch, ownProps) => ({
		load: filter => {
			dispatch(
				Action.layerTemplates.useIndexed(
					null,
					filter,
					order,
					start,
					length,
					componentId
				)
			);
		},
		onChange: val => {
			if (typeof ownProps.onActiveFilterChange === 'function') {
				ownProps.onActiveFilterChange(val.value);
			}
		},
	});
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
