import Header from '../../Header/presentation';
import IndexNav from './IndexNav';

const IndexHeader = () => {
	return (
		<Header>
			<IndexNav />
		</Header>
	);
};

export default IndexHeader;
