import PropTypes from 'prop-types';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import CenteredOptions from '../../../common/maps/CenteredOptions';
import App1_ShowcaseA_LayerSwitch from './App1_ShowcaseA_LayerSwitch';

import RasterMapTooltip from '../../../common/maps/MapTooltip/RasterMapTooltip';
import GetFeatureInfoTooltipContent from './GetFeatureInfoTooltipContent';
import Showcase from '../../../common/Showcase';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';

const Tooltip = props => (
	<RasterMapTooltip {...props}>
		<GetFeatureInfoTooltipContent />
	</RasterMapTooltip>
);

const App1_ShowcaseA = ({maps, mapSetKey}) => {
	if (maps?.length) {
		return (
			<Showcase>
				<MapSetWrapper
					MapTooltip={Tooltip}
					mapSetKey={mapSetKey}
					config={{
						mapSet: {
							highlightActiveMap: false,
							controls: {
								horizontal: true,
							},
						},
						layers: [
							{
								layerTemplateKey: '2d7370d3-ff63-420d-b389-a5660b29c576',
								info: ['2019-06-14'],
							},
							{
								layerTemplateKey: '3dd9e5ce-1ab1-4aa9-999a-44532fa0c0c3',
								info: ['2019-06-14'],
							},
							{
								layerTemplateKey: '1e20339e-68b5-4961-89af-070e7f2378c6',
								info: ['2019-06-14'],
							},
							{
								layerTemplateKey: '0cbd0272-c852-4416-bd68-a4bee8854d03',
								info: ['2019-06-14'],
							},
						],
					}}
					componentsByLayer={[
						{
							layerTemplateKey: '1e20339e-68b5-4961-89af-070e7f2378c6',
							legend: {
								component: StyleBasedLegend,
								props: {
									applicationStoryKey: 'ap01-surface-dynamics',
									showcaseKey: 'App1_ShowcaseA',
									title: 'Temperature',
									unit: '°C',
								},
							},
						},
						{
							layerTemplateKey: '0cbd0272-c852-4416-bd68-a4bee8854d03',
							legend: {
								component: StyleBasedLegend,
								props: {
									applicationStoryKey: 'ap01-surface-dynamics',
									showcaseKey: 'App1_ShowcaseA',
									title: 'Temperature',
									unit: '°C',
								},
							},
						},
						{
							layerTemplateKey: '2d7370d3-ff63-420d-b389-a5660b29c576',
							legend: {
								component: StyleBasedLegend,
								props: {
									applicationStoryKey: 'ap01-surface-dynamics',
									showcaseKey: 'App1_ShowcaseA',
									title: 'Temperature',
									unit: '°C',
								},
							},
						},
						{
							layerTemplateKey: '3dd9e5ce-1ab1-4aa9-999a-44532fa0c0c3',
							legend: {
								component: StyleBasedLegend,
								props: {
									applicationStoryKey: 'ap01-surface-dynamics',
									showcaseKey: 'App1_ShowcaseA',
									title: 'Temperature',
									unit: '°C',
								},
							},
						},
					]}
				>
					<CenteredOptions>
						<App1_ShowcaseA_LayerSwitch componentKey="ap01-surface-dynamics-showcase-a-layer-switch" />
					</CenteredOptions>
				</MapSetWrapper>
			</Showcase>
		);
	} else {
		return null;
	}
};

App1_ShowcaseA.propTypes = {
	maps: PropTypes.array,
	mapSetKey: PropTypes.string,
	rasterLegend: PropTypes.object,
};

export default App1_ShowcaseA;
