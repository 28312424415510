import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const sidePanelisOpen = Select.components.get(
		state,
		'CityDemonstrations',
		'sidePanelisOpen'
	);
	return {
		cityMetadata: Select.places.getByKey(state, ownProps.cityKey),
		applicationStories:
			Select.cure.applicationStories.getPossibleApplicationStoriesForPlace(
				state,
				ownProps.cityKey
			),
		sidePanelisOpen,
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
