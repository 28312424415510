import PropTypes from 'prop-types';

const SourceLabel = ({tags}) => {
	return (
		<>
			{tags.length
				? tags.map(tag => <p key={tag.key}>{tag?.data?.nameDisplay}</p>)
				: null}
		</>
	);
};

SourceLabel.propTypes = {
	tags: PropTypes.array,
};

export default SourceLabel;
