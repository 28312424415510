import PropTypes from 'prop-types';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import RasterMapTooltip from '../../../common/maps/MapTooltip/RasterMapTooltip';
import GetFeatureInfoTooltipContent from '../../App2_UrbanHeatIslandEffect/App2_ShowcaseA/GetFeatureInfoTooltipContent';
import Showcase from '../../../common/Showcase';
import ShowcaseNoDataInfo from '../../../common/Showcase/ShowcaseNoDataInfo';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';

import './style.scss';

const Tooltip = props => (
	<RasterMapTooltip {...props}>
		<GetFeatureInfoTooltipContent {...props} />
	</RasterMapTooltip>
);

const App2_ShowcaseA = ({mapSetKey, noDataForCurrentPlace}) => {
	return (
		<Showcase>
			{noDataForCurrentPlace ? (
				<ShowcaseNoDataInfo>
					This showcase has no data for selected city.
				</ShowcaseNoDataInfo>
			) : (
				<MapSetWrapper
					MapTooltip={Tooltip}
					mapSetKey={mapSetKey}
					config={{
						mapSet: {
							highlightActiveMap: false,
							controls: {
								horizontal: true,
							},
						},
						layers: [
							{
								layerTemplateKey: '0fcb3828-60fe-49f6-af73-ad03937fb13d', //day lt
								info: ['2019-06-28'],
							},
							{
								layerTemplateKey: '5d6ab306-b540-4c34-b0ff-f2d401f4a2e7', //day lt
								info: ['2019-06-28'],
							},
						],
					}}
					componentsByLayer={[
						{
							layerTemplateKey: '0fcb3828-60fe-49f6-af73-ad03937fb13d',
							legend: {
								component: StyleBasedLegend,
								props: {
									applicationStoryKey: 'ap02-urban-heat-island',
									showcaseKey: 'App2_ShowcaseA',
									title: 'Imperviousness density',
									unit: '%',
								},
							},
						},
						{
							layerTemplateKey: '5d6ab306-b540-4c34-b0ff-f2d401f4a2e7',
							legend: {
								component: StyleBasedLegend,
								props: {
									applicationStoryKey: 'ap02-urban-heat-island',
									showcaseKey: 'App2_ShowcaseA',
									title: 'Temperature',
									unit: '°C',
								},
							},
						},
					]}
				/>
			)}
		</Showcase>
	);
};

App2_ShowcaseA.propTypes = {
	mapSetKey: PropTypes.string,
	noDataForCurrentPlace: PropTypes.bool,
	rasterLegendData: PropTypes.object,
};

export default App2_ShowcaseA;
