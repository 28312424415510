import {useState} from 'react';
import PropTypes from 'prop-types';
import User from './User';
import LoginModal from './LoginModal';
import AppSettings from './AppSettings';
import Help from './Help';

import './style.scss';

const HeaderTools = ({user, logoutUser}) => {
	const [loginModalOpen, setLoginModalOpen] = useState(false);

	return (
		<>
			<div className="cure-HeaderTools">
				<User
					user={user}
					onOpenModelClick={() => setLoginModalOpen(true)}
					onLogoutClick={logoutUser}
				/>
				<AppSettings />
				<Help />
			</div>
			<LoginModal
				isOpen={loginModalOpen}
				onClose={() => setLoginModalOpen(false)}
			/>
		</>
	);
};

HeaderTools.propTypes = {
	onUserClick: PropTypes.func,
	logoutUser: PropTypes.func,
	user: PropTypes.object,
};

export default HeaderTools;
