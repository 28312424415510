import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const styleKey = Select.cure.applicationStories.getStyleKeyForLegendByKey(
		state,
		ownProps.applicationStoryKey,
		ownProps.showcaseKey,
		ownProps.layerKey,
		ownProps.layerTemplateKey,
		ownProps.propKey
	);
	const style = Select.cure.applicationStories.getPreparedStylesForLegendByKey(
		state,
		ownProps.applicationStoryKey,
		ownProps.showcaseKey,
		ownProps.layerKey,
		ownProps.layerTemplateKey,
		ownProps.propKey
	);

	return {
		styleKey,
		style,
	};
};

const mapDispatchToProps = () => {};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
