import PropTypes from 'prop-types';
import {includes as _includes} from 'lodash';
import {Tag, TagsContainer} from '@gisatcz/visat-components';
import './style.scss';

const CatalogFilterCategorySection = ({
	activeTagKeys,
	tags,
	title,
	onTagClick,
}) => {
	let tagsContent;
	let titleContent;

	if (tags?.length) {
		tagsContent = (
			<TagsContainer>
				{tags.map(tag => {
					const active = _includes(activeTagKeys, tag.key);
					return (
						<Tag
							active={active}
							activeIcon={active}
							key={tag.key}
							tagKey={tag.key}
							ghost
							onClick={onTagClick && onTagClick.bind(this, tag.key)}
						>
							{tag.data?.nameDisplay}
						</Tag>
					);
				})}
			</TagsContainer>
		);
	} else {
		tagsContent = (
			<TagsContainer>
				<Tag placeholder />
				<Tag placeholder />
				<Tag placeholder />
			</TagsContainer>
		);
	}

	if (title) {
		titleContent = (
			<div className="cure-CatalogFilterCategorySection-title">{title}</div>
		);
	} else {
		titleContent = (
			<div className="cure-CatalogFilterCategorySection-title is-placeholder" />
		);
	}

	return (
		<div className="cure-CatalogFilterCategorySection">
			{titleContent}
			<div className="cure-CatalogFilterCategorySection-body">
				{tagsContent}
			</div>
		</div>
	);
};

CatalogFilterCategorySection.propTypes = {
	activeTagKeys: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
	onTagClick: PropTypes.func,
	tags: PropTypes.array,
	title: PropTypes.string,
};

export default CatalogFilterCategorySection;
