import {Action} from '@gisatcz/ptr-state';
import Select from '../../../Select';
import {
	getLayerOpacity,
	getLayerOptions,
	getPreviousMapLayerSettings,
} from '../helpers';

function onFilterChange(componentKey, mapKey, componentId) {
	return (dispatch, getState) => {
		const state = getState();
		const component = Select.components.getByComponentKey(state, componentKey);

		const tags = Select.cure.applicationStories.ap04.getBaseTags(
			state,
			componentKey
		);

		const activeTags = tags.map(tagKey => {
			return Select.cure.applicationStories.ap04.getActiveTagKey(
				state,
				componentKey,
				tagKey,
				mapKey
			);
		});

		//remove all layers from map
		const previousLayerSettings = getPreviousMapLayerSettings(state, mapKey);

		dispatch(Action.maps.removeAllMapLayers(mapKey));

		if (tags.length > 0 && activeTags.every(i => i)) {
			//get proper period and case
			const activePeriodTagKeys = Object.values(component.tagKeys)
				.filter(t => t.for === 'period')
				.map(t => t.active[mapKey]);
			const activeCaseTagKeys = Object.values(component.tagKeys)
				.filter(t => t.for === 'case')
				.map(t => t.active[mapKey]);

			const promises = [];

			const periodsParams = [
				{},
				{tagKeys: {in: activePeriodTagKeys}},
				null,
				1,
				100,
			];

			const casesParams = [
				{},
				{tagKeys: {in: activeCaseTagKeys}},
				null,
				1,
				100,
			];

			const casesRequest = dispatch(
				Action.cases.useIndexed(...casesParams, componentId)
			);

			promises.push(casesRequest);

			const periodsRequest = dispatch(
				Action.periods.useIndexed(...periodsParams, componentId)
			);

			promises.push(periodsRequest);

			return Promise.all(promises).then(() => {
				const periods = Select.periods.getIndexed(getState(), ...periodsParams);

				const cases = Select.cases.getIndexed(getState(), ...casesParams);

				const caseByKeys = cases.find(c =>
					c.data.tagKeys.every(t => activeCaseTagKeys.includes(t))
				);
				const periodByKeys = periods.find(p =>
					p.data.tagKeys.every(t => activePeriodTagKeys.includes(t))
				);

				const caseKey = caseByKeys.key;
				const periodKey = periodByKeys.key;

				const storyConfiguration =
					Select.cure.applicationStories.getConfigForActiveApplicationStory(
						state
					);

				dispatch(
					Action.maps.addMapLayerToIndex(
						mapKey,
						{
							key: 'ap04-urban_co2_emissions-a',
							layerKey: 'ap04-urban_co2_emissions-a',
							filterByActive: {
								place: true,
							},
							layerTemplateKey: storyConfiguration.layerTemplateKey,
							metadataModifiers: {
								caseKey,
								periodKey,
							},
							options: getLayerOptions(previousLayerSettings),
							opacity: getLayerOpacity(previousLayerSettings),
						},
						1
					)
				);
			});
		}
	};
}

function setActiveTag(componentKey, parentTag, activeTag, mapKey) {
	return (dispatch, getState) => {
		const state = getState();
		const componentState = Select.components.getByComponentKey(
			state,
			componentKey
		);
		const updatedComponentState = {
			...componentState,
			tagKeys: {
				...componentState.tagKeys,
				[parentTag]: {
					...componentState.tagKeys[parentTag],
					active: {
						...componentState.tagKeys[parentTag].active,
						[mapKey]: activeTag,
					},
				},
			},
		};
		dispatch(Action.components.update(componentKey, updatedComponentState));
	};
}

export default {
	setActiveTag,
	onFilterChange,
};
