import {connect, Select as CommonSelect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Presentation from '../../../../common/maps/MapLegends/StyleBasedLegend/presentation';

const componentId = 'StyleBasedLegend';

const mapStateToProps = (state, ownProps) => {
	const style = CommonSelect.styles.getByKey(state, ownProps.styleKey);
	const definition = style?.data?.definition?.rules?.[0]?.styles;
	const baseStyle = definition?.[0];
	const attributeStyle = definition?.[1];

	// get prepared style
	return {
		baseStyle: baseStyle,
		attributeStyle: attributeStyle,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAttributeChange: attributeKey => {
			dispatch(Action.attributes.useKeys([attributeKey], componentId));
		},
		onStyleChange: styleKey => {
			dispatch(Action.styles.useKeys([styleKey], componentId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
