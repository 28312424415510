import {cloneElement} from 'react';
import PropTypes from 'prop-types';
import GetFeatureInfoTooltip from '../../GetFeatureInfoTooltip';
const RasterMapTooltip = ({children, event, layerKey, mapKey}) => {
	if (event.coordinate) {
		const viewport = event?.layer?.context?.viewport;
		const bbox = viewport && viewport.getBounds();

		const childWithProps = cloneElement(children, {
			event,
			layerKey,
			mapKey,
		});
		return (
			<GetFeatureInfoTooltip
				timeout={200}
				coordinates={event.coordinate}
				bbox={bbox}
				layers={[event?.layer?.props?.options?.params?.layers]}
				url={event?.layer?.props?.options?.url}
				width={event.viewport.width}
				height={event.viewport.height}
				x={event.x}
				y={event.y}
				crs={'EPSG:4326'}
			>
				{childWithProps}
			</GetFeatureInfoTooltip>
		);
	}
};

RasterMapTooltip.propTypes = {
	layerKey: PropTypes.string,
	feature: PropTypes.object,
	info: PropTypes.object,
	children: PropTypes.node,
	event: PropTypes.object,
	mapKey: PropTypes.string,
};

export default RasterMapTooltip;
