import PropTypes from 'prop-types';

const RasterTooltipContent = ({response}) => {
	if (response) {
		const subsidence = Math.round(Number.parseFloat(response?.value) * 10) / 10;
		return (
			<div className="ptr-FeatureTooltip-body">
				<div>
					<b>{`Annual displacement rates: ${subsidence} mm/year`}</b>
				</div>
			</div>
		);
	} else {
		return null;
	}
};

RasterTooltipContent.propTypes = {
	response: PropTypes.object,
	mapKey: PropTypes.string,
	layerKey: PropTypes.string,
};

export default RasterTooltipContent;
