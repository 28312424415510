import PropTypes from 'prop-types';
import './style.scss';

const ShowcaseNoDataInfo = ({children, onClick}) => {
	return (
		<div className="cure-NoDataInfo" onClick={onClick}>
			{children}
		</div>
	);
};

ShowcaseNoDataInfo.propTypes = {
	children: PropTypes.node,
	onClick: PropTypes.func,
};

export default ShowcaseNoDataInfo;
