// Default config values

export const defaultMapSetConfig = {
	highlightActiveMap: true,
	controls: {
		horizontal: false,
	},
};

export const defaultLayerConfig = {
	label: {
		tools: {
			opacity: true,
			legend: true,
			tooltip: true,
		},
	},
};

export const defaultMapTooltipProps = {
	width: 200,
};
