import {cloneElement, useEffect} from 'react';
import {map as _map, isNumber as _isNumber} from 'lodash';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

// TODO get precision from attribute metadata
const NUMBER_PRECISION = 2;

const FeatureTooltip = ({
	attributesMetadata,
	attributes,
	featureKey,
	nameAttributeKey,
	onMount,
	onUnmount,
	children,
	...props
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	if (children) {
		return cloneElement(children, {
			attributesMetadata,
			attributes,
			featureKey,
			nameAttributeKey,
			...props,
		});
	} else if (attributes && attributesMetadata) {
		const properties = {...attributes};
		const name = properties[nameAttributeKey];
		delete properties[nameAttributeKey];
		const classes = classnames('ptr-FeatureTooltip-item', {
			'is-emphasized': !name,
		});

		return (
			<div className="ptr-FeatureTooltip-content">
				{name ? (
					<div className="ptr-FeatureTooltip-header">{`${name} (${featureKey})`}</div>
				) : null}
				<div className="ptr-FeatureTooltip-body">
					{_map(properties, (value, key) => {
						const attributeMetadata = attributesMetadata[key];
						const unit = attributeMetadata?.data?.unit;
						const name = attributeMetadata?.data?.nameDisplay;

						// TODO temporary solution for shp no-data values
						const displayValue = _isNumber(value)
							? value === -9999
								? 'No data'
								: value === 0
								? value
								: value.toFixed(NUMBER_PRECISION)
							: value;
						if (attributeMetadata) {
							return (
								<div key={key} className={classes}>{`${name}: ${displayValue} ${
									displayValue === 'No data' ? '' : unit || ''
								}`}</div>
							);
						} else {
							return null;
						}
					})}
				</div>
			</div>
		);
	} else {
		return null;
	}
};

FeatureTooltip.propTypes = {
	attributesMetadata: PropTypes.object,
	attributes: PropTypes.object,
	children: PropTypes.node,
	featureKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	nameAttributeKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default FeatureTooltip;
