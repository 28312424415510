const Welcome = () => (
	<div>
		<p>
			Welcome to the CURE Portal - a web demonstration of the application
			potential and the service capacity of the CURE application solutions. This
			help guides you through the main parts and functionalities of the portal.
		</p>
		<p>
			<b>CURE application potential</b> is presented by means of interlinked
			interactive storylines focused on each CURE application.
		</p>
		<p>
			<b>CURE service capacity</b> is demonstrated by a web-based service
			production job scheduler for communication with production system via
			services APIs.
		</p>
	</div>
);

export default Welcome;
