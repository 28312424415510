import {connect} from '@gisatcz/ptr-state';
import Action from '../../../../../state/Action';
import Presentation from './presentation';
import {scopesFilter} from '../../../../../constants/app';
import {utils} from '@gisatcz/ptr-utils';
import Select from '../../../../../state/Select';

const mapStateToProps = state => {
	const openAppSettings = Select.components.get(
		state,
		'ordered-services',
		'openAppSettings'
	);
	return {openAppSettings};
};

const mapDispatchToProps = dispatch => {
	const componentId = `AppServices_${utils.uuid()}`;
	return {
		setOpenAppSettings: (applicationStoryKey, status, openAppSettings) => {
			return dispatch(
				Action.components.set('ordered-services', 'openAppSettings', {
					...openAppSettings,
					[applicationStoryKey]: status,
				})
			);
		},
		onMount: () => {
			dispatch(
				Action.scopes.useIndexed(
					scopesFilter.filterByActive,
					scopesFilter.filter,
					scopesFilter.order,
					scopesFilter.start,
					scopesFilter.length,
					componentId
				)
			);
			return dispatch(
				Action.cure.applicationStories.usePlacesForActiveStory('services')
			);
		},
		onUnmount: () => {
			dispatch(Action.scopes.clearIndex(componentId));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
