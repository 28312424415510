import PropTypes from 'prop-types';
import ap01 from '../../assets/img/apps/ap01.webp';
import ap02 from '../../assets/img/apps/ap02.webp';
import ap03 from '../../assets/img/apps/ap03.webp';
import ap04 from '../../assets/img/apps/ap04.webp';
import ap05 from '../../assets/img/apps/ap05.webp';
import ap06 from '../../assets/img/apps/ap06.webp';
import ap07 from '../../assets/img/apps/ap07.webp';
import ap08 from '../../assets/img/apps/ap08.webp';
import ap09 from '../../assets/img/apps/ap09.webp';
import ap10 from '../../assets/img/apps/ap10.webp';
import ap11 from '../../assets/img/apps/ap11.webp';

const AppThumbnail = ({imageKey, imageAlt}) => {
	switch (imageKey) {
		case 'ap01':
			return <img src={ap01} alt={imageAlt} />;
		case 'ap02':
			return <img src={ap02} alt={imageAlt} />;
		case 'ap03':
			return <img src={ap03} alt={imageAlt} />;
		case 'ap04':
			return <img src={ap04} alt={imageAlt} />;
		case 'ap05':
			return <img src={ap05} alt={imageAlt} />;
		case 'ap06':
			return <img src={ap06} alt={imageAlt} />;
		case 'ap07':
			return <img src={ap07} alt={imageAlt} />;
		case 'ap08':
			return <img src={ap08} alt={imageAlt} />;
		case 'ap09':
			return <img src={ap09} alt={imageAlt} />;
		case 'ap10':
			return <img src={ap10} alt={imageAlt} />;
		case 'ap11':
			return <img src={ap11} alt={imageAlt} />;
		default:
			return null;
	}
};

AppThumbnail.propTypes = {
	imageAlt: PropTypes.string,
	imageKey: PropTypes.string,
};

export default AppThumbnail;
