import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';
import {getRouter} from '../../../router';
import Link from '../../common/Link';
import OrderServiceTool from '../../common/OrderServiceTool';
import App5_ShowcaseA from './App5_ShowcaseA';
import App5_ShowcaseA_BarChart from './App5_ShowcaseA/BarChart/presentation';
import App5_ShowcaseB from './App5_ShowcaseB';
import App5_ShowcaseB_BarChart from './App5_ShowcaseB/BarChart/presentation';
import StoryFooterSection from '../../common/StoryFooterSection';

const App5_UrbanFloodRisk = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						While water is a resource before being a threat, risks related to
						urban floods are critical for the security and resilience of the
						cities as especially as these are gradually amplified by climate
						change.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Floods are the natural hazard with the highest frequency and the
						widest geographical distribution worldwide. Various climatic and
						non-climatic processes can result in different types of floods e.g.
						river floods, coastal flood and flash floods, often defining their
						intensity, speed and severity.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Floods have several direct and indirect impacts on cities and
						citizens. Beside the direct threat to human life, they destroy
						houses, business sites and infrastructure, contributing to loss of
						jobs and other sources of income. Floods can also result in lack of
						quality of public service provisioning as they can cut off people
						and businesses from vital services such as energy, transport and
						clean water.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The overall magnitude of damaging flood events in the last decade
						clearly shows the need for comprehensive and innovative flood risk
						management. It is absolutely necessary to implement controlling
						mechanisms for the protection of population, land use areas,
						infrastructure and the natural space in endangered areas. Therefore
						flood monitoring tools and appropriate simulation procedures are
						indispensable. They have to provide necessary information for flood
						risk evaluation, successful risk management and communication.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline>Flood hazard in the city area</StorySubheadline>
					<StoryParagraph className={''}>
						The CURE Urban Flood application supports cities with enriched
						information about flood hazard to urban assets and related dangers.
						Height above the Nearest Drainage (HAND) method is used to hastily
						identify floodplains thereby enhancing the efficiency in a flood
						risk management project. This application can provide city
						authorities with both synoptic and detailed knowledge about
						localization, extent and magnitude of exposure to flood hazard due
						to flash or other types of flood. This application is based on
						combination of information about flood risk depending on local
						terrain and hydrological characteristics and information about
						spatial pattern, internal structure and temporal evolution of urban
						areas.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The solution adopts a “scenario modelling” approach – which means
						that scenarios representing different intensities of flood events
						can be modelled and compared as well as (potential) damage on urban
						assets and people in the cities caused by these events. This is done
						based on input data (e.g. DEM, river network), reference information
						about flood events in the past and simulations of estimated
						intensities of expected floods in the future also in relation with
						different potential climate change scenarios.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Based purely on Copernicus open data (EU-DEM, river network),
						different flooding scenarios can be easily (automatically) modelled
						for any area of interest, representing different flooding
						intensities, also with user-specified parameters (flood zone extent
						etc.). Integration of local datasets (e,g, DEM, river network) if
						available increases precision of the modelling, especially if local
						specificities need to be taken into consideration. See maps showing
						flood hazard zones (inundation depth zones) resulting from CURE
						Urban Flood service (left).
					</StoryParagraph>
					<App5_ShowcaseA_BarChart />
					<StorySubheadline className={''}>
						Flood hazard to urban assets (functional urban blocks)
					</StorySubheadline>
					<StoryParagraph className={''}>
						Integrating the information about flood hazard zones with Copernicus
						Urban Atlas blocks, flood hazard to urban assets can be easily (and
						automatically) estimated for any city. For each urban block, the
						level of flood risk is estimated and combined with information about
						its typology (residential, commercial, industrial, transportation,
						urban green, etc.). See maps showing flood hazard level resulting
						from CURE Urban Flood service assigned to Urban Atlas blocks
						(right).
					</StoryParagraph>
					<StoryParagraph className={''}>
						Such analysis can be applied on any user-delineation, which may
						represent local objects/assets of interests – e.g. potential
						development zones. This can support both effective urban planning
						and early warning systems in the cities.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Flood hazard to urban assets (buildings´ footprints)
					</StorySubheadline>
					<StoryParagraph className={''}>
						Similar analysis can be provided also for buildings, using the
						buildings footprints from city database (or other sources, e.g. VHR
						satellite imagery analysis) and thus potential flood hazard for each
						particular building in the city can be estimated. Moreover,
						additional information about construction materials, age, height,
						current condition, or other characteristics of buildings can be
						included into this analysis. Based on this, cost of damage in case
						of potential flood event can be estimated.
					</StoryParagraph>
					<App5_ShowcaseB_BarChart />
					<StorySubheadline className={''}>
						Integrated analysis - flood and subsidence hazard
					</StorySubheadline>
					<StoryParagraph className={''}>
						Information about flood hazard can be combined with information
						about subsidence risk coming from{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap06-urban-subsidence'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							CURE Urban Subsidence, Movements and Deformation Risk
						</Link>
						. This will support integrated approach to risk assessment, taking
						into consideration different types of risks while assessing the
						overall sustainability of the city. Moreover, mutual correlations
						can be identified using such analysis, e.g. increased subsidence
						hazards in flooding zones of the city or potential extension of
						flood zones caused by ongoing subsidence processes etc.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						The CURE Urban Flood service provides the flood inundation mapping
						tool based purely on Copernicus open data streams, to identify
						quickly flood hazard zones (inundation depth zones). As such it
						might contribute to streamlining and efficiency of a flood risk
						management and emergency response activities. The solutions can be
						driven by purely engineering approaches (dikes, dams or other
						anti-flood engineering construction), it can be focused on use of
						nature-based solution. Beside city resilience capacity against flood
						hazard, (as for example in{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap10-nature-based-solutions'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							the CURE NBS application
						</Link>{' '}
						dealing with green roofs to reduce water run-off) such NB solution
						provides also other co-benefits link to overall city liveability
						contributing to green areas availability, reduction of heat,
						improving clear air and so on at the same time. The CURE Urban Flood
						service can be further enhanced by integration of local datasets,
						when applicated in urban areas with complicated built and natural
						environment.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								Height Above the Nearest Drainage (HAND){' '}
								<a href="https://www.researchgate.net/publication/232042361_Height_Above_the_Nearest_Drainage_-_a_hydrologically_relevant_new_terrain_model">
									methodology paper
								</a>
								.
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						The service is based on{' '}
						<a href="https://land.copernicus.eu/">CLMS</a> and{' '}
						<a href="https://climate.copernicus.eu/">C3S</a> and{' '}
						<a href="https://emergency.copernicus.eu/">EMS</a> Copernicus
						services, with the modelling method using CLMS EU-DEM and EU – Hydro
						River Network database as main data inputs. Methodology is based on
						geomorphic analysis (the{' '}
						<a href="https://www.researchgate.net/publication/232042361_Height_Above_the_Nearest_Drainage_-_a_hydrologically_relevant_new_terrain_model">
							HAND model
						</a>{' '}
						– high-above-nearest-drainage approach) using DEM/DSM for estimation
						of flood susceptibility and modelling inundation extent.
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<StoryParagraph className={''}>
						The service is provided by GISAT s.r.o.{' '}
						<a href="htttp://www.gisat.cz">htttp://www.gisat.cz</a>. For more
						information please contact <a>gisat@gisat.cz</a>.
					</StoryParagraph>
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro05.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Monitor and analyse flood risk in your city
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App5_ShowcaseA mapSetKey="ap05-urban-flood-risk-showcase-a" />
				<App5_ShowcaseB mapSetKey="ap05-urban-flood-risk-showcase-b" />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Heraklion',
								link: 'https://www.dropbox.com/s/0iwxk0wmzcsddez/CURE_AP05_Heraklion_sourcedata.zip?dl=0',
							},
							{
								city: 'Ostrava',
								link: 'https://www.dropbox.com/s/v8dv13v5uhvsrcl/CURE_AP05_Ostrava_sourcedata.zip?dl=0',
							},
						]}
						otherApps={[
							{
								key: 'ap06-urban-subsidence',
								label: 'Urban Subsidence, Movements and Deformation Risk',
							},
							{
								key: 'ap10-nature-based-solutions',
								label: 'Nature-Based Solutions',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App5_UrbanFloodRisk.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App5_UrbanFloodRisk;
