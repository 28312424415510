import React, {useEffect} from 'react';
import {connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../state/Action';
import Select from '../../state/Select';
import PropTypes from 'prop-types';
import {useMountUnmount} from '../../utils/hooks';

const AppStory = ({
	onMount,
	onUnmount,
	onActiveStoryChange,
	tags,
	activeApplicationStory,
	activeApplicationStoryKey,
	activeStep,
	onStepChange,
	component,
	updateTourStep,
}) => {
	useMountUnmount(onMount, onUnmount, [activeApplicationStory.data.tagKeys]);
	useEffect(() => {
		onActiveStoryChange(activeApplicationStory.data.tagKeys);
	}, [activeApplicationStory]);
	return React.createElement(component, {
		onStepChange,
		activeStep,
		activeApplicationStory,
		activeApplicationStoryKey,
		tags,
		updateTourStep: updateTourStep,
	});
};

AppStory.propTypes = {
	component: PropTypes.func,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	activeStep: PropTypes.number,
	onActiveStoryChange: PropTypes.func,
	onStepChange: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
	updateTourStep: PropTypes.func,
};

const mapStateToProps = state => {
	const activeApplicationStoryKey = Select.router.getStory(state);
	const activeApplicationStory =
		Select.cure.applicationStories.getActiveScopeByApplicationStoryKey(
			state,
			activeApplicationStoryKey
		);

	return {
		activeApplicationStory,
		activeApplicationStoryKey,
		tags:
			Select.tags.getByKeys(state, activeApplicationStory.data.tagKeys) || [],
	};
};

const mapDispatchToProps = () => {
	const componentId = `Story_${utils.uuid()}`;
	return dispatch => {
		return {
			onActiveStoryChange: tagKeys => {
				dispatch(Action.tags.useKeys(tagKeys, componentId));
				dispatch(Action.cure.applicationStories.setActivePlaceForActiveStory());
			},
			onStepChange: step => {
				dispatch(
					Action.router.updateAppUrl(undefined, {
						step,
					})
				);
			},
			onMount: tagKeys => {
				dispatch(Action.tags.useKeys(tagKeys, componentId));
				dispatch(Action.cure.applicationStories.setActivePlaceForActiveStory());
			},
			onUnmount: () => {
				dispatch(Action.tags.useKeysClear(componentId));
			},
			updateTourStep: tourStepRef => {
				dispatch(Action.components.set('currentTourStep', 'ref', tourStepRef));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppStory);
