import PropTypes from 'prop-types';
import './style.scss';
import {Button} from '@gisatcz/ptr-atoms';
import {useEffect} from 'react';
import classNames from 'classnames';

const PanelControlBtn = ({onPanelControl, sidePanelisOpen}) => {
	useEffect(() => {
		onPanelControl(false);
	}, []);

	return (
		<Button
			className={classNames('cure-CityInfoPanelBtn', {
				'is-closed': !sidePanelisOpen,
			})}
			icon={'chevron-right'}
			onClick={() => onPanelControl(!sidePanelisOpen)}
		/>
	);
};

PanelControlBtn.propTypes = {
	onPanelControl: PropTypes.func,
	sidePanelisOpen: PropTypes.bool,
};

export default PanelControlBtn;
