import {
	cloneDeep as _cloneDeep,
	filter as _filter,
	get as _get,
	merge as _merge,
} from 'lodash';
import {
	defaultMapSetConfig,
	defaultLayerConfig,
} from './MapSetWrapper/constants';

/**
 * Get config for layer by layerKey of layerTemplateKey
 * @param configs {Array}
 * @param layerState {Object} Panther's layerState
 * @return {null|Object}
 */
function getConfigByLayerState(configs, layerState) {
	if (configs?.length && layerState) {
		const {key: layerKey, layerTemplateKey} = layerState;
		return _filter(configs, component => {
			return (
				component.layerKey === layerKey ||
				component.layerTemplateKey === layerTemplateKey
			);
		});
	} else {
		return null;
	}
}

/**
 * Merge given config with the default
 * @param config {Object} config passed as prop to the MapSetWrapper
 * @param defaultConfig {Object}
 * @param path {string} path to the value
 * @return {string|number|boolean|Object} final config value
 */
function getMergedConfig(config, defaultConfig, path) {
	const pathParts = path.split('.');
	if (config && pathParts.length > 1) {
		const property = pathParts[pathParts.length - 1];
		pathParts.splice(-1);
		const newPath = pathParts.join('.');
		return Object.hasOwn(_get(config, newPath), property)
			? _get(config, path)
			: _get(defaultConfig, path);
	} else if (config) {
		return Object.hasOwn(config, path)
			? _get(config, path)
			: _get(defaultConfig, path);
	} else {
		return _get(defaultConfig, path);
	}
}

/**
 * Get config for map set
 * @param config {Object} config passed as prop to the MapSetWrapper
 * @param path {string} path to the value
 * @return {string|number|boolean|Object} final config value
 */
function getMapSetConfig(config, path) {
	return getMergedConfig(config, defaultMapSetConfig, path);
}

/**
 * Get config for layer
 * @param config {Object} config passed as prop to the MapSetWrapper
 * @return {string|number|boolean|Object} final config value
 */
function getLayerConfig(config) {
	return _merge(_cloneDeep(defaultLayerConfig), config);
}

export default {
	getConfigByLayerState,
	getLayerConfig,
	getMapSetConfig,
};
