import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';
import Select from '../../../Select';

const getMapSetKeyFromConfiguration = createSelector(
	[
		CommonSelect.places.getActiveKey,
		state =>
			Select.app.getConfiguration(
				state,
				'configByApplicationStoryKey.ap01-surface-dynamics.showcases.App1_ShowcaseC.data.mapSetByPlaceKey'
			),
	],
	(activePlaceKey, config) => {
		if (activePlaceKey && config) {
			return config[activePlaceKey];
		} else {
			return null;
		}
	}
);

export default {
	getMapSetKeyFromConfiguration,
};
