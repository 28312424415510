import PropTypes from 'prop-types';

const UrbanAtlasLegendItem = ({children}) => (
	<div className="cure-UrbanAtlasLegend-item">{children}</div>
);

UrbanAtlasLegendItem.propTypes = {
	children: PropTypes.node,
};

const Color = ({code}) => (
	<div className="cure-UrbanAtlasLegend-color" style={{background: code}} />
);

Color.propTypes = {
	code: PropTypes.string,
};

const Name = ({children}) => (
	<span className="cure-UrbanAtlasLegend-name" title={children}>
		{children}
	</span>
);

Name.propTypes = {
	children: PropTypes.node,
};

const UrbanAtlasLegend = () => (
	<div className="cure-UrbanAtlasLegend">
		<UrbanAtlasLegendItem>
			<Color code="rgb(128,0,0)" />
			<Name>11100: Continuous Urban fabric (S.L. &#62; 80%)</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(191,0,0)" />
			<Name>11210: Discontinuous Dense Urban Fabric (S.L.: 50% - 80%)</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(255,64,64)" />
			<Name>
				11220: Discontinuous Medium Density Urban Fabric (S.L.: 30% - 50%)
			</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(255,128,128)" />
			<Name>
				11230: Discontinuous Low Density Urban Fabric (S.L.: 10% - 30%)
			</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(255,191,191)" />
			<Name>
				11240: Discontinuous very low density urban fabric (S.L. &#60; 10%)
			</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(204,102,102)" />
			<Name>11300: Isolated Structures</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(204,77,242)" />
			<Name>
				12100: Industrial, commercial, public, military and private units
			</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(149,149,149)" />
			<Name>12210: Fast transit roads and associated land</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(179,179,179)" />
			<Name>12220: Other roads and associated land</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(89,89,89)" />
			<Name>12230: Railways and associated land</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(230,204,204)" />
			<Name>12300: Port areas</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(230,204,230)" />
			<Name>12400: Airports</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(115,77,55)" />
			<Name>13100: Mineral extraction and dump sites</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(185,165,110)" />
			<Name>13300: Construction sites</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(135,69,69)" />
			<Name>13400: Land without current use</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(140,220,0)" />
			<Name>14100: Green urban areas</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(175,210,165)" />
			<Name>14200: Sports and leisure facilities</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(255,255,168)" />
			<Name>21000: Arable land (annual crops)</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(242,166,77)" />
			<Name>22000: Permanent crops</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(230,230,77)" />
			<Name>23000: Pastures</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(255,230,77)" />
			<Name>24000: Complex and mixed cultivation patterns</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(242,204,128)" />
			<Name>25000: Orchads</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(0,140,0)" />
			<Name>31000: Forests</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(204,242,77)" />
			<Name>32000: Herbaceous vegetation associations</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(204,255,204)" />
			<Name>33000: Open spaces with little or no vegetations</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(166,166,255)" />
			<Name>40000: Wetlands</Name>
		</UrbanAtlasLegendItem>
		<UrbanAtlasLegendItem>
			<Color code="rgb(128,242,230)" />
			<Name>50000: Water</Name>
		</UrbanAtlasLegendItem>
	</div>
);

export default UrbanAtlasLegend;
