import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Icon} from '@gisatcz/ptr-atoms';
import {getRouter} from '../../../../../../../../router';
import Link from '../../../../../../../common/Link';
import OrderServiceTool from '../../../../../../../common/OrderServiceTool';

const AppPreviewTools = ({applicationStoryKey, className}) => {
	const classes = classnames('cure-AppPreviewTools', {}, className);

	return (
		<div className={classes}>
			{applicationStoryKey ? (
				<Link
					tabIndex={0}
					classes="ptr-button primary"
					name={`applicationStoryScreen`}
					updateParams={{key: applicationStoryKey}}
					router={getRouter()}
					paramsFilter={['tags', 'step']}
					recoverParamsFilter={['darkMode']}
				>
					<div className="ptr-button-caption">Explore</div>
					<Icon icon="ri-forward" />
				</Link>
			) : null}
			{applicationStoryKey ? (
				<Link
					tabIndex={0}
					classes="ptr-button download"
					name={`applicationStoryScreen`}
					updateParams={{key: applicationStoryKey, step: -1}}
					router={getRouter()}
					paramsFilter={['tags']}
					recoverParamsFilter={['darkMode']}
				>
					<div className="ptr-button-caption">Download data</div>
					<Icon icon="ri-download" />
				</Link>
			) : null}
			<OrderServiceTool applicationStoryKey={applicationStoryKey} />
		</div>
	);
};

AppPreviewTools.propTypes = {
	applicationStoryKey: PropTypes.string,
	className: PropTypes.string,
};

export default AppPreviewTools;
