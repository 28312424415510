import PropTypes from 'prop-types';

const DateLabel = ({date}) => {
	return <>{date}</>;
};

DateLabel.propTypes = {
	date: PropTypes.string,
};

export default DateLabel;
