import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';

const mapStateToProps = state => {
	return {
		services: Select.components.get(state, 'ordered-services', 'services'),
		orderStatus: Select.components.get(
			state,
			'ordered-services',
			'order-status'
		),
		user: Select.users.getActive(state),
		servicesModalIsOpen: Select.components.get(
			state,
			'ordered-services',
			'servicesModalIsOpen'
		),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		createUserServices: () => {
			return dispatch(Action.cure.createUserServices());
		},
		setOrderStatus: status => {
			return dispatch(
				Action.components.set('ordered-services', 'order-status', status)
			);
		},
		setServicesModalIsOpen: isOpen => {
			return dispatch(
				Action.components.set('ordered-services', 'servicesModalIsOpen', isOpen)
			);
		},
		closeAppSettings: () => {
			return dispatch(
				Action.components.set('ordered-services', 'openAppSettings')
			);
		},
		onMount: () => {
			return dispatch(Action.cure.createUserServices());
		},
		onUnmount: () => {},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
