import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const activePlace = Select.places.getActive(state);
	return {
		activePlace,
		activeKeys: Select.components.getByComponentKey(
			state,
			ownProps.componentKey
		),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
