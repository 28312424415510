import {connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../../../state/Action';
import Select from '../../../../state/Select';
import {placesFilter, scopesFilter} from '../../../../constants/app';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		places: Select.places.getIndexed(
			state,
			placesFilter.filterByActive,
			placesFilter.filter,
			placesFilter.order,
			placesFilter.start,
			placesFilter.length
		),
	};
};

const mapDispatchToPropsFactory = () => {
	const componentId = `CityDemonstrations_${utils.uuid()}`;
	return dispatch => {
		return {
			onMount: () => {
				dispatch(
					Action.places.useIndexed(
						placesFilter.filterByActive,
						placesFilter.filter,
						placesFilter.order,
						placesFilter.start,
						placesFilter.length,
						componentId
					)
				);
				dispatch(
					Action.scopes.useIndexed(
						scopesFilter.filterByActive,
						scopesFilter.filter,
						scopesFilter.order,
						scopesFilter.start,
						scopesFilter.length,
						componentId
					)
				);
			},
			onUnmount: () => {
				dispatch(Action.places.useIndexedClear(componentId));
				dispatch(Action.scopes.useIndexedClear(componentId));
			},
			onPanelControl: value => {
				dispatch(
					Action.components.set('CityDemonstrations', 'sidePanelisOpen', value)
				);
			},
		};
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(Presentation);
