import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Presentation from './presentation';

const showcaseKey = 'App2_ShowcaseA';

const mapStateToProps = state => {
	return {
		rasterLegendData: Select.cure.applicationStories.getRasterLegend(
			state,
			showcaseKey
		),
		noDataForCurrentPlace:
			Select.cure.applicationStories.isDataForCurrentPlaceAvailable(
				state,
				'ap02-urban-heat-island',
				showcaseKey
			),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
