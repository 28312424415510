import {ResponsiveLine} from '@nivo/line';
import PropTypes from 'prop-types';

const theme = {
	axis: {
		domain: {
			line: {
				stroke: 'var(--base30)',
			},
		},
		ticks: {
			line: {
				stroke: 'var(--base20)',
			},
			text: {
				fill: 'var(--base60)',
			},
		},
		legend: {
			text: {
				fontSize: 12,
				fill: 'var(--base60)',
			},
		},
	},
	grid: {
		line: {
			stroke: 'var(--base20)',
		},
	},
	crosshair: {
		line: {
			stroke: 'var(--base60)',
		},
	},
	legends: {
		text: {
			fill: 'var(--base60)',
		},
	},
};

const LineChart = ({data, tooltip, colors}) => {
	return (
		<ResponsiveLine
			data={data}
			theme={theme}
			colors={colors || {scheme: 'category10'}}
			margin={{top: 10, right: 180, bottom: 40, left: 80}}
			xScale={{
				type: 'time',
				format: '%Y-%m',
				min: '2019-01',
				max: '2020-01',
			}}
			xFormat="time:%Y-%m"
			yScale={{
				type: 'linear',
				min: -0.02,
				max: 0.11,
				stacked: false,
				reverse: false,
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				format: '%B',
				tickValues: 'every 2 month',
			}}
			axisLeft={{
				format: value => value.toLocaleString(),
				tickValues: 5,
				legend: 'SUHII',
				legendOffset: -70,
				legendPosition: 'middle',
			}}
			gridYValues={5}
			gridXValues={'every 2 month'}
			pointSize={8}
			pointBorderWidth={2}
			pointBorderColor={{from: 'serieColor'}}
			pointLabelYOffset={-12}
			useMesh={true}
			markers={[
				{
					axis: 'y',
					value: 0,
					lineStyle: {stroke: 'var(--base50)', strokeWidth: 1},
				},
			]}
			legends={[
				{
					anchor: 'bottom-right',
					direction: 'column',
					justify: false,
					translateX: 180,
					translateY: 0,
					itemsSpacing: 0,
					itemDirection: 'right-to-left',
					itemWidth: 160,
					itemHeight: 20,
					symbolSize: 12,
					symbolShape: 'circle',
					symbolBorderColor: 'rgba(0, 0, 0, .5)',
					effects: [
						{
							on: 'hover',
							style: {
								itemBackground: 'rgba(0, 0, 0, .03)',
								itemOpacity: 1,
							},
						},
					],
					toggleSerie: true,
				},
			]}
			tooltip={tooltip}
		/>
	);
};

LineChart.propTypes = {
	data: PropTypes.array,
	tooltip: PropTypes.func,
	colors: PropTypes.object,
};

export default LineChart;
