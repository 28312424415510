import {Select as commonSelect, connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../../state/Action';

import presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const periodKey = ownProps?.layerState?.metadataModifiers?.periodKey;
	const period = ownProps.date
		? ownProps.date
		: commonSelect.periods.getByKey(state, periodKey);
	return {period};
};

const mapDispatchToPropsFactory = () => {
	return dispatch => {
		const componentId = `${utils.uuid()}_PeriodLabel`;
		return {
			onMount: periodKey => {
				if (periodKey) {
					dispatch(Action.periods.useKeys([periodKey], componentId));
				}
			},
			onUnmount: () => {
				dispatch(Action.periods.useKeysClear(componentId));
			},
		};
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(presentation);
