import classnames from 'classnames';
import PropTypes from 'prop-types';
import CenteredWrap from '../common/CenteredWrap';
import Link from '../common/Link';
import HeaderTools from './HeaderTools';
import {getRouter} from '../../router';
import logo_c from '../../assets/img/logo_c.png';
import './style.scss';

const Header = ({children, wide}) => {
	const classes = classnames('cure-Header', {
		'is-wide': wide,
	});
	let content;
	const inner = (
		<div className="cure-Header-content" data-tour="top-bar">
			<Link
				tabIndex={0}
				classes={'cure-TitleWrapper'}
				name={'home'}
				paramsFilter={['key', 'step']}
				router={getRouter()}
			>
				<img className="cure-Logo" src={logo_c} />
				<span className="cure-Title">cure portal</span>
			</Link>
			{children}
			<HeaderTools />
		</div>
	);
	if (wide) {
		content = inner;
	} else {
		content = <CenteredWrap>{inner}</CenteredWrap>;
	}

	return <div className={classes}>{content}</div>;
};

Header.propTypes = {
	children: PropTypes.node,
	wide: PropTypes.bool,
};

export default Header;
