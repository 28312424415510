import PropTypes from 'prop-types';
import {useEffect} from 'react';

const Layer = ({title, onMount, onUnmount}) => {
	useEffect(() => {
		onMount();

		return onUnmount;
	}, []);

	return <p>{title}</p>;
};

Layer.propTypes = {
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	title: PropTypes.string,
};

export default Layer;
