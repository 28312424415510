const TopBar = () => (
	<div>
		<h4>Landing page</h4>
		<p>
			A landing page of the CURE Portal is your starting point to all
			information available.
		</p>
		<p>
			<b>About</b> - see context to the CURE project
		</p>
		<p>
			<b>Grand Challenges</b> - check policy context, city needs and links to
			the CURE services contributing to solutions.
		</p>
		<p>
			<b>Applications</b> - dive into the CURE applications overview page. This
			is your main entry point to individual services storylines.
		</p>
		<p>
			<b>Cities</b> - examine partnering cities, where the CURE applications
			were tested.
		</p>
		<p>
			<b>Services</b> manage on-demand service production (available for logged
			in users only).
		</p>
		<p>
			<b>User Log in</b> - your log in / log out area
		</p>
		<p>
			<b>Light/Dark theme switch</b> - some like it light, some like it dark
		</p>
		<p>
			<b>Help</b> - here you are
		</p>
	</div>
);

export default TopBar;
