// import PropTypes from 'prop-types';
import {zones} from '../../enumerations';
import '../../../../../common/FeatureTootip/style.scss';
import PropTypes from 'prop-types';

const App5_ShowcaseA_VectorTooltip = ({attributes}) => {
	const zoneAttributeKey = Object.keys(attributes);
	const zone = attributes[zoneAttributeKey];
	return (
		<div className="ptr-FeatureTooltip-content">
			<div className="ptr-FeatureTooltip-body">
				{zones[zone] ? <b>{`${zones[zone]}`}</b> : <b>{`Zone: ${zone}`}</b>}
			</div>
		</div>
	);
};

App5_ShowcaseA_VectorTooltip.propTypes = {
	attributes: PropTypes.object,
	attributesMetadata: PropTypes.object,
};

export default App5_ShowcaseA_VectorTooltip;
