import {connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import {scopesFilter} from '../../../../../constants/app';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		applicationStories:
			Select.cure.applicationStories.getForCurrentFilterOrdered(
				state,
				scopesFilter.filterByActive,
				scopesFilter.filter,
				scopesFilter.order,
				scopesFilter.start,
				scopesFilter.length
			),
	};
};

const mapDispatchToPropsFactory = () => {
	const componentId = `AppList_${utils.uuid()}`;
	return dispatch => {
		return {
			onMount: () => {
				dispatch(
					Action.scopes.useIndexed(
						scopesFilter.filterByActive,
						scopesFilter.filter,
						scopesFilter.order,
						scopesFilter.start,
						scopesFilter.length,
						componentId
					)
				);
			},
			onUnmount: () => {
				dispatch(Action.scopes.useIndexedClear(componentId));
			},
		};
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(Presentation);
