import PropTypes from 'prop-types';
import {MapScale} from '@gisatcz/ptr-maps';
import MapAttribution from '../MapAttribution';
import MapComponentsPositionWrapper from '../MapComponentsPositionWrapper';
import './style.scss';

const MapSetInfo = props => {
	return (
		<MapComponentsPositionWrapper {...props} className="cure-MapSetInfo">
			<MapScale maxWidth={100} />
			<MapAttribution
				backgroundLayer={props.backgroundLayer}
				mapSetKey={props.mapSetKey}
			/>
		</MapComponentsPositionWrapper>
	);
};

MapSetInfo.propTypes = {
	backgroundLayer: PropTypes.object,
	mapSetKey: PropTypes.string,
};

export default MapSetInfo;
