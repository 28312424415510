// supported query strings
export default {
	darkMode: 'darkMode',
	tags: 'tags',
};

export const screens = {
	applicationStoryScreen: {
		key: 'applicationStoryScreen',
		represents: ['applicationStoryScreen'],
	},
	homeScreen: {
		key: 'homeScreen',
		represents: ['homeScreen'],
	},
};

export const defaultScreen = screens['homeScreen'].key;
