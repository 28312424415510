import PropTypes from 'prop-types';
import {TagsContainer} from '@gisatcz/visat-components';
import AppTag from './AppTag';

const AppPreviewTags = ({tagKeys}) => {
	return (
		<TagsContainer className="cure-AppPreviewTags" small>
			{tagKeys.map(tagKey => (
				<AppTag key={tagKey} tagKey={tagKey} />
			))}
		</TagsContainer>
	);
};

AppPreviewTags.propTypes = {
	tagKeys: PropTypes.array,
};

export default AppPreviewTags;
