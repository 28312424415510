// import PropTypes from 'prop-types';
import {IconTool} from '@gisatcz/visat-components';
import {useTour} from '@reactour/tour';
import Tooltip from '../../../common/Tooltip';
import './style.scss';
import {getRouter} from '../../../../router';
import Link from '../../../common/Link';

const Help = () => {
	const {setIsOpen} = useTour();

	return (
		<Link
			tabIndex={0}
			name={`home`}
			router={getRouter()}
			recoverParamsFilter={['darkMode']}
			paramsFilter={['key', 'step']}
		>
			<IconTool
				icon="ri-help"
				onClick={() => setIsOpen(true)}
				tooltip={{text: 'Guidance', position: 'bottom', component: Tooltip}}
			/>
		</Link>
	);
};

Help.propTypes = {};

export default Help;
