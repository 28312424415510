import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = state => {
	const activePlace = Select.places.getActive(state);
	return {activePlace};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
