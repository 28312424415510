import PropTypes from 'prop-types';
import classnames from 'classnames';
import {IconTool} from '@gisatcz/visat-components';
import './style.scss';

const fundingText =
	"CURE has received funding from the European Union's Horizon 2020 research and innovation programme under grant agreement No 870337";

const Footer = className => {
	// prop "is-AppStory", "is-cityDemonstrations", "is-cityDemonstrations-smallScreen"
	const classes = classNames => classnames(classNames, {}, className.className);
	const scrollToTop = () => {
		document.querySelector('.cure-IndexBody').scrollTop = 0;
	};
	return (
		<div className={classes('cure-Footer')}>
			<div className={classes('cure-Footer-container-main')}>
				<div className={classes('cure-Footer-container')}>
					<div className="cure-Footer-socialLinksContainer">
						<a
							className="cure-Footer-projectLink"
							href="http://cure-copernicus.eu/index.html"
						>
							<div className={classes('cure-Footer-projectWrapper')}>
								<img
									className={classes('cure-Footer-projectImg')}
									src={require('../../assets/img/logo_c.png')}
								/>
							</div>
						</a>
						<a href="https://www.facebook.com/H2020Cure">
							<IconTool icon="ri-facebook" className={classes('social-link')} />
						</a>
						<a href="https://twitter.com/h2020cure">
							<IconTool icon="ri-twitter" className={classes('social-link')} />
						</a>
						<a href="https://www.linkedin.com/groups/13807518/">
							<IconTool icon="ri-linkedin" className={classes('social-link')} />
						</a>
						<a href="https://www.researchgate.net/project/CURE-Copernicus-for-Urban-Resilience-in-Europe">
							<IconTool
								icon="ri-researchgate"
								className={classes('social-link')}
							/>
						</a>
						<a href="https://zenodo.org/communities/cure-h2020/?page=1&size=20">
							<IconTool icon="ri-zenodo" className={classes('social-link')} />
						</a>
					</div>
				</div>
				<div className={classes('cure-Footer-container')}>
					<img
						className={classes('cure-Footer-euImg')}
						src={require('../../assets/img/eulogo.png')}
					/>
				</div>
				<div className={classes('cure-Footer-container')}>
					<p className={classes('cure-Footer-policyText')}>
						{fundingText}
						<br />
						<a
							className="cure-Footer-policyLink"
							href="http://cure-copernicus.eu/content/ConditionofUse_CURE.pdf"
						>
							Terms of Use
						</a>
						<span> / </span>
						<a
							className="cure-Footer-policyLink"
							href="http://cure-copernicus.eu/content/PrivacyPolicy_CURE.pdf"
						>
							Privacy Policy
						</a>
					</p>
				</div>
			</div>
			<div className={classes('cure-Footer-scrollToTopWrapper')}>
				<IconTool
					icon="angle-double-up"
					className="scrollToTop-btn"
					tooltip={{text: 'Scroll to top', position: 'bottom'}}
					onClick={() => scrollToTop()}
					id="Scroll top"
				/>
			</div>
		</div>
	);
};

Footer.propTypes = {
	className: PropTypes.string,
};

export default Footer;
