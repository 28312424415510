import {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import LayerLabelTool from '../../../common/maps/LayerLabel/LayerLabelTool';
import {
	ExpandableLabelBody,
	IconTool,
	Icon,
	useOnClickOutside,
} from '@gisatcz/visat-components';

import Tooltip from '../../../common/Tooltip';

import './style.scss';

const User = ({user, onOpenModelClick, onLogoutClick}) => {
	const [openUserMenu, setOpenUserMenu] = useState(false);
	const userIsLogged = user && user?.data?.name !== 'guest';
	const loginRef = useRef(null);

	useOnClickOutside(loginRef, () => {
		if (openUserMenu) {
			setOpenUserMenu(false);
		}
	});

	return (
		<div ref={loginRef} style={{position: 'relative'}}>
			<IconTool
				active={userIsLogged}
				icon="ri-user"
				onClick={() =>
					userIsLogged ? setOpenUserMenu(!openUserMenu) : onOpenModelClick()
				}
				tooltip={{
					text: `${userIsLogged ? `Logged as ${user?.data?.email}` : 'Login'}`,
					position: 'bottom',
					component: Tooltip,
					id: 'User',
				}}
			/>
			<ExpandableLabelBody expanded={openUserMenu} className="cure-UserMenu">
				<div className="cure-LayerLabelBody">
					<>
						<LayerLabelTool
							className={'cure-Username'}
							title={user?.data?.email || 'Guest'}
						></LayerLabelTool>
						<LayerLabelTool
							onClick={() => {
								onLogoutClick();
								setOpenUserMenu(false);
							}} //and close labels
							title={'Log Out'}
						>
							<Icon icon="ri-logout" />
						</LayerLabelTool>
					</>
				</div>
			</ExpandableLabelBody>
		</div>
	);
};

User.propTypes = {
	user: PropTypes.object,
	onOpenModelClick: PropTypes.func,
	onLogoutClick: PropTypes.func,
};

export default User;
