import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';
import {getRouter} from '../../../router';
import Link from '../../common/Link';
import OrderServiceTool from '../../common/OrderServiceTool';
import App6_ShowcaseA from './App6_ShowcaseA';
import StoryFooterSection from '../../common/StoryFooterSection';

const App6_UrbanSubsidence = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						Ground movements are responsible for hundreds of deaths and billions
						of Euros lost annually. Similar to floods the threat they pose is
						increasing due to urbanisation, land use changes, but also changes
						in water flows under and around the city in general due to climate
						change and related drought, increasingly intense rainfalls or sea
						level rise. Therefore, prevention, monitoring and early warning is
						essential subsidence domain as with any other risks.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Diagnostic tools are needed monitoring ground motion hazard and its
						impacts for subsidence, slope instabilities, surface faulting,
						structural stability and integrity of assets or flood risk
						aggravation. Such tools have to provide relevant metrics for
						movement detection, its quantification and direction and monitoring
						in time to support cities in assessment of risks and impacts to
						urban elements exposed to ground motion hazard allowing
						prioritization, optimization and securing of investments as well as
						prevention of lives endangered and city assets compromised or
						destroyed.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The CURE Subsidence, Movements and Deformation service supports
						cities with enriched information about land subsidence risk and
						related dangers. This application can provide city authorities with
						both synoptic and detailed knowledge about localization, extent and
						magnitude of exposure to slow terrain motions due to subsidence or
						slope instability (landslide risk), and building or infrastructure
						deformations (due to terrain motions or maintenance failure) both in
						urban and sub-urban areas.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Such analysis enables identification of potential subsidence risk
						not only for urban assets, like buildings, transportation networks´
						infrastructure incl. railway tracks, highway crossings, bridges etc.
						or underground infrastructures like pipelines but also as for the
						inhabitants living in the residential areas of the city or people
						moving around the city in general. This application can also help to
						identify those dangers in time and thus to serve as an effective
						early warning system for the cities and city-regions.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Moreover, the combination of the information about localization and
						velocity of subsidence with information about chronology of building
						blocks´ construction in the city can be very valuable for the
						end-users. It can help to detect potential deviations from standard
						multi-temporal subsidence profiles which may reveal subsidence
						issues of some urban blocks. Also, this will allow to detect
						potential correlations between the construction age of urban blocks
						and their inclination to subsidence, which may indicate systematic
						issues in constructions.
					</StoryParagraph>
					<StoryParagraph className={''}>
						On the other hand, timely information about subsidence in specific
						areas intended for city-development in the near future (including
						brownfields or patches of land without current use which are crucial
						for the city re-development) may help to predict and thus to prevent
						potential issues related to increased susceptibility of these areas
						to subsidence.
					</StoryParagraph>
					<StoryParagraph className={''}>
						This applications is ready to integration of results with
						information related to other hazards (e.g. from{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap05-urban-flood-risk'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							CURE Urban Flood
						</Link>{' '}
						service) to support integrated hazard and risk assessment. This
						shall support identification and assessment of potential links
						between land subsidence and flood risk. Typically, flood risk may be
						enhanced by local terrain subsidence or, vice versa, flood events
						may lead to land subsidence acceleration.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Subsidence patterns in the city area
					</StorySubheadline>
					<StoryParagraph className={''}>
						For over than 20 years, Synthetic Aperture Radar Interferometry
						(InSAR) has been providing ground deformation data at centimetre
						precision. In the past decade, new ways of processing satellite
						radar images have been developed using Persistent Scatterer
						Interferometry (PSI) that allows ground movements over wide areas to
						be detected and monitored with even greater sensitivity. Free and
						routinely-available SAR data collected by Sentinel- 1 sensors’
						constellation within Copernicus programme represent a unique
						opportunity for applying these methods on operational level on a
						global scale (
						<a href="https://www.mdpi.com/2076-3417/7/12/1264">
							Pepe and Calò 2017
						</a>
						).
					</StoryParagraph>
					<StoryParagraph className={''}>
						Based on Copernicus open data (Sentinel-1, Urban Atlas) and
						state-of-art PS InSAR methods, a subsidence patterns can be observed
						and monitored in multi-temporal manner, for any area of interest.
						This allows to identify and follow local subsidence processes and
						trends and further assess the subsidence and surface faulting
						hazards to urban assets in the city. With upcoming Copernicus EGMS
						service the CURE Subsidence, Movements and Deformation service will
						further enhance efficiency of service production. See map showing
						subsidence point measurements with velocity resulting from CURE
						Subsidence, Movements and Deformation service (left).
					</StoryParagraph>
					<StorySubheadline className={''}>
						Subsidence hazard to urban assets (functional urban blocks)
					</StorySubheadline>
					<StoryParagraph className={''}>
						Integrating the information about subsidence hazard with Copernicus
						Urban Atlas blocks, subsidence hazard to urban assets can be easily
						(and automatically) estimated for any city. For each urban block,
						the level of subsidence-related hazard is estimated and combined
						with information about its typology (residential, commercial,
						industrial, transportation, urban green, etc.). Such analysis can be
						applied on any user-delineation, which may represent local
						objects/assets of interests - e.g. potential development zones. This
						can support both effective urban planning and early warning systems
						in the cities. See maps showing subsidence hazard level (mean annual
						displacement rate in mm/year) resulting from CURE Subsidence,
						Movements and Deformation service assigned to Urban Atlas blocks
						(right).
					</StoryParagraph>
					<StorySubheadline className={''}>
						Temporal patterns of subsidence in the city
					</StorySubheadline>
					<StoryParagraph className={''}>
						Integrating the information about subsidence hazard with Copernicus
						Urban Atlas blocks, subsidence hazard to urban assets can be easily
						(and automatically) estimated for any city. For each urban block,
						the level of subsidence-related hazard is estimated and combined
						with information about its typology (residential, commercial,
						industrial, transportation, urban green, etc.). Such analysis can be
						applied on any user-delineation, which may represent local
						objects/assets of interests – e.g. potential development zones. This
						can support both effective urban planning and early warning systems
						in the cities.
					</StoryParagraph>
					<StorySubheadline className={''}>
						Surface faulting risk/geotechnical risk to urban assets
					</StorySubheadline>
					<StoryParagraph className={''}>
						From the subsidence measurements, surface faulting risk can be
						estimated using sophisticated methods described in scientific
						literature. The surface faulting risk evaluates the level of
						geotechnical hazard to buildings or other objects in the city
						(transportation infrastructure, pipelines etc.). Such advanced
						analysis may illustrate the level of hazard caused by subsidence
						processes better than the avg. subsidence velocities, because they
						indicate potential of real risk to these objects.
					</StoryParagraph>
					<StorySubheadline>
						Integrated analysis - subsidence and flood hazard
					</StorySubheadline>
					<StoryParagraph className={''}>
						Information about subsidence hazard can be combined with information
						about flood risk coming from the CURE Urban Flood Risk application.
						This will support integrated approach to risk assessment, taking
						into consideration different types of risks while assessing the
						overall sustainability of the city. Moreover, mutual correlations
						can be identified using such analysis, e.g. increased subsidence
						hazards in flooding zones of the city or potential extension of
						flood zones caused by ongoing subsidence processes etc.
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						The CURE Subsidence, Movements and Deformation service focuses on
						risks related to ground motion. It provides a diagnostic tool
						supporting cities in assessment of risks and impacts to urban
						elements exposed to ground motion hazard as it derives relevant
						metrics for movement detection, its quantification and direction and
						monitoring in time.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Beside detailed identification of ground motion hazard, it also
						evaluates its impacts for subsidence, slope instabilities, surface
						faulting, structural stability and integrity of assets or flood risk
						aggravation. As such the CURE Subsidence, Movements and Deformation
						service provides. This application provides city authorities with
						both synoptic and detailed knowledge about localization, extent and
						magnitude of exposure to slow terrain motions due to subsidence or
						slope instability (landslide risk), and building or infrastructure
						deformations (due to terrain motions or maintenance failure) both in
						urban and sub-urban areas and allows prioritization, optimization
						and securing of investments as well as prevention of lives
						endangered and city assets compromised or destroyed.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								Persistent Scatterer Interferometry (PSI) methodological review{' '}
								<a href="https://www.sciencedirect.com/science/article/pii/S0924271615002415">
									publication
								</a>
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								Example of the CURE service applications in other context -{' '}
								<a href="https://eo4sd-drr.gisat.cz/samoa/">
									EO4SD-DRR Service: EO in Support of Alaoa Multipurpose Dam
									Project, Samoa: Assessment of ground motions and exposure
								</a>
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph className={''}>
						The service is based on{' '}
						<a href="https://sentinels.copernicus.eu/web/sentinel/home">
							Copernicus Sentinel satellite imagery
						</a>
						, <a href="https://land.copernicus.eu/">CLMS</a> and upcoming{' '}
						<a href="https://land.copernicus.eu/pan-european/european-ground-motion-service">
							EGMS
						</a>{' '}
						Copernicus services. The methodology is based on{' '}
						<a href="https://www.esa.int/Applications/Observing_the_Earth/Copernicus/Sentinel-1">
							Sentinel-1
						</a>{' '}
						imagery using state-of-art PS InSAR (Persistent Scatterer
						Interferometry) providing ground deformation data at detailed level,
						combined with land use information as provided e.g. by CLMS Urban
						Atlas data.
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<StoryParagraph className={''}>
						The service is provided by GISAT s.r.o.{' '}
						<a href="htttp://www.gisat.cz">htttp://www.gisat.cz</a>. For more
						information please contact <a>gisat@gisat.cz</a>.
					</StoryParagraph>
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro06.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Monitor and analyze subsidence-related hazard in your city
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App6_ShowcaseA mapSetKey="ap06-urban-subsidence-showcase-a" />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Heraklion',
								link: 'https://www.dropbox.com/s/euc5nqpv3e11r5m/CURE_AP06_Heraklion_sourcedata.zip?dl=0',
							},
							{
								city: 'Ostrava',
								link: 'https://www.dropbox.com/s/fv81zybs98xwmw2/CURE_AP06_Ostrava_sourcedata.zip?dl=0',
							},
						]}
						otherApps={[
							{
								key: 'ap05-urban-flood-risk',
								label: 'Urban Flood Risk',
							},
							{
								key: 'ap11-health-impact',
								label: 'Health Impact',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App6_UrbanSubsidence.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App6_UrbanSubsidence;
