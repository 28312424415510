import tools from '../../../assets/img/tour/layerTools.png';

const LayerLabel = () => (
	<div>
		<h4>Layer info</h4>
		<p>Layer name, details and some layer settings on expand.</p>
		<img src={tools} style={{width: 200}} alt="Layer tools preview" />
	</div>
);

export default LayerLabel;
