import PropTypes from 'prop-types';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import MapTooltip from '../../../common/maps/MapTooltip/VectorMapTooltip';
import Showcase from '../../../common/Showcase';
import StyleBasedLegend from '../../../common/maps/MapLegends/StyleBasedLegend';
import DateLabel from '../../../common/DateLabel';

const App9_ShowcaseC = ({mapSetKey}) => {
	return (
		<Showcase>
			<MapSetWrapper
				MapTooltip={MapTooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						highlightActiveMap: false,
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerKey: 'ap09-urban-heat-storage-showcase-c-1-daytime',
							info: [<DateLabel key={'DateLabel_day_1'} date={'2019-08-24'} />],
						},
						{
							layerKey: 'ap09-urban-heat-storage-showcase-c-2-nighttime',
							info: [<DateLabel key={'DateLabel_day_1'} date={'2019-06-25'} />],
						},
					],
				}}
				componentsByLayer={[
					{
						layerKey: 'ap09-urban-heat-storage-showcase-c-1-daytime',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap09-urban-heat-storage',
								showcaseKey: 'App9_ShowcaseC',
							},
						},
					},
					{
						layerKey: 'ap09-urban-heat-storage-showcase-c-2-nighttime',
						legend: {
							component: StyleBasedLegend,
							props: {
								applicationStoryKey: 'ap09-urban-heat-storage',
								showcaseKey: 'App9_ShowcaseC',
							},
						},
					},
				]}
			/>
		</Showcase>
	);
};

App9_ShowcaseC.propTypes = {
	mapSetKey: PropTypes.string,
};

export default App9_ShowcaseC;
