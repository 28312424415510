import {ResponsiveBar} from '@nivo/bar';
import ReactResizeDetector from 'react-resize-detector';
import PropTypes from 'prop-types';
import {useState} from 'react';

import './style.scss';

const BarChart = ({
	data,
	dataProperties,
	activeKeys,
	placeholder,
	// graph options
	keys,
	padding,
	marginTop,
	marginRight,
	marginBottom,
	marginLeft,
	innerPadding,
	colors,
	indexBy,
	units,
	legendDirection,
	groupMode,
	layout,
	labelX,
	labelY,
	offsetX,
	offsetY,
	maxValue,
	minValue,
	legendTranslateX,
	legendTranslateY,
	legendItemsSpacing,
	legendWidth,
	enableLabel,
	enableLabelX,
	enableLabelY,
	Tooltip,
}) => {
	const [screenSize, setScreenSize] = useState();
	const getScreenSize = () => {
		window.matchMedia('(max-width: 575.98px)').matches
			? setScreenSize('small')
			: window.matchMedia('(min-width: 2599.98px)').matches
			? setScreenSize('big')
			: setScreenSize('medium');
	};

	let activeData = data;

	dataProperties && activeKeys
		? dataProperties.forEach(
				(properties, index) =>
					(activeData = activeData?.[activeKeys?.['key' + index]?.nameInternal])
		  )
		: null;

	// setting up options from data
	let keysFromData = [];
	activeData?.data?.[0]
		? Object.entries(activeData.data[0]).map(item => keysFromData.push(item[0]))
		: null;

	function getOption(option1, option2, defaultValue) {
		return activeData?.options?.[option1] || option2 || defaultValue;
	}

	return (
		<div className="cure-BarChart-chart">
			{activeData?.data ? (
				<div className="cure-BarChart-chartWrapper">
					<ResponsiveBar
						data={activeData.data}
						keys={getOption('keys', keys, keysFromData)}
						indexBy={getOption('indexBy', indexBy, ['value'])}
						layout={getOption('layout', layout, 'vertical')}
						groupMode={getOption('groupMode', groupMode, 'grouped')}
						margin={{
							top: getOption('marginTop', marginTop, 20),
							right: getOption('marginRight', marginRight, 300),
							bottom: getOption('marginBottom', marginBottom, 40),
							left: getOption('marginLeft', marginLeft, 70),
						}}
						padding={getOption('padding', padding, 0.1)}
						innerPadding={getOption('innerPadding', innerPadding, 5)}
						valueScale={{type: 'linear'}}
						indexScale={{type: 'band', round: true}}
						maxValue={getOption('maxValue', maxValue, undefined)}
						minValue={getOption('minValue', minValue, undefined)}
						theme={{
							fontSize:
								screenSize == 'small'
									? '0.6rem'
									: screenSize == 'big'
									? '0.8rem'
									: '0.7rem',
							textColor: 'var(--base90)',
							axis: {
								legend: {
									text: {
										fontSize: screenSize == 'small' ? '0.65rem' : '0.7rem',
									},
								},
							},
							legends: {
								text: {
									fontSize:
										screenSize == 'small'
											? '0.6rem'
											: screenSize == 'big'
											? '0.8rem'
											: '0.7rem',
								},
							},
						}}
						colors={getOption(
							'colors',
							colors
								? colors.scheme
									? colors
									: bar => colors[bar.id]
								: {scheme: 'set3'},
							{scheme: 'set3'}
						)}
						colorBy="id"
						axisBottom={{
							format: enableLabelX
								? () => {
										return;
								  }
								: () => '',
							legend: getOption('labelX', labelX, 'X'),
							legendPosition: 'middle',
							legendOffset: getOption('offsetX', offsetX, 20),
						}}
						axisLeft={{
							format: !enableLabelY
								? () => {
										return;
								  }
								: () => '',
							tickSize: 5,
							tickPadding: 5,
							tickRotation: 0,
							legend: getOption('labelY', labelY, 'Y'),
							legendPosition: 'middle',
							legendOffset: getOption('offsetY', offsetY, -50),
						}}
						labelTextColor={{
							from: 'color',
							modifiers: [['darker', 1.7]],
						}}
						tooltip={
							Tooltip
								? Tooltip
								: ({id, indexValue, value, color}) => {
										return (
											<div
												className="cure-BarChart-tooltip"
												style={{color: color}}
											>
												{getOption('indexBy') ? indexValue : id}: {value}
												{getOption('units', units, '')}
											</div>
										);
								  }
						}
						animate={true}
						isInteractive={true}
						enableLabel={enableLabel == false ? false : true}
						legends={[
							{
								dataFrom: 'keys',
								anchor: 'bottom-right',
								direction: getOption('direction', legendDirection, 'column'),
								justify: false,
								translateX: getOption('translateX', legendTranslateX, 280),
								translateY: getOption('translateY', legendTranslateY, 0),
								itemsSpacing: getOption(
									'legendItemsSpacing',
									legendItemsSpacing,
									screenSize == 'small' ? 0 : screenSize == 'big' ? 8 : 2
								),
								itemWidth: getOption(
									'legendWidth',
									legendWidth,
									screenSize == 'small' ? 170 : screenSize == 'big' ? 250 : 220
								),
								itemHeight: screenSize == 'small' ? 26 : 20,
								itemDirection:
									screenSize == 'small' ? 'bottom-to-top' : 'right-to-left',
								itemOpacity: 0.85,
								symbolSize:
									screenSize == 'small' ? 10 : screenSize == 'big' ? 25 : 20,
								toggleSerie: true,
								effects: [
									{
										on: 'hover',
										style: {
											itemOpacity: 1,
										},
									},
								],
							},
						]}
					/>
				</div>
			) : (
				<div className="cure-BarChart-chartPlaceholder">
					{placeholder ? placeholder : <h3>No data provided</h3>}
				</div>
			)}
			<ReactResizeDetector onResize={getScreenSize} />
		</div>
	);
};

BarChart.propTypes = {
	data: PropTypes.object,
	dataProperties: PropTypes.array,
	activeKeys: PropTypes.object,
	placeholder: PropTypes.element,
	// graph options
	keys: PropTypes.array,
	padding: PropTypes.number,
	marginTop: PropTypes.number,
	marginRight: PropTypes.number,
	marginBottom: PropTypes.number,
	marginLeft: PropTypes.number,
	innerPadding: PropTypes.number,
	colors: PropTypes.object,
	indexBy: PropTypes.string,
	units: PropTypes.string,
	legendDirection: PropTypes.string,
	groupMode: PropTypes.string,
	layout: PropTypes.string,
	labelX: PropTypes.string,
	labelY: PropTypes.string,
	offsetX: PropTypes.string,
	offsetY: PropTypes.string,
	maxValue: PropTypes.number,
	minValue: PropTypes.number,
	legendTranslateX: PropTypes.number,
	legendTranslateY: PropTypes.number,
	legendItemsSpacing: PropTypes.number,
	legendWidth: PropTypes.number,
	enableLabel: PropTypes.bool,
	enableLabelX: PropTypes.bool,
	enableLabelY: PropTypes.bool,
	Tooltip: PropTypes.node,
};

export default BarChart;
