import {useEffect} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const DateLabel = ({onMount, onUnmount, layerState, period, format}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount(layerState?.metadataModifiers?.periodKey);
		}

		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	const finalDateFormat = format || 'YYYY-MM-DD';
	const dateFormated = period?.data?.nameDisplay
		? moment(period?.data?.nameDisplay).format(finalDateFormat)
		: period;
	return <>{dateFormated}</>;
};

DateLabel.propTypes = {
	period: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	layerState: PropTypes.object,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	format: PropTypes.string,
};

export default DateLabel;
