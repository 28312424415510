import {isPlainObject as _isPlainObject} from 'lodash';
import {utils, map as mapUtils} from '@gisatcz/ptr-utils';
import {
	Action as CommonAction,
	commonActions,
	commonSelectors,
} from '@gisatcz/ptr-state';
import applicationStories from './applicationStories/actions';
import routerActions from '../router/actions';
import Select from '../Select';

function setMapViewFromPlace(placeKey, mapSetKey) {
	return (dispatch, getState) => {
		let place = null;
		const state = getState();

		if (placeKey) {
			place = Select.places.getByKey(state, placeKey);
		} else {
			place = Select.places.getActive(state);
		}

		if (place?.data?.geometry) {
			let view = mapUtils.view.getViewFromGeometry(place.data.geometry, true);

			// TODO fix to zoom area properly
			view.boxRange /= 2;

			if (!mapSetKey) {
				mapSetKey = Select.maps.getActiveSetKey(state);
			}

			if (mapSetKey) {
				dispatch(CommonAction.maps.updateSetView(mapSetKey, view));
			}
		}
	};
}

const loginUser = (
	name,
	password,
	cookies,
	componentKey,
	onSuccessfulLoginCallback
) => {
	return dispatch => {
		return dispatch(
			CommonAction.users.apiLoginUser(name, password, cookies)
		).then(data => {
			if (data instanceof Error) {
				dispatch(
					CommonAction.components.set(
						componentKey,
						'errors',
						'Wrong combination of username and password'
					)
				);
			} else {
				dispatch(routerActions.updateAppUrl('cure-services'));
				onSuccessfulLoginCallback();
			}
		});
	};
};

const createUser = (name, password, componentKey) => {
	return dispatch => {
		const onError = err => {
			if (err instanceof Error) {
				return dispatch(
					CommonAction.components.set(
						componentKey,
						'errors',
						'User was not created.'
					)
				);
			}
		};

		const onCreate = () => {
			return CommonAction.components.set(
				componentKey,
				'messages',
				`User ${name} was created, you can log in.`
			);
		};
		return dispatch(
			commonActions.request(
				'rest/project/cure/register',
				'POST',
				null,
				{
					email: name,
					password: password,
				},
				onCreate,
				onError,
				undefined,
				null
			)
		);
	};
};

const createUserServices = () => {
	return dispatch => {
		const succesAction = result => {
			return dispatch(
				CommonAction.components.set('ordered-services', 'services', result)
			);
		};

		const errorAction = result => {
			return CommonAction.components.set(
				'ordered-services',
				'services',
				result
			);
		};

		return dispatch(
			commonActions.request(
				'rest/project/cure/orders',
				'GET',
				null,
				undefined,
				succesAction,
				errorAction,
				undefined,
				null
			)
		);
	};
};

const sendUserServices = (appKey, params) => {
	return dispatch => {
		const succesAction = () => {
			return dispatch(
				CommonAction.components.set(
					'ordered-services',
					'order-status',
					'success'
				)
			);
		};

		const errorAction = () => {
			return dispatch(
				CommonAction.components.set('ordered-services', 'order-status', 'error')
			);
		};

		return dispatch(
			commonActions.request(
				'rest/project/cure/service',
				'POST',
				null,
				{
					app: appKey,
					...params,
				},
				succesAction,
				errorAction,
				undefined,
				null
			)
		);
	};
};

const setupTimeline = (showCaseKey, mapSetKey) => {
	return (dispatch, getState) => {
		const state = getState();
		const activeKeys = commonSelectors.getAllActiveKeys(state);
		const storyKey = Select.router.getStory(state);
		const storyConfig =
			Select.cure.applicationStories.getConfigByApplicationStoryKey(
				state,
				storyKey
			);
		const activePlaceKey = Select.places.getActiveKey(state);
		const timelineDefaultLayers =
			storyConfig?.showcases?.[showCaseKey]?.timeline?.defaultLayers?.[
				activePlaceKey
			];
		const mapSet = Select.maps.getMapSetByKey(state, mapSetKey);

		// removeAllMapLayers
		mapSet.maps.forEach(mapKey => {
			dispatch(CommonAction.maps.removeAllMapLayers(mapKey));
		});

		const timelineKey = storyConfig?.showcases?.[showCaseKey]?.timeline?.key;
		if (!timelineDefaultLayers) {
			//no default layers
			// use first periods as a default
			const timelineLayers = timelineKey
				? Select.cure.applicationStories.getTimelineLayers(state, timelineKey)
				: [];

			const layers = [];

			timelineLayers.forEach(layerRow => {
				layerRow.items.forEach(layer => {
					const periodsConfig = _isPlainObject(layer?.periods)
						? layer.periods
						: null;
					const order = null;
					const start = 0;
					const length = 1000;
					const periodKeys = periodsConfig
						? Select.timeline.mapTimeline.getPeriodKeysForFilteredSpatialRelations(
								state,
								periodsConfig.filterByActive,
								periodsConfig.filter,
								order,
								start,
								length
						  )
						: null;
					if (periodKeys && periodKeys.length > 0) {
						// add layer with
						// periodKeys[0]
						const firstPeriod = periodKeys[0];
						const timelineMapLayerDefinition =
							Select.timeline.mapTimeline.getTimelineMapLayerPeriodDefinition(
								layer?.layerState,
								firstPeriod,
								activeKeys
							);
						const l = {
							...layer.layerState,
							...timelineMapLayerDefinition,
							key: utils.uuid(),
							mapZIndex: layer.mapZIndex,
						};

						layers.push(l);
					}
				});
			});

			if (layers.length > 0 && layers.length === mapSet.maps.length) {
				mapSet.maps.forEach((mapKey, i) => {
					const mapZIndex = layers[i].mapZIndex;
					delete layers[i].mapZIndex;
					dispatch(
						CommonAction.maps.addMapLayerToIndex(mapKey, layers[i], mapZIndex)
					);
				});
			}
		} else {
			//set defult layers to map
			mapSet.maps.forEach(mapKey => {
				if (timelineDefaultLayers[mapKey]) {
					const mapZIndex = 0;
					dispatch(
						CommonAction.maps.addMapLayerToIndex(
							mapKey,
							{
								...timelineDefaultLayers[mapKey],
								metadataModifiers: {
									...timelineDefaultLayers[mapKey].metadataModifiers,
									placeKey: activePlaceKey,
								},
							},
							mapZIndex
						)
					);
				}
			});
		}
	};
};

const mapTimelineRefreshUses = () => {
	const order = null;
	const start = 1;
	const length = 1000;

	return (dispatch, getState) => {
		const state = getState();
		const storyKey = Select.router.getStory(state);
		const storyConfig =
			Select.cure.applicationStories.getConfigByApplicationStoryKey(
				state,
				storyKey
			);
		const showCaseKeys = Object.keys(storyConfig?.showcases);
		const timelineKeys = showCaseKeys.reduce((acc, showCaseKey) => {
			const timelineKey = storyConfig?.showcases?.[showCaseKey]?.timeline?.key;
			if (timelineKey) {
				return [...acc, timelineKey];
			} else {
				return acc;
			}
		}, []);

		if (timelineKeys && timelineKeys.length > 0) {
			const promises = [];
			timelineKeys.forEach(timelineKey => {
				const timelineLayers = Select.cure.applicationStories.getTimelineLayers(
					state,
					timelineKey
				);

				timelineLayers.forEach(layer => {
					layer.items.forEach(layerItem => {
						// Reload spatial relations with new filter
						promises.push(
							dispatch(
								CommonAction.timeline.mapTimeline.useRelationsForLayerRowItem(
									layerItem.periods.filterByActive,
									layerItem.periods.filter,
									order,
									start,
									length
								)
							)
						);
					});
				});
			});

			return Promise.all(promises);
		}
	};
};

export default {
	loginUser,
	createUser,
	createUserServices,
	sendUserServices,
	applicationStories,
	setMapViewFromPlace,
	mapTimelineRefreshUses,
	setupTimeline,
};
