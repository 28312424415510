import {createSelector} from 'reselect';
import {Select as CommonSelect} from '@gisatcz/ptr-state';

const getBaseTags = createSelector(
	[CommonSelect.components.getByComponentKey],
	component => {
		if (component?.tagKeys) {
			return Object.keys(component?.tagKeys);
		} else {
			return [];
		}
	}
);

const getOptionTagKeys = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		(state, componentKey, tagKey) => tagKey,
	],
	(component, tagKey) => {
		if (component?.tagKeys) {
			if (component?.tagKeys?.[tagKey]?.keys) {
				return component?.tagKeys?.[tagKey]?.keys;
			} else {
				return [];
			}
		} else {
			return [];
		}
	}
);

const getActiveTagKey = createSelector(
	[
		CommonSelect.components.getByComponentKey,
		(state, componentKey, tagKey) => tagKey,
		(state, componentKey, tagKey, mapKey) => mapKey,
	],
	(component, tagKey, mapKey) => {
		if (component?.tagKeys) {
			if (component?.tagKeys?.[tagKey]?.keys) {
				const activeTagKey = component?.tagKeys?.[tagKey]?.active?.[mapKey];
				return activeTagKey;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

export default {
	getBaseTags,
	getOptionTagKeys,
	getActiveTagKey,
};
