import PropTypes from 'prop-types';
import {connects} from '@gisatcz/ptr-state';
import {
	PresentationMap,
	DeckGlMap,
	MapSet as PresentationMapSet,
} from '@gisatcz/ptr-maps';
import MapSetInfo from '../MapSetInfo';
import MapSetControls from '../MapSetControls';
import MapWrapper from '../MapWrapper';
import helpers from '../helpers';

import './styles.scss';

const ConnectedMap = connects.Map(PresentationMap);
const ConnectedMapSet = connects.MapSet(PresentationMapSet);

const MapSet = ({
	mapSetKey,
	children,
	componentsByLayer,
	config,
	MapTooltip,
	mapTooltipProps,
	displayOverlay,
	Overlay,
}) => {
	if (mapSetKey) {
		return (
			<div className="ptr-MapSetContainer">
				<ConnectedMapSet
					stateMapSetKey={mapSetKey}
					mapComponent={DeckGlMap}
					connectedMapComponent={ConnectedMap}
					wrapper={MapWrapper}
					wrapperProps={{
						displayOverlay,
						Overlay,
						mapSetKey,
						highlightActive: helpers.getMapSetConfig(
							config?.mapSet,
							'highlightActiveMap'
						),
						componentsByLayer,
						configurationsByLayer: config?.layers,
					}}
					Tooltip={MapTooltip}
					tooltipProps={mapTooltipProps}
				>
					<MapSetInfo mapSetKey={mapSetKey} key={'MapSetInfo'} />
					{helpers.getMapSetConfig(config?.mapSet, 'controls') ? (
						<MapSetControls
							mapSetKey={mapSetKey}
							key={'MapSetControls'}
							horizontal={helpers.getMapSetConfig(
								config?.mapSet,
								'controls.horizontal'
							)}
						/>
					) : (
						<></>
					)}
					{children}
				</ConnectedMapSet>
			</div>
		);
	} else {
		return null;
	}
};

MapSet.propTypes = {
	componentsByLayer: PropTypes.array,
	config: PropTypes.object,
	highlightActiveMap: PropTypes.bool,
	horizontalMapSetControls: PropTypes.bool,
	mapSetKey: PropTypes.string,
	children: PropTypes.node,
	legendByLayerKey: PropTypes.object,
	MapTooltip: PropTypes.func,
	mapTooltipProps: PropTypes.object,
	displayOverlay: PropTypes.object,
	Overlay: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.func,
		PropTypes.elementType,
	]),
};

export default MapSet;
