import PropTypes from 'prop-types';
import './style.scss';

const CenteredOptions = ({children}) => {
	return <div className="cure-CenteredOptions">{children}</div>;
};

CenteredOptions.propTypes = {
	children: PropTypes.node,
};

export default CenteredOptions;
