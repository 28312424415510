import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = (state, ownProps) => {
	const layerComponent = Select.components.getByComponentKey(
		state,
		'ap01-surface-dynamics-showcase-a-layer-switch'
	);

	const activeRasterLegend = layerComponent?.activeOptionKey;
	const rasterLegendData = Select.cure.applicationStories.getRasterLegend(
		state,
		'App1_ShowcaseA'
	);

	const rasterLegend = rasterLegendData?.[activeRasterLegend];
	return {
		maps: Select.maps.getMapSetMapKeys(state, ownProps.mapSetKey),
		rasterLegend,
	};
};

export default connect(mapStateToProps)(Presentation);
