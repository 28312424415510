import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
	ExpandableLabel,
	ExpandableLabelBody,
	ExpandableLabelHeader,
	Icon,
} from '@gisatcz/visat-components';

import './style.scss';

const DownloadData = ({downloadOptions}) => {
	return (
		<div className="cure-StoryMainPanelFooter-dataDownload">
			<ExpandableLabel className="cure-dataDownload-selector" zIndex={1}>
				<ExpandableLabelHeader
					className="cure-dataDownload-selectorHeader"
					wholeInteractive
				>
					<div>Select city to download</div>
				</ExpandableLabelHeader>
				<ExpandableLabelBody className="cure-dataDownload-selectorBody">
					{downloadOptions.map(options => {
						return (
							<a
								className={classNames('cure-dataDownload-selectorBody-link', {
									'is-disabled': !options?.link,
								})}
								key={options?.city}
								href={options?.link}
								target="_blank"
								rel="noreferrer"
							>
								{options?.city} <Icon icon="ri-download" />
							</a>
						);
					})}
				</ExpandableLabelBody>
			</ExpandableLabel>
		</div>
	);
};

DownloadData.propTypes = {
	downloadOptions: PropTypes.array,
};

export default DownloadData;
