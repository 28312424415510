import PropTypes from 'prop-types';
import {useEffect} from 'react';
import MapCompare from '../MapCompare';
import MapSet from '../MapSet';
import {defaultMapTooltipProps} from './constants';
import './style.scss';

const MapSetWrapper = ({
	mapMode,
	mapSetKey,
	mapKeys,
	componentsByLayer,
	config,
	children,
	MapTooltip,
	mapTooltipProps,
	onMount,
	activePlaceKey,
	displayOverlay,
	Overlay,
}) => {
	useEffect(() => {
		if (typeof onMount === 'function') {
			onMount(activePlaceKey);
		}
	}, []);

	const tooltipProps = mapTooltipProps
		? {...defaultMapTooltipProps, ...mapTooltipProps}
		: defaultMapTooltipProps;

	return (
		<div className="ptr-MapSetWrapper">
			{mapMode === 'set' ? (
				<MapSet
					mapSetKey={mapSetKey}
					componentsByLayer={componentsByLayer}
					config={config}
					MapTooltip={MapTooltip}
					mapTooltipProps={tooltipProps}
					displayOverlay={displayOverlay}
					Overlay={Overlay}
				>
					{children ? children : <></>}
				</MapSet>
			) : (
				<MapCompare
					leftMapKey={mapKeys?.[0]}
					rightMapKey={mapKeys?.[1]}
					mapSetKey={mapSetKey}
					componentsByLayer={componentsByLayer}
					config={config}
					MapTooltip={MapTooltip}
					mapTooltipProps={tooltipProps}
					displayOverlay={displayOverlay}
					Overlay={Overlay}
				>
					{children ? children : <></>}
				</MapCompare>
			)}
		</div>
	);
};

MapSetWrapper.propTypes = {
	children: PropTypes.node,
	componentsByLayer: PropTypes.array,
	config: PropTypes.object,
	mapKeys: PropTypes.array,
	mapMode: PropTypes.string,
	mapSetKey: PropTypes.string,
	MapTooltip: PropTypes.func,
	mapTooltipProps: PropTypes.object,
	onMount: PropTypes.func,
	activePlaceKey: PropTypes.string,
	displayOverlay: PropTypes.object,
	Overlay: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.func,
		PropTypes.elementType,
	]),
};

export default MapSetWrapper;
