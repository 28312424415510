import {connect} from '@gisatcz/ptr-state';
import Select from '../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = (state, ownProps) => {
	const tags = Select.cure.applicationStories.ap04.getBaseTags(
		state,
		ownProps.componentKey
	);
	return {
		tags,
	};
};

const mapDispatchToProps = () => {
	return (dispatch, ownProps) => ({
		onChange: () => {
			if (typeof ownProps.onActiveFilterChange === 'function') {
				ownProps.onActiveFilterChange(
					ownProps.componentKey,
					ownProps.activeMapKey
				);
			}
		},
	});
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
