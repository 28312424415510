import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';
import {getRouter} from '../../../router';
import Link from '../../common/Link';
import App2_ShowcaseC from '../App2_UrbanHeatIslandEffect/App2_ShowcaseC';
import OrderServiceTool from '../../common/OrderServiceTool';
import App2_ShowcaseA from './App2_ShowcaseA';
import StoryFooterSection from '../../common/StoryFooterSection';

const App2_UrbanHeatIslandEffect = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						Human well-being in urban environments is, among others, strongly
						driven by local climatic conditions, in particular temperature, and,
						as such, is expected to be impacted heavily by recent and forecasted
						temperature variability and increase due to climate change. In
						general, urban or metropolitan areas are warmer than their rural
						surroundings due to the thermal properties of the built-up
						environment as well as excess heat produced by human activities.
						This phenomenon is commonly referred to as the urban heat island
						effect. Increasing temperature in the urban environment directly
						affects human well-being through heat stress, it decreases air
						quality by increasing the production of pollutants such as ozone, it
						decreases water quality as warmer waters flow into area streams, it
						increases energy consumption and puts stress on the remaining urban
						vegetation.
					</StoryParagraph>
					<StoryParagraph className={''}>
						Cities differ strongly in how pronounced the urban heat island
						effect plays out. This is due to complex interactions of local and
						regional climatic conditions with the urban fabric, such as roads
						and buildings but also vegetation and their respective spatial
						arrangement. The Urban Heat Island application developed in the CURE
						project provides a quantitative way to assess the intensity of the
						surface-driven heat island effect (Surface Urban Heat Island
						Indicator (SUHII) on the city level. Such a quantitative
						characterization of the urban heat island effect can, on the one
						hand, contribute to the evaluation of potential heat risks of
						Europe’s cities and on the other hand, guide the planning of
						resilient urban development in face of global climate change.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Urban Heat Stress Relations
					</StorySubheadline>
					<StoryParagraph className={''}>
						This CURE application generates an indicator of the surface urban
						heat island effect (SUHII) for variable temporal windows for both
						day-time and night-time. It relies on the CURE high resolution land
						surface temperature service, that is, based on Copernicus Sentinel-3
						SLSTR temperature data, in combination with high-resolution layers
						of imperviousness provided by the Copernicus Land Monitoring
						Service. By quantifying the relationship between imperviousness and
						land-surface temperature, Surface Urban Heat Island Assessment
						service produces time-series of SUHI Indicator values, along with
						reliability. See how two mains components for the SUHI indicator
						calculation are related - the CURE high resolution land surface
						temperature service (left) and CLMS high-resolution layers of
						imperviousness (right).
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>
						Tracking Temporal Susceptibility To Urban Heat Stress
					</StorySubheadline>
					<StoryParagraph className={''}>
						SUHII effects differ strongly based on season, daytime, and city.
						Interpretation of SUHI indicator values allows to track temporal and
						diurnal susceptibility to urban heat stress. As seen on example, the
						higher the estimate of the SUHII, the more pronounced is the heating
						impact of cities impervious surface. SUHII values around zero
						indicate no heating effect, positive values indicate higher
						temperatures in the center than the surroundings, negative values
						indicate cooler temperatures in the city center. SUHII provides
						information for a relative comparison or ranking of cities by their
						thermal characteristics and allows for an assessment of
						vulnerability to urban heat stress, which may allow to focus efforts
						on cities most affected and steer urban developments, e.g. through
						targeted location of urban green spaces or other city interventions
						(see{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap10-nature-based-solutions'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							the CURE NBS service
						</Link>
						).
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						Interpretation of SUHI indicator values allows to track temporal and
						diurnal susceptibility to urban heat stress. Relative ranking of
						cities by their thermal characteristics might be performed at a
						city, regional or on a national level and provides valuable input
						for an assessment of vulnerability to urban heat stress, so
						important for overall resilience assessment in a context of climate
						changes. While the service cannot perform explicit forecasting of
						the SUHII, it can provide a retrospective analysis of SUHII
						development i.e. sensitivity to potential changes in urban green
						inventory. Surface Urban Heat Island Assessment addresses the
						stakeholder needs of both local city planners, but also larger scale
						policy makers. It can assist urban planners to optimize their
						adaptation strategies with regard to heat stress, and sustainable
						development optimization.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								Methodological background -{' '}
								<a href="https://www.sciencedirect.com/science/article/pii/S0048969717334186">
									Li, H., Zhou, Y., Li, X., Meng, L., Wang, X., Wu, S., Sodoudi,
									S. (2018). A new method to quantify surface urban heat island
									intensity. Science of The Total Environment 624, 262–272.
								</a>
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph>
						The service is based on{' '}
						<a href="https://sentinels.copernicus.eu/web/sentinel/home">
							Copernicus Sentinel satellite imagery
						</a>
						, and <a href="https://land.copernicus.eu/">CLMS</a> Copernicus
						Services. The methodology is based on a regression of the measured
						LST against the imperviousness degree following the method of{' '}
						<a href="https://www.sciencedirect.com/science/article/pii/S0048969717334186">
							Li et al. (2018).
						</a>
						, based on{' '}
						<a href="https://sentinels.copernicus.eu/web/sentinel/missions/sentinel-3">
							Sentinel-3
						</a>{' '}
						thermal images, the CURE
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap01-surface-dynamics'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							Land Surface Temperature service
						</Link>{' '}
						and <a href="https://land.copernicus.eu/">CLMS Urban Atlas</a>{' '}
						products for SUHII estimation.
					</StoryParagraph>
					<StoryParagraph>
						This service is provided by German Aerospace Center (DLR), German
						Remote Sensing Data Center - Land Surface, Germany.{' '}
						<a href="https://www.dlr.de/eoc">https://www.dlr.de/eoc</a>
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro02.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Track how the built-up environment drives heating effects in your
						city
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App2_ShowcaseA mapSetKey="ap02-urban-heat-island-showcase-a" />
				<App2_ShowcaseC />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Berlin',
								link: 'https://www.dropbox.com/s/gxl4omvbdjsr7m0/CURE_AP02_Berlin_sourcedata.zip?dl=0',
							},
							{
								city: 'Copenhagen',
								link: 'https://www.dropbox.com/s/8w11iy7qezka3cd/CURE_AP02_Copenhagen_sourcedata.zip?dl=0',
							},
							{
								city: 'Sofia',
								link: 'https://www.dropbox.com/s/yc3lyz1bpfp7asz/CURE_AP02_Sofia_sourcedata.zip?dl=0',
							},
							{
								city: 'Heraklion',
								link: 'https://www.dropbox.com/s/v2o3317fuc86ujb/CURE_AP02_Heraklion_sourcedata.zip?dl=0',
							},
							{
								city: 'Bristol',
								link: 'https://www.dropbox.com/s/o4zlvikajpgubku/CURE_AP02_Bristol_sourcedata.zip?dl=0',
							},
							{
								city: 'Ostrava',
								link: 'https://www.dropbox.com/s/fqppz9qwdkbp4zw/CURE_AP02_Ostrava_sourcedata.zip?dl=0',
							},
							{
								city: 'Basel',
								link: 'https://www.dropbox.com/s/k4vtnq3pp6zhrsk/CURE_AP02_Basel_sourcedata.zip?dl=0',
							},
							{
								city: 'Munich',
								link: 'https://www.dropbox.com/s/g696remckhgq72d/CURE_AP02_Munich_sourcedata.zip?dl=0',
							},
							{
								city: 'San Sebastian',
								link: 'https://www.dropbox.com/s/6vuyro46zekvflb/CURE_AP02_SanSebastian_sourcedata.zip?dl=0',
							},
							{
								city: 'Vitoria-Gasteiz',
								link: 'https://www.dropbox.com/s/fhb6bw0cr4tsyns/CURE_AP02_Vitoria_sourcedata.zip?dl=0',
							},
						]}
						otherApps={[
							{
								key: 'ap01-surface-dynamics',
								label: 'Local Scale Surface Temperature Dynamics',
							},
							{
								key: 'ap03-urban-heat-emissions',
								label: 'Urban Heat Emissions Monitoring',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App2_UrbanHeatIslandEffect.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App2_UrbanHeatIslandEffect;
