import {
	Story,
	StoryMainPanel,
	StoryMainPanelIntro,
	StoryMainPanelFooter,
	StorySidePanel,
	StorySidePanelCase,
	StorySidePanelFooter,
	StorySidePanelIntro,
	StoryHeadline,
	StorySubheadline,
	StoryTags,
	StoryParagraph,
	StoryLink,
} from '@gisatcz/visat-components';

import '../style.scss';
import Footer from '../../Footer/presentation';

import PropTypes from 'prop-types';
import {getRouter} from '../../../router';
import Link from '../../common/Link';

import App11_ShowcaseA from './App11_ShowcaseA';
import App11_ShowcaseB from './App11_ShowcaseB';
import OrderServiceTool from '../../common/OrderServiceTool';
import StoryFooterSection from '../../common/StoryFooterSection';

const App11_HealthImpact = ({
	activeStep,
	activeApplicationStory,
	activeApplicationStoryKey,
	tags,
	onStepChange,
}) => {
	return (
		<Story
			className={''}
			theme="cure"
			activeStep={activeStep}
			onStepChange={onStepChange}
		>
			<StorySidePanel className={''}>
				<StorySidePanelIntro className={''}>
					<StorySubheadline className={''}>Service Rationale</StorySubheadline>
					<StoryParagraph className={''}>
						Air pollution in cities is a serious environmental problem. The air
						pollution in the urban atmosphere consists of emission and
						transmission sources, each path influenced by different factors. In
						order to understand the magnitude of the negative impacts to
						people’s health and subsequently the economy, this application will
						be able to provide cities with a detailed survey of air pollution
						and its sources to indicate how many people die prematurely due to
						air pollution and associated costs for society. The results from the
						application can guide local and regional decision makers in their
						policymaking with sound economic estimations of costs due to poor
						air quality.
					</StoryParagraph>
					<StorySubheadline className={'cure-Story-InfoBox'}>
						Assessing Health Impacts
					</StorySubheadline>
					<StoryParagraph className={''}>
						This CURE application uses the Economic Valuation of Air pollution
						(EVA) model, which adopts an impact-pathway approach to assess
						health impacts. This model considers acute deaths (from short and
						long term exposure), asthma and bronchitis in adults and children,
						sick days, hospital admissions for respiratory and cardiovascular
						diseases, lung cancer, years of lost life, total count of deaths, as
						well as related health costs of exposure to air pollution. The EVA
						model also includes chemical components important for human health
						such as nitrogen dioxide (NO
						<span className="cure-Story-smallSymbolBottom">2</span>), sulphur
						dioxide (SO
						<span className="cure-Story-smallSymbolBottom">2</span>), ozone (O
						<span className="cure-Story-smallSymbolBottom">3</span>) and fine
						particulate matters (PM
						<span className="cure-Story-smallSymbolBottom">2.5</span>). The
						health effects and related health costs are calculated for the total
						air pollution of each city. The total air pollution includes all
						sources from a city, and all other sources from the corresponding
						country and abroad. The EVA model can estimate how much of the total
						air pollution originates from local sources and how much comes from
						sources outside the city, and thereby the related health costs.
						Moreover, calculations are performed for each type of emission
						source in the city to quantify the contribution of different
						sources. These include sources such as road traffic, industry, power
						plants and residential heating.
					</StoryParagraph>
				</StorySidePanelIntro>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>Health Effects</StorySubheadline>
					<StoryParagraph className={''}>
						Results outlines selected health effects calculated for the total
						air pollution in particular city, considering also individual
						pollutants contributions. Moreover, calculations are performed for
						each type of emission source in the city to quantify the
						contribution of different sources. These include sources such as
						road traffic, industry, power plants and residential heating.
					</StoryParagraph>
					<StorySubheadline className={'cure-Story-InfoBox'}>
						Copenhagen, Denmark
					</StorySubheadline>
					<StoryParagraph className={''}>
						Findings from Copenhagen in 2019 showed that acute premature deaths
						from short term exposure of O
						<span className="cure-Story-smallSymbolBottom">3</span> and PM
						<span className="cure-Story-smallSymbolBottom">2.5</span> and
						chronic premature deaths from long-term exposure of PM
						<span className="cure-Story-smallSymbolBottom">2.5</span> in year
						2019 are mostly due to background contributions from outside the
						city (~90%). The local contributions stem from road traffic (~45%),
						residential combustion (~33%), industry (~13%) and power and waste
						management (~8%).
					</StoryParagraph>
					<StorySubheadline className={'cure-Story-InfoBox'}>
						Sofia, Bulgaria
					</StorySubheadline>
					<StoryParagraph className={''}>
						Findings from Sofia in 2018 showed acute premature deaths from short
						term exposure of O
						<span className="cure-Story-smallSymbolBottom">3</span> and PM
						<span className="cure-Story-smallSymbolBottom">2.5</span> and
						chronic premature deaths from long-term exposure of PM
						<span className="cure-Story-smallSymbolBottom">2.5</span> in year
						2018 are a bit more divided between background (~70%) and local
						(~30%) contributions. The local contributions stem mainly from
						residential combustion (~87%) and road traffic (~12%).
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelCase className={''}>
					<StorySubheadline className={''}>Health Costs</StorySubheadline>
					<StoryParagraph className={''}>
						Results outline related health costs using the total air pollution
						(and different pollutants contribution) in particular city.
						Moreover, calculations are performed for each type of emission
						source in the city to quantify the contribution of different
						sources. These include sources such as road traffic, industry, power
						plants and residential heating. Total cost is expressed in various
						means.
					</StoryParagraph>
					<StorySubheadline className={'cure-Story-InfoBox'}>
						Copenhagen, Denmark
					</StorySubheadline>
					<StoryParagraph className={''}>
						The total external costs in Copenhagen due to all air pollution from
						both Danish and foreign emission sources were around 890 Million EUR
						in 2019.
					</StoryParagraph>
					<StorySubheadline className={'cure-Story-InfoBox'}>
						Sofia, Bulgaria
					</StorySubheadline>
					<StoryParagraph className={''}>
						The total external costs in Sofia due to all air pollution from both
						Bulgarian and foreign emission sources were around 2780 Million EUR
						in 2018 .
					</StoryParagraph>
				</StorySidePanelCase>
				<StorySidePanelFooter className={''}>
					<StorySubheadline className={''}>
						Added value discussion
					</StorySubheadline>
					<StoryParagraph className={''}>
						The results from the application show that the air pollution
						constitutes a serious problem to human health and that the related
						health costs are considerable. The model can find the primary
						responsible activities and the emission sources for cities in
						Europe, and overseas. The results emphasize the importance of
						defining the right questions and actions in the decision-making
						process, since the most of the atmospheric chemical compounds are
						linked via non-linear chemical reactions. The model can answer
						relevant health-related socio-economic questions and can be used for
						ranking environmental stressors. For example, major visible and
						already highly regulated emission sources, such as the power plants
						and the road traffic do not always constitute the most significant
						problems for human health. Less obvious sources can have significant
						impacts on human health, and thus, it is important to make an
						overall screening of all emission sectors and sources in order to
						create a scientific basis for sound political decisions.
					</StoryParagraph>
					<StorySubheadline className={''}>Further resources</StorySubheadline>
					<ul className={'cure-Story-listLinks'}>
						<li>
							<StoryLink className={''}>
								EVA (Economic Valuation of Air Pollution) model -{' '}
								<a href="https://envs.au.dk/en/research-areas/air-pollution-emissions-and-effects/the-monitoring-program/air-pollution-models/eva/">
									description
								</a>
							</StoryLink>
						</li>
						<li>
							<StoryLink className={''}>
								Related{' '}
								<a href="https://envs.au.dk/en/research-areas/air-pollution-emissions-and-effects/the-monitoring-program/air-pollution-models/eva/references-and-publications">
									References and publications
								</a>{' '}
								of the Department of Environmental Science, Aarhus University
							</StoryLink>
						</li>
					</ul>
					<StorySubheadline className={''}>
						Service provider info and other copyrights and credits
					</StorySubheadline>
					<StoryParagraph>
						The model in this CURE Application uses the products from the{' '}
						<Link
							tabIndex={0}
							name={`applicationStoryScreen`}
							updateParams={{key: 'ap07-urban-air-quality'}}
							router={getRouter()}
							paramsFilter={['tags', 'step']}
							recoverParamsFilter={['darkMode']}
						>
							CURE Urban Air Quality Application
						</Link>
						, which utilises raw input data that stems from Copernicus datasets
						and integrates as much as possible into the ATMO-Streets model
						chain. The main outputs of the CURE Health Impact Service are
						estimations of health effects related to air pollution and the
						emission sources as well as economic costs attributed to air
						pollution exposure.
					</StoryParagraph>
					<StoryParagraph className={''}>
						The CURE Health Impact Service uses the Economic Valuation of Air
						pollution (EVA) model developed by Aarhus University.
					</StoryParagraph>
					<OrderServiceTool applicationStoryKey={activeApplicationStoryKey} />
					<StoryParagraph className={''}>
						For more information contact: <b>bgha@cware.eu</b>.
					</StoryParagraph>
					<Footer className="is-AppStory" />
				</StorySidePanelFooter>
			</StorySidePanel>
			<StoryMainPanel className={''}>
				<StoryMainPanelIntro
					className={''}
					backgroundImage={require('../../../assets/img/apps/appIntro11.jpg')}
				>
					<StoryHeadline className={'cure-Story-mainPanel-text'} isIntro>
						{activeApplicationStory?.data?.nameDisplay}
					</StoryHeadline>
					<StorySubheadline className={'cure-Story-mainPanel-text'} isIntro>
						Calculate health effects and related external costs of air pollution
						in your city
					</StorySubheadline>
					<StoryTags
						className={''}
						tagKeys={activeApplicationStory?.data?.tagKeys}
						tags={tags}
					/>
				</StoryMainPanelIntro>
				{/* SHOWCASES */}
				<App11_ShowcaseA componentKey="ap11-health-impact-showcase-a-BarChart" />
				<App11_ShowcaseB componentKey="ap11-health-impact-showcase-b-BarChart" />
				<StoryMainPanelFooter className={''}>
					<StoryFooterSection
						applicationStoryKey={activeApplicationStoryKey}
						downloadOptions={[
							{
								city: 'Copenhagen',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AACTnSb4dRJrGfBqsiqV9yjYa/CURE_AP11_Copenhagen_sourcedata.zip?raw=1',
							},
							{
								city: 'Sofia',
								link: 'https://www.dropbox.com/sh/xivukxo3l2b1loq/AAB0_jtnw8lOP1sVI2fymaq2a/CURE_AP11_Sofia_sourcedata.zip?raw=1',
							},
						]}
						otherApps={[
							{
								key: 'ap01-surface-dynamics',
								label: 'Local Scale Surface Temperature Dynamics',
							},
							{
								key: 'ap07-urban-air-quality',
								label: 'Urban Air Quality',
							},
						]}
					/>
				</StoryMainPanelFooter>
			</StoryMainPanel>
		</Story>
	);
};

App11_HealthImpact.propTypes = {
	onStepChange: PropTypes.func,
	activeStep: PropTypes.number,
	activeApplicationStory: PropTypes.object,
	activeApplicationStoryKey: PropTypes.string,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	tags: PropTypes.array,
};

export default App11_HealthImpact;
