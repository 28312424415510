import {connect} from '@gisatcz/ptr-state';
import Action from '../../state/Action';
import Select from '../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		currentStepRef: Select.components.get(state, 'currentTourStep', 'ref'),
	};
};

const mapDispatchToProps = dispatch => {
	return {
		redirectToHome: () => {
			dispatch(Action.router.updateAppUrl('home'));
		},
		redirectToApplications: () => {
			dispatch(Action.router.updateAppUrl('cure-applications'));
		},
		redirectToApplicationsAndFilter: () => {
			dispatch(
				Action.router.updateAppUrl('cure-applications', {
					tags: '1ee69396-fb52-43e3-b9a2-30c8ce8d10d0,28aa1d23-ac24-4bf3-a605-da62ef1dfd67,5c323672-afae-4718-9f27-79baf663318f',
				})
			);
		},
		redirectToStory: step => {
			dispatch(
				Action.router.updateAppUrl('applicationStoryScreen', {
					step,
					key: 'ap01-surface-dynamics',
				})
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
