import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../../state/Select';

const mapStateToProps = state => {
	const activeScreen = Select.router.getHomepageScreen(state);
	return {
		activeTab: activeScreen,
		user: Select.users.getActive(state),
	};
};

const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
