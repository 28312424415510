import {create as createRouter} from '@gisatcz/ptr-router';

// base styles need to be imported before all components
import '@gisatcz/ptr-core/lib/styles/reset.css';
import '@gisatcz/ptr-core/lib/styles/base.scss';
import Actions from './state/Action';
import {getStoreFromRequest} from './state/router/utils';
import './styles/index.scss';
import analytics from './utils/analytics';

let router;

function createRoutes() {
	return {
		'': {
			name: 'home',
			handler: request => {
				const store = getStoreFromRequest(request);
				const dispatch = store.dispatch;
				dispatch(Actions.router.updateAppUrl('about'));
				// todo set some default page??
				// request.context.next();
				// console.log('xxx home');
			},
		},
		'/about': {
			name: 'about',
			handler: () => {
				analytics.page({
					path: '/about',
					title: 'about',
				});
			},
		},
		'/city-grand-challenges': {
			name: 'city-grand-challenges',
			handler: () => {
				analytics.page({
					path: '/city-grand-challenges',
					title: 'city-grand-challenges',
				});
			},
		},
		'/cure-applications': {
			name: 'cure-applications',
			handler: request => {
				request.context.next();
			},
			children: {
				'/:key': {
					name: 'applicationStoryScreen',
					handler: request => {
						analytics.page({
							path: '/cure-applications',
							title: request?.pathParams?.key,
						});
					},
				},
			},
		},
		'/city-demonstrations': {
			name: 'city-demonstrations',
			handler: () => {
				analytics.page({
					path: '/city-demonstrations',
					title: 'city-demonstrations',
				});
			},
		},
		'/cure-services': {
			name: 'cure-services',
			handler: () => {
				analytics.page({
					path: '/cure-services',
					title: 'cure-services',
				});
			},
		},
	};
}

/**
 * Creates router instance that can be used to manipulate urls.
 *
 * App handler updates store with current page and it's up to views to react to the change.
 * In case of url change, redux selector possibly retrieves different data and passes them
 * into some the component.
 *
 */
const initRouter = (absPath, currentUrl, Store, isPreloaded, navHandler) => {
	router = createRouter({
		rootUrl: absPath,
		currentUrl,
		routes: createRoutes(),
		navHandler,
		store: Store,
		generateUrlsOptions: {
			stringifyQueryParams: params => new URLSearchParams(params).toString(),
		},
	});

	return router;
};

const getRouter = () => router;

export {getRouter, initRouter, createRoutes};
