import PropTypes from 'prop-types';
import RasterMapTooltip from './RasterMapTooltip';
import VectorMapTooltip from './VectorMapTooltip';
import App5_ShowcaseA_VectorTooltip from '../../../stories/App5_UrbanFloodRisk/App5_ShowcaseA/tooltips/App5_ShowcaseA_VectorTooltip';

const MapTooltip = props => {
	// TODO pass layerState from onHover callbacks
	// TODO in vectorLayer - layerKey is layer in fact, fix it in ptr-maps
	const rasterLayerKey = props.event?.layer?.props?.layerKey;
	const vectorLayerKey = props?.layerKey?.props?.layerKey;
	const layerKey = rasterLayerKey || vectorLayerKey;
	switch (layerKey) {
		case 'ap05-urban-flood-risk-showcase-a-2-polygons':
		case 'ap05-urban-flood-risk-showcase-b-2-polygons':
			return (
				<VectorMapTooltip {...props}>
					<App5_ShowcaseA_VectorTooltip />
				</VectorMapTooltip>
			);
		default:
			if (rasterLayerKey) {
				return <RasterMapTooltip {...props}>{props.children}</RasterMapTooltip>;
			} else if (vectorLayerKey) {
				const restProps = {...props};
				delete restProps.children;
				return <VectorMapTooltip {...restProps} />;
			} else {
				return null;
			}
	}
};

MapTooltip.propTypes = {
	children: PropTypes.node,
	event: PropTypes.object,
	layerKey: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default MapTooltip;
