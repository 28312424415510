import classnames from 'classnames';
import PropTypes from 'prop-types';

const AppPreviewDescription = ({className, children}) => {
	const classes = classnames('cure-AppPreviewDescription', {}, className);

	return <p className={classes}>{children}</p>;
};

AppPreviewDescription.propTypes = {
	className: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default AppPreviewDescription;
