import PropTypes from 'prop-types';
import {useEffect} from 'react';
import AppStoryHeaderSelect from '../../../ApplicationStoryScreen/ApplicationStoryHeader/ApplicationStoryNav/AppStoryHeaderSelect';

import './style.scss';

const TagsSelect = ({
	tags = [],
	activeTagKey,
	loadKeys,
	optionsKeys,
	onChange,
	title,
}) => {
	useEffect(() => {
		if (typeof loadKeys === 'function') {
			loadKeys(optionsKeys);
		}
	}, []);

	const options = tags.map(t => ({
		value: t.key,
		label: t.data.nameDisplay,
	}));

	let value = null;
	if (activeTagKey) {
		const tag = tags.find(t => t.key === activeTagKey);
		if (tag) {
			value = {
				value: tag.key,
				label: tag.data.nameDisplay,
			};
		}
	}

	return (
		<div className={'cure-TagSelect'}>
			<span>{title}</span>
			<AppStoryHeaderSelect
				options={options}
				onChange={onChange}
				value={value}
			/>
		</div>
	);
};

TagsSelect.propTypes = {
	tagKey: PropTypes.string,
	tags: PropTypes.array,
	optionsKeys: PropTypes.array,
	activeTagKey: PropTypes.string,
	onActiveFilterChange: PropTypes.func,
	loadKeys: PropTypes.func,
	onChange: PropTypes.func,
	title: PropTypes.string,
};

export default TagsSelect;
