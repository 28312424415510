import {createRoot} from 'react-dom/client';
import {Provider} from '@gisatcz/ptr-state';
import createStore from './state/Store';
import {AppWrapper} from './app';
import Tour from './components/Tour';
const {store} = createStore();

const ConnectedApp = () => (
	<Provider store={store}>
		<Tour>
			<AppWrapper />
		</Tour>
	</Provider>
);

function renderApp() {
	const rootEl = document.getElementById('root');
	const root = createRoot(rootEl);
	root.render(<ConnectedApp />);
}

renderApp();
