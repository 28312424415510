import PropTypes from 'prop-types';
import {Toggle} from '@gisatcz/visat-components';
import LayerLabelTool from '../LayerLabelTool';
import './style.scss';

const TooltipControl = ({options, onTooltipVisibilityChange}) => {
	return (
		<LayerLabelTool
			title="Enable tooltips"
			key="tootips"
			onClick={() =>
				onTooltipVisibilityChange('hoverable', !options?.hoverable)
			}
		>
			<Toggle notInteractive on={options?.hoverable} />
		</LayerLabelTool>
	);
};

TooltipControl.propTypes = {
	layerKey: PropTypes.string,
	mapKey: PropTypes.string,
	onTooltipVisibilityChange: PropTypes.func,
	options: PropTypes.object,
};

export default TooltipControl;
