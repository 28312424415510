import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Actions from '../../../../state/Action';

import Presentation from './presentation';
const showCaseKey = 'App3_ShowcaseA';

const mapStateToProps = (state, ownProps) => {
	const storyKey = Select.router.getStory(state);
	const storyConfig =
		Select.cure.applicationStories.getConfigByApplicationStoryKey(
			state,
			storyKey
		);
	const timelineKey = storyConfig?.showcases?.[showCaseKey]?.timeline?.key;

	const timelineLayers = timelineKey
		? Select.cure.applicationStories.getTimelineLayers(state, timelineKey)
		: [];
	const periodLimit = Select.cure.applicationStories.getTimelinePeriod(
		state,
		timelineKey
	);
	const activeMapKey = Select.maps.getMapSetActiveMapKey(
		state,
		ownProps.mapSetKey
	);
	const activePlaceKey = Select.places.getActiveKey(state);

	const rasterLegendData = Select.cure.applicationStories.getRasterLegend(
		state,
		showCaseKey
	);

	return {
		timelineLayers,
		activeMapKey,
		activePlaceKey,
		periodLimit,
		rasterLegendData,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onActivePlaceKeyChange: () => {
			dispatch(Actions.data.spatialRelations.refreshUses()).then(() => {
				//setup timeline layers
				dispatch(Actions.cure.mapTimelineRefreshUses()).then(() => {
					dispatch(Actions.cure.setupTimeline(showCaseKey, ownProps.mapSetKey));
				});
			});
		},
		onLayerClick: (
			timelineLayerPeriodItem,
			timelineLayer,
			mapKey,
			layerRow,
			layers
		) => {
			dispatch(
				Actions.cure.applicationStories.toggleTimelineLayer(
					timelineLayerPeriodItem,
					timelineLayer,
					mapKey,
					layerRow,
					layers
				)
			);
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
