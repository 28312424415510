import PropTypes from 'prop-types';
import ChartTooltip from '../../../../../common/ChartTooltip';
import {zones, uaClasses} from '../../enumerations';
import './style.scss';

const App5_ShowcaseB_ChartTooltip = ({formattedValue, id, data}) => {
	return (
		<ChartTooltip>
			<div className="App5_ShowcaseA_ChartTooltip">
				<div>
					<b>{zones[id]}</b>
				</div>
				<div>
					<b>UA class: </b> {data?.code}, {uaClasses[data?.code]}
				</div>
				<div>
					<b>Area: </b> {formattedValue}
				</div>
			</div>
		</ChartTooltip>
	);
};

App5_ShowcaseB_ChartTooltip.propTypes = {
	data: PropTypes.object,
	formattedValue: PropTypes.string,
	id: PropTypes.string,
	zone: PropTypes.string,
};

export default App5_ShowcaseB_ChartTooltip;
