import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
	ExpandableLabel,
	ExpandableLabelBody,
	ExpandableLabelHeader,
} from '@gisatcz/visat-components';
import LayerLabelHeader from './LayerLabelHeader';
import LegendControl from './LegendControl';
import OpacityControl from './OpacityControl';
import TooltipControl from './TooltipControl';

import './style.scss';

function renderHeader(title, info, layerState, mapKey) {
	return (
		<ExpandableLabelHeader wholeInteractive>
			<LayerLabelHeader
				title={title}
				info={info}
				layerState={layerState}
				mapKey={mapKey}
			/>
		</ExpandableLabelHeader>
	);
}

function renderTools(tools, mapKey, layerState) {
	const components = [];
	if (tools?.opacity) {
		components.push(
			<OpacityControl mapKey={mapKey} layerKey={layerState.key} key="opacity" />
		);
	}

	if (tools?.legend) {
		components.push(
			<LegendControl mapKey={mapKey} layerKey={layerState.key} key="legend" />
		);
	}

	if (tools?.tooltip) {
		components.push(
			<TooltipControl mapKey={mapKey} layerKey={layerState.key} key="tooltip" />
		);
	}

	return components;
}

const LayerLabel = ({
	layerConfiguration,
	layerState,
	layerTemplate,
	mapKey,
	zIndex,
	info,
	onMount,
	onUnmount,
}) => {
	useEffect(() => {
		onMount(layerState.layerTemplateKey);
		return onUnmount();
	}, []);

	if (layerConfiguration?.label) {
		const title =
			layerState?.options?.name || layerTemplate?.data?.nameDisplay || 'Layer';
		const tools = layerConfiguration?.label?.tools;

		if (tools) {
			return (
				<ExpandableLabel floating className="cure-LayerLabel" zIndex={zIndex}>
					{renderHeader(title, info, layerState, mapKey)}
					<ExpandableLabelBody>
						<div className="cure-LayerLabelBody">
							<div>{renderTools(tools, mapKey, layerState)}</div>
						</div>
					</ExpandableLabelBody>
				</ExpandableLabel>
			);
		} else {
			return (
				<ExpandableLabel floating className="cure-LayerLabel" zIndex={zIndex}>
					{renderHeader(title, info, layerState, mapKey)}
				</ExpandableLabel>
			);
		}
	} else {
		return <></>;
	}
};

LayerLabel.propTypes = {
	layerConfiguration: PropTypes.object,
	layerState: PropTypes.object,
	layerTemplate: PropTypes.object,
	mapKey: PropTypes.string,
	period: PropTypes.object,
	zIndex: PropTypes.number,
	info: PropTypes.array,
	onRemove: PropTypes.func,
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
};

export default LayerLabel;
