import {Modal, IconTool} from '@gisatcz/visat-components';
import PropTypes from 'prop-types';
import {useState} from 'react';
import classnames from 'classnames';
import LogInForm from './LoginForm';
import SingUpForm from './SingUpForm';
import './style.scss';

Modal.setAppElement('#root');
const ModalWindow = Modal.default;

const LoginModal = ({isOpen, onClose, user = {}, logoutUser}) => {
	const [createUser, setCreateUser] = useState(false);
	const titleClasses = classnames('cure-LoginForm-title', {
		'has-icon': createUser,
	});

	return (
		<ModalWindow
			isOpen={isOpen}
			onClose={() => {
				setCreateUser(false);
				onClose();
			}}
			className="cure-LoginModal"
		>
			<div className="cure-LoginForm">
				<div className={titleClasses}>
					{createUser ? (
						<h3>
							<IconTool
								icon={'chevron-left'}
								onClick={() => {
									setCreateUser(false);
								}}
							/>
							Create new user
						</h3>
					) : (
						<h3>Log in</h3>
					)}
				</div>
				{createUser ? <SingUpForm componentKey={'cureSingUpForm'} /> : null}
				{!createUser && user?.data?.name !== 'guest' ? (
					<>
						<div>User is successfully logged in as {user?.data?.email}</div>
						<button
							className={'cure-login-btn'}
							onClick={() => logoutUser().then(onClose)}
						>
							{'Logout'}
						</button>
					</>
				) : null}
				{user?.data?.name === 'guest' && !createUser ? (
					<div>
						<LogInForm
							componentKey={'cureLogInForm'}
							onCreateNewUserClick={() => {
								setCreateUser(true);
							}}
							onCloseModal={onClose}
						/>
					</div>
				) : null}
			</div>
		</ModalWindow>
	);
};

LoginModal.propTypes = {
	user: PropTypes.object,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	logoutUser: PropTypes.func,
};

export default LoginModal;
