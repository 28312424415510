import PropTypes from 'prop-types';
import {useEffect} from 'react';
import ParametersSelects from '../../../common/ParametersSelects';
import LayerTemplateSelect from '../../../common/LayerTemplateSelect';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import RasterMapTooltip from '../../../common/maps/MapTooltip/RasterMapTooltip';
import GetFeatureInfoTooltipContent from './GetFeatureInfoTooltipContent';
import StyleBasedLegendByKey from './StyleBasedLegendByKey';
import Overlay from './Overlay';
import ActiveTag from '../../../common/ActiveTag';

import './style.scss';
import Showcase from '../../../common/Showcase';
import Layer from '../common/Layer';
import ShowcaseNoDataInfo from '../../../common/Showcase/ShowcaseNoDataInfo';

const Tooltip = props => (
	<RasterMapTooltip {...props}>
		<GetFeatureInfoTooltipContent />
	</RasterMapTooltip>
);

const layerTemplates = [
	'509c70ed-9e51-4b25-8a8c-50694aa4eef6',
	'd9b1acd9-32e0-4885-b49d-f31ce8136f4b',
	'45f2c440-fc5b-4382-b507-a334a3458a45',
	'd9e22e59-31d5-4f1c-935b-ea8a64bd2bbd',
	'ed9a1f28-e2df-4034-af6b-b56f2060f15b',
	'8ae02aa0-bc7b-4126-8eb0-f5feeb2951bf',
];

const App8_ShowcaseA = ({
	onActiveFilterChange,
	mapSetKey,
	activeMapKey,
	onUnmount,
	tagsComponentKey,
	layerTemplatesComponentKey,
	displayOverlay,
	wgbtTagKey,
	layerTemplateKeysInMaps,
	noDataForCurrentPlace,
}) => {
	useEffect(() => {
		if (typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);
	return (
		<Showcase className="cure-App8_ShowcaseA">
			{noDataForCurrentPlace ? (
				<ShowcaseNoDataInfo>
					This showcase has no data for selected city.
				</ShowcaseNoDataInfo>
			) : (
				<>
					<div className="top-bar">
						<ParametersSelects
							componentKey={tagsComponentKey}
							activeMapKey={activeMapKey}
							onActiveFilterChange={() =>
								onActiveFilterChange(
									tagsComponentKey,
									layerTemplatesComponentKey,
									activeMapKey,
									wgbtTagKey
								)
							}
						>
							<LayerTemplateSelect
								componentKey={layerTemplatesComponentKey}
								activeMapKey={activeMapKey}
								title={'Adaptation Scenario'}
								onActiveFilterChange={layerTemplateKey =>
									onActiveFilterChange(
										tagsComponentKey,
										layerTemplatesComponentKey,
										activeMapKey,
										wgbtTagKey,
										layerTemplateKey
									)
								}
							/>
						</ParametersSelects>
					</div>
					{/* CaseSelect */}
					{/* LayerTemplateSelect */}
					<MapSetWrapper
						MapTooltip={Tooltip}
						mapSetKey={mapSetKey}
						displayOverlay={displayOverlay}
						Overlay={Overlay}
						config={{
							mapSet: {
								highlightActiveMap: true,
								controls: {
									horizontal: true,
								},
							},
							layers: layerTemplateKeysInMaps.map((ltKey, i) => ({
								layerTemplateKey: ltKey,
								info: [
									<Layer key={'layer'} layerTemplateKey={ltKey} />,
									<ActiveTag
										componentKey={tagsComponentKey}
										key={`ActiveTag_${i}`}
										parentTags={['c049ff3b-705c-427b-b406-52a773bb453f']}
									/>,
								],
							})),
						}}
						componentsByLayer={[
							...layerTemplates.map(lt => ({
								layerTemplateKey: lt,
								legend: {
									component: StyleBasedLegendByKey,
									props: {
										noData: false,
										applicationStoryKey: 'ap08-urban-thermal-comfort',
										showcaseKey: 'App8_ShowcaseA',
										caseKey: 'de966aaa-84cb-4627-9841-88fd38500c67',
										title: 'Temperature',
										unit: '°C',
									},
								},
							})),
						]}
					/>
				</>
			)}
		</Showcase>
	);
};

App8_ShowcaseA.propTypes = {
	onActiveFilterChange: PropTypes.func,
	mapSetKey: PropTypes.string,
	activeMapKey: PropTypes.string,
	onUnmount: PropTypes.func,
	tagsComponentKey: PropTypes.string,
	layerTemplatesComponentKey: PropTypes.string,
	rasterLegendData: PropTypes.object,
	displayOverlay: PropTypes.object,
	wgbtTagKey: PropTypes.string,
	layerTemplateKeysInMaps: PropTypes.array,
	noDataForCurrentPlace: PropTypes.bool,
};

export default App8_ShowcaseA;
