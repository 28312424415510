import PropTypes from 'prop-types';

import Showcase from '../../../common/Showcase';
import ShowcaseHead from '../../../common/ShowcaseHead';
import InfoWindow from '../../../common/InfoWindow';
import Table from '../../../common/Table';

import './style.scss';

import data from './data/data.json';

const App8_ShowcaseB = ({activePlace}) => {
	const infoWindow = (
		<div className="cure-App8_ShowcaseB-infoContent">
			<h3 className="cure-App8_ShowcaseB-infoTitle">Table description</h3>
			<p className="cure-App8_ShowcaseB-infoDescription">
				This table shows the impact of several adaptation options on the average
				WBGT values on a typical hot summer day in the urban areas of the city
				(based on Urban Atlas classification). Also a comparison to the rural
				areas is given.
			</p>
		</div>
	);
	return (
		<Showcase>
			<ShowcaseHead
				infoWindow={<InfoWindow content={infoWindow} />}
				customLabel={
					<h2 className="cure-App8_ShowcaseB-headlineTitle">
						Overview statistics
					</h2>
				}
			/>
			<div className="cure-App8_ShowcaseB-Table">
				<Table
					data={data.data?.[activePlace?.data?.nameInternal]}
					options={data.columns}
					responsive
					highlightOnHover
					striped
					fixedHeader
				/>
			</div>
		</Showcase>
	);
};

App8_ShowcaseB.propTypes = {
	activePlace: PropTypes.object,
};

export default App8_ShowcaseB;
