import data from './data/data.json';
import types from './data/types.json';
import impacts from './data/impacts.json';

import PropTypes from 'prop-types';

import BarChart from '../../../common/BarChart';
import ShowcaseHead from '../../../common/ShowcaseHead';
import InfoWindow from '../../../common/InfoWindow';
import Showcase from '../../../common/Showcase';

import './style.scss';

const App11_ShowcaseA = ({activePlace, activeKeys, componentKey}) => {
	const infoWindow = (
		<div className="cure-App11_ShowcaseA-infoContent">
			<h3 className="cure-App11_ShowcaseA-infoTitle">Data description</h3>
			{activePlace?.data?.nameInternal === 'sofia' ? (
				<ul>
					<li>
						Copernicus data describes the runs with the emission data from
						Copernicus downscaled with publicly available data, like Open Street
						Map and population distribution.
					</li>
					<li>
						Local data describes the runs where the downscaled Copernicus
						emission data have been supplemented with local emission data.
					</li>
					<li>
						Background denotes the air pollution contribution from sources
						outside the Sofia area.
					</li>
					<li>
						Local contribution consists of the local pollution sources from
						“traffic”, “residential combustion”, “industry” and “public power”,
						all located within the Sofia area.
					</li>
					<li>
						In total background, the model is run for all the sources together,
						and a combined signal is obtained in the atmospheric concentrations.
						When you separate the emission sources and run the model for one
						sector at a time, the concentrations will not be the same because
						the chemistry in the atmosphere is not linear, and the background
						chemistry has an influence on the local transformations.
					</li>
				</ul>
			) : (
				<ul>
					<li>
						Copernicus data describes the runs with the emission data from
						Copernicus downscaled with publicly available data, like Open Street
						Map and population distribution.
					</li>
					<li>
						Local data describes the runs where the downscaled Copernicus
						emission data have been supplemented with local emission data.
					</li>
					<li>
						Background denotes the air pollution contribution from sources
						outside the Copenhagen area.
					</li>
					<li>
						Local contribution consists of the local pollution sources from
						“traffic”, “residential combustion”, “industry” and “public power”,
						all located within the Copenhagen area.
					</li>
					<li>
						In total background, the model is run for all the sources together,
						and a combined signal is obtained in the atmospheric concentrations.
						When you separate the emission sources and run the model for one
						sector at a time, the concentrations will not be the same because
						the chemistry in the atmosphere is not linear, and the background
						chemistry has an influence on the local transformations.
					</li>
				</ul>
			)}
		</div>
	);

	const city = {
		data: [
			{
				key: activePlace?.key,
				nameInternal: activePlace?.data?.nameInternal,
				nameDisplay: activePlace?.data?.nameDisplay,
			},
		],
	};
	const dataProperties = [city, types, impacts];
	const yMetadata = activeKeys?.['key2'];
	return (
		<Showcase>
			<ShowcaseHead
				data={data}
				dataProperties={dataProperties}
				componentKey={componentKey}
				infoWindow={<InfoWindow content={infoWindow} />}
				noSelectIndex={[0]}
				reverseSelectsOrder={true}
				customLabel={
					<div className="cure-App11_ShowcaseA-headline">
						<h1 className="cure-App11_ShowcaseA-mainHeading">
							Health Effects by Pollution Source Contribution
						</h1>
						<h2 className="cure-App11_ShowcaseA-subHeading">
							{activeKeys?.['key' + (dataProperties.length - 1)]?.title
								? activeKeys?.['key' + (dataProperties.length - 1)]?.title
								: activeKeys?.['key' + (dataProperties.length - 1)]
										?.nameDisplay}
						</h2>
					</div>
				}
			/>
			<BarChart
				data={data}
				dataProperties={dataProperties}
				height="calc(100% - 8rem)"
				componentKey={componentKey}
				groupMode="grouped"
				padding={0.01}
				innerPadding={5}
				labelX={'Pollution Source Contribution'}
				labelY={
					yMetadata
						? `${yMetadata?.nameDisplay} ${
								yMetadata?.unit ? `[${yMetadata?.unit}]` : ''
						  }`
						: 'Value'
				}
			/>
		</Showcase>
	);
};

App11_ShowcaseA.propTypes = {
	activePlace: PropTypes.object,
	activeKeys: PropTypes.object,
	componentKey: PropTypes.string,
};

export default App11_ShowcaseA;
