import {connect} from '@gisatcz/ptr-state';
import Select from '../../../../state/Select';
import Actions from '../../../../state/Action';

import Presentation from './presentation';
import {utils} from '@gisatcz/ptr-utils';

const mapStateToProps = (state, ownProps) => {
	return {
		activeMapKey: Select.maps.getMapSetActiveMapKey(state, ownProps.mapSetKey),
	};
};

const mapDispatchToProps = () => {
	const componentId = `TagsParamsSelect_${utils.uuid()}`;
	return dispatch => {
		return {
			onActiveFilterChange: (componentKey, mapKey) => {
				dispatch(
					Actions.cure.applicationStories.ap04.onFilterChange(
						componentKey,
						mapKey,
						componentId
					)
				);
			},
			onUnmount: () => {
				dispatch(Actions.cases.useIndexedClear(componentId));
				dispatch(Actions.periods.useIndexedClear(componentId));
			},
		};
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
