import ApplicationsSelectors from './applicationStories/selectors';
import ProductFilterSelectors from './productFilter/selectors';
import cities from '../../data/cities';

const getCitiesForProcessing = () => {
	return cities;
};

export default {
	applicationStories: ApplicationsSelectors,
	productFilter: ProductFilterSelectors,

	getCitiesForProcessing,
};
