import {isMatch as _isMatch} from 'lodash';
import {Action as CommonAction, commonSelectors} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Select from '../../Select';
import Action from '../../Action';
import applicationSelectors from './selectors';
import {forEachPeriodAndLayerInLayerRow} from './helpers';
import {placesFilter} from '../../../constants/app';
import ap01 from './ap01/actions';
import ap04 from './ap04/actions';
import ap07 from './ap07/actions';
import ap08 from './ap08/actions';

/**
 * Use scope and view based on story name
 * @param storyKey {string}
 * @param componentId {string}
 */
function use(storyKey, componentId) {
	return (dispatch, getState) => {
		const storyConfig = applicationSelectors.getConfigByApplicationStoryKey(
			getState(),
			storyKey
		);

		if (storyConfig?.scopeKey) {
			dispatch(
				CommonAction.scopes.useKeys([storyConfig.scopeKey], componentId)
			);
		}

		if (storyConfig?.viewKey) {
			dispatch(
				CommonAction.views.applyAndSetActive(storyConfig.viewKey, CommonAction)
			);
		}
	};
}

/**
 * Remove all map layers  defined in timeline layerRow
 * @param {string} mapKey
 * @param {Object} layerRow LayerRow definition
 * @returns
 */
const removeLayerRow = (mapKey, layerRow) => {
	return (dispatch, getState) => {
		const state = getState();
		const mapLayers = Select.maps.getLayersStateByMapKey(state, mapKey);

		const removeLayerIfInMap = (timelineLayer, timelineMapLayerDefinition) => {
			const mapLayer = mapLayers?.find(l =>
				_isMatch(l, timelineMapLayerDefinition)
			);
			const isInMap = !!mapLayer;

			if (isInMap) {
				dispatch(
					Action.maps.removeMapLayersByFilter(
						mapKey,
						timelineMapLayerDefinition
					)
				);
			}
		};

		forEachPeriodAndLayerInLayerRow(state, layerRow, removeLayerIfInMap);
	};
};

/**
 * Calculate new map zIndex on the base of wanted "expectedLayerZIndex" which respects layers in map and theirs "expectedLayerZIndex".
 * Compare map layers and theirs "expectedLayerZIndex" with wanted "expectedLayerZIndex".
 * @param {Object} state
 * @param {Array} mapLayers Map Layers definitions
 * @param {Array} timelineLayers Timeline Layers definitions
 * @param {Number} expectedLayerZIndex ZIndex value defined external of map. This value define wanted zIndex due to rest of layers in timeline.
 * @returns
 */
function getMapZIndexForLayer(
	state,
	mapLayers = [],
	timelineLayers = [],
	expectedLayerZIndex
) {
	const indexes = new Set();

	const findMapLayerIndexes = (timelineLayer, timelineMapLayerDefinition) => {
		const mapLayer = mapLayers?.find(l =>
			_isMatch(l, timelineMapLayerDefinition)
		);
		const isInMap = !!mapLayer;

		if (isInMap) {
			indexes.add(timelineLayer?.mapZIndex);
		}
	};

	timelineLayers.forEach(timelineLayerRow => {
		forEachPeriodAndLayerInLayerRow(
			state,
			timelineLayerRow,
			findMapLayerIndexes
		);
	});

	const lowerIndexesArr = [...indexes]
		.sort((a, b) => a - b)
		.filter(i => i < expectedLayerZIndex);
	if (lowerIndexesArr.length === 0) {
		return 0;
	} else {
		return lowerIndexesArr.length;
	}
}

/**
 * Change layer state in map state depends on defined behavior on layerRow.
 * Each layerRow in MapTimeline could have defined how or if layer state in Map should be controlled.
 * If layerRow.controlMapState is false, then nothing happens on click.
 * If layerRow.controlMapState is true, then layer visibility in map is changing on each click.
 * If layerRow.controlMapState is 'toggle', only one layer in row could be in Map. So other layers are removed.
 *
 * Separate property "layerRow.allowNonActiveLayer" define if it is possible to remove all layers from row.
 *
 * @param {Object} timelineLayerPeriodItem Timeline period with data about origin period.
 * @param {Object} timelineLayer Timeline Layer definition
 * @param {string} mapKey
 * @param {Object} layerRow MapTimeline row definition
 * @param {Array} layers Timeline Layers definitions
 * @returns
 */
const toggleTimelineLayer = (
	timelineLayerPeriodItem,
	timelineLayer,
	mapKey,
	layerRow,
	layers
) => {
	return (dispatch, getState) => {
		const state = getState();
		const activeKeys = commonSelectors.getAllActiveKeys(state);
		const period = {
			...(timelineLayerPeriodItem?.origin?.originPeriod
				? {key: timelineLayerPeriodItem?.origin?.originPeriod?.key}
				: {}),
		};
		const timelineMapLayerDefinition =
			Select.timeline.mapTimeline.getTimelineMapLayerPeriodDefinition(
				timelineLayer?.layerState,
				period?.key,
				activeKeys
			);
		const mapLayer =
			Select.timeline.mapTimeline.getMapLayerByTimelineLayerAndPeriod(
				mapKey,
				timelineLayer,
				period
			);
		const isInMap = !!mapLayer;
		const mapLayers = Select.maps.getLayersStateByMapKey(state, mapKey);

		const expectedLayerZIndex = timelineLayer.mapZIndex;

		const newLayerZIndex = getMapZIndexForLayer(
			state,
			mapLayers,
			layers,
			expectedLayerZIndex
		);
		if (isInMap) {
			// click on active layer
			const preventRemoveLayer = layerRow.allowNonActiveLayer === false;
			if (!preventRemoveLayer) {
				dispatch(removeLayerRow(mapKey, layerRow));
			}
		} else {
			// click on non-active layer
			const opacity = mapLayers?.[0].opacity;
			const options = mapLayers?.[0].options;
			// remove all layers from all rows
			layers.forEach(layerRow => {
				dispatch(removeLayerRow(mapKey, layerRow));
			});
			dispatch(
				Action.maps.addMapLayerToIndex(
					mapKey,
					{
						...timelineLayer.layerState,
						...timelineMapLayerDefinition,
						...(opacity ? {opacity} : {}),
						...(options ? {options} : {}),
						key: utils.uuid(),
					},
					newLayerZIndex
				)
			);
		}
	};
};

/**
 * Set active place for current story, if active place is not among story's available places
 */
function setActivePlaceForActiveStory() {
	return (dispatch, getState) => {
		const state = getState();
		const activePlaceKey = Select.places.getActiveKey(state);
		const placesForStory =
			Select.cure.applicationStories.getAvailablePlacesForActiveApplicationStory(
				state
			);

		if (
			placesForStory?.length &&
			(!activePlaceKey || !placesForStory.includes(activePlaceKey))
		) {
			dispatch(CommonAction.places.setActiveKey(placesForStory[0]));
		}
	};
}

/**
 * Set active story by scope key
 */
function setActiveStoryByScopeKey(scopeKey) {
	return (dispatch, getState) => {
		const state = getState();
		const applicationStoryKey =
			Select.cure.applicationStories.getApplicationStoryKeyByScopeKey(
				state,
				scopeKey
			);

		if (applicationStoryKey) {
			dispatch(
				Action.router.updateAppUrl(
					'applicationStoryScreen',
					{
						key: applicationStoryKey,
					},
					['step']
				)
			);
		}
	};
}

/**
 * Use places
 * @param componentId {string}
 */
function usePlacesForActiveStory(componentId) {
	return dispatch => {
		dispatch(
			CommonAction.places.useIndexed(
				placesFilter.filterByActive,
				placesFilter.filter,
				placesFilter.order,
				placesFilter.start,
				placesFilter.length,
				componentId
			)
		).then(() => {
			// TODO if active place is not among story's available places, then set story available place as active
		});
	};
}

export default {
	ap01,
	ap04,
	ap07,
	ap08,

	setActivePlaceForActiveStory,
	setActiveStoryByScopeKey,
	use,
	usePlacesForActiveStory,
	toggleTimelineLayer,
};
