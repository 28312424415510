const StoryAbout = () => (
	<div>
		<h4>Application Storyline Page – Story and Showcases</h4>
		<p>
			Left part (or bottom part - subject to platform/screen resolution used)
			tells each application story. Scroll or use the navigation through the
			storyline. A standardized content includes Service Rationale, Showcases,
			Added Value Discussion, links to Further Resources and Copyrights, Credits
			and Service Provider information.
		</p>
		<p>
			Don&apos;t miss application showcase(s) and play with interactive
			demonstration(s).
		</p>
	</div>
);

export default StoryAbout;
