import {connect} from '@gisatcz/ptr-state';
import Presentation from './presentation';
import Select from '../../../state/Select';

const mapStateToProps = state => {
	return {
		isDark: Select.router.getDarkModeActive(state),
	};
};
const mapDispatchToProps = () => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentation);
