import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

const Showcase = ({className, children, dataTour}) => {
	const classes = classnames('cure-Showcase', {}, className);

	return (
		<div className={classes} data-tour={dataTour}>
			{children}
		</div>
	);
};

Showcase.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node,
	dataTour: PropTypes.string,
};

export default Showcase;
