import {connect} from '@gisatcz/ptr-state';
import {utils} from '@gisatcz/ptr-utils';
import Action from '../../../../../state/Action';
import Select from '../../../../../state/Select';
import Presentation from './presentation';

const mapStateToProps = state => {
	return {
		activePlaceKey: Select.places.getActiveKey(state),
		places: Select.cure.applicationStories.getPlacesForActiveStory(state),
	};
};

const mapDispatchToPropsFactory = (dispatch, ownProps) => {
	const componentId = `PlaceSelect_${utils.uuid()}`;
	return () => {
		return {
			onActivePlaceChange: activePlaceKey => {
				setTimeout(() => {
					dispatch(Action.places.setActiveKey(activePlaceKey));
					dispatch(
						Action.cure.setMapViewFromPlace(activePlaceKey, ownProps.mapSetKey)
					);
				}, 200);
			},
			onMount: () => {
				dispatch(
					Action.cure.applicationStories.usePlacesForActiveStory(componentId)
				);
			},
			onUnmount: () => {},
		};
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToPropsFactory
)(Presentation);
