import PropTypes from 'prop-types';
import './style.scss';

const MapLegendTitle = ({unit, children}) => {
	return (
		<div className="cure-MapLegendTitle">
			<span className="cure-MapLegendTitle-name">{children}</span>
			{unit ? <span className="cure-MapLegendTitle-unit">[{unit}]</span> : null}
		</div>
	);
};

MapLegendTitle.propTypes = {
	children: PropTypes.node,
	unit: PropTypes.string,
};

export default MapLegendTitle;
