import PropTypes from 'prop-types';
import {useEffect} from 'react';
import ParametersSelects from '../../../common/ParametersSelects';
import MapSetWrapper from '../../../common/maps/MapSetWrapper';
import RasterMapTooltip from '../../../common/maps/MapTooltip/RasterMapTooltip';
import GetFeatureInfoTooltipContent from './GetFeatureInfoTooltipContent';
import ActiveTag from '../../../common/ActiveTag';
import StyleBasedLegendByKey from './StyleBasedLegendByKey';

import './style.scss';

const Tooltip = props => (
	<RasterMapTooltip {...props}>
		<GetFeatureInfoTooltipContent />
	</RasterMapTooltip>
);

const App4_ShowcaseA = ({
	onActiveFilterChange,
	mapSetKey,
	activeMapKey,
	onUnmount,
}) => {
	useEffect(() => {
		if (typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);
	const componentKey = 'ap04-tags-parameters';
	return (
		<div className="cure-App4_ShowcaseA">
			<ParametersSelects
				componentKey={componentKey}
				activeMapKey={activeMapKey}
				onActiveFilterChange={onActiveFilterChange}
			/>
			<MapSetWrapper
				MapTooltip={Tooltip}
				mapSetKey={mapSetKey}
				config={{
					mapSet: {
						highlightActiveMap: true,
						controls: {
							horizontal: true,
						},
					},
					layers: [
						{
							layerTemplateKey: '09a144da-8d4e-4a2d-93bb-31d7aefd7fc8',
							info: [
								<ActiveTag
									componentKey={componentKey}
									key={'ActiveTag_1'}
									parentTags={[
										'a3b11f8e-7213-4ad5-b5d7-1761bea8b3c9',
										'b30f2a33-2f16-427f-81e2-543ce4313b75',
										'c7b838a9-8ec7-4924-99f2-9d1a6a1fdda6',
									]}
								/>,
								<ActiveTag
									componentKey={componentKey}
									key={'ActiveTag_2'}
									parentTags={[
										'de966aaa-84cb-4627-9841-88fd38500c67',
										'e2f6bbdc-51b7-4d4a-85ba-92e4a4252581',
									]}
								/>,
							],
						},
					],
				}}
				componentsByLayer={[
					{
						layerKey: 'ap04-urban_co2_emissions-a',
						legend: {
							component: StyleBasedLegendByKey,
							props: {
								noData: false,
								applicationStoryKey: 'ap04-urban-co2-emissions',
								showcaseKey: 'App4_ShowcaseA',
								propKey: '8854b8ec-68e5-49d5-8e52-3669c2408ed0',
								parentPropKey: 'de966aaa-84cb-4627-9841-88fd38500c67',
								title: 'Emissions',
								unit: 'CO2',
							},
						},
					},
					{
						layerKey: 'ap04-urban_co2_emissions-a',
						legend: {
							component: StyleBasedLegendByKey,
							props: {
								noData: false,
								applicationStoryKey: 'ap04-urban-co2-emissions',
								showcaseKey: 'App4_ShowcaseA',
								propKey: 'c8b42059-f18d-4728-88da-51a1842949b9',
								parentPropKey: 'de966aaa-84cb-4627-9841-88fd38500c67',
								title: 'Emissions',
								unit: 'CO2',
							},
						},
					},
					{
						layerKey: 'ap04-urban_co2_emissions-a',
						legend: {
							component: StyleBasedLegendByKey,
							props: {
								noData: false,
								applicationStoryKey: 'ap04-urban-co2-emissions',
								showcaseKey: 'App4_ShowcaseA',
								propKey: '18681030-cfdf-4952-8732-da14d1d02f08',
								parentPropKey: 'de966aaa-84cb-4627-9841-88fd38500c67',
								title: 'Emissions',
								unit: 'CO2',
							},
						},
					},
					{
						layerKey: 'ap04-urban_co2_emissions-a',
						legend: {
							component: StyleBasedLegendByKey,
							props: {
								noData: false,
								applicationStoryKey: 'ap04-urban-co2-emissions',
								showcaseKey: 'App4_ShowcaseA',
								propKey: '933e89e4-8e42-4e0f-9f84-b340123024d1',
								parentPropKey: 'de966aaa-84cb-4627-9841-88fd38500c67',
								title: 'Emissions',
								unit: 'CO2',
							},
						},
					},
					{
						layerKey: 'ap04-urban_co2_emissions-a',
						legend: {
							component: StyleBasedLegendByKey,
							props: {
								noData: false,
								applicationStoryKey: 'ap04-urban-co2-emissions',
								showcaseKey: 'App4_ShowcaseA',
								propKey: '503db335-c2bd-45fc-94a8-281875c30a67',
								parentPropKey: 'de966aaa-84cb-4627-9841-88fd38500c67',
								title: 'Emissions',
								unit: 'CO2',
							},
						},
					},
				]}
			/>
		</div>
	);
};

App4_ShowcaseA.propTypes = {
	onActiveFilterChange: PropTypes.func,
	mapSetKey: PropTypes.string,
	activeMapKey: PropTypes.string,
	onUnmount: PropTypes.func,
};

export default App4_ShowcaseA;
