import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {Modal} from '@gisatcz/visat-components';
import './style.scss';
import LocalScaleSurfaceTemperatureDynamics from './LocalScaleSurfaceTemperatureDynamics';
import UrbanFloodRisk from './UrbanFloodRisk';
import UrbanThermalComfort from './UrbanThermalComfort';
import NatureBasedSolutions from './NatureBasedSolutions';

import DisabledAppService from './DisabledAppService';

Modal.setAppElement('#root');
const ModalWindow = Modal.default;

const Services = ({
	onMount,
	onUnmount,
	isOpen,
	onClose,
	openAppSettings,
	setOpenAppSettings,
}) => {
	useEffect(() => {
		if (onMount && typeof onMount === 'function') {
			onMount();
		}
		if (onUnmount && typeof onUnmount === 'function') {
			return onUnmount;
		}
	}, []);

	return (
		<ModalWindow
			className="cure-ManageServices-modalWindow"
			isOpen={isOpen}
			onClose={onClose}
		>
			<div className="cure-ManageServices-modalWindowContent">
				<div className="cure-ManageServices-container">
					<LocalScaleSurfaceTemperatureDynamics
						onClose={onClose}
						scopeKey={'045fb1db-bf01-4b97-a7f7-a1d6c7ab7fc9'}
						openAppSettings={openAppSettings}
						setOpenAppSettings={setOpenAppSettings}
					/>
					<UrbanFloodRisk
						onClose={onClose}
						scopeKey={'21aa4c4b-dcce-4774-a22e-3ab2f7ac46a9'}
						openAppSettings={openAppSettings}
						setOpenAppSettings={setOpenAppSettings}
					/>
					<UrbanThermalComfort
						onClose={onClose}
						scopeKey={'597bb362-884b-4112-b556-09deaf67e484'}
						openAppSettings={openAppSettings}
						setOpenAppSettings={setOpenAppSettings}
					/>
					<NatureBasedSolutions
						onClose={onClose}
						scopeKey={'42592396-f85d-4b31-96c2-7ab539ba3f62'}
						openAppSettings={openAppSettings}
						setOpenAppSettings={setOpenAppSettings}
					/>

					<DisabledAppService name={'Urban CO2 Emissions Monitoring'} />
					<DisabledAppService name={'Urban Air Quality'} />
					<DisabledAppService
						name={'Socioeconomic perspective of Health Impacts'}
					/>
					<DisabledAppService
						name={'Urban Subsidence, Movements and Deformation Risk'}
					/>
					<DisabledAppService name={'Urban Heat Emissions Monitoring'} />
					<DisabledAppService name={'Urban Heat Storage Monitoring'} />
					<DisabledAppService name={'Surface Urban Heat Island Assessment'} />
				</div>
			</div>
		</ModalWindow>
	);
};

Services.propTypes = {
	onMount: PropTypes.func,
	onUnmount: PropTypes.func,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	openAppSettings: PropTypes.object,
	setOpenAppSettings: PropTypes.func,
};

export default Services;
