import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';

import './style.scss';

const Table = ({
	data,
	options,
	isDark,
	responsive,
	highlightOnHover,
	striped,
	pagination,
	fixedHeader,
	conditionalRowStyles,
	selectableRows,
	selectableRowsHighlight,
	defaultSortFieldId,
	customSort,
	rowFontSize,
	rowFontWeight,
	paginationPerPage,
	tableHeight,
}) => {
	const customStyles = {
		tableWrapper: {
			style: {
				display: 'block',
				height: tableHeight,
			},
		},
		head: {
			style: {},
		},
		rows: {
			style: {
				minHeight: '2.8rem',
				fontSize: rowFontSize,
				fontWeight: rowFontWeight,
			},
		},
	};
	const columns = options?.map(item => {
		return {
			name: item.name,
			selector:
				typeof item.selector == 'string'
					? row => row?.[item.selector]
					: item.selector,
			right: item.right,
			minWidth: item.minWidth,
			sortable: item.sortable,
			conditionalCellStyles: item.conditionalCellStyles,
		};
	});
	return (
		<div className="cure-Table-container">
			{columns ? (
				<DataTable
					data={data}
					columns={columns}
					responsive={responsive}
					highlightOnHover={highlightOnHover}
					striped={striped}
					pagination={pagination}
					fixedHeader={fixedHeader}
					customStyles={customStyles}
					theme={isDark ? 'dark' : 'light'}
					conditionalRowStyles={conditionalRowStyles}
					selectableRows={selectableRows}
					selectableRowsHighlight={selectableRowsHighlight}
					sortFunction={customSort}
					defaultSortFieldId={defaultSortFieldId}
					paginationPerPage={paginationPerPage}
				/>
			) : (
				<div />
			)}
		</div>
	);
};

Table.propTypes = {
	data: PropTypes.array,
	options: PropTypes.array,
	isDark: PropTypes.bool,
	responsive: PropTypes.bool,
	highlightOnHover: PropTypes.bool,
	striped: PropTypes.bool,
	pagination: PropTypes.bool,
	fixedHeader: PropTypes.bool,
	conditionalRowStyles: PropTypes.array,
	selectableRows: PropTypes.bool,
	selectableRowsHighlight: PropTypes.bool,
	defaultSortFieldId: PropTypes.any,
	customSort: PropTypes.func,
	rowFontSize: PropTypes.string,
	rowFontWeight: PropTypes.number,
	paginationPerPage: PropTypes.number,
	tableHeight: PropTypes.string,
};

export default Table;
