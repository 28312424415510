import PropTypes from 'prop-types';
import {Button} from '@gisatcz/ptr-atoms';
import {Icon} from '@gisatcz/ptr-atoms';

import {useState} from 'react';
import {isNumber} from 'lodash';

import Select from '../../../../../common/Select';

const defaultDate = '2019-01-02';
const defaultCity = {
	value: 'San Sebastian',
	label: 'San Sebastian',
};

const appStoryKey = 'ap10-nature-based-solutions';

const NatureBasedSolutions = ({
	scope,
	onClose,
	cities,
	onServiceSent,
	openAppSettings,
	setOpenAppSettings,
}) => {
	const [date, setDate] = useState(defaultDate);
	const [city, setCity] = useState(defaultCity);
	const [inclinationThreshold, setInclinationThreshold] = useState(1.5);
	const [weightZoneLst, setWeightZoneLst] = useState(0.4);
	const [weightZoneImperviousness, setWeightZoneImperviousness] = useState(0.3);
	const [weightZoneNdvi, setWeightZoneNdvi] = useState(0.3);

	const [weightBuMaxGreenRoofPotential, setWeightBuMaxGreenRoofPotential] =
		useState(0.5);
	const [weightBuYear, setWeightBuYear] = useState(0.5);

	const [buildingYearDiscard, setBuildingYearDiscard] = useState(1900);
	const [buildingYearLowHighPriority, setBuildingYearLowHighPriority] =
		useState(1962);

	const firstSetFit =
		isNumber(weightZoneLst) &&
		isNumber(weightZoneImperviousness) &&
		isNumber(weightZoneNdvi) &&
		weightZoneLst + weightZoneImperviousness + weightZoneNdvi === 1;

	const secondSetFit =
		isNumber(weightBuMaxGreenRoofPotential) &&
		isNumber(weightBuYear) &&
		weightBuMaxGreenRoofPotential + weightBuYear === 1;

	const fulfill =
		date &&
		city &&
		firstSetFit &&
		secondSetFit &&
		buildingYearDiscard &&
		buildingYearLowHighPriority;

	return (
		<div
			className="cure-ManageServices-apContainer"
			style={
				openAppSettings?.[appStoryKey]
					? {border: '2px solid var(--accent45)'}
					: {border: '2px solid rgb(182, 182, 182)'}
			}
		>
			<div className="cure-ManageServices-apHead">
				<h2 className="cure-ManageServices-apTitle">
					{scope?.data?.nameDisplay}
				</h2>
				<div className="cure-ManageServices-openServiceBtnWrapper">
					<Button
						className="cure-ManageServices-openServiceBtn"
						enabled
						onClick={() => {
							setOpenAppSettings(
								appStoryKey,
								!openAppSettings?.[appStoryKey],
								openAppSettings
							);
						}}
					>
						<div
							className="cure-ManageServices-openServiceIcon"
							style={
								openAppSettings?.[appStoryKey]
									? {transform: 'rotate(180deg)'}
									: {transform: 'rotate(0deg)'}
							}
						>
							<Icon icon={'ri-chevron-down'} />
						</div>
						Setup service
					</Button>
				</div>
			</div>
			<div
				className="cure-ManageServices-apSelects"
				style={
					openAppSettings?.[appStoryKey]
						? {
								overflow: 'visible',
								height: '100%',
								padding: '2rem 0 0 0',
						  }
						: {
								overflow: 'hidden',
								height: '0rem',
								padding: '0rem',
						  }
				}
			>
				<div className="cure-ManageServices-apSelectContainer">
					<h3 className="cure-ManageServices-apSelectTitle">Places</h3>
					<div className="cure-ManageServices-apSelect">
						<Select
							disabled
							small
							options={cities}
							value={city}
							onChange={object => setCity(object)}
							menuPosition={'fixed'}
						/>
					</div>
				</div>
				<div className="cure-ManageServices-apSelectContainer">
					<h3 className="cure-ManageServices-apSelectTitle">Start date</h3>
					<div className="cure-ManageServices-apSelect">
						<input
							type="date"
							value={date}
							min="2018-01-01"
							max="2021-12-31"
							disabled
							onChange={val => {
								setDate(val.target.value);
							}}
						/>
					</div>
				</div>
				<div className="cure-ManageServices-apSelectContainer">
					<label
						className="cure-ManageServices-apSelectTitle"
						htmlFor={'inclinationThreshold'}
					>
						Inclination threshold [1-3]
					</label>
					<input
						name={'inclinationThreshold'}
						type={'number'}
						min="1"
						max="3"
						required
						step={0.1}
						onChange={e => {
							if (e.target.validity.valid) {
								setInclinationThreshold(Number.parseFloat(e.target.value));
							}
						}}
						className={`ptr-input-text`}
						value={inclinationThreshold}
					/>
				</div>
				<div className="cure-ManageServices-apSelectContainer">
					<label
						className="cure-ManageServices-apSelectTitle"
						htmlFor={'weightZoneLst'}
					>
						Weight zone lst [0-1]
					</label>
					<input
						name={'weightZoneLst'}
						type={'number'}
						min="0"
						max="1"
						required
						step={0.1}
						onChange={e => {
							if (e.target.validity.valid) {
								setWeightZoneLst(Number.parseFloat(e.target.value));
							}
						}}
						className={`ptr-input-text`}
						value={weightZoneLst}
					/>
					<label
						className="cure-ManageServices-apSelectTitle"
						htmlFor={'weightZoneImperviousness'}
					>
						Weight zone imperviousness [0-1]
					</label>
					<input
						name={'weightZoneImperviousness'}
						type={'number'}
						min="0"
						max="1"
						required
						step={0.1}
						onChange={e => {
							if (e.target.validity.valid) {
								setWeightZoneImperviousness(Number.parseFloat(e.target.value));
							}
						}}
						className={`ptr-input-text`}
						value={weightZoneImperviousness}
					/>
					<label
						className="cure-ManageServices-apSelectTitle"
						htmlFor={'weightZoneNdvi'}
					>
						Weight zone ndvi [0-1]
					</label>
					<input
						name={'weightZoneNdvi'}
						type={'number'}
						min="0"
						max="1"
						required
						step={0.1}
						onChange={e => {
							if (e.target.validity.valid) {
								setWeightZoneNdvi(Number.parseFloat(e.target.value));
							}
						}}
						className={`ptr-input-text`}
						value={weightZoneNdvi}
					/>
				</div>
				<div className="cure-ManageServices-apSelectContainer cure-ManageServices-note">
					<span>*</span>Sum of values <b>Weight zone lst</b>,{' '}
					<b>Weight zone imperviousness</b>
					{' and '}
					<b>Weight zone ndvi</b> must equals <b>1</b>.
				</div>

				<div className="cure-ManageServices-apSelectContainer">
					<label
						className="cure-ManageServices-apSelectTitle"
						htmlFor={'weightBuMaxGreenRoofPotential'}
					>
						Weight bu max green roof potential [0-1]
					</label>
					<input
						name={'weightBuMaxGreenRoofPotential'}
						type={'number'}
						min="0"
						max="1"
						required
						step={0.1}
						onChange={e => {
							if (e.target.validity.valid) {
								setWeightBuMaxGreenRoofPotential(
									Number.parseFloat(e.target.value)
								);
							}
						}}
						className={`ptr-input-text`}
						value={weightBuMaxGreenRoofPotential}
					/>
					<label
						className="cure-ManageServices-apSelectTitle"
						htmlFor={'weightBuYear'}
					>
						Weight bu year [0-1]
					</label>
					<input
						name={'weightBuYear'}
						type={'number'}
						min="0"
						max="1"
						required
						step={0.1}
						onChange={e => {
							if (e.target.validity.valid) {
								setWeightBuYear(Number.parseFloat(e.target.value));
							}
						}}
						className={`ptr-input-text`}
						value={weightBuYear}
					/>
				</div>
				<div className="cure-ManageServices-apSelectContainer cure-ManageServices-note">
					<span>*</span>Sum of values <b>Weight bu max green roof potential</b>
					{' and '}
					<b>Weight bu year</b> must equals <b>1</b>.
				</div>

				<div className="cure-ManageServices-apSelectContainer">
					<label
						className="cure-ManageServices-apSelectTitle"
						htmlFor={'buildingYearDiscard'}
					>
						Building year discard [1-2022]
					</label>
					<input
						name={'buildingYearDiscard'}
						type={'number'}
						min="1"
						max="2022"
						required
						step={1}
						onChange={e => {
							if (e.target.validity.valid) {
								setBuildingYearDiscard(Number.parseInt(e.target.value));
							}
						}}
						className={`ptr-input-text`}
						value={buildingYearDiscard}
					/>
				</div>

				<div className="cure-ManageServices-apSelectContainer">
					<label
						className="cure-ManageServices-apSelectTitle"
						htmlFor={'buildingYearLowHighPriority'}
					>
						Building year low high priority [1-2022]
					</label>
					<input
						name={'buildingYearLowHighPriority'}
						type={'number'}
						min="1"
						max="2022"
						required
						step={1}
						onChange={e => {
							if (e.target.validity.valid) {
								setBuildingYearLowHighPriority(Number.parseInt(e.target.value));
							}
						}}
						className={`ptr-input-text`}
						value={buildingYearLowHighPriority}
					/>
				</div>

				<div className="cure-ManageServices-apStartBtnWrapper">
					<Button
						className="cure-ManageServices-apStartBtn"
						icon="settings"
						disabled={!fulfill}
						onClick={() => {
							setOpenAppSettings(
								appStoryKey,
								!openAppSettings?.[appStoryKey],
								openAppSettings
							);
							onServiceSent({
								city: city?.label,
								date: date,
								inclination_threshold: inclinationThreshold,
								weight_zone_lst: weightZoneLst,
								weight_zone_imperviousness: weightZoneImperviousness,
								weight_zone_ndvi: weightZoneNdvi,
								weight_bu_max_green_roof_potential:
									weightBuMaxGreenRoofPotential,
								weight_bu_year: weightBuYear,
								building_year_discard: buildingYearDiscard,
								building_year_low_high_priority: buildingYearLowHighPriority,
							});
							onClose(false);
							setCity(undefined);
						}}
					>
						Start processing
					</Button>
				</div>
			</div>
		</div>
	);
};

NatureBasedSolutions.propTypes = {
	cities: PropTypes.array,
	scope: PropTypes.object,
	onServiceSent: PropTypes.func,
	onClose: PropTypes.func,
	openAppSettings: PropTypes.object,
	setOpenAppSettings: PropTypes.func,
};

export default NatureBasedSolutions;
