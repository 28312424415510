import PropTypes from 'prop-types';
import {OpacitySlider} from '@gisatcz/visat-components';
import LayerLabelTool from '../LayerLabelTool';
import './style.scss';

const OpacityControl = ({opacity, onOpacityChange}) => {
	return (
		<LayerLabelTool title="Set opacity" key="opacity">
			<OpacitySlider
				value={opacity >= 0 ? opacity * 100 : 100}
				onChange={onOpacityChange}
			/>
		</LayerLabelTool>
	);
};

OpacityControl.propTypes = {
	layerKey: PropTypes.string,
	mapKey: PropTypes.string,
	opacity: PropTypes.number,
	onOpacityChange: PropTypes.func,
};

export default OpacityControl;
